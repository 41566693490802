import { Badge, List, ListItem } from "framework7-react";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { talkSelector } from "core/slice/talk";
import { formattedTalkDate, initializeTalkList } from "core/usecase/talk";

import { TalkMessageType } from "global/enum";

import { Avatar } from "ui/widget/avatar";
import { NoData } from "ui/widget/no-data";
import { TextEllipsis } from "ui/widget/text-ellipsis";

import { TalkListSkeleton } from "./talk-list-skeleton";

const Container = styled.div({
  ".item-title-row": {
    paddingRight: "0 !important",

    "&::before": {
      display: "none !important",
    },
  },
  ".item-subtitle > div": {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",

    ".badge": {
      marginLeft: "auto",
    },
  },
});

type TalkListProps = {
  isGirlAccess?: boolean;
};

/**
 * TalkList
 * @param props
 * @param props.isGirlAccess
 * @returns TalkList
 */
export const TalkList: React.FC<TalkListProps> = ({ isGirlAccess }) => {
  useEffect(() => {
    initializeTalkList();
  }, []);

  const { initialized, data } = useSelector(talkSelector).talkList;
  const linkPrefix = isGirlAccess ? "/girl" : "";

  return (
    <Container>
      {initialized ? (
        data && data.length > 0 ? (
          <List mediaList>
            {data.map(o => (
              <ListItem
                key={o.talkId}
                link={`${linkPrefix}/talk/${o.talkId}`}
                title={o.talkUserName}
                transition="f7-push"
              >
                <Avatar src={o.talkUserAvatar} size={48} shape="circle" slot="media" />
                <div slot="after">
                  <div>{formattedTalkDate(o.lastSendDtm)}</div>
                </div>
                <div slot="subtitle">
                  {o.lastMessageType === TalkMessageType.text && <TextEllipsis text={o.lastMessage} />}
                  {o.lastMessageType === TalkMessageType.image && (
                    <>
                      [<FormattedMessage id="common.photo" />]
                    </>
                  )}
                  {o.lastMessageType === TalkMessageType.video && (
                    <>
                      [<FormattedMessage id="common.video" />]
                    </>
                  )}
                  {o.unreadCnt > 0 && <Badge color="red">{o.unreadCnt}</Badge>}
                </div>
              </ListItem>
            ))}
          </List>
        ) : (
          <NoData title={<FormattedMessage id="common.nodata" />} />
        )
      ) : (
        <TalkListSkeleton />
      )}
    </Container>
  );
};
