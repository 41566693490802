import classNames from "classnames";
import dayjs from "dayjs";
import { Block, BlockTitle, Link, List, ListItem, Navbar, NavRight, Page, Popup } from "framework7-react";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReserveItem } from "core/model/girl/order";
import { OrderSelector } from "core/slice/girl/order";

import { AreaLabel, AddressLabel } from "global/enum-label";

import { Avatar } from "ui/widget/avatar";
import { FormattedDate } from "ui/widget/formatted-date";

import { OrderItem } from "../order-item";

const Container = styled.div({
  ".item-processing": {
    background: "#f1f6d6",
  },
});

/**
 * OrderHistoryList
 * @returns OrderHistoryList
 */
export const OrderHistoryList: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<ReserveItem>();
  const { data } = useSelector(OrderSelector).reserveList;
  const now = dayjs();
  const thisYear = now.year();

  /**
   * showTitle
   */
  const showTitle = useCallback((item: ReserveItem, prevItem?: ReserveItem) => {
    if (!prevItem) return true;
    return !dayjs(item.orderTime).isSame(dayjs(prevItem.orderTime), "year");
  }, []);

  /**
   * closePopup
   */
  const closePopup = useCallback(() => {
    setSelectedItem(undefined);
  }, []);

  return (
    <Container>
      <List mediaList>
        {data.map((item, index) => (
          <React.Fragment key={item.reserveId}>
            {showTitle(item, data[index - 1]) && (
              <Block className="no-margin-bottom">
                <BlockTitle medium>
                  {dayjs(item.orderTime).year() === thisYear ? "今年" : `${dayjs(item.orderTime).year()}年`}
                </BlockTitle>
              </Block>
            )}
            <ul>
              <ListItem
                className={classNames({
                  "item-processing": now.isBefore(item.meetTime),
                })}
                link
                onClick={() => setSelectedItem(item)}
                after={`${item.hour} 時間`}
              >
                <div slot="media">
                  <Avatar src={item.customerAvatar} shape="circle" size={48} />
                </div>
                <div slot="title" className="text-align-middle">
                  <div>
                    【<FormattedDate value={item.meetTime} format="MM/DD HH:mm" />
                    &nbsp;
                    <FormattedMessage id={AreaLabel[item.area]} />
                    &nbsp;
                    <FormattedMessage id={AddressLabel[item.areaAddr]} />】
                  </div>
                </div>
                <div slot="footer">予約人数(自分を含む): {item.peopleNumber}人</div>
              </ListItem>
            </ul>
          </React.Fragment>
        ))}
      </List>

      <Popup opened={!!selectedItem} onPopupClosed={closePopup} swipeToClose>
        <Page>
          <Navbar title="予約詳細">
            <NavRight>
              <Link href={false} popupClose>
                閉じる
              </Link>
            </NavRight>
          </Navbar>
          {selectedItem && <OrderItem item={selectedItem} useExpand={false} />}
        </Page>
      </Popup>
    </Container>
  );
};
