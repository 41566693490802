import { Navbar, NavRight, Page, PageContent } from "framework7-react";
import React, { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { OrderSelector } from "core/slice/girl/order";
import { getReserveListOfNextPage, initializeReserveList } from "core/usecase/girl/order";

import { OrderHistoryList } from "ui/component/girl/order/order-history/order-history-list";
import { Icon } from "ui/widget/icon";
import { IconButton } from "ui/widget/icon-button";

/**
 * 合流履歴ページ
 * @returns OrderHistoryPage
 */
export const OrderHistoryPage: React.FC = () => {
  const allowInfinite = useRef(true);
  const { initialized, hasMore } = useSelector(OrderSelector).reserveList;

  useEffect(() => {
    initializeReserveList();
  }, []);

  /**
   * handleInfinite
   */
  const handleInfinite = useCallback(() => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    getReserveListOfNextPage().finally(() => {
      allowInfinite.current = true;
    });
  }, []);

  return (
    <Page noToolbar pageContent={false} onPageBeforeIn={initializeReserveList}>
      <Navbar title="合流履歴" backLink backLinkForce backLinkUrl="/girl/order">
        <NavRight>
          <IconButton onClick={() => initializeReserveList(true)} icon={<Icon name="refresh" />} />
        </NavRight>
      </Navbar>
      <PageContent infinite={initialized && hasMore} onInfinite={handleInfinite}>
        <OrderHistoryList />
      </PageContent>
    </Page>
  );
};
