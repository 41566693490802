import {
  Actions,
  ActionsButton,
  ActionsGroup,
  ActionsLabel,
  f7,
  Link,
  MenuDropdown,
  MenuDropdownItem,
  MenuItem,
} from "framework7-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { authorizationSelector } from "core/slice/authorization";
import { logout } from "core/usecase/authorization";

import { Avatar } from "ui/widget/avatar";
import { Divider } from "ui/widget/divider";
import { Icon } from "ui/widget/icon";

const Container = styled.div({
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 16,
  position: "relative",
  paddingLeft: "calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left))",
  paddingRight: "calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-right))",

  ".menu": {
    position: "absolute",
    right: 20,
    top: 0,
  },

  ".avatar-add": {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0.75,
    background: "#ababab",
    borderRadius: "50%",
    width: "100%",
    height: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },

  ".avatar-edit-btn": {
    width: 32,
    height: 32,
    borderRadius: "50%",
    position: "absolute",
    right: -10,
    bottom: 0,
    background: "#09e6e6",
    color: "#fff",
    border: "2px solid #fff",
    boxShadow: "none",
    fontSize: 20,
    cursor: "pointer",
    transition: "background .2s ease-in-out",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  ".avatar-edit-btn:hover, .avatar-edit-btn:active": {
    background: "#08d6d6",
  },

  ".username": {
    fontWeight: "bold",
    fontSize: 16,
    marginTop: 4,
  },
});

/**
 * プロファイルのアバターコンポーネント
 * @returns AvatarSection
 */
export const AvatarSection: React.FC = () => {
  const [logoutActionOpened, setLogoutActionOpened] = useState(false);
  const { principal } = useSelector(authorizationSelector);

  /**
   * ログアウト処理
   */
  const logoutHandler = () => {
    logout();
    // トップ画面へ
    f7.view.main.router.navigate("/girl/login", { transition: "f7-cover-v", clearPreviousHistory: true });
  };

  return (
    <Container>
      <MenuItem className="menu" iconMaterial="menu" dropdown bgColor="primary">
        <MenuDropdown right>
          {/* <MenuDropdownItem href="#">
            <span>ユーザネーム変更&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <Icon className="margin-left" name="person" shape="outlined" />
          </MenuDropdownItem>
          <MenuDropdownItem divider /> */}
          <MenuDropdownItem href="/girl/present-log" style={{ minWidth: 200 }}>
            <span>プレゼント履歴</span>
            <Icon className="margin-left" name="redeem" />
          </MenuDropdownItem>
          <MenuDropdownItem divider />
          <MenuDropdownItem href="/girl/password-change" style={{ minWidth: 200 }}>
            <span>パスワード変更</span>
            <Icon className="margin-left" name="lock" shape="outlined" />
          </MenuDropdownItem>
          <MenuDropdownItem divider />
          <MenuDropdownItem href="#" onClick={() => setLogoutActionOpened(true)}>
            <span>ログアウト</span>
            <Icon className="margin-left" name="logout" />
          </MenuDropdownItem>
        </MenuDropdown>
      </MenuItem>
      <Actions opened={logoutActionOpened} onActionsClosed={() => setLogoutActionOpened(false)}>
        <ActionsGroup>
          <ActionsLabel>ログアウトしても宜しいでしょうか</ActionsLabel>
          <ActionsButton onClick={logoutHandler}>ログアウト</ActionsButton>
        </ActionsGroup>
        <ActionsGroup>
          <ActionsButton color="red">
            <Link href={false} actionsClose>
              キャンセル
            </Link>
          </ActionsButton>
        </ActionsGroup>
      </Actions>

      <Avatar src={principal?.avatar} size={100} shape="circle">
        {!principal?.avatar && (
          <div className="avatar-add">
            <Icon name="photo_camera" size={42} />
            <span>Add</span>
          </div>
        )}
        <div
          className="avatar-edit-btn"
          onClick={() => {
            f7.view.main.router.navigate("/girl/profile/avatar", { transition: "f7-cover-v" });
          }}
        >
          {principal?.avatar ? <Icon name="edit" shape="outlined" size={18} /> : <Icon name="add" />}
        </div>
      </Avatar>
      <div className="username">@{principal?.username ?? ""}</div>
      <Divider style={{ margin: "8px 0" }} />
    </Container>
  );
};
