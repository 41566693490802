import { Page } from "framework7-react";
import React, { useCallback } from "react";
import { PropsWithF7Router } from "routes";

import { ForgotPasswordRequest } from "core/model/forgot-password";
import { passwordResetProcessStart } from "core/usecase/forgot-password";

import { Role } from "global/enum";

import { ForgotPasswordForm } from "ui/component/forgot-password/form";

/**
 * パスワードお忘れ画面
 * @param props
 * @param props.f7router
 * @returns ForgotPassword
 */
export const ForgotPasswordPage: React.FC<PropsWithF7Router> = ({ f7router }) => {
  /**
   * handleSubmit
   */
  const handleSubmit = useCallback(
    (values: ForgotPasswordRequest) => {
      passwordResetProcessStart(values.email, Role.site).then(success => {
        if (success) {
          f7router.navigate("/forgot-password/send-complete", {
            props: {
              email: values.email,
            },
          });
        }
      });
    },
    [f7router],
  );

  return (
    <Page noNavbar noToolbar loginScreen>
      <ForgotPasswordForm onSubmit={handleSubmit} loginLink="/login" />
    </Page>
  );
};
