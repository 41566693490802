import { Page } from "framework7-react";
import React from "react";

import { Role } from "global/enum";

import { UrlExpiredBlock } from "ui/component/forgot-password/url-expired";

/**
 * 有効期限切れページ
 * @returns UrlExpired
 */
export const UrlExpiredPage: React.FC = () => (
  <Page noNavbar noToolbar>
    <UrlExpiredBlock role={Role.site} forgotPasswordLink="/forgot-password" />
  </Page>
);
