import { Button, Block, Page } from "framework7-react";

import { AvatarSection } from "ui/component/site/profile/avatar-section";
import { TabsSection } from "ui/component/site/profile/tabs-section";
import { Icon } from "ui/widget/icon";

/**
 * ProfilePage
 * @returns ProfilePage
 */
export const ProfilePage: React.FC = () => (
  <Page noNavbar>
    <AvatarSection />
    {/* <FollowSection /> */}
    <Block style={{ margin: "12px 0", display: "flex", justifyContent: "center" }}>
      <Button outline color="blue" href="/profile/base" transition="f7-push" style={{ width: "50%" }}>
        <Icon name="border_color" shape="outlined" size={18} />
        &nbsp;编辑资料
      </Button>
    </Block>
    <TabsSection />
  </Page>
);
