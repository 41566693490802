import { Block, BlockTitle, Link } from "framework7-react";
import React from "react";
import { useSelector } from "react-redux";

import { OrderSelector } from "core/slice/girl/order";

import { Icon } from "ui/widget/icon";

import { OrderItem } from "./order-item";

/**
 * LatestOrder
 * @returns LatestOrder
 */
export const LatestOrder: React.FC = () => {
  const { data } = useSelector(OrderSelector).latestReserveList;

  return (
    <>
      <BlockTitle style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <span>■直近の合流予定</span>
        <Link href="/girl/order/history" transition="f7-push">
          <Icon name="format_list_bulleted" size={18} />
          &nbsp;合流履歴&nbsp;&gt;
        </Link>
      </BlockTitle>
      {data.map(item => (
        <OrderItem key={item.reserveId} item={item} />
      ))}
      {data.length === 0 && (
        <Block inset strong bgColor="info" textColor="info" borderColor="info" className="text-align-middle bordered">
          <Icon name="info" />
          <strong style={{ marginLeft: 8 }}>現在予約なし</strong>
        </Block>
      )}
    </>
  );
};
