import { Block, BlockTitle, Link, List, ListItem, Navbar, Page } from "framework7-react";
import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { authorizationSelector } from "core/slice/authorization";
import { profileSelector } from "core/slice/girl/profile";
import { profileInitialize, updateProfile } from "core/usecase/girl/profile";

import { SMART_SELECT_DEFAULT_PARAMS } from "global/constant";
import { Education, HairColor, HairType, Hidden, Job, Sake, Tobacco } from "global/enum";
import { EducationLabel, HairColorLabel, HairTypeLabel, JobLabel, SakeLabel, TobaccoLabel } from "global/enum-label";
import { BloodLabel, SexLabel } from "global/enum-label/girl";

import { SexSelector, BirthdaySelector, BloodSelector } from "ui/component/girl/profile/base-editor";
import { Avatar } from "ui/widget/avatar";
import { FormattedDate } from "ui/widget/formatted-date";
import { TextEllipsis } from "ui/widget/text-ellipsis";

/**
 * プロファイル編集画面
 * @returns ProfileBaseEditor
 */
export const ProfileBaseEditorPage: React.FC = () => {
  const profile = useSelector(profileSelector);
  const { principal } = useSelector(authorizationSelector);
  // const sns = useSelector(snsSelector);

  // selectors
  const [sexSelectorVisible, setSexSelectorVisible] = useState(false);
  const [birthSelectorVisible, setBirthSelectorVisible] = useState(false);
  const [bloodSelectorVisible, setBloodSelectorVisible] = useState(false);

  // 画面表示用姓名
  const nameDisp = useMemo(() => {
    const kanji = `${profile.lastNmKanji || ""} ${profile.firstNmKanji || ""}`.trim();
    const kana = `${profile.lastNmKana || ""} ${profile.firstNmKana || ""}`.trim();
    if (kanji && kana) return `${kanji} / ${kana}`;
    if (kanji) return kanji;
    return kana || "未設定";
  }, [profile.lastNmKanji, profile.firstNmKanji, profile.lastNmKana, profile.firstNmKana]);

  return (
    <Page onPageBeforeIn={profileInitialize}>
      <Navbar backLink title="プロファイルを編集" />
      <Block style={{ textAlign: "center" }}>
        <Link href="/girl/profile/avatar" transition="f7-cover-v">
          <Avatar src={principal?.avatar} size={80} shape="circle" />
        </Link>
        <BlockTitle style={{ marginTop: 4 }}>写真を変更</BlockTitle>
      </Block>
      <BlockTitle>ユーザ情報</BlockTitle>
      <List>
        <ListItem
          link="/girl/profile/base/nick-name"
          transition="f7-push"
          title="ニックネーム"
          after={profile.nickName || "未設定"}
        />
        <ListItem link="/girl/profile/base/name" transition="f7-push" title="姓名" after={nameDisp} />
        <ListItem
          link
          title="性別"
          onClick={() => setSexSelectorVisible(true)}
          after={profile.sex ? SexLabel[profile.sex] : "選択してください"}
        />
        <ListItem link title="生年月日" onClick={() => setBirthSelectorVisible(true)}>
          <div slot="after">
            {profile.birthHidden === Hidden.hide ? (
              "非表示"
            ) : (
              <FormattedDate defaultValue="選択してください" value={profile.birth} />
            )}
          </div>
        </ListItem>
        <ListItem
          link
          title="血液型"
          after={profile.bloodType ? BloodLabel[profile.bloodType] : "選択してください"}
          onClick={() => setBloodSelectorVisible(true)}
        />
        <ListItem
          link="/girl/profile/base/height"
          transition="f7-push"
          title="身長(cm)"
          after={profile.height || "未設定"}
        />
        <ListItem link title="髪型" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
          <div slot="after">
            {profile.hairType ? <FormattedMessage id={HairTypeLabel[profile.hairType]} /> : "選択してください"}
          </div>
          <select
            name="hairType"
            value={profile.hairType}
            onChange={e => updateProfile("/profile/hair-type/s", { hairType: e.target.value as HairType })}
          >
            {Object.entries(HairTypeLabel).map(([value, label]) => (
              <option value={value} key={value}>
                <FormattedMessage id={label} />
              </option>
            ))}
          </select>
        </ListItem>
        <ListItem link title="髪の色" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
          <div slot="after">
            {profile.hairColor ? <FormattedMessage id={HairColorLabel[profile.hairColor]} /> : "選択してください"}
          </div>
          <select
            name="hairColor"
            value={profile.hairColor}
            onChange={e => updateProfile("/profile/hair-color/s", { hairColor: e.target.value as HairColor })}
          >
            {Object.entries(HairColorLabel).map(([value, label]) => (
              <option value={value} key={value}>
                <FormattedMessage id={label} />
              </option>
            ))}
          </select>
        </ListItem>
      </List>
      <List>
        <ListItem
          link="/girl/profile/base/birthplace"
          transition="f7-push"
          title="出身地"
          after={profile.birthplace || "未設定"}
        />
        <ListItem
          link="/girl/profile/base/address"
          transition="f7-push"
          title="居住地"
          after={profile.address || "未設定"}
        />
        <ListItem
          link
          title="お仕事"
          smartSelect
          smartSelectParams={{
            openIn: "popup",
            setValueText: false,
            closeOnSelect: true,
          }}
        >
          <div slot="after">{profile.job ? <FormattedMessage id={JobLabel[profile.job]} /> : "選択してください"}</div>
          <select
            name="job"
            value={profile.job}
            onChange={e => updateProfile("/profile/job/s", { job: e.target.value as Job })}
          >
            {Object.entries(JobLabel).map(([value, label]) => (
              <option value={value} key={value}>
                <FormattedMessage id={label} />
              </option>
            ))}
          </select>
        </ListItem>
        <ListItem link title="学歴" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
          <div slot="after">
            {profile.educationBk ? <FormattedMessage id={EducationLabel[profile.educationBk]} /> : "選択してください"}
          </div>
          <select
            name="educationBk"
            value={profile.educationBk}
            onChange={e => updateProfile("/profile/education-bk/s", { educationBk: e.target.value as Education })}
          >
            {Object.entries(EducationLabel).map(([value, label]) => (
              <option value={value} key={value}>
                <FormattedMessage id={label} />
              </option>
            ))}
          </select>
        </ListItem>
        <ListItem link title="お酒" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
          <div slot="after">
            {profile.sake ? <FormattedMessage id={SakeLabel[profile.sake]} /> : "選択してください"}
          </div>
          <select
            name="sake"
            value={profile.sake}
            onChange={e => updateProfile("/profile/sake/s", { sake: e.target.value as Sake })}
          >
            {Object.entries(SakeLabel).map(([value, label]) => (
              <option value={value} key={value}>
                <FormattedMessage id={label} />
              </option>
            ))}
          </select>
        </ListItem>
        <ListItem link title="タバコ" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
          <div slot="after">
            {profile.tobacco ? <FormattedMessage id={TobaccoLabel[profile.tobacco]} /> : "選択してください"}
          </div>
          <select
            name="tobacco"
            value={profile.tobacco}
            onChange={e => updateProfile("/profile/tobacco/s", { tobacco: e.target.value as Tobacco })}
          >
            {Object.entries(TobaccoLabel).map(([value, label]) => (
              <option value={value} key={value}>
                <FormattedMessage id={label} />
              </option>
            ))}
          </select>
        </ListItem>
      </List>
      <List>
        <ListItem link="/girl/profile/base/interest" transition="f7-push" title="趣味">
          <TextEllipsis slot="after" limit={15} text={profile.interest || "趣味を追加"} />
        </ListItem>
        <ListItem link="/girl/profile/base/title" transition="f7-push" title="タイトル">
          <TextEllipsis slot="after" limit={15} text={profile.title || "タイトルを追加"} />
        </ListItem>
        <ListItem link="/girl/profile/base/note" transition="f7-push" title="自己紹介">
          <TextEllipsis slot="after" limit={15} text={profile.note || "自己紹介を追加"} />
        </ListItem>
      </List>

      {/* <BlockTitle>SNS</BlockTitle>
      <List>
        <ListItem link title="Facebook" after={sns.facebook || "Facebookを追加"} />
        <ListItem link title="Instagram" after={sns.instagram || "Instagramを追加"} />
        <ListItem link title="Twitter" after={sns.twitter || "Twitterを追加"} />
        <ListItem link title="Youtube" after={sns.youtube || "Youtubeを追加"} />
        <ListItem link title="Tiktok" after={sns.tiktok || "Tiktokを追加"} />
        <ListItem link title="Line" after={sns.line || "Lineを追加"} />
      </List> */}

      <SexSelector visible={sexSelectorVisible} onClose={() => setSexSelectorVisible(false)} />
      <BirthdaySelector visible={birthSelectorVisible} onClose={() => setBirthSelectorVisible(false)} />
      <BloodSelector visible={bloodSelectorVisible} onClose={() => setBloodSelectorVisible(false)} />
    </Page>
  );
};
