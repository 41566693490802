import emptySvg from "assets/image/empty.svg";
import { BlockTitle } from "framework7-react";
import React from "react";

type NoDataProps = {
  title?: React.ReactNode;
  detail?: React.ReactNode;
};

/**
 * NoData
 * @param props
 * @param props.title
 * @param props.detail
 * @returns NoData
 */
export const NoData: React.FC<NoDataProps> = ({ title, detail }) => (
  <div className="text-align-center margin-top">
    <img src={emptySvg} alt="no data" style={{ maxWidth: 260 }} />
    {title && (
      <BlockTitle className="no-margin">
        <h2>{title}</h2>
      </BlockTitle>
    )}
    {detail && <p>{detail}</p>}
  </div>
);
