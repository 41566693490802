import { MessageKey } from "i18n";

import { ReserveStatus, ReserveType } from "global/enum/reserve";

/** 合流時間予定 */
export const ReserveTypeLabel: Record<ReserveType, MessageKey> = {
  [ReserveType.halfHourLater]: "enum.reserve.type.half.hour.later",
  [ReserveType.oneHourLater]: "enum.reserve.type.one.hour.later",
  [ReserveType.oneAndHalfHourLater]: "enum.reserve.type.one.and.half.hour.later",
  [ReserveType.other]: "enum.reserve.type.other",
};

/** 予約ステータス */
export const ReserveStatusLabel: Record<ReserveStatus, MessageKey> = {
  [ReserveStatus.PROCESSING]: "enum.reserve.status.processing",
  [ReserveStatus.SUCCESS]: "enum.reserve.status.success",
  [ReserveStatus.FAIL]: "enum.reserve.status.fail",
  [ReserveStatus.CANCEL]: "enum.reserve.status.cancel",
  [ReserveStatus.DELETE]: "enum.reserve.status.delete",
};
