import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import {
  LatestReserveResponse,
  ListReserveResponse,
  ListReserveState,
  ReserveItem,
  ReserveState,
} from "core/model/site/order";
import { GirlItem } from "core/model/site/top";

import { CommonConfig } from "global/config";
import { Address, Area, Ranking } from "global/enum";
import { ReserveType } from "global/enum/reserve";

/**
 * state
 */
export type SiteOrderState = {
  /** 予約一覧 */
  reserveList: {
    /** 初期化済 */
    initialized: boolean;
    /** page */
    page: number;
    /** data */
    data: ListReserveState;
    /** hasMore */
    hasMore: boolean;
  };
  /** 直近の予約一覧 */
  latestReserveList: {
    /** 初期化済 */
    initialized: boolean;
    /** 直近の予約一覧 */
    latestList: Array<ReserveItem>;
    /** 直近の予約一覧 */
    processingList: Array<ReserveItem>;
    /** 有効期限切れの予約一覧 */
    expiredList: Array<ReserveItem>;
  };
  /** 予約登録 */
  reserve: ReserveState;
};

const initialState: SiteOrderState = {
  reserveList: {
    initialized: false,
    page: 1,
    data: [],
    hasMore: true,
  },
  latestReserveList: {
    initialized: false,
    latestList: [],
    processingList: [],
    expiredList: [],
  },
  reserve: {
    type: ReserveType.halfHourLater,
    area: Area.Tokyo,
    areaAddr: Address.Roppongi,
    hour: 1,
    casts: [
      {
        ranking: Ranking.vip,
        peopleNumber: 1,
      },
    ],
  },
};

/**
 * slice
 */
export const siteOrderSlice = createSlice({
  name: "site/order",
  initialState,
  reducers: {
    /**
     * 予約一覧初期化
     * @param state
     * @param action
     */
    initializeReserveListAction: (state, action: PayloadAction<ListReserveResponse>) => {
      state.reserveList.initialized = true;
      state.reserveList.data = action.payload;
      const hasMore = action.payload.length >= CommonConfig.apiPageSize;
      state.reserveList.hasMore = hasMore;
      if (hasMore) {
        state.reserveList.page += 1;
      }
    },
    /**
     * 次のページの予約一覧を追加する
     * @param state
     * @param action
     */
    appendReserveListAction: (state, action: PayloadAction<ListReserveResponse>) => {
      state.reserveList.data = [...state.reserveList.data, ...action.payload];
      const hasMore = action.payload.length >= CommonConfig.apiPageSize;
      state.reserveList.hasMore = hasMore;
      if (hasMore) {
        state.reserveList.page += 1;
      }
    },
    /**
     * 直近の予約一覧初期化
     * @param state
     * @param action
     */
    initializeLatestReserveList: (state, action: PayloadAction<LatestReserveResponse>) => {
      state.latestReserveList.initialized = true;
      // 直近の予約成功一覧
      state.latestReserveList.latestList = action.payload.latestList;
      // 処理中の予約一覧
      state.latestReserveList.processingList = action.payload.processingList;
      // 有効期限切れ予約一覧
      state.latestReserveList.expiredList = action.payload.expiredList;
    },
    /**
     * 予約Itemの追加
     * @param state
     * @param action
     */
    appendReserveItemAction: (state, action: PayloadAction<ReserveItem>) => {
      // 予約一覧にレコードを追加する
      if (state.reserveList.initialized) {
        state.reserveList.data = [action.payload, ...state.reserveList.data];
      }
      // 処理中の予約一覧にレコードを追加する
      if (state.latestReserveList.initialized) {
        if (state.latestReserveList.processingList) {
          const insertIndex = state.latestReserveList.processingList.findIndex(
            o => o.meetTime <= action.payload.meetTime,
          );
          if (insertIndex === -1) {
            state.latestReserveList.processingList.push(action.payload);
          } else {
            state.latestReserveList.processingList.splice(insertIndex, 0, action.payload);
          }
        } else {
          state.latestReserveList.processingList = [action.payload];
        }
      }
    },
    /**
     * 予約削除
     * @param state
     * @param action
     */
    deleteReserveAction: (state, action: PayloadAction<{ reserveId: string }>) => {
      const { reserveId } = action.payload;
      // 予約一覧から該当レコードを削除する
      if (state.reserveList.initialized) {
        const index = state.reserveList.data.findIndex(o => o.reserveId === reserveId);
        if (index !== -1) {
          state.reserveList.data.splice(index, 1);
        }
      }
      // 処理中の予約一覧にレコードを追加する
      if (state.latestReserveList.initialized) {
        // 有効期限きれ予約一覧からレコードを削除する
        let index = state.latestReserveList.expiredList.findIndex(o => o.reserveId === reserveId);
        if (index !== -1) {
          state.latestReserveList.expiredList.splice(index, 1);
          return;
        }
        // 処理中予約一覧からレコードを削除する
        index = state.latestReserveList.processingList.findIndex(o => o.reserveId === reserveId);
        if (index !== -1) {
          state.latestReserveList.processingList.splice(index, 1);
          return;
        }
        // 直近の予約一覧からレコードを削除する
        index = state.latestReserveList.latestList.findIndex(o => o.reserveId === reserveId);
        if (index !== -1) {
          state.latestReserveList.latestList.splice(index, 1);
        }
      }
    },
    /**
     * 予約データ更新
     * @param state
     * @param action
     */
    updateReserveAction: (state, action: PayloadAction<Partial<ReserveState>>) => {
      state.reserve = {
        ...state.reserve,
        ...action.payload,
      };
    },
    /**
     * 予約データクリア
     * @param state
     */
    clearReserveAction: state => {
      state.reserve = initialState.reserve;
    },
    /**
     * selectCastAction
     * @param state
     * @param action
     */
    toggleSelectCastAction: (state, action: PayloadAction<GirlItem>) => {
      const { ranking } = action.payload;
      const index = state.reserve.casts.findIndex(o => o.ranking === ranking);
      if (index === -1) return;
      // 選択されなかった場合、新規追加
      if (!state.reserve.casts[index].selected) {
        state.reserve.casts[index].selected = [action.payload];
      } else {
        const { userId } = action.payload;
        const userIdIndex = state.reserve.casts[index].selected!.findIndex(o => o.userId === userId);
        // 選択されたユーザIDが存在しない場合、新規追加
        if (userIdIndex === -1) {
          // 予約人数を超える場合、スキップ
          if (state.reserve.casts[index].selected!.length + 1 > state.reserve.casts[index].peopleNumber) return;
          state.reserve.casts[index].selected!.push(action.payload);
        } else {
          // 選択されたユーザIDが存在する場合、削除
          state.reserve.casts[index].selected!.splice(userIdIndex, 1);
        }
      }
    },
  },
});

/**
 * action
 */
export const siteOrderAction = siteOrderSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const siteOrderSelector = (state: RootState) => state["site/order"];
