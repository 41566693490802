import { Block, BlockTitle, Button, List, ListItem } from "framework7-react";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { scheduleSelector } from "core/slice/girl/schedule";
import { saveAttendance } from "core/usecase/girl/schedule";
import { toast } from "core/utils/common";

import { SMART_SELECT_DEFAULT_PARAMS } from "global/constant";
import { Address, Area } from "global/enum";
import { AddressLabel, AreaLabel } from "global/enum-label";

import { Icon } from "ui/widget/icon";

/**
 * FormSection
 * @returns FormSection
 */
export const FormSection: React.FC = () => {
  const { attendance } = useSelector(scheduleSelector);
  const [localAttendance, setLocaleAttendance] = useState(attendance ?? {});
  const { formatMessage } = useIntl();

  useEffect(() => {
    setLocaleAttendance(attendance ?? {});
  }, [attendance]);

  /**
   * エリア変更処理
   * @param e
   */
  const onAreaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    setLocaleAttendance(prev => ({
      ...prev,
      area: value as Area,
    }));
  };

  /**
   * 場所変更処理
   * @param e
   */
  const onAddressChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const values = Array.from(e.target.selectedOptions, option => option.value);
    setLocaleAttendance(prev => ({
      ...prev,
      address: values as Address[],
    }));
  };

  /**
   * 出勤繰返しクリック処理
   * @param value
   */
  const onrepeatsClick = (value: number) => {
    setLocaleAttendance(prev => {
      const next = { ...prev };
      if (!next.repeats) {
        next.repeats = [value];
      } else {
        const newrepeats = [...next.repeats];
        const index = newrepeats.indexOf(value);
        if (index === -1) {
          newrepeats.push(value);
        } else {
          newrepeats.splice(index, 1);
        }
        next.repeats = newrepeats;
      }
      return next;
    });
  };

  /**
   * 保存処理
   */
  const handleSave = () => {
    saveAttendance(localAttendance).then(success => {
      if (success) toast("保存成功しました");
    });
  };

  return (
    <>
      <BlockTitle>出勤場所設定</BlockTitle>
      <List inset>
        <ListItem link title="都道府県" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
          <div slot="after">
            {localAttendance.area ? <FormattedMessage id={AreaLabel[localAttendance.area]} /> : <span>任意</span>}
          </div>
          <select name="area" value={localAttendance.area} onChange={onAreaChange}>
            <option value="">任意</option>
            {Object.entries(AreaLabel).map(([value, label]) => (
              <option value={value} key={value}>
                <FormattedMessage id={label} />
              </option>
            ))}
          </select>
        </ListItem>
        <ListItem link title="場所" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
          <div slot="after">
            {localAttendance.address && localAttendance.address.length > 0 ? (
              localAttendance.address.map(addr => formatMessage({ id: AddressLabel[addr] })).join(", ")
            ) : (
              <span>任意</span>
            )}
          </div>
          <select multiple name="address" value={localAttendance.address} onChange={onAddressChange}>
            {Object.entries(AddressLabel).map(([value, label]) => (
              <option value={value} key={value}>
                <FormattedMessage id={label} />
              </option>
            ))}
          </select>
        </ListItem>
      </List>

      <BlockTitle>出勤繰り返し設定</BlockTitle>
      <List inset>
        <ListItem noChevron link title="毎日曜日" onClick={() => onrepeatsClick(0)}>
          {localAttendance?.repeats?.includes(0) && (
            <div slot="after">
              <Icon name="check" color="orange" />
            </div>
          )}
        </ListItem>
        <ListItem noChevron link title="毎月曜日" onClick={() => onrepeatsClick(1)}>
          {localAttendance?.repeats?.includes(1) && (
            <div slot="after">
              <Icon name="check" color="orange" />
            </div>
          )}
        </ListItem>
        <ListItem noChevron link title="毎火曜日" onClick={() => onrepeatsClick(2)}>
          {localAttendance?.repeats?.includes(2) && (
            <div slot="after">
              <Icon name="check" color="orange" />
            </div>
          )}
        </ListItem>
        <ListItem noChevron link title="毎水曜日" onClick={() => onrepeatsClick(3)}>
          {localAttendance?.repeats?.includes(3) && (
            <div slot="after">
              <Icon name="check" color="orange" />
            </div>
          )}
        </ListItem>
        <ListItem noChevron link title="毎木曜日" onClick={() => onrepeatsClick(4)}>
          {localAttendance?.repeats?.includes(4) && (
            <div slot="after">
              <Icon name="check" color="orange" />
            </div>
          )}
        </ListItem>
        <ListItem noChevron link title="毎金曜日" onClick={() => onrepeatsClick(5)}>
          {localAttendance?.repeats?.includes(5) && (
            <div slot="after">
              <Icon name="check" color="orange" />
            </div>
          )}
        </ListItem>
        <ListItem noChevron link title="毎土曜日" onClick={() => onrepeatsClick(6)}>
          {localAttendance?.repeats?.includes(6) && (
            <div slot="after">
              <Icon name="check" color="orange" />
            </div>
          )}
        </ListItem>
      </List>

      <Block>
        <Button fill color="orange" onClick={handleSave}>
          <Icon name="done_outline" size={18} />
          &nbsp;保存
        </Button>
      </Block>
    </>
  );
};
