import { CustomDateLocale, CustomStringLocale, LocaleObject } from "yup";

const mixed: Required<LocaleObject["mixed"]> = {
  default: "正しい値を入力してください",
  required: "必ず入力してください",
  defined: "undefined以外の値にしてください",
  notNull: "null以外の値にしてください",
  oneOf: "${values}のいずれかを入力してください",
  notOneOf: "${values}以外の値を入力してください",
  notType: "'${type}'タイプである必要になります",
};

const string: Required<LocaleObject["string"]> = {
  length: "${length}文字で入力してください",
  min: "${min}文字以上で入力してください",
  max: "${max}文字以内で入力してください",
  matches: "以下の形式で入力してください: ${regex}",
  email: "正しいメールアドレス形式で入力してください",
  url: "正しいURL形式で入力してください",
  uuid: "正しいUUID形式で入力してください",
  trim: "前後の空白を取り除いてください",
  lowercase: "小文字のみ入力してください",
  uppercase: "大文字のみ入力してください",
};

const number: Required<LocaleObject["number"]> = {
  min: "${min}以上にしてください",
  max: "${max}以下にしてください",
  lessThan: "${less}未満にしてください",
  moreThan: "${more}より大きい数にしてください",
  positive: "正の数にしてください",
  negative: "負の数にしてください",
  integer: "整数を入力してください",
};

const date: Required<LocaleObject["date"]> = {
  min: "${min}以降の日付を入力してください",
  max: "${max}より前の日付を入力してください",
};

const boolean: Required<LocaleObject["boolean"]> = {
  isValue: "${value}にしてください",
};

const object: Required<LocaleObject["object"]> = {
  noUnknown: "未定義のキーが見つかりました: ${value}",
};

const array: Required<LocaleObject["array"]> = {
  min: "配列には${min}つ以上のアイテムが必要になります",
  max: "配列には${max}つ以下のアイテムが必要になります",
  length: "配列には${length}つアイテムが必要になります",
};

/**
 * カスとマイズ[string]メッセージ
 */
export const customStringLocale: CustomStringLocale = {
  selectRequired: "必ず選択してください",
  lengthBetween: "${min}~${max}文字で入力してください",
  katakana: "全角カタカナで入力してください",
  hiragana: "全角ひらがなで入力してください",
  zenkaku: "全角文字で入力してください",
  hankaku: "半角文字で入力してください",
  number: "半角数字で入力してください",
  decimal: "整数または小数で入力してください",
  date: "${format}の形式で入力してください",
  pastDate: "現在日時より前で入力してください",
  futureDate: "現在日時以降で入力してください",
  alpha: "半角英字で入力してください",
  alphaNumber: "半角英数字で入力してください",
  alphaNumberSymbol: "半角英数記号で入力してください",
  alphaSymbol: "半角英字記号で入力してください",
  numberSymbol: "半角数字記号で入力してください",
  symbol: "半角記号で入力してください",
  tel: "電話番号を正しい形式で入力してください",
  postal: "郵便番号を正しい形式で入力してください",
  equalWith: "${reference}と一致の値を入力してください。",
};

export const customDateLocale: CustomDateLocale = {
  pastDate: "現在日時より前で入力してください",
  futureDate: "現在日時以降で入力してください",
};

export const locale: Required<LocaleObject> = {
  mixed,
  string,
  number,
  date,
  boolean,
  object,
  array,
};
