import { Router } from "framework7/modules/router/router";
import Cookies from "js-cookie";

import { tokenLogin } from "core/usecase/authorization";
import { changeLanguage } from "core/usecase/global";

import { CommonConfig } from "global/config";
import { Role, SupportedLanguage } from "global/enum";

type AccessControlType = {
  url: string | RegExp;
  role?: Role;
};

const AccessControlUrls: Array<AccessControlType> = [
  {
    url: /^\/girl\/(profile|order|talk)/,
    role: Role.girl,
  },
  {
    url: "/girl/password-change",
    role: Role.girl,
  },
  {
    url: /^\/(top|detail|talk|order|timeline|shop|profile)/,
    role: Role.site,
  },
];

/**
 * 認証処理
 * @param ctx
 */
export const authorization = (ctx: Router.RouteCallbackCtx) => {
  const { router, to, resolve, reject } = ctx;

  // "/"の場合、topへリダイレクトする
  if (to.path === "/") {
    reject();
    router.navigate("/top", { clearPreviousHistory: true });
    return;
  }

  const isGirlAccess = to.path.startsWith("/girl/");

  // girl専用画面の場合、日本語にする
  // 上記以外、中国語にする
  if (isGirlAccess) {
    changeLanguage(SupportedLanguage.japanese);
  } else {
    changeLanguage(SupportedLanguage.chinese);
  }

  // URL prefixが[AccessControlUrls]以外の場合、認証不要
  const controlled = AccessControlUrls.find(o => (o.url instanceof RegExp ? o.url.test(to.path) : o.url === to.path));
  if (!controlled) {
    resolve();
    return;
  }

  // リフレッシュトークン
  const refreshToken = Cookies.get(CommonConfig.refreshTokenName);

  // 認証チェック
  if (!refreshToken) {
    reject();
    router.navigate(isGirlAccess ? "/girl/login" : "/login", { clearPreviousHistory: true });
    return;
  }

  // 認証情報再取得
  tokenLogin().then(role => {
    if (role && controlled.role && controlled.role !== role) {
      reject();
      router.navigate(role === Role.girl ? "/girl/access-denied" : "/access-denied", { clearPreviousHistory: true });
    } else {
      resolve();
    }
  });
};
