import { combineReducers } from "@reduxjs/toolkit";

import { authorizationSlice } from "core/slice/authorization";
import { bankSlice } from "core/slice/girl/bank";
import { followSlice } from "core/slice/girl/follow";
import { mediaSlice } from "core/slice/girl/media";
import { OrderSlice } from "core/slice/girl/order";
import { profileSlice } from "core/slice/girl/profile";
import { scheduleSlice } from "core/slice/girl/schedule";
import { snsSlice } from "core/slice/girl/sns";
import { globalSlice } from "core/slice/global";
import { siteFollowSlice } from "core/slice/site/follow";
import { siteOrderSlice } from "core/slice/site/order";
import { siteProfileSlice } from "core/slice/site/profile";
import { siteProfileChargeSlice } from "core/slice/site/profile/charge";
import { siteProfileWalletSlice } from "core/slice/site/profile/wallet";
import { siteShopSlice } from "core/slice/site/shop";
import { siteTimelineSlice } from "core/slice/site/timeline";
import { siteTopSlice } from "core/slice/site/top";
import { talkSlice } from "core/slice/talk";

export const combineReducer = combineReducers({
  // common
  [globalSlice.name]: globalSlice.reducer,
  [authorizationSlice.name]: authorizationSlice.reducer,
  [talkSlice.name]: talkSlice.reducer,

  //  girl
  [bankSlice.name]: bankSlice.reducer,
  [followSlice.name]: followSlice.reducer,
  [mediaSlice.name]: mediaSlice.reducer,
  [profileSlice.name]: profileSlice.reducer,
  [scheduleSlice.name]: scheduleSlice.reducer,
  [snsSlice.name]: snsSlice.reducer,
  [OrderSlice.name]: OrderSlice.reducer,

  // site
  [siteTopSlice.name]: siteTopSlice.reducer,
  [siteTimelineSlice.name]: siteTimelineSlice.reducer,
  [siteFollowSlice.name]: siteFollowSlice.reducer,
  [siteProfileSlice.name]: siteProfileSlice.reducer,
  [siteProfileChargeSlice.name]: siteProfileChargeSlice.reducer,
  [siteProfileWalletSlice.name]: siteProfileWalletSlice.reducer,
  [siteOrderSlice.name]: siteOrderSlice.reducer,
  [siteShopSlice.name]: siteShopSlice.reducer,
});
