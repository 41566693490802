import dayjs from "dayjs";
import {
  Popup,
  Page,
  Navbar,
  Link,
  NavRight,
  Subnavbar,
  Searchbar,
  BlockTitle,
  List,
  ListItem,
  PageContent,
  Range,
  Toolbar,
  Block,
  Button,
  Col,
  Row,
  Toggle,
} from "framework7-react";
import { trim } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { siteTopSelector } from "core/slice/site/top";
import { searchGirls } from "core/usecase/site/top";

import { SMART_SELECT_DEFAULT_PARAMS } from "global/constant";
import { Ranking } from "global/enum";
import { HairTypeLabel, HairColorLabel, TobaccoLabel, SakeLabel, AreaLabel, AddressLabel } from "global/enum-label";
import { RankingLabel } from "global/enum-label/girl";

import { Icon } from "ui/widget/icon";
import { Tag } from "ui/widget/tag";

type Props = {
  opened: boolean;
  onPopupClosed: () => void;
};

const MIN_HEIGHT = 140;
const MAX_HEIGHT = 200;

/**
 * SearchConditionPopup
 * @param props
 * @param props.opened
 * @param props.onPopupClosed
 * @returns SearchConditionPopup
 */
export const SearchConditionPopup: React.FC<Props> = ({ opened, onPopupClosed }) => {
  const { condition } = useSelector(siteTopSelector);
  const [localCondition, setLocaleCondition] = useState(condition);
  const [todayOnly, setTodayOnly] = useState(false);

  useEffect(() => {
    setLocaleCondition(condition);
    setTodayOnly(!!condition.date);
  }, [condition]);

  /** heightLabel */
  const heightLabel = useMemo(() => {
    if (localCondition.minHeight === undefined && localCondition.maxHeight === undefined) {
      return <span className="text-color-gray">任意</span>;
    }
    return `${localCondition.minHeight ?? MIN_HEIGHT}cm 〜 ${localCondition.maxHeight ?? ""}cm`;
  }, [localCondition.maxHeight, localCondition.minHeight]);

  /**
   * 検索キーワード変更処理
   */
  const onSearchBarChange = useCallback((value?: string) => {
    setLocaleCondition(prev => ({
      ...prev,
      key: value,
    }));
  }, []);

  /**
   * 身長変更処理
   */
  const onHeightChange = useCallback((values: Array<number>) => {
    setLocaleCondition(prev => ({
      ...prev,
      minHeight: values[0],
      maxHeight: values[1],
    }));
  }, []);

  /**
   * onSelectChange
   * @param e
   */
  const onSelectChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setLocaleCondition(prev => ({
      ...prev,
      [name]: value || undefined,
    }));
  }, []);

  /**
   * ランク変更処理
   */
  const onRankingChange = useCallback((value: Ranking) => {
    setLocaleCondition(prev => {
      const next = { ...prev };
      if (!next.ranking) {
        next.ranking = [value];
        return next;
      }
      const newRanking = [...next.ranking];
      const index = newRanking.indexOf(value);
      if (index === -1) {
        newRanking.push(value);
      } else {
        newRanking.splice(index, 1);
      }
      next.ranking = newRanking.length === 0 ? undefined : newRanking;

      return next;
    });
  }, []);

  /**
   * 検索処理
   */
  const handleSearch = useCallback(() => {
    searchGirls({
      ...localCondition,
      key: trim(localCondition.key),
      date: todayOnly ? dayjs().toDate() : undefined,
    }).then(onPopupClosed);
  }, [localCondition, todayOnly, onPopupClosed]);

  return (
    <Popup backdrop={false} tabletFullscreen opened={opened} onPopupClosed={onPopupClosed}>
      <Page pageContent={false}>
        <Navbar title="检索条件">
          <NavRight>
            <Link href={false} popupClose>
              关闭
            </Link>
          </NavRight>
          <Subnavbar inner={false}>
            <Searchbar
              disableButton={false}
              onSearchbarClear={() => onSearchBarChange()}
              onInput={e => onSearchBarChange(e.target.value)}
            />
          </Subnavbar>
        </Navbar>
        <PageContent style={{ marginTop: 48, paddingBottom: 96 }}>
          <BlockTitle>基本条件</BlockTitle>
          <List className="list-inner-wrap">
            <ListItem title="身高">
              <div slot="after">{heightLabel}</div>
              <div className="fluid">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center",
                  }}
                >
                  <Icon name="accessibility" color="yellow" />
                  <div style={{ width: "100%", margin: "16px" }}>
                    <Range
                      min={MIN_HEIGHT}
                      max={MAX_HEIGHT}
                      step={1}
                      value={[localCondition.minHeight ?? MIN_HEIGHT, localCondition.maxHeight ?? MAX_HEIGHT]}
                      label
                      dual
                      scale
                      scaleSteps={6}
                      scaleSubSteps={4}
                      onRangeChange={onHeightChange}
                    />
                  </div>
                  <Icon name="accessibility" color="orange" />
                </div>
              </div>
            </ListItem>
            <ListItem link title="发型" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
              <div slot="after">
                {localCondition.hairType ? (
                  <FormattedMessage id={HairTypeLabel[localCondition.hairType]} />
                ) : (
                  <span className="text-color-gray">任意</span>
                )}
              </div>
              <select name="hairType" value={localCondition.hairType} onChange={onSelectChange}>
                <option value="">任意</option>
                {Object.entries(HairTypeLabel)
                  .slice(1)
                  .map(([value, label]) => (
                    <option value={value} key={value}>
                      <FormattedMessage id={label} />
                    </option>
                  ))}
              </select>
            </ListItem>
            <ListItem link title="发色" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
              <div slot="after">
                {localCondition.hairColor ? (
                  <FormattedMessage id={HairColorLabel[localCondition.hairColor]} />
                ) : (
                  <span className="text-color-gray">任意</span>
                )}
              </div>
              <select name="hairColor" value={localCondition.hairColor} onChange={onSelectChange}>
                <option value="">任意</option>
                {Object.entries(HairColorLabel)
                  .slice(1)
                  .map(([value, label]) => (
                    <option value={value} key={value}>
                      <FormattedMessage id={label} />
                    </option>
                  ))}
              </select>
            </ListItem>

            <ListItem link title="吸烟" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
              <div slot="after">
                {localCondition.tobacco ? (
                  <FormattedMessage id={TobaccoLabel[localCondition.tobacco]} />
                ) : (
                  <span className="text-color-gray">任意</span>
                )}
              </div>
              <select name="tobacco" value={localCondition.tobacco} onChange={onSelectChange}>
                <option value="">任意</option>
                {Object.entries(TobaccoLabel)
                  .slice(1)
                  .map(([value, label]) => (
                    <option value={value} key={value}>
                      <FormattedMessage id={label} />
                    </option>
                  ))}
              </select>
            </ListItem>
            <ListItem link title="喝酒" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
              <div slot="after">
                {localCondition.sake ? (
                  <FormattedMessage id={SakeLabel[localCondition.sake]} />
                ) : (
                  <span className="text-color-gray">任意</span>
                )}
              </div>
              <select name="sake" value={localCondition.sake} onChange={onSelectChange}>
                <option value="">任意</option>
                {Object.entries(SakeLabel)
                  .slice(1)
                  .map(([value, label]) => (
                    <option value={value} key={value}>
                      <FormattedMessage id={label} />
                    </option>
                  ))}
              </select>
            </ListItem>
          </List>

          <BlockTitle>等级</BlockTitle>
          <List>
            <ListItem>
              <Row className="fluid">
                {Object.entries(RankingLabel).map(([value, text]) => (
                  <Col key={value}>
                    <Tag
                      className="fluid"
                      selectable
                      selected={localCondition.ranking?.includes(value as Ranking)}
                      text={text}
                      onClick={() => onRankingChange(value as Ranking)}
                    />
                  </Col>
                ))}
              </Row>
            </ListItem>
          </List>

          <BlockTitle>出勤</BlockTitle>
          <List>
            <ListItem title="只显示今日出勤">
              <Toggle checked={todayOnly} onChange={() => setTodayOnly(!todayOnly)} />
            </ListItem>
            <ListItem link title="所在地" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
              <div slot="after">
                {localCondition.area ? (
                  <FormattedMessage id={AreaLabel[localCondition.area]} />
                ) : (
                  <span className="text-color-gray">任意</span>
                )}
              </div>
              <select name="area" value={localCondition.area} onChange={onSelectChange}>
                <option value="">任意</option>
                {Object.entries(AreaLabel).map(([value, label]) => (
                  <option value={value} key={value}>
                    <FormattedMessage id={label} />
                  </option>
                ))}
              </select>
            </ListItem>
            <ListItem link title="车站" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
              <div slot="after">
                {localCondition.address ? (
                  <FormattedMessage id={AddressLabel[localCondition.address]} />
                ) : (
                  <span className="text-color-gray">任意</span>
                )}
              </div>
              <select name="address" value={localCondition.address} onChange={onSelectChange}>
                <option value="">任意</option>
                {Object.entries(AddressLabel).map(([value, label]) => (
                  <option value={value} key={value}>
                    <FormattedMessage id={label} />
                  </option>
                ))}
              </select>
            </ListItem>
          </List>
        </PageContent>

        <Toolbar bottom>
          <Block className="fluid">
            <Row>
              <Col width={50}>
                <Button
                  type="button"
                  fill
                  color="teal"
                  onClick={() => {
                    setLocaleCondition({});
                    setTodayOnly(false);
                  }}
                >
                  <Icon name="redo" size={18} />
                  &nbsp;重置
                </Button>
              </Col>
              <Col width={50}>
                <Button type="button" fill color="primary" onClick={handleSearch}>
                  <Icon name="search" size={18} />
                  &nbsp;检索
                </Button>
              </Col>
            </Row>
          </Block>
        </Toolbar>
      </Page>
    </Popup>
  );
};
