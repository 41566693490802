import PresentSVG from "assets/icon/present.svg";
import { Block, Button, Col, f7, Fab, Row, Toolbar } from "framework7-react";
import React, { useCallback, useState } from "react";

import { ScheduleListResponse } from "core/model/girl/schedule";
import { sendPresent } from "core/usecase/present";
import { getTalkId, initializeTalkList } from "core/usecase/talk";
import { toast } from "core/utils/common";

import { Address } from "global/enum";

import { Icon } from "ui/widget/icon";

import { PresentSheet } from "../../present/present-sheet";

import { ReserveModal } from "./reserve-modal";

type ActionSectionProps = {
  userId: string;
  price: number;
  scheduleList: ScheduleListResponse;
  addressList: Array<Address> | undefined;
};

/**
 * ActionSection
 * @param props
 * @param props.userId
 * @param props.price
 * @param props.scheduleList
 * @param props.addressList
 * @returns ActionSection
 */
export const ActionSection: React.FC<ActionSectionProps> = ({ userId, price, scheduleList, addressList }) => {
  const [reserveModalOpened, setReserveModalOpened] = useState(false);
  const [presentSheetOpened, setPresentSheetOpened] = useState(false);

  /**
   * プレゼント送る処理
   */
  const handlePresentSendClick = useCallback(
    (presentId: string) => {
      sendPresent(userId, presentId).then(success => {
        if (success) {
          setPresentSheetOpened(false);
          toast("赠送成功");
        }
      });
    },
    [userId],
  );

  /**
   * メッセージ送信処理
   */
  const handleSendMessageBtnClick = useCallback(() => {
    // トークIDの取得
    getTalkId(userId).then(talkId => {
      if (talkId) {
        // トークメッセージ一覧画面に遷移する
        f7.view.main.router.navigate(`/talk/${talkId}`, { transition: "f7-push" });
        // トーク一覧初期化
        initializeTalkList(true);
      }
    });
  }, [userId]);

  return (
    <>
      <Fab position="right-center" className="fab-bg-transparent" onClick={() => setPresentSheetOpened(true)}>
        <img src={PresentSVG} alt="" width="30px" />
      </Fab>
      <PresentSheet
        opened={presentSheetOpened}
        onClosed={() => setPresentSheetOpened(false)}
        onSendPresent={handlePresentSendClick}
      />
      <Toolbar bottom>
        <Block className="fluid">
          <Row>
            {/* <Col width={25}>
          <Button type="button" outline color="red">
            <Icon name="add" size={18} />
            &nbsp;关注
          </Button>
        </Col> */}
            <Col width={50}>
              <Button type="button" fill color="green" onClick={() => setReserveModalOpened(true)}>
                <Icon name="shopping_cart" size={18} />
                &nbsp;下单
              </Button>
            </Col>
            <Col width={50}>
              <Button type="button" fill color="orange" onClick={handleSendMessageBtnClick}>
                <Icon name="send" size={18} />
                &nbsp;私信
              </Button>
            </Col>
          </Row>
        </Block>
      </Toolbar>
      <ReserveModal
        userId={userId}
        price={price}
        opened={reserveModalOpened}
        onClose={() => setReserveModalOpened(false)}
        scheduleList={scheduleList}
        addressList={addressList}
      />
    </>
  );
};
