import dayjs, { Dayjs, UnitType } from "dayjs";
import { Block, BlockTitle, Button, Link, List, ListItem, PageContent, Sheet, Toolbar } from "framework7-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { SMART_SELECT_DEFAULT_PARAMS } from "global/constant";
import { ReserveTypeLabel } from "global/enum-label/reserve";
import { ReserveType } from "global/enum/reserve";

import { Icon } from "ui/widget/icon";
import { Modal } from "ui/widget/modal";

type ReserveStartModalProps = {
  opened: boolean;
  onClose: () => void;
  type: ReserveType;
  typeOther: string | undefined;
  onConfirm: (type: ReserveType, typeOther: string | undefined) => void;
  selectableDates?: Array<Dayjs>;
};

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";
const format = `${dateFormat} ${timeFormat}`;

/**
 * minuteCeil
 * @param date
 * @param amount
 * @returns ceil date
 */
const minuteCeil = (date: Dayjs, amount = 30) => {
  const unit: UnitType = "minute";
  return date.add(amount - (date.get(unit) % amount), unit).startOf(unit);
};

/**
 * time options作成
 * @param selectedDate
 * @returns time options
 */
const createTimeOptions = (selectedDate: string | undefined) =>
  Array.from({ length: 24 })
    .flatMap((_, i) => {
      const prev = `00${i}`.slice(-2);
      return [`${prev}:00`, `${prev}:30`];
    })
    .filter(minute => {
      if (!selectedDate) return true;
      return dayjs(`${selectedDate} ${minute}`, format).isAfter(dayjs().add(30, "minute"));
    });

/**
 * ScheduledMeetTimeModal
 * @param props
 * @param props.opened
 * @param props.onClose
 * @param props.type
 * @param props.typeOther
 * @param props.onConfirm
 * @param props.selectableDates
 * @returns ScheduledMeetTimeModal
 */
export const ScheduledMeetTimeModal: React.FC<ReserveStartModalProps> = ({
  opened,
  onClose,
  type,
  typeOther,
  onConfirm,
  selectableDates,
}) => {
  const [localType, setLocaleType] = useState(type);
  const [selectedDate, setSelectedDate] = useState<string>();
  const [selectedTime, setSelectedTime] = useState<string>();
  const [timeOptions, setTimeOptions] = useState<string[]>([]);
  const [error, setError] = useState<string>();

  // dateLabel
  const dateLabel = useCallback((date: Dayjs | string) => {
    const d = dayjs(date);
    const now = dayjs();
    if (d.isToday()) return "今天";
    if (d.isTomorrow()) return "明天";
    return d.isSame(now, "w") ? d.format("ddd") : `下${d.format("ddd")}`;
  }, []);

  // dateOptions
  const dateOptions = useMemo(() => {
    if (selectableDates) {
      return selectableDates.map(date => ({
        value: date.format(dateFormat),
        label: dateLabel(date),
      }));
    }
    const now = dayjs();
    const length = 15 - (now.get("day") === 0 ? 7 : now.get("day"));
    return Array.from({ length }, (_, i) => {
      const date = now.add(i, "d");
      return {
        value: date.format(dateFormat),
        label: dateLabel(date),
      };
    });
  }, [dateLabel, selectableDates]);

  /**
   * handleModalOpen
   */
  useEffect(() => {
    if (!opened) return;
    setLocaleType(type);
    // エラーメッセージクリア
    setError(undefined);
    // select date time初期化
    const date = typeOther ? dayjs(typeOther, format) : selectableDates?.[0] ?? minuteCeil(dayjs().add(30, "minute"));
    const dateStr = date.format(dateFormat);
    const timeStr = date.format(timeFormat);

    setSelectedDate(dateStr);
    const timeOptions = createTimeOptions(dateStr);
    setTimeOptions(timeOptions);
    if (timeOptions.includes(timeStr)) {
      setSelectedTime(timeStr);
    } else {
      setSelectedTime(timeOptions[0]);
    }
  }, [opened, type, typeOther, selectableDates]);

  /**
   * 日付変更処理
   */
  const handleDateChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target;
      setSelectedDate(value);
      const timeOptions = createTimeOptions(value);
      setTimeOptions(timeOptions);
      if (selectedTime && !timeOptions.includes(selectedTime)) {
        setSelectedTime(timeOptions[0]);
      }
    },
    [selectedTime],
  );

  /**
   * 日付確定処理
   */
  const handleSheetConfirm = useCallback(() => {
    if (!selectedDate || !selectedTime) {
      setError("请选择预约时间");
      return;
    }
    onConfirm(ReserveType.other, `${selectedDate} ${selectedTime}`);
    onClose();
  }, [onClose, onConfirm, selectedDate, selectedTime]);

  /**
   * handleConfirm
   */
  const handleConfirm = useCallback(() => {
    onConfirm(localType, selectedDate && selectedTime ? `${selectedDate} ${selectedTime}` : undefined);
    onClose();
  }, [localType, onClose, onConfirm, selectedDate, selectedTime]);

  return (
    <>
      <Modal
        opened={opened}
        title="预约时间"
        onClose={onClose}
        content={
          <>
            <List>
              {Object.entries(ReserveTypeLabel).map(([value, label]) => (
                <ListItem
                  key={value}
                  radio
                  value={value}
                  name="type"
                  checked={value === localType}
                  onChange={() => setLocaleType(value as ReserveType)}
                >
                  <div slot="title">
                    <FormattedMessage id={label} />
                    {value === ReserveType.other && localType === ReserveType.other && selectedDate && selectedTime && (
                      <span>({`${dateLabel(selectedDate)} ${selectedTime}`})</span>
                    )}
                  </div>
                </ListItem>
              ))}
            </List>
            <Button fill onClick={handleConfirm}>
              确定
            </Button>
          </>
        }
      />
      <Sheet
        opened={opened && localType === ReserveType.other}
        backdrop
        style={{ height: "auto", maxHeight: 600, overflowY: "auto" }}
      >
        <Toolbar>
          <Link href={false} color="gray" sheetClose onClick={onClose}>
            Cancel
          </Link>
          <Link href={false} onClick={handleSheetConfirm}>
            Done
          </Link>
        </Toolbar>
        <PageContent>
          <BlockTitle>预约时间选择</BlockTitle>
          {error && (
            <Block
              inset
              strong
              bgColor="error"
              textColor="error"
              borderColor="error"
              className="text-align-middle bordered"
            >
              <Icon name="error" />
              <strong style={{ marginLeft: 8 }}>{error}</strong>
            </Block>
          )}
          <List>
            <ListItem title="日期" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
              <div slot="media">
                <Icon name="calendar_month" color="orange" />
              </div>
              <div slot="after">{selectedDate && dateLabel(selectedDate)}</div>
              <select value={selectedDate} onChange={handleDateChange}>
                {dateOptions.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </ListItem>
            <ListItem title="时间" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
              <div slot="media">
                <Icon name="schedule" color="red" />
              </div>
              <div slot="after">{selectedTime}</div>
              <select value={selectedTime} onChange={e => setSelectedTime(e.target.value)}>
                {timeOptions.map(value => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </ListItem>
          </List>
        </PageContent>
      </Sheet>
    </>
  );
};
