import { Page } from "framework7-react";
import React, { useCallback } from "react";
import { PropsWithF7Router } from "routes";

import { PasswordResetModel } from "core/model/forgot-password";
import { codeVerify, passwordReset } from "core/usecase/forgot-password";

import { PasswordResetForm } from "ui/component/forgot-password/password-reset-form";

/**
 * パスワード再設定画面
 * @param props
 * @param props.f7router
 * @param props.f7route
 * @returns PasswordReset
 */
export const PasswordResetPage: React.FC<PropsWithF7Router> = ({ f7router, f7route }) => {
  /**
   * handleSubmit
   */
  const handleSubmit = useCallback(
    (values: PasswordResetModel) => {
      passwordReset({ ...values, token: f7route.query.code! }).then(success => {
        if (success) {
          f7router.navigate("/girl/forgot-password/reset-complete", {
            transition: "f7-push",
          });
        }
      });
    },
    [f7route.query.code, f7router],
  );

  return (
    <Page loginScreen onPageBeforeIn={() => codeVerify(f7route.query.code!)}>
      <PasswordResetForm onSubmit={handleSubmit} loginLink="/girl/login" />
    </Page>
  );
};
