import { base64ToFile } from ".";

/**
 * ビデオカバーの作成
 * @param file
 * @param timeout
 * @returns Promise<File>
 */
export const createVideoCover = (file: File, timeout = 30) =>
  new Promise<File>((resolve, reject) => {
    if (!/^video\/.+$/.test(file.type)) {
      reject(new Error("ビデオを選択してください"));
      return;
    }

    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    video.style.display = "none";
    canvas.style.display = "none";

    const url = URL.createObjectURL(file);

    /**
     * timeout
     */
    const timeoutId = setTimeout(() => {
      reject(new Error("timeout"));
    }, timeout * 1000);

    /**
     * onSeeked
     */
    const onSeeked = () => {
      const ctx = canvas.getContext("2d")!;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // cover data url
      const dataUrl = canvas.toDataURL("image/jpeg", 0.5);

      // covert data url to file
      const baseName = file.name.substring(0, file.name.lastIndexOf("."));
      const coverFile = base64ToFile(dataUrl, `${baseName}.jpg`);

      // clear timeout
      clearTimeout(timeoutId);

      // revokeObjectURL
      URL.revokeObjectURL(url);

      // removeEventListener
      video.removeEventListener("seeked", onSeeked);

      resolve(coverFile!);
    };

    video.addEventListener("loadedmetadata", () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      video.currentTime = 1;
    });
    video.addEventListener("seeked", onSeeked);

    // on error
    video.addEventListener("error", error => {
      // clear timeout
      clearTimeout(timeoutId);
      // revokeObjectURL
      URL.revokeObjectURL(url);

      reject(error);
    });

    video.src = url;

    // Safari / IE11
    video.muted = true;
    video.playsInline = true;
    video.play();
  });
