import dayjs from "dayjs";
import { f7 } from "framework7-react";
import { Router } from "framework7/modules/router/router";
import { dispatch, store } from "store";

import { PresentMasterListResponse } from "core/model/present/api";
import { globalAction } from "core/slice/global";
import Api, { isSuccess } from "core/utils/api";
import { yupInit } from "core/utils/yup/yup.ext";

import { SupportedLanguage } from "global/enum";

/**
 * showPageSpinner
 * @param el
 */
export const showPageSpinner = (el?: HTMLElement | null) => {
  if (el) {
    f7.preloader.showIn(el);
  } else {
    f7.preloader.show("multi");
  }
};

/**
 * hidePageSpinner
 * @param el
 */
export const hidePageSpinner = (el?: HTMLElement | null) => {
  if (el) {
    f7.preloader.hideIn(el);
  } else {
    f7.preloader.hide();
  }
};

/**
 * goBack
 * @param backUrl
 * @param options
 */
export const goBack = (backUrl: string | string[], options?: Router.RouteOptions) => {
  const { router } = f7.view.main;
  const { previousRoute } = router;
  const backUrls = [backUrl].flat();

  if (previousRoute && backUrls.includes(previousRoute.path)) {
    router.back();
  } else {
    router.navigate(backUrls[0], options);
  }
};

/**
 * 言語変更
 * @param language
 */
export const changeLanguage = (language: SupportedLanguage) => {
  if (store.getState().global.language === language) return;
  yupInit(language);
  if (language === SupportedLanguage.japanese) {
    dayjs.locale("ja");
  } else {
    dayjs.locale("zh-cn");
  }
  dispatch(globalAction.changeLanguageAction(language));
};

/**
 * プレゼントマスタ一覧初期化
 */
export const initPresentMasterList = async () => {
  try {
    const res = await Api.get<PresentMasterListResponse>("/presents/list");
    if (isSuccess(res) && res.body) {
      dispatch(globalAction.initPresentMasterListAction(res.body));
    }
  } catch {
    // 処理なし
  }
};
