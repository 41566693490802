import { Block, BlockTitle, Button, Col, List, ListInput, LoginScreenTitle, Row } from "framework7-react";
import React from "react";
import { FormattedMessage } from "react-intl";

import { SignupRequest } from "core/model/signup";
import { goBack } from "core/usecase/global";

/**
 * SignupConfirmProps
 */
type SignupConfirmProps = {
  /** メールアドレス */
  email: string;
  /** パスワード */
  password: string;
  /** handleSignup */
  handleSignup: (values: SignupRequest) => void;
  /** signupページリンク */
  signupLink: string;
};

/**
 * SignupConfirmBlock
 * @param props
 * @param props.email
 * @param props.password
 * @param props.handleSignup
 * @param props.signupLink
 * @returns SignupConfirmBlock
 */
export const SignupConfirmBlock: React.FC<SignupConfirmProps> = ({ email, password, handleSignup, signupLink }) => (
  <Block inset strong>
    <LoginScreenTitle>
      <FormattedMessage id="signup.confirm.title" />
    </LoginScreenTitle>
    <br />
    <BlockTitle>
      <FormattedMessage id="signup.confirm.remind" />
    </BlockTitle>
    <List>
      {/* メールアドレス */}
      <ListInput type="text" value={email ?? ""} outline disabled>
        <div slot="label">
          <FormattedMessage id="signup.confirm.email" />
        </div>
      </ListInput>

      {/* パスワード */}
      <ListInput type="text" value={password ?? ""} outline disabled>
        <div slot="label">
          <FormattedMessage id="signup.confirm.password" />
        </div>
      </ListInput>
    </List>
    <Block>
      <Row>
        <Col>
          <Button color="gray" fill onClick={() => goBack(signupLink)}>
            <FormattedMessage id="common.fix" />
          </Button>
        </Col>
        <Col>
          <Button fill onClick={handleSignup}>
            <FormattedMessage id="signup.confirm.button" />
          </Button>
        </Col>
      </Row>
    </Block>
  </Block>
);
