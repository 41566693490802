/** FetchStatus */
export enum FetchStatus {
  /** idle */
  idle,
  /** fetching */
  fetching,
  /** complete */
  complete,
  /** failed */
  failed,
}

/** 画像・ビデオアップロードエラー */
export enum MediaUploadError {
  /** 最大サイズを超える */
  maxSizeExceed = "1",
  /** タイプ不正 */
  invalidType = "2",
  /** その他エラー */
  other = "9",
}

/**
 * 多言語
 */
export enum SupportedLanguage {
  /** 日本語 */
  japanese = "ja",
  /** 中国語 */
  chinese = "zh",
}

/** Role */
export enum Role {
  /** girl user */
  girl = "ROLE_GIRL_USER",
  /** site user */
  site = "ROLE_SITE_USER",
  /** system user */
  system = "ROLE_SYSTEM_USER",
}

/** オンラインステータス */
export enum OnlineStatus {
  /** オンライン */
  online = "1",
  /** オフライン */
  offline = "2",
}

/** トークメッセージ種別 */
export enum TalkMessageType {
  /** テキスト */
  text = "1",
  /** 画像 */
  image = "2",
  /** ビデオ */
  video = "3",
}

/**
 * 表示フラグ
 */
export enum Hidden {
  /** 非表示 */
  hide = "0",
  /** 表示 */
  show = "1",
}

/**
 * MediaType
 */
export enum MediaType {
  /** 写真 */
  photo = "1",
  /** ビデオ */
  video = "2",
}

/** クラス */
export enum Ranking {
  /** プレミアム */
  premium = "1",
  /** VIP */
  vip = "2",
  /** ロイヤルVIP */
  royal = "3",
  /** VIPPP */
  vippp = "4",
}

/** お仕事 */
export enum Job {
  /** 非表示 */
  hide = "0",
  /** 会社員 */
  employee = "1",
  /** 医者 */
  doctor = "2",
  /** 弁護士 */
  lawyer = "3",
  /** 公認会計士 */
  CPA = "4",
  /** 経営者・役員 */
  managerExecutive = "5",
  /** 公務員 */
  governmentWorker = "6",
  /** 事務員 */
  clerk = "7",
  /** 大手商社 */
  majorTrading = "8",
  /** 外資金融 */
  foreignFinance = "9",
  /** 大手企業 */
  majorCompany = "10",
  /** 大手外資 */
  majorForeign = "11",
  /** クリエイター */
  creator = "12",
  /** IT関連 */
  IT = "13",
  /** パイロット */
  pilot = "14",
  /** 芸能・モデル */
  model = "15",
  /** アパレル */
  apparel = "16",
  /** アナウンサー */
  announcer = "17",
  /** 保育士 */
  childminder = "18",
  /** 自由業 */
  free = "19",
  /** 学生 */
  student = "20",
  /** その他 */
  other = "21",
}

/** 学歴 */
export enum Education {
  /** 非表示 */
  hide = "0",
  /** 短大/専門学校卒 */
  juniorVocational = "1",
  /** 高校卒 */
  senior = "2",
  /** 大学卒 */
  university = "3",
  /** 大学院卒 */
  graduate = "4",
  /** その他 */
  other = "5",
}

/** お酒 */
export enum Sake {
  /** 非表示 */
  hide = "0",
  /** 飲まない */
  no = "1",
  /** 飲む */
  yes = "2",
  /** 時々飲む */
  sometimes = "3",
}

/** タバコ */
export enum Tobacco {
  /** 非表示 */
  hide = "0",
  /** 吸わない */
  no = "1",
  /** 吸う */
  yes = "2",
  /** 時々吸う */
  sometimes = "3",
}

/** 髪型 */
export enum HairType {
  /** 非表示 */
  hide = "0",
  /** ショート */
  short = "1",
  /** ミディアム */
  medium = "2",
  /** ロング */
  long = "3",
}

/** 髪の色 */
export enum HairColor {
  /** 非表示 */
  hide = "0",
  /** 黒 */
  black = "1",
  /** 白 */
  white = "2",
  /** 茶色 */
  brown = "3",
  /** カラー */
  color = "4",
}

/** 出勤エリア */
export enum Area {
  /** 東京都 */
  Tokyo = "1",
}

/** 出勤場所 */
export enum Address {
  /** 六本木 */
  Roppongi = "1",
  /** 渋谷 */
  Shibuya = "2",
  /** 銀座 */
  Ginza = "3",
  /** 池袋 */
  Ikebukuro = "4",
  /** 上野 */
  Ueno = "5",
  /** 新宿 */
  Shinjuku = "6",
  /** 恵比寿 */
  Ebisu = "7",
  /** 西麻布 */
  Nishiazabu = "8",
  /** 麻布十番 */
  Azabujuban = "9",
  /** 中目黒 */
  Nakameguro = "10",
  /** その他 */
  Other = "99",
}
