import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { TimelineItem, TimelineListResponse } from "core/model/site/timeline";

/**
 * state
 */
export type SiteTimelineState = {
  /** 初期化済 */
  initialized: boolean;
  /** page */
  page: number;
  /** timelineList */
  timelineList?: TimelineListResponse;
};

const initialState: SiteTimelineState = {
  initialized: false,
  page: 1,
};

/**
 * slice
 */
export const siteTimelineSlice = createSlice({
  name: "site/timeline",
  initialState,
  reducers: {
    /**
     * ページ更新
     * @param state
     */
    increasePageAction: state => {
      state.page += 1;
    },
    /**
     * timeline list初期化
     * @param state
     * @param action
     */
    initializeTimelinesAction: (state, action: PayloadAction<TimelineListResponse>) => {
      state.timelineList = action.payload;
      state.initialized = true;
    },
    /**
     * timeline list追加
     * @param state
     * @param action
     */
    appendTimelinesAction: (state, action: PayloadAction<TimelineListResponse>) => {
      state.timelineList = (state.timelineList || []).concat(action.payload);
    },
    /**
     * timeline新規作成
     * @param state
     * @param action
     */
    prependTimelineAction: (state, action: PayloadAction<TimelineItem>) => {
      state.timelineList = [action.payload].concat(state.timelineList || []);
    },
    /**
     * updateTimelineItem
     * @param state
     * @param action
     */
    updateTimelineItem: (state, action: PayloadAction<TimelineItem>) => {
      if (!state.timelineList) return;
      const index = state.timelineList.findIndex(o => o.id === action.payload.id);
      if (index !== -1) {
        state.timelineList.splice(index, 1, action.payload);
      }
    },
    /**
     * deleteTimelineItem
     * @param state
     * @param action
     */
    deleteTimelineItem: (state, action: PayloadAction<string>) => {
      if (!state.timelineList) return;
      const index = state.timelineList.findIndex(o => o.id === action.payload);
      if (index !== -1) {
        state.timelineList.splice(index, 1);
      }
    },
  },
});

/**
 * action
 */
export const siteTimelineAction = siteTimelineSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const siteTimelineSelector = (state: RootState) => state["site/timeline"];
