import dayjs from "dayjs";
import { Block, BlockTitle, List, ListItem } from "framework7-react";
import React, { useCallback } from "react";
import { FormattedNumber } from "react-intl";

import { PresentsHistory, PresentsHistoryListResponse } from "core/model/present/api";

import { CommonConfig } from "global/config";

import { FormattedDate } from "ui/widget/formatted-date";

type PresentLogListProps = {
  presentHistories: PresentsHistoryListResponse;
};

/**
 * 送ったプレゼント一覧
 * @param props
 * @param props.presentHistories
 * @returns PresentLogList
 */
export const PresentLogList: React.FC<PresentLogListProps> = ({ presentHistories }) => {
  const currentYear = dayjs().year();

  /**
   * showTitle
   */
  const showTitle = useCallback((item: PresentsHistory, prevItem?: PresentsHistory) => {
    if (!prevItem) return true;
    return !dayjs(item.ymd + item.hhmmss).isSame(dayjs(prevItem.ymd + prevItem.hhmmss), "year");
  }, []);

  return (
    <List mediaList className="media-centered">
      {presentHistories.map((item, index) => (
        <React.Fragment key={item.id}>
          {showTitle(item, presentHistories[index - 1]) && (
            <Block className="no-margin-bottom">
              <BlockTitle medium>
                {dayjs(item.ymd + item.hhmmss).year() === currentYear
                  ? "今年"
                  : `${dayjs(item.ymd + item.hhmmss).year()}年`}
              </BlockTitle>
            </Block>
          )}
          <ul>
            <ListItem>
              <div
                slot="media"
                className="text-align-middle"
                style={{ width: 48, height: 48, justifyContent: "center", marginRight: 16 }}
              >
                <img src={CommonConfig.presentBaseUrl + item.iconFile} alt="present" height="100%" />
              </div>
              <div slot="title">
                <FormattedDate value={item.ymd + item.hhmmss} format="MM/DD HH:mm" />
              </div>
              <div slot="footer">
                <strong>{item.fromUserName}</strong>&nbsp;さんから{item.presentNameJp}をもらいました
              </div>
              <div slot="after">
                <FormattedNumber value={item.price} />
                &nbsp;P
              </div>
            </ListItem>
          </ul>
        </React.Fragment>
      ))}
    </List>
  );
};
