import Cookies from "js-cookie";
import { Socket } from "socket.io-client";
import { dispatch } from "store";

import { newMessageData } from "core/model/talk";
import { talkAction } from "core/slice/talk";
import { refreshTokenHandler } from "core/usecase/authorization";
import logger from "core/utils/logger";

import { CommonConfig } from "global/config";

import { SocketEvents } from "./events";

/**
 * 新しいメッセージをもらう
 * @param data
 */
const handleNewMessage = (data: newMessageData) => {
  // 未読メッセージ + 1
  dispatch(talkAction.increaseUnreadMsgCntAction());
  // メッセージをストアに保存する
  dispatch(talkAction.newMessageAction(data));
};

/**
 * commonEventHandler
 * @param socket
 */
export const commonEventHandler = (socket: Socket) => {
  // 接続
  socket.on("connect", () => {
    logger.info("socket connected.");
  });

  // 切断
  socket.on("disconnect", (reason: string) => {
    logger.info(reason);
    if (reason === "io client disconnect ") {
      socket.connect();
    }
  });

  // 接続失敗
  socket.on("connect_error", (error: Error) => {
    logger.error("connect_error: ", error);
    if (error.name === "TokenExpired") {
      const refreshToken = Cookies.get(CommonConfig.refreshTokenName);
      if (!refreshToken) return;
      logger.info("try to refresh token.");
      refreshTokenHandler(refreshToken).then(() => {
        logger.info("refresh token success.");
        // アクセストークン
        const accessToken = Cookies.get(CommonConfig.accessTokenName);
        Object.defineProperty(socket.auth, "Authorization", {
          value: `Bearer ${accessToken}`,
          writable: true,
        });
        // 再接続
        socket.connect();
      });
    }
  });

  // 新しいメッセージをもらう
  socket.on(SocketEvents.newMessage, handleNewMessage);
};
