import { Block, BlockTitle, Button, Card, CardContent, Col, Row } from "framework7-react";
import React, { useContext, useEffect } from "react";
import { FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";

import { siteProfileWalletSelector } from "core/slice/site/profile/wallet";
import { walletInitialize } from "core/usecase/site/profile/wallet";

import { Divider } from "ui/widget/divider";
import { Icon } from "ui/widget/icon";
import { IconButton } from "ui/widget/icon-button";

import { SwiperContext } from "../tabs-section";

/**
 * Wallet Tab
 * @returns Wallet Tab
 */
export const TabWallet: React.FC = () => {
  const { swiper } = useContext(SwiperContext);
  const { data, updateDatetime } = useSelector(siteProfileWalletSelector).wallet;

  useEffect(() => {
    walletInitialize();
  }, []);

  return (
    <>
      <br />
      <Card>
        <CardContent>
          <BlockTitle className="text-align-center" style={{ color: "gray" }}>
            当前余额(P)
          </BlockTitle>
          <BlockTitle medium className="text-align-center no-margin-top">
            <FormattedNumber value={data?.balanceFee ?? 0} />
          </BlockTitle>
          <Divider />
          <BlockTitle className="text-align-center" style={{ fontSize: 12, color: "gray" }}>
            更新日時: {updateDatetime ?? "正在取得..."}
          </BlockTitle>
          <IconButton
            onClick={() => walletInitialize(true)}
            icon={<Icon name="refresh" color="gray" />}
            style={{ position: "absolute", top: 32, right: 16 }}
          />
        </CardContent>
      </Card>
      <Block>
        <Row>
          <Col width={50}>
            <Button color="gray" fill href="/profile/wallet-log" transition="f7-push">
              余额变动明细
            </Button>
          </Col>
          <Col width={50}>
            <Button type="button" color="green" fill onClick={() => swiper?.slideNext()}>
              充值
            </Button>
          </Col>
        </Row>
      </Block>
    </>
  );
};
