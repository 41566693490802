import { f7 } from "framework7-react";
import { Picker as F7Picker } from "framework7/types";
import React, { useCallback, useEffect, useRef } from "react";

export * from "./date-time-picker";

/**
 * PickerProps
 */
export interface PickerProps
  extends Omit<
    F7Picker.Parameters,
    "on" | "inputEl" | "inputReadOnly" | "renderToolbar" | "render" | "toolbar" | "toolbarCloseText"
  > {
  /** open picker */
  opened: boolean;
  /** value */
  value?: string[];
  /** destroy picker when picker closed. default = false */
  destroyOnClose?: boolean;
  /** toolbarCancelText */
  toolbarCancelText?: string;
  /** toolbarConfirmText. default="Done" */
  toolbarConfirmText?: string;
  /** onPickerInited */
  onPickerInited?: (picker: F7Picker.Picker) => void;
  /** onPickerOpen */
  onPickerOpen?: (picker: F7Picker.Picker) => void;
  /** onPickerOpened */
  onPickerOpened?: (picker: F7Picker.Picker) => void;
  /** onPickerClose */
  onPickerClose?: (picker: F7Picker.Picker) => void;
  /** onPickerClosed */
  onPickerClosed?: (picker: F7Picker.Picker) => void;
  /** beforeDestroy */
  beforeDestroy?: (picker: F7Picker.Picker) => void;
  /** onValueChange */
  onValueChange?: (picker: F7Picker.Picker, value: string[]) => void;
  /** onCancel */
  onCancel?: () => void;
  /** onConfirm */
  onConfirm?: (value: string[]) => void;
}

/**
 * PickerColumn
 */
export type PickerColumn = F7Picker.ColumnParameters;

/**
 * Picker
 * @param props
 * @returns Picker
 */
export const Picker: React.FC<PickerProps> = props => {
  const {
    opened,
    value,
    destroyOnClose = false,
    rotateEffect = true,
    backdrop = true,
    toolbarCancelText,
    toolbarConfirmText = "Done",
    onPickerInited,
    onPickerOpen,
    onPickerOpened,
    onPickerClose,
    onPickerClosed,
    beforeDestroy,
    onValueChange,
    onCancel,
    onConfirm,
    ...options
  } = props;

  const pickerRef = useRef<F7Picker.Picker>();
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * handleOnConfirm
   */
  const handleOnConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm(pickerRef.current?.getValue() as string[]);
    }
  }, [onConfirm]);

  /**
   * createPicker
   */
  const createPicker = useCallback(
    () =>
      f7.picker.create({
        ...options,
        inputEl: inputRef.current ?? undefined,
        value,
        rotateEffect,
        backdrop,
        renderToolbar() {
          if (toolbarCancelText) {
            return `
              <div class="toolbar" style="font-size: 16px">
                <div class="toolbar-inner">
                  <div class="left picker-cancel">
                    <a href="#" class="link sheet-close popover-close">${toolbarCancelText}</a>
                  </div>
                  <div class="right">
                    <a href="#" class="link picker-confirm">${toolbarConfirmText}</a>
                  </div>
                </div>
              </div>
            `;
          }
          return `
              <div class="toolbar" style="font-size: 16px">
                <div class="toolbar-inner">
                  <div class="left"></div>
                  <div class="right">
                    <a href="#" class="link picker-confirm">${toolbarConfirmText}</a>
                  </div>
                </div>
              </div>
            `;
        },
        on: {
          init: onPickerInited,
          open(picker) {
            // cancel event
            if (toolbarCancelText && onCancel) {
              picker.$el.find(".picker-cancel").on("click", onCancel);
            }
            // confirm event
            picker.$el.find(".picker-confirm").on("click", handleOnConfirm);
            if (onPickerOpen) {
              onPickerOpen(picker);
            }
          },
          opened: onPickerOpened,
          close: onPickerClose,
          closed: onPickerClosed,
          beforeDestroy,
          change(picker, value) {
            if (onValueChange) {
              onValueChange(picker, value as string[]);
            }
          },
        },
      }),
    [
      backdrop,
      beforeDestroy,
      handleOnConfirm,
      onCancel,
      onPickerClose,
      onPickerClosed,
      onPickerInited,
      onPickerOpen,
      onPickerOpened,
      onValueChange,
      options,
      rotateEffect,
      toolbarCancelText,
      toolbarConfirmText,
      value,
    ],
  );

  useEffect(() => {
    if (opened) {
      if (!pickerRef.current) pickerRef.current = createPicker();
      if (!pickerRef.current.opened) pickerRef.current.open();
    } else if (destroyOnClose) {
      pickerRef.current?.destroy();
    } else {
      pickerRef.current?.close();
    }
  }, [createPicker, destroyOnClose, opened]);

  return <input type="hidden" ref={inputRef} />;
};
