import { Block, BlockTitle, Button } from "framework7-react";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Icon } from "ui/widget/icon";

type PasswordResetCompleteBlockProps = {
  /** ログインページリンク */
  loginLink: string;
};

/**
 * パスワード再設定完了Block
 * @param props
 * @param props.loginLink
 * @returns PasswordResetCompleteBlock
 */
export const PasswordResetCompleteBlock: React.FC<PasswordResetCompleteBlockProps> = ({ loginLink }) => (
  <>
    <Block style={{ textAlign: "center", marginTop: 80 }}>
      <Icon name="check_circle" size="80px" color="green" />
      <BlockTitle medium>
        <FormattedMessage id="forgot.password.reset.complete.title" />
      </BlockTitle>
    </Block>
    <Block>
      <Button fill href={loginLink} transition="f7-push">
        <FormattedMessage id="common.back.login" />
      </Button>
    </Block>
  </>
);
