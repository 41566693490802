import { FormikValues, useFormikContext } from "formik";
import ListInput, { ListInputProps } from "framework7-react/components/list-input";
import React, { useCallback } from "react";

type ExtendsListInputProps = Omit<
  ListInputProps,
  | "value"
  | "errorMessage"
  | "errorMessageForce"
  | "clearButton"
  | "onInputClear"
  | "validate"
  | "validateOnBlur"
  | "required"
  | "pattern"
>;
/**
 * FormikListInputProps
 */
export interface FormikListInputProps extends ExtendsListInputProps {
  /** name */
  name: string;
  /** clearable: default = true */
  clearable?: boolean;
}

/**
 * FormikListInput
 * @param props
 * @returns FormikListInput
 */
export const FormikListInput: React.FC<FormikListInputProps> = props => {
  const { clearable = true, name, onChange, onBlur, inputStyle, ...attrs } = props;
  const { handleChange, handleBlur, setFieldValue, setFieldTouched, values, errors, touched } =
    useFormikContext<FormikValues>();

  /**
   * changeHandler
   * @param e
   */
  const changeHandler = useCallback(
    (e: React.ChangeEvent) => {
      handleChange(e);
      if (!touched[name]) {
        setFieldTouched(name, true);
      }
      if (onChange) onChange(e);
    },
    [handleChange, name, onChange, setFieldTouched, touched],
  );

  /**
   * blurHandler
   * @param e
   */
  const blurHandler = useCallback(
    (e: React.FocusEvent) => {
      handleBlur(e);
      if (onBlur) onBlur(e);
    },
    [handleBlur, onBlur],
  );

  return (
    <ListInput
      {...attrs}
      inputStyle={clearable ? { ...inputStyle, paddingRight: 28 } : inputStyle}
      name={name}
      onChange={changeHandler}
      onBlur={blurHandler}
      value={values[name]}
      clearButton={clearable}
      errorMessage={errors[name] && touched[name] ? String(errors[name]) : undefined}
      errorMessageForce
      onInputClear={() => clearable && setFieldValue(name, "")}
    />
  );
};
