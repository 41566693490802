import { PresentSendRequest, PresentsHistoryListResponse } from "core/model/present/api";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";

import { hidePageSpinner, showPageSpinner } from "../global";

/**
 * 送ったプレゼント一覧
 */
export const getSentPresentList = async () => {
  try {
    showPageSpinner();
    const res = await Api.get<PresentsHistoryListResponse>("/presents/send/history/list");
    if (isSuccess(res) && res.body) {
      return res.body.sort((o1, o2) => (o2.ymd + o2.hhmmss).localeCompare(o1.ymd + o1.hhmmss));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return [];
};

/**
 * もらったプレゼント一覧
 */
export const getReceivedPresentList = async () => {
  try {
    showPageSpinner();
    const res = await Api.get<PresentsHistoryListResponse>("/presents/receive/history/list");
    if (isSuccess(res) && res.body) {
      return res.body.sort((o1, o2) => (o2.ymd + o2.hhmmss).localeCompare(o1.ymd + o1.hhmmss));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return [];
};

/**
 * プレゼントを送る
 * @param toUser
 * @param presentId
 *
 * @returns 処理結果
 */
export const sendPresent = async (toUser: string, presentId: string) => {
  const data: PresentSendRequest = { toUser, presentId };
  try {
    showPageSpinner();
    const res = await Api.post("/presents/send", data);
    if (isSuccess(res)) {
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};
