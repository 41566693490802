import { Link, Tab, Tabs, Toolbar } from "framework7-react";
import React from "react";

import { TabMedia } from "./tab-media";
import { TabPayment } from "./tab-payment";
import { TabSchedule } from "./tab-schedule";

/**
 * TabsSection
 * @returns TabsSection
 */
export const TabsSection: React.FC = () => (
  <>
    <Toolbar tabbar inner className="highlight-bottom">
      <Link href={false} tabLink="#tab_schedule" tabLinkActive>
        スケジュール
      </Link>
      <Link href={false} tabLink="#tab_media">
        写真
      </Link>
      <Link href={false} tabLink="#tab_payment">
        お支払い情報
      </Link>
    </Toolbar>
    <Tabs swipeable swiperParams={{ simulateTouch: false }} style={{ height: "auto" }}>
      {/* スケジュール */}
      <Tab id="tab_schedule" tabActive>
        <TabSchedule />
      </Tab>
      {/* 写真 */}
      <Tab id="tab_media" style={{ minHeight: 450 }}>
        <TabMedia />
      </Tab>
      {/* お支払い情報 */}
      <Tab id="tab_payment">
        <TabPayment />
      </Tab>
    </Tabs>
  </>
);
