import { useFormik } from "formik";
import { Link, Navbar, NavLeft, NavRight, NavTitle, Page } from "framework7-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { siteProfileSelector } from "core/slice/site/profile";
import { goBack } from "core/usecase/global";
import { profileInitialize, updateProfile } from "core/usecase/site/profile";

import { FormikList, FormikListInput } from "ui/widget/formik";

type Shape = { height?: string };

/**
 * 身長編集画面
 * @returns HeightEditor
 */
export const HeightEditorPage: React.FC = () => {
  const { height } = useSelector(siteProfileSelector).profile;

  const formik = useFormik<Shape>({
    initialValues: {
      height: height || "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      height: yup.string().number().length(3),
    }),
    onSubmit(value) {
      updateProfile("height", value.height).then(success => {
        if (success) goBack("/profile/base");
      });
    },
  });

  const { setValues } = formik;

  /**
   * データ再設定
   */
  useEffect(() => {
    setValues({ height });
  }, [height, setValues]);

  return (
    <Page onPageBeforeIn={profileInitialize}>
      <Navbar>
        <NavLeft backLink backLinkUrl="/profile/base" backLinkForce />
        <NavTitle>身高</NavTitle>
        <NavRight>
          <Link href={false} onClick={formik.submitForm}>
            确定
          </Link>
        </NavRight>
      </Navbar>

      <FormikList formik={formik}>
        <FormikListInput type="text" name="height" label="身高(cm)" maxlength={3} />
      </FormikList>
    </Page>
  );
};
