import { Button, List, ListItem, Stepper } from "framework7-react";
import React, { useCallback, useEffect, useState } from "react";

import { Modal } from "ui/widget/modal";

type ReserveHourModalProps = {
  opened: boolean;
  onClose: () => void;
  hour: number;
  onConfirm: (hour: number) => void;
};

/**
 * ReserveHourModal
 * @param props
 * @param props.opened
 * @param props.onClose
 * @param props.hour
 * @param props.onConfirm
 * @returns ReserveHourModal
 */
export const ReserveHourModal: React.FC<ReserveHourModalProps> = ({ opened, onClose, hour, onConfirm }) => {
  const [reserveHour, setReserveHour] = useState(hour);

  useEffect(() => {
    if (!opened) return;
    setReserveHour(hour);
  }, [hour, opened]);

  /**
   * handleConfirm
   */
  const handleConfirm = useCallback(() => {
    onConfirm(reserveHour);
    onClose();
  }, [onClose, onConfirm, reserveHour]);

  return (
    <Modal
      opened={opened}
      title="预约时间"
      onClose={onClose}
      content={
        <>
          <List>
            <ListItem title={`预约时间: ${reserveHour}小时`}>
              <Stepper
                min={1}
                step={0.5}
                buttonsOnly
                value={reserveHour}
                small
                color="green"
                raised
                slot="after"
                onStepperChange={setReserveHour}
              />
            </ListItem>
          </List>
          <Button fill onClick={handleConfirm}>
            确定
          </Button>
        </>
      }
    />
  );
};
