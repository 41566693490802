import { Navbar, NavRight, Page } from "framework7-react";
import React, { useEffect } from "react";

import { initializeLatestReserveList } from "core/usecase/girl/order";

import { LatestOrder } from "ui/component/girl/order/latest-order";
import { Icon } from "ui/widget/icon";
import { IconButton } from "ui/widget/icon-button";

/**
 * 合流履歴画面
 * @returns OrderPage
 */
export const OrderPage: React.FC = () => {
  useEffect(() => {
    initializeLatestReserveList();
  }, []);

  return (
    <Page>
      <Navbar title="合流履歴">
        <NavRight>
          <IconButton onClick={() => initializeLatestReserveList(true)} icon={<Icon name="refresh" />} />
        </NavRight>
      </Navbar>
      <LatestOrder />
    </Page>
  );
};
