import { Navbar, Page, PageContent } from "framework7-react";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { PropsWithF7Router } from "routes";

import { siteOrderSelector } from "core/slice/site/order";

import { ReserveConfirmAction } from "ui/component/site/order/reserve-confirm/reserve-confirm-action";
import { ReserveContent } from "ui/component/site/order/reserve-confirm/reserve-content";
import { ReserveMemo } from "ui/component/site/order/reserve-confirm/reserve-memo";

/**
 * 予約確認ページ
 * @param root0
 * @param root0.f7router
 * @returns ReserveConfirmPage
 */
export const ReserveConfirmPage: React.FC<PropsWithF7Router> = ({ f7router }) => {
  const { reserve } = useSelector(siteOrderSelector);

  /**
   * handlePageBeforeIn
   */
  const handlePageBeforeIn = useCallback(() => {
    // 予約人数と選択されたキャスト人数の一致性チェック
    // 一致しない場合、オーダー画面に戻る
    const isInvalid = reserve.casts.some(o => o.peopleNumber !== o.selected?.length);
    if (isInvalid) {
      f7router.navigate("/order");
    }
  }, [reserve, f7router]);

  return (
    <Page pageContent={false} onPageBeforeIn={handlePageBeforeIn}>
      <Navbar title="预约确认" backLink backLinkForce backLinkUrl="/order/cast-select" />
      <PageContent style={{ paddingBottom: 60 }}>
        <ReserveContent />
        <ReserveMemo />
      </PageContent>
      <ReserveConfirmAction />
    </Page>
  );
};
