import classnames from "classnames";
import _Cropper from "cropperjs";
import React from "react";
import ReactCropper, { ReactCropperProps, ReactCropperElement } from "react-cropper";

import "cropperjs/dist/cropper.css";
import "./index.css";

/**
 * CropperInstance
 */
export type CropperInstance = _Cropper;

/**
 * getCircleCanvas
 * @param sourceCanvas
 * @returns CircleCanvas
 * @throws
 */
export const getCircleCanvas = (sourceCanvas: HTMLCanvasElement) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (context === null) {
    throw new Error("Can not get circle canvas.");
  }
  const { width } = sourceCanvas;
  const { height } = sourceCanvas;

  canvas.width = width;
  canvas.height = height;
  context.imageSmoothingEnabled = true;
  context.drawImage(sourceCanvas, 0, 0, width, height);
  context.globalCompositeOperation = "destination-in";
  context.beginPath();
  context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
  context.fill();
  return canvas;
};

/**
 * CropperProps
 */
export interface CropperProps extends ReactCropperProps, React.RefAttributes<HTMLImageElement | ReactCropperElement> {
  /** circle */
  circle?: boolean;
}

/**
 * Cropper
 * @param props
 * @param props.circle
 * @param props.className
 * @returns Cropper
 */
export const Cropper: React.FC<CropperProps> = ({ circle, className, ...options }) => (
  <ReactCropper {...options} className={classnames(className, { "cropper-circle": circle })} />
);
