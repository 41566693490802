import { Navbar, Page } from "framework7-react";
import React, { useState } from "react";

import { avatarUpload } from "core/usecase/authorization";
import { goBack } from "core/usecase/global";
import { base64ToFile } from "core/utils/file";

import { AvatarEditor } from "ui/component/avatar-editor";
import { Modal } from "ui/widget/modal";

/**
 * アバター編集画面
 * @returns ProfileAvatarEditor
 */
export const ProfileAvatarEditorPage: React.FC = () => {
  const [croppedAvatar, setCroppedAvatar] = useState<string>();
  const [fileName, setFileName] = useState<string>();

  /**
   * handleConfirm
   * @param croppedAvatar
   * @param fileName
   */
  const handleConfirm = (croppedAvatar: string, fileName: string) => {
    setCroppedAvatar(croppedAvatar);
    setFileName(fileName);
  };

  /**
   * ファイルアップロード処理
   */
  const handleUpload = () => {
    if (!croppedAvatar || !fileName) return;
    const file = base64ToFile(croppedAvatar, fileName);
    if (file === undefined) return;
    avatarUpload(file).then(success => {
      setCroppedAvatar(undefined);
      if (success) {
        goBack(["/profile", "/profile/base"]);
      }
    });
  };

  return (
    <Page>
      <Navbar title="头像设定" />
      <AvatarEditor
        onCancel={() => goBack(["/profile", "/profile/base"])}
        onChange={() => setCroppedAvatar(undefined)}
        onConfirm={handleConfirm}
      />

      <Modal
        opened={!!croppedAvatar}
        title="处理确认"
        text="是否将该图片设定为头像?"
        content={
          <div style={{ padding: "24px 24px 0 24px" }}>
            <img width="100%" src={croppedAvatar} alt="" />
          </div>
        }
        actions={[
          {
            text: "取消",
            color: "red",
            onClick: () => setCroppedAvatar(undefined),
          },
          {
            text: "确定",
            onClick: handleUpload,
          },
        ]}
      />
    </Page>
  );
};
