/** 預金種類 */
export enum DepositType {
  /** 普通 */
  usually = "1",
  /** 当座 */
  current = "2",
  /** 定期 */
  regular = "3",
  /** その他 */
  other = "4",
}

/** 性別 */
export enum Sex {
  /** 非表示 */
  hide = "0",
  /** 男 */
  male = "1",
  /** 女 */
  female = "2",
  /** 中性 */
  neuter = "3",
}

/** 血液型 */
export enum Blood {
  /** 非表示 */
  hide = "0",
  A = "1",
  B = "2",
  O = "3",
  AB = "4",
}
