import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { ListLatestReserveResponse, ListReserveResponse, OrderState } from "core/model/girl/order";

import { CommonConfig } from "global/config";

const initialState: OrderState = {
  reserveList: {
    initialized: false,
    page: 1,
    data: [],
    hasMore: true,
  },
  latestReserveList: {
    initialized: false,
    data: [],
  },
};

/**
 * slice
 */
export const OrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    /**
     * 予約一覧初期化
     * @param state
     * @param action
     */
    initializeReserveListAction: (state, action: PayloadAction<ListReserveResponse>) => {
      state.reserveList.initialized = true;
      state.reserveList.data = action.payload;
      const hasMore = action.payload.length >= CommonConfig.apiPageSize;
      state.reserveList.hasMore = hasMore;
      if (hasMore) {
        state.reserveList.page += 1;
      }
    },
    /**
     * 次のページの予約一覧を追加する
     * @param state
     * @param action
     */
    appendReserveListAction: (state, action: PayloadAction<ListReserveResponse>) => {
      state.reserveList.data = [...state.reserveList.data, ...action.payload];
      const hasMore = action.payload.length >= CommonConfig.apiPageSize;
      state.reserveList.hasMore = hasMore;
      if (hasMore) {
        state.reserveList.page += 1;
      }
    },
    /**
     * 直近の予約一覧初期化
     * @param state
     * @param action
     */
    initializeLatestReserveList: (state, action: PayloadAction<ListLatestReserveResponse>) => {
      state.latestReserveList.initialized = true;
      state.latestReserveList.data = action.payload;
    },
  },
});

/**
 * action
 */
export const OrderAction = OrderSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const OrderSelector = (state: RootState) => state.order;
