import { Page, Navbar, NavLeft, NavTitle } from "framework7-react";
import React from "react";

import { initializeAttendance } from "core/usecase/girl/schedule";

import { FormSection } from "ui/component/girl/profile/schedule-config/form-section";

/**
 * AttendancePage
 * @returns AttendancePage
 */
export const AttendancePage: React.FC = () => (
  <Page onPageBeforeIn={initializeAttendance}>
    <Navbar>
      <NavLeft backLink backLinkForce backLinkUrl="/girl/profile" />
      <NavTitle>出勤管理</NavTitle>
    </Navbar>
    <FormSection />
  </Page>
);
