import classNames from "classnames";
import { FormikContextType, FormikProvider } from "formik";
import List, { ListProps } from "framework7-react/components/list";
import React from "react";

import { mixed } from "global/types";

/**
 * FormikListProps
 */
export interface FormikListProps extends Omit<ListProps, "form" | "onSubmit"> {
  /** formik */
  formik: FormikContextType<mixed>;
  /** noDivider */
  noDivider?: boolean;
}

/**
 * FormikList
 * @param props
 * @param props.formik
 * @param props.children
 * @param props.noDivider
 * @param props.className
 * @returns FormikList
 */
export const FormikList: React.FC<FormikListProps> = ({ formik, noDivider, className, children, ...attrs }) => (
  <FormikProvider value={formik}>
    <List
      {...attrs}
      form
      onSubmit={formik.handleSubmit}
      className={classNames(className, { "list-no-divider": noDivider })}
    >
      <ul>{children}</ul>
    </List>
  </FormikProvider>
);
