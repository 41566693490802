import { dispatch, store } from "store";

import { WalletLogResponse, WalletResponse } from "core/model/site/profile/wallet";
import { siteProfileWalletAction, WalletLogQueryCondtion } from "core/slice/site/profile/wallet";
import { hidePageSpinner, showPageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";

import { SiteConfig } from "global/config";

/**
 * wallet初期化
 * @param force
 */
export const walletInitialize = async (force = false) => {
  if (!force && store.getState()["site/profile/wallet"].wallet.initialized) return true;
  showPageSpinner();
  try {
    const res = await Api.get<WalletResponse>("/site/user/wallet/l");
    if (isSuccess(res) && res.body) {
      // storeに保存する
      dispatch(siteProfileWalletAction.initializeWalletAction(res.body));
      return true;
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};

/**
 * 取引履歴一覧初期化
 * @param query
 * @returns hasMore ? true : false
 */
export const initializeWalletLog = async (query: WalletLogQueryCondtion) => {
  showPageSpinner();
  try {
    const res = await Api.get<WalletLogResponse>("/site/user/walletLog/list", {
      params: {
        page: 1,
        targetType: query.targetType === "ALL" ? "0" : query.targetType,
      },
    });
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(siteProfileWalletAction.initializeWalletLogAction(res.body));
      return res.body.length >= SiteConfig.walletLogPageSize;
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return true;
};

/**
 * 次のページの取引履歴を取得する
 * @returns hasMore ? true : false
 */
export const getWalletLogOfNextPage = async () => {
  try {
    const { query } = store.getState()["site/profile/wallet"].walletLog;
    const nextPage = store.getState()["site/profile/wallet"].walletLog.page + 1;
    const res = await Api.get<WalletLogResponse>("/site/user/walletLog/list", {
      params: {
        page: nextPage,
        targetType: query.targetType === "ALL" ? "0" : query.targetType,
      },
    });
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(siteProfileWalletAction.appendWalletLogAction(res.body));
      dispatch(siteProfileWalletAction.increaseWalletLogPageAction());
      return res.body.length >= SiteConfig.walletLogPageSize;
    }
  } catch {
    // 処理なし
  }
  return true;
};

/**
 * 検索条件更新
 * @param condition
 */
export const updateQueryCondition = (condition: WalletLogQueryCondtion) => {
  dispatch(siteProfileWalletAction.updateQueryConditionAction(condition));
};
