import React from "react";
import styled from "styled-components";

import { CommonConfig } from "global/config";

import { Icon } from "../icon";

type ContainerProps = {
  /** shape */
  shape?: "circle" | "rounded" | "rect";
  /** size: default=60 */
  size?: number;
};

/**
 * AvatarProps
 */
export interface AvatarProps extends ContainerProps, React.HTMLAttributes<HTMLDivElement> {
  /** src */
  src: string | undefined;
  /** children */
  children?: React.ReactNode;
}

const Container = styled.div<ContainerProps>(({ size = 60, shape = "rounded" }) => ({
  background: "rgba(0,0,0,.25)",
  color: "#fff",
  margin: 0,
  padding: 0,
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  verticalAlign: "middle",
  border: "1px solid transparent",
  fontSize: size / 2 + 5,
  width: size,
  height: size,
  borderRadius: shape === "circle" ? size : shape === "rounded" ? 8 : 0,

  ".icon": {
    fontSize: "inherit",
  },

  img: {
    width: "100%",
    height: "100%",
    borderRadius: shape === "circle" ? size : shape === "rounded" ? 8 : 0,
    objectFit: "cover",
  },
}));

/**
 * Avatar
 * @param props
 * @param props.src
 * @param props.size
 * @param props.children
 * @returns Avatar
 */
export const Avatar: React.FC<AvatarProps> = ({ src, size, children, ...attrs }) => (
  <Container size={size} {...attrs}>
    {src ? <img src={CommonConfig.avatarBaseUrl + src} alt="" /> : <Icon name="person_outline" />}
    {children}
  </Container>
);
