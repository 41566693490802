import classNames from "classnames";
import { Link, Swiper, SwiperSlide } from "framework7-react";
import React, { useMemo, useState } from "react";
import { useUpdate } from "react-use";
import styled from "styled-components";
import SwiperClass from "swiper/types/swiper-class";

import { ShopItem } from "core/model/site/shop";

import { CommonConfig } from "global/config";

import { Icon } from "ui/widget/icon";

const Container = styled.div({
  position: "relative",
  background: "#efeff3",

  ".swiper": {
    borderBottom: "2px solid #fff",

    ".swiper-slide": {
      height: 360,
      overflow: "hidden",

      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },

      "& > *": {
        maxWidth: "100%",
      },
    },

    ".swiper-button-prev, .swiper-button-next": {
      background: "rgba(0, 0, 0, .675)",
      width: 36,
      height: 72,
      marginTop: "calc(0px - 36px)",
    },
  },

  ".thumbnail": {
    display: "flex",
    flexFlow: "row nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    minWidth: "100%",
    borderBottom: "1px solid #999",

    ".thumbnail-wrapper": {
      width: 80,
      height: 80,
      border: "2px solid #fff",
      cursor: "pointer",
      transition: "all .2s",
      flexShrink: 0,

      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },

      "&.active, &:hover": {
        borderColor: "#f57dc9",
      },
    },
  },

  ".shop-alt": {
    height: 360,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "var(--skeleton-color)",
  },

  ".link.back": {
    position: "absolute",
    top: 24,
    left: 16,
    background: "#888",
    color: "#ddd",
    borderRadius: "50%",
    transition: "all .2s",
    width: 32,
    height: 32,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      opacity: 0.75,
    },
  },
});

type MediaSectionProps = {
  shopItem: ShopItem;
};

/**
 * MediaSection
 * @param props
 * @param props.shopItem
 * @returns MediaSection
 */
export const MediaSection: React.FC<MediaSectionProps> = ({ shopItem }) => {
  const [swiper, setSwiper] = useState<SwiperClass>();
  const update = useUpdate();

  const shopImages: string[] = useMemo(
    () =>
      Array.from(Array(8).keys())
        .map(index => Object.getOwnPropertyDescriptor(shopItem, `image${index + 1}`)?.value)
        .filter(img => !!img),
    [shopItem],
  );

  return (
    <Container>
      {shopImages.length > 0 ? (
        <>
          <Swiper navigation simulateTouch={false} onSwiper={setSwiper} onSlideChange={update}>
            {shopImages.map(img => (
              <SwiperSlide key={img}>
                <img src={CommonConfig.shopPhotoBaseUrl + img} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="thumbnail">
            {shopImages.map((img, index) => (
              <div
                key={img}
                onClick={() => swiper?.slideTo(index)}
                className={classNames("thumbnail-wrapper", {
                  active: swiper && shopImages[swiper.activeIndex] === img,
                })}
              >
                <img src={CommonConfig.shopPhotoBaseUrl + img} alt="" draggable={false} />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="shop-alt">
            <Icon name="store" size={200} color="gray" />
          </div>
        </>
      )}
      <Link href="/shop" back className="icon-only">
        <i className="icon icon-back" />
      </Link>
    </Container>
  );
};
