import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { PresentMasterListResponse } from "core/model/present/api";

import { SupportedLanguage } from "global/enum";

/**
 * state
 */
export type GlobalState = {
  /** 言語 */
  language: SupportedLanguage;
  /** プレゼントマスタ一覧 */
  presentMasterList: PresentMasterListResponse;
};

const initialState: GlobalState = {
  language: SupportedLanguage.chinese,
  presentMasterList: [],
};

/**
 * slice
 */
export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    /**
     * 言語変更
     * @param state
     * @param action
     */
    changeLanguageAction: (state, action: PayloadAction<SupportedLanguage>) => {
      state.language = action.payload;
    },
    /**
     * プレゼントマスタ一覧の初期化
     * @param state
     * @param action
     */
    initPresentMasterListAction: (state, action: PayloadAction<PresentMasterListResponse>) => {
      state.presentMasterList = action.payload;
    },
  },
});

/**
 * action
 */
export const globalAction = globalSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const globalSelector = (state: RootState) => state.global;
