import { Block, BlockTitle, Button } from "framework7-react";

import { Icon } from "ui/widget/icon";

type ReserveCompleteBlockProps = {
  reserveNo: string;
};

/**
 * ReserveCompletePage
 * @param props
 * @param props.reserveNo
 * @returns ReserveCompletePage
 */
export const ReserveCompleteBlock: React.FC<ReserveCompleteBlockProps> = ({ reserveNo }) => (
  <>
    <Block style={{ textAlign: "center", marginTop: 80 }}>
      <Icon name="check_circle" size="80px" color="green" />
      <BlockTitle medium>预约成功，等待管理员确认</BlockTitle>
      <BlockTitle>
        预约NO:&nbsp;<span>{reserveNo}</span>
      </BlockTitle>
    </Block>
    <Block>
      <Button color="orange" fill href="/order" transition="f7-push">
        返回我的订单
      </Button>
    </Block>
  </>
);
