import { Block, BlockTitle, Button, Link } from "framework7-react";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import { siteOrderSelector } from "core/slice/site/order";
import { cancelReserve } from "core/usecase/site/order";
import { showConfirmModal } from "core/utils/common";

import { Icon } from "ui/widget/icon";

import { OrderItem } from "./order-item";

/**
 * LatestOrder
 * @returns LatestOrder
 */
export const LatestOrder: React.FC = () => {
  const { latestList, processingList, expiredList } = useSelector(siteOrderSelector).latestReserveList;

  /**
   * handleCancelAllReserve
   */
  const handleCancelAllReserve = useCallback(() => {
    if (expiredList.length === 0) return;
    showConfirmModal("确定要取消所有过期的预约吗?", () => {
      const reserveIds = expiredList.map(o => o.reserveId);
      cancelReserve(reserveIds, "预约已过期");
    });
  }, [expiredList]);

  return (
    <>
      <BlockTitle style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <span>当前预约</span>
        <Link href="/order/history" transition="f7-push">
          <Icon name="format_list_bulleted" size={18} />
          &nbsp;预约记录&nbsp;&gt;
        </Link>
      </BlockTitle>
      {latestList.map(item => (
        <OrderItem key={item.reserveId} item={item} />
      ))}
      {latestList.length === 0 && (
        <Block inset strong bgColor="info" textColor="info" borderColor="info" className="text-align-middle bordered">
          <Icon name="info" />
          <strong style={{ marginLeft: 8 }}>暂时没有预约</strong>
        </Block>
      )}
      <BlockTitle>处理中的预约</BlockTitle>
      {processingList.map(item => (
        <OrderItem key={item.reserveId} item={item} />
      ))}
      {processingList.length === 0 && (
        <Block inset strong bgColor="info" textColor="info" borderColor="info" className="text-align-middle bordered">
          <Icon name="info" />
          <strong style={{ marginLeft: 8 }}>暂时没有处理中的预约</strong>
        </Block>
      )}
      {expiredList.length > 0 && (
        <>
          <Block
            inset
            strong
            bgColor="warning"
            textColor="white"
            borderColor="warning"
            className="text-align-middle bordered no-margin-bottom"
          >
            <Icon name="warning" />
            <strong style={{ marginLeft: 8 }}>以下是已经过期的预约</strong>
            <Button
              fill
              small
              style={{ marginLeft: "auto", paddingLeft: 8, minWidth: 72 }}
              onClick={handleCancelAllReserve}
            >
              一键取消
            </Button>
          </Block>
          <div>
            {expiredList.map(item => (
              <OrderItem key={item.reserveId} item={item} />
            ))}
          </div>
        </>
      )}
    </>
  );
};
