import dayjs from "dayjs";
import { BlockTitle, Link } from "framework7-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { ScheduleItem } from "core/model/girl/schedule";
import { scheduleSelector } from "core/slice/girl/schedule";
import { updateSchedule } from "core/usecase/girl/schedule";

import { Icon } from "ui/widget/icon";

import { ScheduleSettingModal } from "./schedule-setting-modal";
import { ScheduleWeek } from "./schedule-week";

/**
 * Schedule Tab
 * @returns ScheduleTab
 */
export const TabSchedule: React.FC = () => {
  const { list } = useSelector(scheduleSelector);
  const [clickedScheduleItem, setClickedScheduleItem] = useState<ScheduleItem>();

  /**
   * スケジュールitemクリック処理
   * @param item
   */
  const hanldeScheduleItemClick = (item: ScheduleItem) => {
    // 過去日の場合、更新不可
    if (dayjs().isAfter(dayjs(item.workYmd, "YYYYMMDD"), "day")) {
      return;
    }
    setClickedScheduleItem(item);
  };

  /**
   * スケジュール更新
   * @param item
   */
  const handleScheduleUpdate = (item: ScheduleItem) => {
    const { workStartTime, workEndTime, ...requestData } = item;
    updateSchedule(requestData).then(success => {
      if (success) setClickedScheduleItem(undefined);
    });
  };

  return (
    <>
      <BlockTitle style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        直近のスケジュール
        <Link href="/girl/profile/attendance" transition="f7-cover-v" color="orange">
          <Icon name="settings" size={20} />
          <span>出勤管理</span>
        </Link>
      </BlockTitle>
      <ScheduleWeek label="今週" list={list?.slice(0, 7)} onStatusClick={hanldeScheduleItemClick} />
      <ScheduleWeek label="来週" list={list?.slice(7)} onStatusClick={hanldeScheduleItemClick} />

      <ScheduleSettingModal
        item={clickedScheduleItem}
        onCancel={() => setClickedScheduleItem(undefined)}
        onConfirm={handleScheduleUpdate}
      />
    </>
  );
};
