import { useFormik } from "formik";
import { Block, Button, ListItem, LoginScreenTitle } from "framework7-react";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDebounce } from "react-use";
import * as yup from "yup";

import { SignupModel } from "core/model/signup";
import { goBack } from "core/usecase/global";
import * as usecase from "core/usecase/signup";

import { Divider } from "ui/widget/divider";
import { FormikList, FormikListInput } from "ui/widget/formik";
import { Icon } from "ui/widget/icon";

/**
 * バリデーションスキーマ
 * @returns バリデーションスキーマ
 */
const createEmailSchema = () => yup.string().required().email().max(45);
const createSchema = (userExistError: string, passwordLabel: string): yup.ObjectSchema<SignupModel> =>
  yup.object().shape({
    email: createEmailSchema().test("user-exist", userExistError, () => !userExistError),
    password: yup.string().required().lengthBetween(6, 45),
    passwordConfirm: yup
      .string()
      .required()
      .equalWith({ ref: yup.ref("password"), label: passwordLabel }),
  });

/**
 * SignupProps
 */
type SignupFormProps = {
  /** メールアドレス */
  email?: string;
  /** パスワード */
  password?: string;
  /** onSubmit */
  onSubmit: (values: SignupModel) => void;
  /** ログインページリンク */
  loginLink: string;
};

/**
 * Signupフォーム
 * @param props
 * @param props.email
 * @param props.password
 * @param props.onSubmit
 * @param props.loginLink
 * @returns SignupForm
 */
export const SignupForm: React.FC<SignupFormProps> = ({ email, password, onSubmit, loginLink }) => {
  const [userExistError, setUserExistError] = useState("");
  const intl = useIntl();

  const validationSchema = useMemo(
    () => createSchema(userExistError, intl.formatMessage({ id: "signup.password" })),
    [intl, userExistError],
  );

  const formik = useFormik<SignupModel>({
    initialValues: {
      email: email ?? "",
      password: password ?? "",
      passwordConfirm: password ?? "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit,
  });

  /**
   * メールアドレス変更処理
   * @param e
   */
  useDebounce(
    () => {
      const { email } = formik.values;
      if (createEmailSchema().isValidSync(email)) {
        // ユーザ存在チェック
        usecase.userExist(email).then(msg => setUserExistError(msg ?? ""));
      }
    },
    200,
    [formik.values.email],
  );

  return (
    <Block inset strong>
      <LoginScreenTitle>
        <FormattedMessage id="signup.title" />
      </LoginScreenTitle>
      <br />
      <FormikList noDivider formik={formik}>
        {/* メールアドレス */}
        <FormikListInput
          type="text"
          placeholder={intl.formatMessage({ id: "signup.email.placeholder" })}
          name="email"
          outline
        >
          <div slot="label">
            <FormattedMessage id="signup.email" />
          </div>
          <Icon name="person_outline" size="28px" slot="media" style={{ marginTop: 28 }} />
        </FormikListInput>

        {/* パスワード */}
        <FormikListInput
          type="password"
          placeholder={intl.formatMessage({ id: "signup.password.placeholder" })}
          name="password"
          outline
        >
          <div slot="label">
            <FormattedMessage id="signup.password" />
          </div>
          <Icon name="lock_outline" size="28px" slot="media" style={{ marginTop: 28 }} />
        </FormikListInput>

        {/* 確認パスワード */}
        <FormikListInput
          type="password"
          placeholder={intl.formatMessage({ id: "signup.password.confirm.placeholder" })}
          name="passwordConfirm"
          outline
        >
          <div slot="label">
            <FormattedMessage id="signup.password.confirm" />
          </div>
          <Icon name="lock_outline" size="28px" slot="media" style={{ marginTop: 28 }} />
        </FormikListInput>

        <ListItem className="margin-top">
          <Button type="submit" fill>
            <FormattedMessage id="common.next" />
          </Button>
        </ListItem>

        <ListItem>
          <Divider label={<FormattedMessage id="common.or" />} style={{ padding: 0 }} />
        </ListItem>

        <ListItem>
          <Button type="button" outline onClick={() => goBack(loginLink)}>
            <FormattedMessage id="common.back.login" />
          </Button>
        </ListItem>
      </FormikList>
    </Block>
  );
};
