import dayjs from "dayjs";
import React, { PropsWithChildren } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { store } from "store";

import { globalSelector } from "core/slice/global";

import { SupportedLanguage } from "global/enum";

import LabelsJa from "./ja/labels.json";
import LabelsZh from "./zh/labels.json";

import "dayjs/locale/zh-cn";
import "dayjs/locale/ja";

dayjs.locale("zh-cn");

/** messages */
const messages = {
  zh: LabelsZh,
  ja: LabelsJa,
};

/** MessageKey */
export type MessageKey = keyof typeof LabelsJa;

/**
 * getLocaleMessage
 * @param id
 * @param language
 * @returns message
 */
export const getLocaleMessage = (id: MessageKey, language?: SupportedLanguage) => {
  const lang = language ?? store.getState().global.language;
  if (lang === SupportedLanguage.japanese) {
    return messages.ja[id];
  }
  return messages.zh[id];
};

/**
 * Localized
 * @param props
 * @param props.children
 * @returns IntlProvider
 */
export const Localized: React.FC<PropsWithChildren> = ({ children }) => {
  const { language } = useSelector(globalSelector);

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      {children}
    </IntlProvider>
  );
};
