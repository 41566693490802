import Compressor from "compressorjs";
import heic2any from "heic2any";

/**
 * getImageSize
 * @param src
 * @returns width height
 */
export const getImageSize = (src: string) =>
  new Promise<{ width: number; height: number }>(resolve => {
    const image = new Image();
    image.onload = () => {
      resolve({
        width: image.naturalWidth,
        height: image.naturalHeight,
      });
    };
    image.src = src;
  });

/**
 * heicからjpgへの変換処理
 * @param file
 * @returns jpg file
 */
export const heicToJpg = (file: File): Promise<File> => {
  if (!/^image\/.+$/.test(file.type)) return Promise.reject(new Error("画像を選択してください"));
  if (["image/heic", "image/heif"].includes(file.type)) {
    const baseName = file.name.substring(0, file.name.lastIndexOf("."));
    return heic2any({
      blob: file,
      toType: "image/jpeg",
      quality: 1,
    }).then(blob => Promise.resolve(new File([blob as Blob], `${baseName}.jpg`, { type: (blob as Blob).type })));
  }
  return Promise.resolve(file);
};

/**
 * 画像変換圧縮処理
 * @param file
 * @param options
 * @returns compressed file
 */
export const compress = async (file: File, options?: Compressor.Options) =>
  new Promise<File>((resolve, reject) => {
    if (!/^image\/.+$/.test(file.type)) {
      reject(new Error("画像を選択してください"));
    } else {
      new Compressor(file, {
        quality: file.size > 1024 * 1024 ? 0.8 : 1,
        maxWidth: 1200,
        maxHeight: 1200,
        success(result) {
          resolve(result as File);
        },
        error: reject,
        ...options,
      });
    }
  });
