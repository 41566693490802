import { dispatch, store } from "store";

import { GirlListResponse, GirlSearchCondition } from "core/model/site/top";
import { siteTopAction } from "core/slice/site/top";
import { hidePageSpinner, showPageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";

import { CommonConfig } from "global/config";

/**
 * girl一覧初期化
 * @returns hasMore ? true : false
 */
export const initializeGirls = async () => {
  if (store.getState()["site/top"].initialized) return false;

  showPageSpinner();
  try {
    const res = await Api.post<GirlListResponse>("/site/user/girl/list", {
      page: 1,
    });
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(siteTopAction.initializeGirlsAction(res.body));
      return res.body.length >= CommonConfig.apiPageSize;
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};

/**
 * girl一覧取得
 * @param condition
 * @returns hasMore ? true : false
 */
export const searchGirls = async (condition: GirlSearchCondition) => {
  showPageSpinner();
  try {
    const res = await Api.post<GirlListResponse>("/site/user/girl/list", {
      page: 1,
      ...condition,
    });
    // 成功の場合
    if (isSuccess(res)) {
      dispatch(siteTopAction.resetGirlsAction(res.body ?? []));
      dispatch(siteTopAction.updateSearchConditionAction(condition));
      if (!res.body || res.body.length < CommonConfig.apiPageSize) {
        return false;
      }
      return true;
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};

/**
 * 次のページのgirlListを取得する
 * @param page
 * @param condition
 * @returns hasMore ? true : false
 */
export const getGirlsOfNextPage = async (page: number, condition: GirlSearchCondition) => {
  try {
    const res = await Api.post<GirlListResponse>("/site/user/girl/list", {
      page,
      ...condition,
    });
    // 成功の場合
    if (isSuccess(res)) {
      dispatch(siteTopAction.updateSearchConditionAction(condition));
      if (res.body) {
        dispatch(siteTopAction.appendGirlsAction(res.body));
        dispatch(siteTopAction.increasePageAction());
        return res.body.length >= CommonConfig.apiPageSize;
      }
    }
  } catch {
    // 処理なし
  }
  return false;
};
