/**
 * 合流予定時間
 */
export enum ReserveType {
  /** 30分後（今すぐ） */
  halfHourLater = "1",
  /** 60分後 */
  oneHourLater = "2",
  /** 90分後 */
  oneAndHalfHourLater = "3",
  /** 上記時間以外 */
  other = "9",
}

/**
 * 予約ステータス
 */
export enum ReserveStatus {
  /** 处理中(手続中) */
  PROCESSING = "1",
  /** 予約成功 */
  SUCCESS = "2",
  /** 予約失敗(取り下げ) */
  FAIL = "3",
  /** 予約取消 */
  CANCEL = "4",
  /** 削除 */
  DELETE = "9",
}
