import { Block, Button, Page } from "framework7-react";
import React, { useEffect } from "react";

import { bankInitialize } from "core/usecase/girl/bank";
import { followFavoriteInitialize } from "core/usecase/girl/follow";
import { mediaListInitialize } from "core/usecase/girl/media";
import { scheduleListInitialize } from "core/usecase/girl/schedule";

import { AvatarSection, FollowSection, TabsSection } from "ui/component/girl/profile";
import { Icon } from "ui/widget/icon";

/**
 * プロファイル
 * @returns Profile
 */
export const ProfilePage: React.FC = () => {
  useEffect(() => {
    // フォロー、フォロワー、いいねの総件数取得
    followFavoriteInitialize();
    // スケジュール一覧取得
    scheduleListInitialize();
    // 写真・ビデオ一覧取得
    mediaListInitialize();
    // 口座情報取得
    bankInitialize();
  }, []);

  return (
    <Page noNavbar>
      <AvatarSection />
      <FollowSection />
      <Block style={{ margin: "12px 0", display: "flex", justifyContent: "center" }}>
        <Button outline color="blue" href="/girl/profile/base" transition="f7-push" style={{ width: "50%" }}>
          <Icon name="border_color" shape="outlined" size={18} />
          &nbsp;プロファイルを編集
        </Button>
      </Block>
      <TabsSection />
    </Page>
  );
};
