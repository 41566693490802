import classNames from "classnames";
import { Link } from "framework7-react";
import React from "react";

// const Container = styled.div({
//   padding: "4px 15px",
//   display: "inline-flex",
//   alignItems: "center",
//   justifyContent: "center",
//   cursor: "pointer",
//   transition: "all .2s cubic-bezier(.645,.045,.355,1)",
//   userSelect: "none",
//   touchAction: "manipulation",
//   lineHeight: "1.5714285714285714",
//   color: "rgba(0,0,0,.88)",

//   "&:hover, &:active": {
//     opacity: 0.5,
//   },
// });

/**
 * IconButtonProps
 */
export interface IconButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  /** icon */
  icon: React.ReactNode;
  /** onClick */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

/**
 * IconButton
 * @param props
 * @param props.icon
 * @param props.onClick
 * @param props.className
 * @returns IconButton
 */
export const IconButton: React.FC<IconButtonProps> = ({ icon, onClick, className, ...attrs }) => (
  <Link href={false} onClick={onClick} className={classNames("icon-button", className)} {...attrs}>
    {icon}
  </Link>
);
