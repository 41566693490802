import { Navbar, NavTitle, SkeletonBlock } from "framework7-react";

import { InitMessageResponse } from "core/model/talk";

import { OnlineStatus } from "global/enum";

import { useOnlineStatus } from "ui/hooks/use-online-status";
import { OnlineStatusBar } from "ui/widget/online-status-bar";

type MessageNavProps = {
  initialized: boolean;
  data: InitMessageResponse | undefined;
  backLinkUrl: string;
};

/**
 * MessageNav
 * @param props
 * @param props.data
 * @param props.backLinkUrl
 * @param props.initialized
 * @returns MessageNav
 */
export const MessageNav: React.FC<MessageNavProps> = ({ initialized, data, backLinkUrl }) => {
  const onlineStatus = useOnlineStatus(data?.talkUserId);

  return (
    <Navbar backLink backLinkForce backLinkUrl={backLinkUrl}>
      <NavTitle>
        {initialized && data ? (
          <>
            {data.talkUserName}
            <div className="subtitle">
              <OnlineStatusBar
                online={onlineStatus?.online === OnlineStatus.online}
                lastConnectedDtm={onlineStatus?.lastConnectedDtm}
              />
            </div>
          </>
        ) : (
          <>
            <SkeletonBlock tag="div" width="100px" height="21px" borderRadius="0" effect="wave" />
            <div slot="subtitle">
              <SkeletonBlock
                tag="div"
                width="60px"
                height="16px"
                borderRadius="0"
                effect="wave"
                style={{ marginTop: 4 }}
              />
            </div>
          </>
        )}
      </NavTitle>
    </Navbar>
  );
};
