import { dispatch, store } from "store";

import {
  TimelineCreateRequest,
  TimelineCreateResponse,
  TimelineItem,
  TimelineListResponse,
} from "core/model/site/timeline";
import { siteTimelineAction } from "core/slice/site/timeline";
import { hidePageSpinner, showPageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";

import { SiteConfig } from "global/config";

/**
 * timeline一覧初期化
 * @returns hasMore ? true : false
 */
export const initializeTimelines = async () => {
  if (store.getState()["site/timeline"].initialized) return true;

  showPageSpinner();
  try {
    const res = await Api.get<TimelineListResponse>("/site/user/timeline/list", {
      params: {
        page: 1,
      },
    });
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(siteTimelineAction.initializeTimelinesAction(res.body));
      return res.body.length >= SiteConfig.timelinePageSize;
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return true;
};

/**
 * 次のページのTimeline Listを取得する
 * @returns hasMore ? true : false
 */
export const getTimelinesOfNextPage = async () => {
  try {
    const nextPage = store.getState()["site/timeline"].page + 1;
    const res = await Api.get<TimelineListResponse>("/site/user/timeline/list", {
      params: {
        page: nextPage,
      },
    });
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(siteTimelineAction.appendTimelinesAction(res.body));
      dispatch(siteTimelineAction.increasePageAction());
      return res.body.length >= SiteConfig.timelinePageSize;
    }
  } catch {
    // 処理なし
  }
  return true;
};

/**
 * timeline新規作成
 * @param message
 * @param image
 * @returns 処理結果(true / false)
 */
export const newTimeline = async (message: string, image?: File) => {
  showPageSpinner();
  try {
    const request: TimelineCreateRequest = { message };
    // 画像が存在している場合は、先に画像アップロード処理を行う
    if (image) {
      const formData = new FormData();
      formData.append("file", image, image.name);
      const res = await Api.post<string>("/site/user/timeline/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (isSuccess(res) && res.body) {
        request.image = res.body;
      }
    }

    // timelineの作成処理を行う
    const res = await Api.post<TimelineCreateResponse>("/site/user/timeline/c", request);
    // 成功の場合
    if (isSuccess(res) && res.body) {
      const { principal } = store.getState().authorization;
      dispatch(siteTimelineAction.prependTimelineAction({ ...principal!, ...res.body }));
      return true;
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};

/**
 * toggleFavorite
 * @param timelineItem
 */
export const toggleFavorite = async (timelineItem: TimelineItem) => {
  try {
    const res = await Api.get("/site/user/timeline/favorite", { params: { timelineId: timelineItem.id } });
    // 成功の場合
    if (isSuccess(res)) {
      const { favorited, favoriteCount, ...others } = timelineItem;
      if (favorited) {
        dispatch(
          siteTimelineAction.updateTimelineItem({ ...others, favorited: false, favoriteCount: favoriteCount - 1 }),
        );
      } else {
        dispatch(
          siteTimelineAction.updateTimelineItem({ ...others, favorited: true, favoriteCount: favoriteCount + 1 }),
        );
      }
      return true;
    }
  } catch {
    // 処理なし
  }
  return false;
};

/**
 * 指定するページのuser timeline一覧取得
 * @param page
 * @param user
 * @returns user timeline一覧
 */
export const getUserTimelines = async (page: number, user: string) => {
  if (page === 1) showPageSpinner();
  try {
    const res = await Api.get<TimelineListResponse>("/site/user/timeline/list", {
      params: {
        page,
        user,
      },
    });
    // 成功の場合
    if (isSuccess(res) && res.body) {
      return res.body;
    }
  } catch {
    // 処理なし
  } finally {
    if (page === 1) hidePageSpinner();
  }
  return [];
};

/**
 * deleteTimelineItem
 * @param timelineId
 */
export const deleteTimelineItem = async (timelineId: string) => {
  try {
    const res = await Api.post("/site/user/timeline/d", { id: timelineId });
    // 成功の場合
    if (isSuccess(res)) {
      dispatch(siteTimelineAction.deleteTimelineItem(timelineId));
      return true;
    }
  } catch {
    // 処理なし
  }
  return false;
};
