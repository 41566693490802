import { Block, BlockTitle, List, ListItem } from "framework7-react";
import React from "react";
import { FormattedMessage } from "react-intl";

import { ProfileResponse } from "core/model/girl/profile";

import { EducationLabel, HairColorLabel, HairTypeLabel, JobLabel, SakeLabel, TobaccoLabel } from "global/enum-label";

import { Divider } from "ui/widget/divider";

type BaseInfoSectionProps = {
  profile: ProfileResponse;
};

/**
 * BaseInfoSection
 * @param props
 * @param props.profile
 * @returns BaseInfoSection
 */
export const BaseInfoSection: React.FC<BaseInfoSectionProps> = ({ profile }) => (
  <Block strong>
    <BlockTitle medium>自我介绍</BlockTitle>
    <div style={{ whiteSpace: "pre-line" }}>{profile.note || "这个人很懒，什么也没有写"}</div>
    <Divider padding={false} />

    <BlockTitle medium className="no-margin-left">
      兴趣爱好
    </BlockTitle>
    <div style={{ whiteSpace: "pre-line" }}>{profile.interest || "这个人很懒，什么也没有写"}</div>
    <Divider padding={false} />

    <BlockTitle medium className="no-margin-left">
      基本信息
    </BlockTitle>
    <List>
      <ListItem title="身高(cm)" after={profile.height} />
      <ListItem title="居住地" after={profile.address} />
      <ListItem title="家乡" after={profile.birthplace} />
      <ListItem title="学历">
        {profile.educationBk && (
          <div slot="after">
            <FormattedMessage id={EducationLabel[profile.educationBk]} />
          </div>
        )}
      </ListItem>
      <ListItem title="职业">
        {profile.job && (
          <div slot="after">
            <FormattedMessage id={JobLabel[profile.job]} />
          </div>
        )}
      </ListItem>
      <ListItem title="吸烟">
        {profile.tobacco && (
          <div slot="after">
            <FormattedMessage id={TobaccoLabel[profile.tobacco]} />
          </div>
        )}
      </ListItem>
      <ListItem title="喝酒">
        {profile.sake && (
          <div slot="after">
            <FormattedMessage id={SakeLabel[profile.sake]} />
          </div>
        )}
      </ListItem>
      <ListItem title="发型">
        {profile.hairType && (
          <div slot="after">
            <FormattedMessage id={HairTypeLabel[profile.hairType]} />
          </div>
        )}
      </ListItem>
      <ListItem title="发色">
        {profile.hairColor && (
          <div slot="after">
            <FormattedMessage id={HairColorLabel[profile.hairColor]} />
          </div>
        )}
      </ListItem>
    </List>
  </Block>
);
