import { mixed } from "global/types";

type LoggerType = "INFO" | "WARN" | "ERROR";

/**
 * log
 * @param type
 * @param message
 * @param args
 */
const log = (type: LoggerType, message: mixed, ...args: mixed[]) => {
  console.log(`[${type}]`, message, args.join(", "));
};

/**
 * info
 * @param message
 * @param args
 */
const info = (message: mixed, ...args: mixed[]) => {
  log("INFO", message, ...args);
};

/**
 * warn
 * @param message
 * @param args
 */
const warn = (message: mixed, ...args: mixed[]) => {
  log("WARN", message, ...args);
};

/**
 * info
 * @param message
 * @param args
 */
const error = (message: mixed, ...args: mixed[]) => {
  log("ERROR", message, ...args);
};

export default {
  info,
  warn,
  error,
};
