import ErrorSvg from "assets/image/error.svg";
import { Block, BlockTitle, Button, Col, Page, Row } from "framework7-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { PropsWithF7Router } from "routes";

import { authorizationSelector } from "core/slice/authorization";

import { Role } from "global/enum";

/**
 * 権限認証失敗画面
 * @param props
 * @param props.f7route
 * @returns AccessDeniedPage
 */
export const AccessDeniedPage: React.FC<PropsWithF7Router> = ({ f7route }) => {
  const { principal } = useSelector(authorizationSelector);

  return (
    <Page noNavbar noToolbar>
      <Block style={{ textAlign: "center", marginTop: 40 }}>
        <img src={ErrorSvg} alt="access denied" style={{ maxWidth: 360 }} />
        <BlockTitle medium>
          <FormattedMessage id="common.access.denied.title" />
        </BlockTitle>
        <BlockTitle>
          <FormattedMessage id="common.access.denied.message1" />
          <br />
          <FormattedMessage id="common.access.denied.message2" />
        </BlockTitle>
      </Block>
      <Block>
        {principal?.role === Role.girl ? (
          <Button fill href="/girl/profile">
            <FormattedMessage id="common.back.mypage" />
          </Button>
        ) : (
          <Row>
            <Col>
              <Button fill href="/">
                <FormattedMessage id="common.back.top" />
              </Button>
            </Col>
            <Col>
              <Button outline href="/profile">
                <FormattedMessage id="common.back.mypage" />
              </Button>
            </Col>
          </Row>
        )}
      </Block>
    </Page>
  );
};
