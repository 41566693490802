import classNames from "classnames";
import React from "react";
import styled from "styled-components";

const Container = styled.div<{ padding: boolean; margin: boolean; backRhombus: boolean }>(
  ({ padding, margin, backRhombus }) => ({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: padding ? "calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left))" : 0,
    paddingRight: padding ? "calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-right))" : 0,
    fontSize: "var(--f7-block-font-size)",
    margin: margin ? "var(--f7-block-margin-vertical) 0" : 0,

    ".divider-fill": {
      display: "block",
      height: 1,
      flex: 1,
      background: "var(--f7-list-item-border-color)",
      transformRrigin: "50% 100%",
      transform: "scaleY(calc(1 / var(--f7-device-pixel-ratio)))",
    },

    ".divider-label": {
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: 16,
      color: "var(--f7-theme-color-text-color)",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "&:before": {
        content: backRhombus ? "''" : undefined,
        position: "absolute",
        display: "block",
        width: 36,
        height: 36,
        background: "var(--f7-theme-color-divider-bg-color)",
        transform: "rotate(45deg)",
        zIndex: -1,
      },
    },
  }),
);

/**
 * DividerProps
 */
export interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
  /** padding */
  padding?: boolean;
  /** margin */
  margin?: boolean;
  /** label */
  label?: React.ReactNode;
  /** back-rhombus */
  backRhombus?: boolean;
}

/**
 * Divider
 * @param props
 * @param props.padding
 * @param props.margin
 * @param props.label
 * @param props.backRhombus
 * @param props.className
 * @returns Divider
 */
export const Divider: React.FC<DividerProps> = ({
  label,
  padding = true,
  margin = true,
  backRhombus = false,
  className,
  ...attrs
}) => (
  <Container
    padding={padding}
    margin={margin}
    backRhombus={backRhombus}
    className={classNames("divider", className)}
    {...attrs}
  >
    <div className="divider-fill" />
    {label && <div className="divider-label">{label}</div>}
    <div className="divider-fill" />
  </Container>
);
