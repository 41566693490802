import SVGLine from "assets/icon/line.svg";
import { Block, BlockTitle, Link, List, ListItem } from "framework7-react";
import React from "react";
import styled from "styled-components";

import { ShopItem } from "core/model/site/shop";

import { Icon } from "ui/widget/icon";

type DescSectionProps = {
  shopItem: ShopItem;
};

const Container = styled.div({
  ".list": {
    ".item-media": {
      minWidth: "auto",
    },

    ".item-after": {
      whiteSpace: "pre-line",
      maxWidth: "calc(100vw - 140px)",
    },

    "@media (min-width: 540px)": {
      ".item-after": {
        maxWidth: 400,
      },
    },
  },
});

/**
 * 店舗基本情報
 * @param props
 * @param props.shopItem
 * @returns DescSection
 */
export const DescSection: React.FC<DescSectionProps> = ({ shopItem }) => (
  <Container>
    <Block strong className="no-margin">
      <BlockTitle medium className="no-margin-bottom">
        {shopItem.shopName}
      </BlockTitle>
    </Block>
    <List className="no-margin-top">
      <ListItem title="所在地" after={shopItem.access}>
        <Icon name="place" color="orange" slot="media" />
      </ListItem>
      <ListItem title="电话">
        <Icon name="call" color="green" slot="media" />
        <Link href={`tel:${shopItem.tel}`} slot="after" external target="_system">
          {shopItem.tel}
        </Link>
      </ListItem>
      <ListItem title="营业时间" after={shopItem.openTime}>
        <Icon name="schedule" color="purple" slot="media" />
      </ListItem>
      <ListItem title="LINE" after={shopItem.line}>
        <img src={SVGLine} width={24} alt="" slot="media" />
      </ListItem>
      <ListItem title="主页">
        <Icon name="home" color="red" slot="media" />
        <Link href={shopItem.link} slot="after" external className="text-align-middle" target="_blank">
          {shopItem.link}&nbsp;
          <Icon name="open_in_new" size={16} />
        </Link>
      </ListItem>
    </List>
  </Container>
);
