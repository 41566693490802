import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { FollowFavoriteResponse } from "core/model/girl/follow";

/**
 * state
 */
export type FollowState = {
  /** フォロー、フォロワー、いいねの総件数取得response情報 */
  followFavorite?: FollowFavoriteResponse;
};

const initialState: FollowState = {};

/**
 * slice
 */
export const followSlice = createSlice({
  name: "follow",
  initialState,
  reducers: {
    updateFollowFavoriteAction: (state, action: PayloadAction<FollowFavoriteResponse>) => {
      state.followFavorite = action.payload;
    },
  },
});

/**
 * action
 */
export const followAction = followSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const followSelector = (state: RootState) => state.follow;
