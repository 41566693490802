import { useFormik } from "formik";
import { Navbar, NavLeft, Link, NavTitle, NavRight, Page } from "framework7-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { profileSelector } from "core/slice/girl/profile";
import { updateProfile, profileInitialize } from "core/usecase/girl/profile";
import { goBack } from "core/usecase/global";

import { FormikList, FormikListInput } from "ui/widget/formik";

type Shape = { address?: string };

/**
 * 居住地編集画面
 * @returns AddressEditor
 */
export const AddressEditorPage: React.FC = () => {
  const { address } = useSelector(profileSelector);

  const formik = useFormik<Shape>({
    initialValues: {
      address: address || "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      address: yup.string().max(200),
    }),
    onSubmit(value) {
      updateProfile("/profile/address/s", value).then(success => {
        if (success) goBack("/girl/profile/base");
      });
    },
  });

  const { setValues } = formik;

  /**
   * データ再設定
   */
  useEffect(() => {
    setValues({ address });
  }, [address, setValues]);

  return (
    <Page onPageBeforeIn={profileInitialize}>
      <Navbar>
        <NavLeft backLink backLinkUrl="/girl/profile/base" backLinkForce />
        <NavTitle>居住地</NavTitle>
        <NavRight>
          <Link href={false} onClick={formik.submitForm}>
            確定
          </Link>
        </NavRight>
      </Navbar>

      <FormikList formik={formik}>
        <FormikListInput
          type="text"
          name="address"
          label="居住地"
          maxlength={200}
          info="200文字以内で入力してください"
        />
      </FormikList>
    </Page>
  );
};
