import { dispatch, store } from "store";

import { ProfileResponse } from "core/model/girl/profile";
import { profileAction, ProfileState } from "core/slice/girl/profile";
import { hidePageSpinner, showPageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";

/**
 * プロファイル取得
 */
export const profileInitialize = async () => {
  if (store.getState().profile.userId) return;
  // プロファイル取得処理
  showPageSpinner();
  try {
    const res = await Api.get<ProfileResponse>("/user/userinfo/l");
    if (isSuccess(res)) {
      // storeに保存する
      dispatch(profileAction.updateAction(res.body as ProfileResponse));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};

/**
 * プロファイルitem更新
 * @param url
 * @param data
 */
export const updateProfile = async (url: string, data: ProfileState) => {
  try {
    const res = await Api.post(url, data);
    if (isSuccess(res)) {
      // store更新
      dispatch(profileAction.updateAction(data));
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  }
  return false;
};
