import classNames from "classnames";
import { Actions, ActionsButton, ActionsGroup, Button } from "framework7-react";
import React, { useCallback, useState } from "react";
import { FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { globalSelector } from "core/slice/global";

import { CommonConfig } from "global/config";

const Container = styled.div({
  ".actions-group": {
    padding: 8,
  },

  ".actions-button": {
    width: "25%",

    "&.actions-selected": {
      border: "1px solid #666",
      borderRadius: "8px !important",
      background: "rgba(0, 0, 0, .25)",
    },

    ".button": {
      color: "#fff",
    },
  },

  ".actions-button-text": {
    height: "auto",
  },
});

type PresentSheetProps = {
  opened: boolean;
  onClosed: () => void;
  onSendPresent: (presentId: string) => void;
};

/**
 * PresentSheet
 * @param props
 * @param props.opened
 * @param props.onClosed
 * @param props.onSendPresent
 * @returns PresentSheet
 */
export const PresentSheet: React.FC<PresentSheetProps> = ({ opened, onClosed, onSendPresent }) => {
  const { presentMasterList } = useSelector(globalSelector);
  const [selected, setSelected] = useState<string>();

  /**
   * プレゼント送る処理
   * @param e
   * @param presentId
   */
  const handlePresentSendClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, presentId: string) => {
      e.stopPropagation();
      onSendPresent(presentId);
    },
    [onSendPresent],
  );

  return (
    <Actions grid opened={opened} onActionsClosed={onClosed} className="actions-modal-fluid">
      <Container>
        <ActionsGroup>
          {presentMasterList.map(item => (
            <ActionsButton
              key={item.presentId}
              className={classNames({ "actions-selected": selected === item.presentId })}
              onClick={() => setSelected(item.presentId)}
              close={false}
            >
              <img slot="media" src={CommonConfig.presentBaseUrl + item.iconFile} width="40" alt="present" />
              {selected === item.presentId ? (
                <Button fill small color="red" onClick={e => handlePresentSendClick(e, item.presentId)}>
                  赠送
                </Button>
              ) : (
                <>
                  <strong>{item.presentName}</strong>
                  <br />
                  <span>
                    <FormattedNumber value={item.price} />
                    &nbsp;P
                  </span>
                </>
              )}
            </ActionsButton>
          ))}
        </ActionsGroup>
      </Container>
    </Actions>
  );
};
