import { Fab, PageContent } from "framework7-react";
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { InitMessageResponse } from "core/model/talk";
import { listPrevPageMessages } from "core/usecase/talk";
import { isInViewport } from "core/utils/common";

import { Icon } from "ui/widget/icon";

type MessageListContentProps = PropsWithChildren<{
  initialized: boolean;
  data: InitMessageResponse | undefined;
}>;

const FabUnreadMessage = styled(Fab)({
  right: 0,
  transition: "all .75s",

  "&.fab-hide": {
    opacity: 0,
    transform: "translate3d(2000px, 0, 0) scaleX(2)",
  },

  a: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    height: 40,
    width: 160,
    display: "inline-flex",
    alignItems: "center",
    flexFlow: "row nowrap",

    ".icon": {
      position: "unset",
      transform: "none",
    },
  },
});

/**
 * MessageListContent
 * @param props
 * @param props.initialized
 * @param props.data
 * @param props.children
 * @returns MessageListContent
 */
export const MessageListContent: React.FC<MessageListContentProps> = ({ initialized, data, children }) => {
  const pageContentRef = useRef<{ el: HTMLElement | null }>({ el: null });
  // infinite loading
  const allowInfinite = useRef(true);
  const [loadMore, setLoadMore] = useState(true);
  // show fab
  const [showFab, setShowFab] = useState(false);
  // layoutInitialized
  const [layoutInitialized, setLayoutInitialized] = useState(false);

  const talkId = data?.talkId;
  const firstMessageSendDtm = data?.messageList[0]?.sendDtm;

  /**
   * 画面レイアウト設定
   */
  useEffect(() => {
    const pageContent = pageContentRef.current.el;
    if (!initialized || !pageContent) return;
    setTimeout(() => {
      // message bar height
      pageContent.style.paddingBottom = `${pageContent.parentElement!.querySelector(".messagebar")?.clientHeight}px`;
      // scroll to bottom
      pageContent.scrollTo({ top: pageContent.scrollHeight + pageContent.clientHeight });
      // layoutInitialized
      setLayoutInitialized(true);
    }, 300);
  }, [initialized]);

  /**
   * fab設定
   */
  useEffect(() => {
    const pageContent = pageContentRef.current.el;
    if (!layoutInitialized || !pageContent) return undefined;
    // fab ボタン
    const fabEl = pageContent.querySelector<HTMLElement>(".readed-message-title");

    const scrollListener = () => {
      if (!fabEl) return;
      const inViewport = isInViewport(fabEl, { top: 64 });
      if (inViewport) {
        setShowFab(false);
        pageContent.removeEventListener("scroll", scrollListener);
      }
    };
    if (!fabEl || isInViewport(fabEl, { top: 64 })) {
      setShowFab(false);
      return undefined;
    }
    setShowFab(true);
    // scroll event
    pageContent.addEventListener("scroll", scrollListener);
    return () => {
      pageContent.removeEventListener("scroll", scrollListener);
    };
  }, [layoutInitialized]);

  /**
   * handleInfinite
   */
  const handleInfinite = useCallback(() => {
    const pageContent = pageContentRef.current.el;
    if (allowInfinite.current === false || !initialized || !firstMessageSendDtm || !talkId || !pageContent) return;
    allowInfinite.current = false;

    // first message element
    const firstMessageEl = pageContent.querySelectorAll(".message")[0];

    // 前のページのトークメッセージ情報を取得する
    listPrevPageMessages({ talkId, date: firstMessageSendDtm })
      .then(setLoadMore)
      .finally(() => {
        // allowInfinite.current = true;
        // スクロールを維持する
        setTimeout(() => {
          firstMessageEl.scrollIntoView({ block: "nearest", inline: "nearest" });
          allowInfinite.current = true;
        }, 480);
      });
  }, [initialized, firstMessageSendDtm, talkId]);

  /**
   * gotoUnreadMessage
   */
  const gotoUnreadMessage = useCallback(() => {
    const pageContent = pageContentRef.current.el;
    if (!pageContent) return;
    const fabEl = pageContent.querySelector<HTMLElement>(".readed-message-title");
    if (!fabEl) return;
    pageContent.scrollTo({ top: fabEl.offsetTop, behavior: "smooth" });
  }, []);

  return (
    <>
      <PageContent
        ref={pageContentRef}
        infiniteTop
        infinite={loadMore}
        onInfinite={handleInfinite}
        // style={{ overflowY: allowInfinite.current === true ? "auto" : "hidden" }}
      >
        {children}
      </PageContent>
      <FabUnreadMessage
        position="right-top"
        slot="fixed"
        color="orange"
        className={showFab ? "fab-show" : "fab-hide"}
        onClick={gotoUnreadMessage}
      >
        <Icon name="keyboard_double_arrow_up" />
        <FormattedMessage id="talk.message.unread.fab" />
      </FabUnreadMessage>
    </>
  );
};
