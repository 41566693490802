import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import isYesterday from "dayjs/plugin/isYesterday";
import timezone from "dayjs/plugin/timezone";
import Framework7React from "framework7-react";
import Framework7 from "framework7/lite/bundle";
import ReactDOM from "react-dom/client";
import "framework7/css/bundle";
import "material-icons/iconfont/material-icons.css";

import "./index.css";
import "core/utils/yup/yup.ext";

import App from "./App";

// dayjs設定
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.tz.setDefault("Asia/Tokyo");

// framework7設定
Framework7.use(Framework7React);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);
