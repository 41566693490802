import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { GirlListResponse, GirlSearchCondition } from "core/model/site/top";

/**
 * state
 */
export type SiteTopState = {
  /** 初期化済 */
  initialized: boolean;
  /** page */
  page: number;
  /** girlList */
  girlList?: GirlListResponse;
  /** 検索条件 */
  condition: GirlSearchCondition;
};

const initialState: SiteTopState = {
  initialized: false,
  page: 1,
  condition: {},
};

/**
 * slice
 */
export const siteTopSlice = createSlice({
  name: "site/top",
  initialState,
  reducers: {
    /**
     * 検索条件更新
     * @param state
     * @param action
     */
    updateSearchConditionAction: (state, action: PayloadAction<GirlSearchCondition>) => {
      state.condition = action.payload;
    },
    /**
     * ページ更新
     * @param state
     */
    increasePageAction: state => {
      state.page += 1;
    },
    /**
     * girls初期化
     * @param state
     * @param action
     */
    initializeGirlsAction: (state, action: PayloadAction<GirlListResponse>) => {
      state.girlList = action.payload;
      state.initialized = true;
    },
    /**
     * girls再設定
     * @param state
     * @param action
     */
    resetGirlsAction: (state, action: PayloadAction<GirlListResponse>) => {
      state.girlList = action.payload;
    },
    /**
     * girls追加
     * @param state
     * @param action
     */
    appendGirlsAction: (state, action: PayloadAction<GirlListResponse>) => {
      state.girlList = (state.girlList || []).concat(action.payload);
    },
  },
});

/**
 * action
 */
export const siteTopAction = siteTopSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const siteTopSelector = (state: RootState) => state["site/top"];
