import { dispatch, store } from "store";

import {
  ScheduleListResponse,
  SaveScheduleRequest,
  SaveScheduleResponse,
  AttendanceRequest,
  AttendanceResponse,
} from "core/model/girl/schedule";
import { scheduleAction } from "core/slice/girl/schedule";
import { showPageSpinner, hidePageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";

/**
 * スケジュール一覧取得
 * @param force
 */
export const scheduleListInitialize = async (force = false) => {
  if (!force && store.getState().schedule.list) return;
  try {
    showPageSpinner();
    const res = await Api.get<ScheduleListResponse>("/user/schedule/list");
    if (isSuccess(res)) {
      // store更新
      dispatch(scheduleAction.updateScheduleListAction(res.body!));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};

/**
 * スケジュール更新処理
 * @param data
 */
export const updateSchedule = async (data: SaveScheduleRequest) => {
  try {
    showPageSpinner();
    const res = await Api.post<SaveScheduleResponse>("/user/schedule/s", data);
    if (isSuccess(res)) {
      // store更新
      dispatch(scheduleAction.updateScheduleItemAction(res.body!));
      return true;
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};

/**
 * 出勤管理取得処理
 */
export const initializeAttendance = async () => {
  if (store.getState().schedule.attendanceInitialized) return;
  try {
    showPageSpinner();
    const res = await Api.get<AttendanceResponse>("/user/schedule/attendance/l");
    if (isSuccess(res)) {
      // store更新
      dispatch(scheduleAction.updateAttendance(res.body));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};

/**
 * 出勤管理保存処理
 * @param data
 */
export const saveAttendance = async (data: AttendanceRequest) => {
  try {
    showPageSpinner();
    const res = await Api.post("/user/schedule/attendance/s", data);
    if (isSuccess(res)) {
      // store更新
      dispatch(scheduleAction.updateAttendance(data));
      // スケジュールの更新
      scheduleListInitialize(true);
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};
