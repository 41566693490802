import { Block, Button, Page, PageContent } from "framework7-react";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { PropsWithF7Router } from "routes";

import { ShopItem } from "core/model/site/shop";
import { siteShopSelector } from "core/slice/site/shop";
import { shopListInitialize } from "core/usecase/site/shop";

import { DescSection } from "ui/component/site/shop/shop-detail/desc-section";
import { MediaSection } from "ui/component/site/shop/shop-detail/media-section";
import { SkeletonSection } from "ui/component/site/shop/shop-detail/skeleton-section";
import { NoData } from "ui/widget/no-data";

/**
 * 店舗詳細画面
 * @param props
 * @param props.f7route
 * @returns ShopDetailPage
 */
export const ShopDetailPage: React.FC<PropsWithF7Router> = ({ f7route }) => {
  const shopId = f7route.params.id;
  const { initialized, data } = useSelector(siteShopSelector).list;
  const [shopItem, setShopItem] = useState<ShopItem>();

  useEffect(() => {
    if (!initialized) return;
    // 店舗詳細取得
    if (shopId && data) {
      setShopItem(data.find(o => o.shopId === shopId));
    }
  }, [shopId, data, initialized]);

  return (
    <Page noNavbar onPageBeforeIn={shopListInitialize} pageContent={false}>
      {initialized ? (
        shopItem ? (
          <PageContent>
            <MediaSection shopItem={shopItem} />
            <DescSection shopItem={shopItem} />
          </PageContent>
        ) : (
          <>
            <NoData title={<FormattedMessage id="common.nodata" />} />
            <Block>
              <Button fill href="/shop" back force>
                返回店铺列表
              </Button>
            </Block>
          </>
        )
      ) : (
        <SkeletonSection />
      )}
    </Page>
  );
};
