import notFoundSvg from "assets/image/404.svg";
import { Block, BlockTitle, Button, Page } from "framework7-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { authorizationSelector } from "core/slice/authorization";

import { Role } from "global/enum";

/**
 * 404 Not Found
 * @returns NotFound
 */
export const NotFoundPage: React.FC = () => {
  const { principal } = useSelector(authorizationSelector);

  return (
    <Page noNavbar noToolbar>
      <Block style={{ textAlign: "center", marginTop: 40 }}>
        <img src={notFoundSvg} alt="404 Not Found" style={{ maxWidth: 360 }} />
        <BlockTitle medium>
          <FormattedMessage id="common.notfound.title" />
        </BlockTitle>
        <BlockTitle>
          <FormattedMessage id="common.notfound.message1" />
          <br />
          <FormattedMessage id="common.notfound.message2" />
        </BlockTitle>
      </Block>
      <Block>
        {principal?.role === Role.girl ? (
          <Button fill href="/girl/profile">
            <FormattedMessage id="common.back.mypage" />
          </Button>
        ) : (
          <Button fill href="/">
            <FormattedMessage id="common.back.top" />
          </Button>
        )}
      </Block>
    </Page>
  );
};
