import { useFormik } from "formik";
import { BlockTitle, Button, ListItem, Navbar, NavLeft, NavTitle, Page } from "framework7-react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { SaveBankRequest } from "core/model/girl/bank";
import { bankSelector } from "core/slice/girl/bank";
import { saveBank, bankInitialize } from "core/usecase/girl/bank";
import { goBack } from "core/usecase/global";

import { DepositTypeLabel } from "global/enum-label/girl";
import { DepositType } from "global/enum/profile-girl";

import { FormikList, FormikListInput } from "ui/widget/formik";
import { Icon } from "ui/widget/icon";

/**
 * BankEditor
 * @returns BankEditor
 */
export const BankEditorPage: React.FC = () => {
  const bank = useSelector(bankSelector);

  const formik = useFormik<SaveBankRequest>({
    initialValues: {
      bankCd: bank?.bankCd ?? "",
      bankNm: bank?.bankNm ?? "",
      bankchKanji: bank?.bankchKanji ?? "",
      bankchKana: bank?.bankchKana ?? "",
      bankchCd: bank?.bankchCd ?? "",
      depositType: bank?.depositType ?? DepositType.usually,
      accountCd: bank?.accountCd ?? "",
      accountNm: bank?.accountNm ?? "",
      accountNmKana: bank?.accountNmKana ?? "",
    },
    validationSchema: yup.object().shape({
      bankCd: yup.string().required().number().max(10),
      bankNm: yup.string().required().zenkaku().max(90),
      bankchKanji: yup.string().required().zenkaku().max(90),
      bankchKana: yup.string().required().katakana().max(120),
      bankchCd: yup.string().required().number().max(5),
      depositType: yup.string().required(),
      accountCd: yup.string().required().number().max(12),
      accountNm: yup.string().required().zenkaku().max(120),
      accountNmKana: yup.string().required().zenkaku().max(120),
    }),
    validateOnChange: true,
    onSubmit(values) {
      // 口座情報保存処理
      saveBank(values).then(success => {
        if (success) goBack("/girl/profile");
      });
    },
  });

  const { setValues } = formik;
  /**
   * データ設定
   */
  useEffect(() => {
    setValues({
      bankCd: bank?.bankCd ?? "",
      bankNm: bank?.bankNm ?? "",
      bankchKanji: bank?.bankchKanji ?? "",
      bankchKana: bank?.bankchKana ?? "",
      bankchCd: bank?.bankchCd ?? "",
      depositType: bank?.depositType ?? DepositType.usually,
      accountCd: bank?.accountCd ?? "",
      accountNm: bank?.accountNm ?? "",
      accountNmKana: bank?.accountNmKana ?? "",
    });
  }, [bank, setValues]);

  return (
    <Page onPageBeforeIn={bankInitialize}>
      <Navbar>
        <NavLeft backLink backLinkUrl="/girl/profile" />
        <NavTitle>口座情報設定</NavTitle>
      </Navbar>
      <BlockTitle>口座情報</BlockTitle>
      <FormikList formik={formik}>
        <FormikListInput label="金融機関コード" name="bankCd" maxlength={10} />
        <FormikListInput label="金融機関名称" name="bankNm" maxlength={90} />
        <FormikListInput label="支店名（漢字）" name="bankchKanji" maxlength={90} />
        <FormikListInput label="支店名（カナ）" name="bankchKana" maxlength={120} />
        <FormikListInput label="支店コード" name="bankchCd" maxlength={5} />
        <FormikListInput label="預金種類" name="depositType" type="select" clearable={false}>
          {Object.entries(DepositTypeLabel).map(([value, text]) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </FormikListInput>
        <FormikListInput label="口座番号" name="accountCd" maxlength={12} />
        <FormikListInput label="口座名義（漢字）" name="accountNm" maxlength={120} />
        <FormikListInput label="口座名義（カナ）" name="accountNmKana" maxlength={120} />

        <ListItem>
          <Button type="submit" fill color="orange">
            <Icon name="done_outline" size={18} />
            &nbsp;保存
          </Button>
        </ListItem>
      </FormikList>
    </Page>
  );
};
