import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";

import { profileSelector } from "core/slice/girl/profile";
import { updateProfile } from "core/usecase/girl/profile";

import { Hidden } from "global/enum";

import { DateTimePicker } from "ui/widget/picker";

import { SelectorsProps } from ".";

/**
 * 生年月日選択用
 */
export const BirthdaySelector = React.memo(({ visible, onClose }: SelectorsProps) => {
  const now = dayjs();
  const { birth } = useSelector(profileSelector);
  const defaultBirth = now.subtract(20, "year").format("YYYY-MM-DD");

  /**
   * handleCancel
   */
  const handleCancel = () => {
    updateProfile("/profile/birth/s", {
      birth: "",
      birthHidden: Hidden.hide,
    });
    onClose();
  };

  /**
   * handleConfirm
   * @param value
   */
  const handleConfirm = (value: string) => {
    updateProfile("/profile/birth/s", {
      birth: dayjs(value, "YYYY-MM-DD").format("YYYYMMDD"),
      birthHidden: Hidden.show,
    });
    onClose();
  };

  return (
    <DateTimePicker
      opened={visible}
      onPickerClosed={onClose}
      minYear={now.subtract(100, "year").year()}
      maxYear={now.year()}
      value={birth ? dayjs(birth, "YYYYMMDD").format("YYYY-MM-DD") : defaultBirth}
      format="YYYY-MM-DD"
      toolbarCancelText="非表示"
      toolbarConfirmText="確定"
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  );
});
