/**
 * events
 */
export enum SocketEvents {
  /** ユーザ接続 */
  userConnect = "user-connect",
  /** ユーザ切断 */
  userDisconnect = "user-disconnect",
  /** 新規メッセージ */
  newMessage = "new-message",
}
