import { useFormik } from "formik";
import { Navbar, NavLeft, Link, NavTitle, NavRight, Page } from "framework7-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { siteProfileSelector } from "core/slice/site/profile";
import { goBack } from "core/usecase/global";
import { profileInitialize, updateProfile } from "core/usecase/site/profile";

import { FormikList, FormikListInput } from "ui/widget/formik";

type Shape = { title?: string };

/**
 * タイトル編集画面
 * @returns TitleEditor
 */
export const TitleEditorPage: React.FC = () => {
  const { title } = useSelector(siteProfileSelector).profile;

  const formik = useFormik<Shape>({
    initialValues: {
      title: title || "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      title: yup.string().max(200),
    }),
    onSubmit(value) {
      updateProfile("title", value.title).then(success => {
        if (success) goBack("/profile/base");
      });
    },
  });

  const { setValues } = formik;

  /**
   * データ再設定
   */
  useEffect(() => {
    setValues({ title });
  }, [setValues, title]);

  return (
    <Page onPageBeforeIn={profileInitialize}>
      <Navbar>
        <NavLeft backLink backLinkUrl="/profile/base" backLinkForce />
        <NavTitle>现在心情</NavTitle>
        <NavRight>
          <Link href={false} onClick={formik.submitForm}>
            确定
          </Link>
        </NavRight>
      </Navbar>

      <FormikList formik={formik}>
        <FormikListInput
          resizable
          type="textarea"
          name="title"
          label="现在心情"
          placeholder="这一刻的想法"
          maxlength={200}
          info="200个字符以内"
        />
      </FormikList>
    </Page>
  );
};
