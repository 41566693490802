import { Block, Row, Col } from "framework7-react";
import React from "react";
import { useSelector } from "react-redux";

import { followSelector } from "core/slice/girl/follow";

import { NumberMenu } from "ui/widget/number-menu";

/**
 * FollowSection
 * @returns FollowSection
 */
export const FollowSection: React.FC = () => {
  const { followFavorite } = useSelector(followSelector);

  return (
    <Block className="no-margin">
      <Row>
        <Col>
          <NumberMenu number={followFavorite?.followedSum ?? 0} label="フォロー中" />
        </Col>
        <Col>
          <NumberMenu number={followFavorite?.followerSum ?? 0} label="フォロワー" />
        </Col>
        <Col>
          <NumberMenu number={followFavorite?.favoriteSum ?? 0} label="いいね" />
        </Col>
      </Row>
    </Block>
  );
};
