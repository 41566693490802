import { Router } from "framework7/modules/router/router";

import { AccessDeniedPage } from "ui/page/common/access-denied";
import { NotFoundPage } from "ui/page/common/not-found";
import { ForgotPasswordPage } from "ui/page/girl/forgot-password";
import { PasswordResetPage } from "ui/page/girl/forgot-password/password-reset";
import { PasswordResetCompletePage } from "ui/page/girl/forgot-password/password-reset-complete";
import { SendCompletePage } from "ui/page/girl/forgot-password/send-complete";
import { UrlExpiredPage } from "ui/page/girl/forgot-password/url-expired";
import { IntroducePage } from "ui/page/girl/introduce";
import { LoginPage } from "ui/page/girl/login";
import { OrderPage } from "ui/page/girl/order";
import { OrderHistoryPage } from "ui/page/girl/order/order-history";
import { PasswordChangePage } from "ui/page/girl/password-change";
import { PresentLogPage } from "ui/page/girl/present/present-log";
import { ProfilePage } from "ui/page/girl/profile";
import { AttendancePage } from "ui/page/girl/profile/attendance";
import { ProfileAvatarEditorPage } from "ui/page/girl/profile/avatar-editor";
import { BankEditorPage } from "ui/page/girl/profile/bank-editor";
import { ProfileBaseEditorPage } from "ui/page/girl/profile/base-editor";
import { AddressEditorPage } from "ui/page/girl/profile/base-editor/editor/address";
import { BirthplaceEditorPage } from "ui/page/girl/profile/base-editor/editor/birthplace";
import { HeightEditorPage } from "ui/page/girl/profile/base-editor/editor/height";
import { InterestEditorPage } from "ui/page/girl/profile/base-editor/editor/interest";
import { NameEditorPage } from "ui/page/girl/profile/base-editor/editor/name";
import { NickNameEditorPage } from "ui/page/girl/profile/base-editor/editor/nick-name";
import { NoteEditorPage } from "ui/page/girl/profile/base-editor/editor/note";
import { TitleEditorPage } from "ui/page/girl/profile/base-editor/editor/title";
import { SignupPage } from "ui/page/girl/signup";
import { SignupCompletePage } from "ui/page/girl/signup/complete";
import { SignupConfirmPage } from "ui/page/girl/signup/confirm";
import { SignupVerifyPage } from "ui/page/girl/signup/verify";
import { TalkPage } from "ui/page/girl/talk";
import { TalkMessagePage } from "ui/page/girl/talk/talk-message";

import { GirlRoot } from "../ui/page/girl";

const PREFIX = "/girl";

export const girlRoutes: Router.RouteParameters[] = [
  {
    path: PREFIX,
    component: GirlRoot,
    tabs: [
      // {
      //   path: "/home",
      //   id: "tab-home",
      //   component: HomePage,
      // },
      {
        path: "/order",
        id: "tab-order",
        component: OrderPage,
      },
      {
        path: "/talk",
        id: "tab-talk",
        component: TalkPage,
      },
      {
        path: "/profile",
        id: "tab-profile",
        component: ProfilePage,
      },
    ],
  },

  // 合流履歴
  {
    path: `${PREFIX}/order/history`,
    component: OrderHistoryPage,
  },

  // トーク
  {
    path: `${PREFIX}/talk/:id`,
    component: TalkMessagePage,
  },

  // プロファイル系
  {
    path: `${PREFIX}/profile/base`,
    component: ProfileBaseEditorPage,
    routes: [
      {
        path: "/nick-name",
        component: NickNameEditorPage,
      },
      {
        path: "/name",
        component: NameEditorPage,
      },
      {
        path: "/height",
        component: HeightEditorPage,
      },
      {
        path: "/birthplace",
        component: BirthplaceEditorPage,
      },
      {
        path: "/address",
        component: AddressEditorPage,
      },
      {
        path: "/interest",
        component: InterestEditorPage,
      },
      {
        path: "/title",
        component: TitleEditorPage,
      },
      {
        path: "/note",
        component: NoteEditorPage,
      },
    ],
  },
  // アバター設定
  {
    path: `${PREFIX}/profile/avatar`,
    component: ProfileAvatarEditorPage,
  },
  // 出勤管理
  {
    path: `${PREFIX}/profile/attendance`,
    component: AttendancePage,
  },
  // 口座情報設定
  {
    path: `${PREFIX}/profile/bank`,
    component: BankEditorPage,
  },

  // プレゼント履歴
  {
    path: `${PREFIX}/present-log`,
    component: PresentLogPage,
  },

  // パスワード変更
  {
    path: `${PREFIX}/password-change`,
    component: PasswordChangePage,
  },

  // ログイン
  {
    path: `${PREFIX}/login`,
    component: LoginPage,
  },

  // アカウント作成
  {
    path: `${PREFIX}/signup`,
    component: SignupPage,
    routes: [
      {
        path: "/confirm",
        component: SignupConfirmPage,
      },
      {
        path: "/complete",
        component: SignupCompletePage,
      },
      {
        path: "/verify",
        async({ resolve, to }) {
          // code必須
          if (to.query.code) {
            resolve({ component: SignupVerifyPage });
          } else {
            resolve({ component: NotFoundPage });
          }
        },
      },
    ],
  },

  // パスワードお忘れ
  {
    path: `${PREFIX}/forgot-password`,
    component: ForgotPasswordPage,
    routes: [
      {
        path: "/send-complete",
        component: SendCompletePage,
      },
      {
        path: "/reset",
        async({ resolve, to }) {
          // code必須
          if (to.query.code) {
            resolve({ component: PasswordResetPage });
          } else {
            resolve({ component: NotFoundPage });
          }
        },
      },
      {
        path: "/url-expired",
        component: UrlExpiredPage,
      },
      {
        path: "/reset-complete",
        component: PasswordResetCompletePage,
      },
    ],
  },

  // お紹介画面
  {
    path: `${PREFIX}/introduce`,
    async({ resolve, to }) {
      // id必須
      if (to.query.id) {
        resolve({ component: IntroducePage });
      } else {
        resolve({ component: NotFoundPage });
      }
    },
  },

  // アクセス権限なし画面
  {
    path: `${PREFIX}/access-denied`,
    component: AccessDeniedPage,
  },
];
