import Cookies from "js-cookie";

import { PasswordChangeRequest, PasswordChangeResponse } from "core/model/password-change";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";

import { CommonConfig } from "global/config";

import { showPageSpinner, hidePageSpinner } from "../global";

/**
 * パスワード変更処理
 * @param data
 */
export const passwordChange = async (data: PasswordChangeRequest) => {
  try {
    showPageSpinner();
    const res = await Api.post<PasswordChangeResponse>("/password/change", data);
    if (isSuccess(res) && res.body) {
      const { accessToken, refreshToken } = res.body;
      // cookieのtoken情報を更新する
      Cookies.set(CommonConfig.accessTokenName, accessToken, { expires: CommonConfig.tokenExpire, path: "/" });
      Cookies.set(CommonConfig.refreshTokenName, refreshToken, { expires: CommonConfig.tokenExpire, path: "/" });
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }

  return false;
};
