import React from "react";
import { useSelector } from "react-redux";

import { profileSelector } from "core/slice/girl/profile";
import { updateProfile } from "core/usecase/girl/profile";

import { SexLabel } from "global/enum-label/girl";
import { Sex } from "global/enum/profile-girl";

import { Picker, PickerColumn } from "ui/widget/picker";

import { SelectorsProps } from ".";

const cols: PickerColumn[] = [
  {
    textAlign: "center",
    values: Object.keys(SexLabel),
    displayValues: Object.values(SexLabel),
  },
];

/**
 * 性別選択用
 */
export const SexSelector = React.memo(({ visible, onClose }: SelectorsProps) => {
  const { sex } = useSelector(profileSelector);

  /**
   * onAction
   * @param value
   */
  const handleConfirm = (value: string[]) => {
    updateProfile("/profile/sex/s", { sex: value[0] as Sex });
    onClose();
  };

  return (
    <Picker cols={cols} value={[sex as string]} onConfirm={handleConfirm} opened={visible} onPickerClosed={onClose} />
  );
});
