import { Badge, Link, Page, Tab, Tabs, Toolbar } from "framework7-react";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { talkSelector } from "core/slice/talk";
import { initPresentMasterList } from "core/usecase/global";
import { getUnreadCntSum } from "core/usecase/talk";

import { Icon } from "ui/widget/icon";

import { useRoute } from "../../../routes";

/**
 * SiteRoot
 * @returns SiteRoot
 */
export const SiteRoot: React.FC = () => {
  const { path } = useRoute();

  // メッセージ数
  const { unreadMsgCnt } = useSelector(talkSelector);
  const unreadMsgCntDisp = useMemo(() => {
    if (unreadMsgCnt <= 0) return undefined;
    if (unreadMsgCnt > 99) return "+99";
    return unreadMsgCnt;
  }, [unreadMsgCnt]);

  useEffect(() => {
    // 未読総件数の取得
    getUnreadCntSum();
    // プレゼントマスタ一覧の初期化
    initPresentMasterList();
  }, []);

  return (
    <Page pageContent={false}>
      <Toolbar tabbar bottom>
        <Link tab-link href="/top" route-tab-id="tab-top" iconMaterial="search" tabLinkActive={path === "/top"}>
          发现
        </Link>
        <Link tab-link href="/talk" route-tab-id="tab-talk" tabLinkActive={path === "/talk"}>
          <Icon name="message">{unreadMsgCntDisp && <Badge color="red">{unreadMsgCntDisp}</Badge>}</Icon>
          <span className="tabbar-label">消息</span>
        </Link>
        <Link tab-link href="/order" route-tab-id="tab-order" tabLinkActive={path === "/order"}>
          <Icon name="shopping_cart" shape="outlined" />
          预约
        </Link>
        <Link
          tab-link
          href="/timeline"
          route-tab-id="tab-timeline"
          iconMaterial="share"
          tabLinkActive={path === "/timeline"}
        >
          广场
        </Link>
        <Link tab-link href="/shop" route-tab-id="tab-shop" tabLinkActive={path === "/shop"}>
          <Icon name="store" shape="outlined" />
          店铺
        </Link>
        <Link
          tab-link
          href="/profile"
          route-tab-id="tab-profile"
          iconMaterial="manage_accounts"
          tabLinkActive={path === "/profile"}
        >
          我
        </Link>
      </Toolbar>
      <Tabs swipeable animated routable swiperParams={{ simulateTouch: false }}>
        <Tab id="tab-top" />
        <Tab id="tab-talk" />
        <Tab id="tab-order" />
        <Tab id="tab-timeline" />
        <Tab id="tab-shop" />
        <Tab id="tab-profile" />
      </Tabs>
    </Page>
  );
};
