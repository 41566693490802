import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { SNSResponse } from "core/model/girl/sns";

import { mixed } from "global/types";

/**
 * state
 */
export type SNSState = Partial<SNSResponse>;
/**
 * UpdateItemType
 */
export type UpdateSNSItemType = {
  /** key */
  key: keyof SNSResponse;
  /** value */
  value: mixed;
};

const initialState: SNSState = {};

/**
 * slice
 */
export const snsSlice = createSlice({
  name: "sns",
  initialState,
  reducers: {
    updateAction: (state, action: PayloadAction<SNSState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

/**
 * action
 */
export const SNSAction = snsSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const snsSelector = (state: RootState) => state.sns;
