import { dispatch, store } from "store";

import { ChargeLogResponse, ChargeMResponse, NewChargeOrderRequest } from "core/model/site/profile/charge";
import { siteProfileChargeAction } from "core/slice/site/profile/charge";
import { showPageSpinner, hidePageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";

import { SiteConfig } from "global/config";
import { ChargeType } from "global/enum/profile-site";

/**
 * chargeM情報の初期化
 * @param chargeType
 */
export const initializeChargeMInfo = async (chargeType: ChargeType) => {
  const { chargeMInfo } = store.getState()["site/profile/charge"];
  // 既に存在する場合、APIの再実行は不要
  if (chargeMInfo && chargeMInfo.chargeType === chargeType) return true;
  showPageSpinner();
  try {
    const res = await Api.get<ChargeMResponse>("/site/user/chargem/l", {
      params: {
        chargeType,
      },
    });
    if (isSuccess(res) && res.body && res.body.chargeType) {
      // storeに保存する
      dispatch(siteProfileChargeAction.updateChargeMInfoAction(res.body));
      return true;
    }
    showErrorModal(res.message ?? "当前充值方式暂时无法使用，请重新选择。");
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};

/**
 * チャージオーダーの作成
 * @param chargeType
 * @param payMoney
 * @param chargeImg
 */
export const createChargeOrder = async (chargeType: ChargeType, payMoney: string, chargeImg: File) => {
  try {
    showPageSpinner();

    // チャージ証明書のアップロード
    const formData = new FormData();
    formData.append("file", chargeImg, chargeImg.name);
    const res = await Api.post<string>("/site/user/rechargeOrder/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const chargeImgName = res.body;
    if (!isSuccess(res) || !chargeImgName) {
      return false;
    }

    // チャージオーダーの作成
    const data: NewChargeOrderRequest = {
      rechargeType: chargeType,
      payMoney,
      rechargeImg: chargeImgName,
    };
    const res2 = await Api.post("/site/user/rechargeOrder/c", data);
    return isSuccess(res2);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};

/**
 * チャージ履歴一覧初期化
 * @returns hasMore ? true : false
 */
export const initializeChargeLog = async () => {
  showPageSpinner();
  try {
    const res = await Api.get<ChargeLogResponse>("/site/user/rechargeOrder/list", {
      params: {
        page: 1,
      },
    });
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(siteProfileChargeAction.initializeChargeLogAction(res.body));
      return res.body.length >= SiteConfig.chargeLogPageSize;
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return true;
};

/**
 * 次のページのチャージ履歴を取得する
 * @returns hasMore ? true : false
 */
export const getChargeLogOfNextPage = async () => {
  try {
    const nextPage = store.getState()["site/profile/charge"].chargeLog.page + 1;
    const res = await Api.get<ChargeLogResponse>("/site/user/rechargeOrder/list", {
      params: {
        page: nextPage,
      },
    });
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(siteProfileChargeAction.appendChargeLogAction(res.body));
      dispatch(siteProfileChargeAction.increaseChargeLogPageAction());
      return res.body.length >= SiteConfig.chargeLogPageSize;
    }
  } catch {
    // 処理なし
  }
  return true;
};

/**
 * チャージオーダーキャンセル処理
 * @param orderId
 * @returns 処理結果
 */
export const revokeChargeOrder = async (orderId: string) => {
  showPageSpinner();
  try {
    const res = await Api.post<ChargeLogResponse>("/site/user/rechargeOrder/d", {
      orderId,
    });
    // 成功の場合
    if (isSuccess(res)) {
      dispatch(siteProfileChargeAction.deleteChargeLogItemAction(orderId));
      return true;
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};
