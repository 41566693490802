import { dispatch, store } from "store";

import { ProfileResponse, ProfileSaveRequest } from "core/model/site/profile";
import { SelectableTab, siteProfileAction, SiteProfileDataType } from "core/slice/site/profile";
import { hidePageSpinner, showPageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";

import { Hobby, Language, Personality } from "global/enum/profile-site";
import { mixed } from "global/types";

/**
 * プロファイル取得
 */
export const profileInitialize = async () => {
  if (store.getState()["site/profile"].initialized) return;
  // プロファイル取得処理
  showPageSpinner();
  try {
    const res = await Api.get<ProfileResponse>("/site/user/userinfo/l");
    if (isSuccess(res) && res.body) {
      const { personality, hobby, lang, ...others } = res.body;
      const state: SiteProfileDataType = { ...others };
      // 性格
      if (personality) {
        state.personality = personality.split(",") as Personality[];
      }
      // 趣味
      if (hobby) {
        state.hobby = hobby.split(",") as Hobby[];
      }
      // 言語
      if (lang) {
        state.lang = lang.split(",") as Language[];
      }
      // storeに保存する
      dispatch(siteProfileAction.profileInitializeAction(state));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};

/**
 * 性格更新
 * @param value
 */
export const updatePersonality = async (value: string) => {
  const { personality } = store.getState()["site/profile"].profile;
  // 変更ない場合、スキップ
  if (value === personality?.join(",")) return true;
  try {
    const res = await Api.post("/site/user/userinfo/s", { personality: value });
    if (isSuccess(res)) {
      // store更新
      const arr = value.split(",");
      dispatch(
        siteProfileAction.updateProfileItemAction({
          personality: arr.length === 0 ? undefined : (arr as Personality[]),
        }),
      );
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  }
  return false;
};

/**
 * 趣味更新
 * @param value
 */
export const updateHobby = async (value: string) => {
  const { hobby } = store.getState()["site/profile"].profile;
  // 変更ない場合、スキップ
  if (value === hobby?.join(",")) return true;
  try {
    const res = await Api.post("/site/user/userinfo/s", { hobby: value });
    if (isSuccess(res)) {
      // store更新
      const arr = value.split(",");
      dispatch(siteProfileAction.updateProfileItemAction({ hobby: arr.length === 0 ? undefined : (arr as Hobby[]) }));
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  }
  return false;
};

/**
 * 言語更新
 * @param value
 */
export const updateLanguage = async (value: string) => {
  const { lang } = store.getState()["site/profile"].profile;
  // 変更ない場合、スキップ
  if (value === lang?.join(",")) return true;
  try {
    const res = await Api.post("/site/user/userinfo/s", { lang: value });
    if (isSuccess(res)) {
      // store更新
      const arr = value.split(",");
      dispatch(siteProfileAction.updateProfileItemAction({ lang: arr.length === 0 ? undefined : (arr as Language[]) }));
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  }
  return false;
};

/**
 * プロファイルitem更新
 * @param name
 * @param value
 */
export const updateProfile = async (
  name: keyof Omit<ProfileSaveRequest, "personality" | "hobby" | "lang">,
  value: mixed,
) => {
  const currentValue = store.getState()["site/profile"].profile[name];
  // 変更ない場合、スキップ
  if (value === currentValue) return true;
  try {
    const res = await Api.post("/site/user/userinfo/s", { [name]: value });
    if (isSuccess(res)) {
      // store更新
      dispatch(siteProfileAction.updateProfileItemAction({ [name]: value }));
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  }
  return false;
};

/**
 * active tab更新
 * @param tab
 */
export const updateActiveTab = (tab: SelectableTab) => {
  dispatch(siteProfileAction.updateActiveTabAction(tab));
};
