import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { FollowFavoriteResponse } from "core/model/girl/follow";

/**
 * state
 */
export type SiteFollowState = {
  /** フォロー、フォロワー、いいねの総件数取得response情報 */
  followFavorite?: FollowFavoriteResponse;
};

const initialState: SiteFollowState = {};

/**
 * slice
 */
export const siteFollowSlice = createSlice({
  name: "site/follow",
  initialState,
  reducers: {
    updateFollowFavoriteAction: (state, action: PayloadAction<FollowFavoriteResponse>) => {
      state.followFavorite = action.payload;
    },
  },
});

/**
 * action
 */
export const siteFollowAction = siteFollowSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const siteFollowSelector = (state: RootState) => state["site/follow"];
