import { AxiosProgressEvent } from "axios";
import { f7 } from "framework7-react";
import { dispatch, store } from "store";

import { MediaListResponse, PhotoUploadResponse, VideoUploadResponse, MediaItem } from "core/model/media";
import { mediaAction } from "core/slice/girl/media";
import { showPageSpinner, hidePageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";
import { heicToJpg, compress, createVideoCover } from "core/utils/file";

import { CommonConfig } from "global/config";

/**
 * 写真・ビデオ一覧取得
 */
export const mediaListInitialize = async () => {
  if (store.getState().media.list) return;
  try {
    showPageSpinner();
    const res = await Api.get<MediaListResponse>("/user/media/l");
    if (isSuccess(res)) {
      // store更新
      dispatch(mediaAction.updateMediaListAction(res.body!));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};

/**
 * completed percent
 */
type UploadProgressType = (event: AxiosProgressEvent) => void;

/**
 * 写真アップロード処理
 * @param file
 * @param onUploadProgress
 */
const photoUpload = async (file: File, onUploadProgress: UploadProgressType) => {
  // heicからjpgの変換
  const covertedFile = await heicToJpg(file);

  // サムネイル作成
  const thumbnail = await compress(covertedFile, {
    quality: 0.8,
    maxWidth: 450,
    maxHeight: 450,
  });

  // 画像圧縮処理
  let uploadFile = covertedFile;
  if (covertedFile.size > 2 * 1024 * 1024) {
    uploadFile = await compress(covertedFile, {
      quality: 0.8,
      maxWidth: 1920,
      maxHeight: 1920,
    });
  }

  // form data
  const formData = new FormData();
  formData.append("thumbnailFile", thumbnail, thumbnail.name);
  formData.append("file", uploadFile, uploadFile.name);

  // アップロード処理を行う
  const res = await Api.post<PhotoUploadResponse>("/user/media/photo/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
  if (isSuccess(res)) {
    dispatch(mediaAction.prependMediaItemAction(res.body!));
    return true;
  }
  showErrorModal(res.message);

  return false;
};

/**
 * ビデオアップロード処理
 * @param file
 * @param onUploadProgress
 */
const videoUpload = async (file: File, onUploadProgress: UploadProgressType) => {
  // ビデオカバーの作成
  const videoCoverOrigin = await createVideoCover(file);

  // ビデオカバーの圧縮処理
  const videoCover = await compress(videoCoverOrigin, {
    quality: 0.8,
    maxWidth: 450,
    maxHeight: 450,
  });

  // form data
  const formData = new FormData();
  formData.append("thumbnailFile", videoCover, videoCover.name);
  formData.append("file", file, file.name);

  // アップロード処理を行う
  const res = await Api.post<VideoUploadResponse>("/user/media/video/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
  if (isSuccess(res)) {
    dispatch(mediaAction.prependMediaItemAction(res.body!));
    return true;
  }
  showErrorModal(res.message);

  return false;
};

/**
 * 写真・ビデオアップロード処理
 * @param file
 */
export const mediaUpload = async (file: File | undefined) => {
  if (!file) return false;
  // ファイルサイズチェック
  if (file.size > CommonConfig.uploadMaxFileSize * 1024 * 1024) {
    showErrorModal(`アップローダファイルサイズ上限(${CommonConfig.uploadMaxFileSize}MB)を超えています。`);
    return false;
  }

  const dialog = f7.dialog.preloader();
  dialog.open();
  // onUploadProgress
  const onUploadProgress = (event: AxiosProgressEvent) => {
    const total = (event.total! / 1024 / 1024).toFixed(2);
    const loaded = (event.loaded! / 1024 / 1024).toFixed(2);
    dialog.setText(`アップロード中: ${loaded}MB / ${total}MB`);
  };

  try {
    // 写真の場合
    if (/^image\/.+$/.test(file.type)) {
      return await photoUpload(file, onUploadProgress);
    }

    // ビデオの場合
    if (/^video\/.+$/.test(file.type)) {
      return await videoUpload(file, onUploadProgress);
    }

    showErrorModal("画像もしくはビデオを選択してください。");
  } catch {
    showErrorModal();
  } finally {
    dialog.close();
  }

  return false;
};

/**
 * 写真・ビデオ削除処理
 * @param item
 */
export const mediaDelete = async (item: MediaItem) => {
  try {
    showPageSpinner();
    const res = await Api.post("/user/media/d", { rowsId: item.rowsId });
    if (isSuccess(res)) {
      // store更新
      dispatch(mediaAction.deleteMediaItemAction(item));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};
