/**
 * numberFormat
 * @param num
 * @returns format result
 */
export const numberFormat = (num: number): string => num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

/**
 * kmgFormat
 * @param num
 * @returns format result
 */
export const kmgFormat = (num: number): string => {
  const digitPattern = /\.0+$|(\.[0-9]*[1-9])0+$/;
  if (num >= 1e9) {
    return `${(num / 1e9).toFixed(2).replace(digitPattern, "$1")}G`;
  }
  if (num >= 1e6) {
    return `${(num / 1e6).toFixed(2).replace(digitPattern, "$1")}M`;
  }
  if (num >= 1e4) {
    return `${(num / 1e4).toFixed(2).replace(digitPattern, "$1")}K`;
  }
  if (num >= 1e3) {
    return numberFormat(num);
  }
  return num.toString();
};
