import { Block, BlockTitle, Button, Card, CardContent, CardHeader } from "framework7-react";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Icon } from "ui/widget/icon";

/**
 * SignupCompleteProps
 */
type SignupCompleteProps = {
  /** メールアドレス */
  email: string;
  /** ログインページリンク */
  loginLink: string;
};

/**
 * SignupCompleteBlock
 * @param props
 * @param props.email
 * @param props.loginLink
 * @returns SignupCompleteBlock
 */
export const SignupCompleteBlock: React.FC<SignupCompleteProps> = ({ email, loginLink }) => (
  <>
    <Block style={{ textAlign: "center", marginTop: 80 }}>
      <Icon name="check_circle" size="80px" color="green" />
      <BlockTitle medium>
        <FormattedMessage id="signup.complete.title" />
      </BlockTitle>
    </Block>
    <Card>
      <CardHeader>
        <FormattedMessage id="signup.complete.email" />
      </CardHeader>
      <CardContent>
        <div style={{ padding: 16, textAlign: "center", background: "#f5dbbb", borderRadius: 4, color: "#333" }}>
          {email}
        </div>
        <div style={{ textAlign: "left", fontSize: 14, color: "#666", marginTop: 16 }}>
          <FormattedMessage id="signup.complete.message1" />
          <br />
          <FormattedMessage id="signup.complete.message2" />
        </div>
      </CardContent>
    </Card>
    <Block>
      <Button fill href={loginLink} transition="f7-push">
        <FormattedMessage id="common.back.login" />
      </Button>
    </Block>
  </>
);
