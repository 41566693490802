import { Page } from "framework7-react";
import React from "react";
import { PropsWithF7Router } from "routes";

import { SignupCompleteBlock } from "ui/component/signup/complete-block";

/**
 * SignupCompleteProps
 */
type SignupCompleteProps = PropsWithF7Router<{
  /** メールアドレス */
  email?: string;
}>;

/**
 * SignupComplete
 * @param props
 * @param props.f7router
 * @param props.email
 * @returns SignupComplete
 */
export const SignupCompletePage: React.FC<SignupCompleteProps> = ({ f7router, email }) => {
  /**
   * onPageBeforeIn
   */
  const onPageBeforeIn = () => {
    if (!email) {
      f7router.navigate("/404", {
        clearPreviousHistory: true,
      });
    }
  };

  return (
    <Page noNavbar noToolbar onPageBeforeIn={onPageBeforeIn}>
      <SignupCompleteBlock email={email || ""} loginLink="/login" />
    </Page>
  );
};
