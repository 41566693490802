import Logo from "assets/image/logo.png";
import { Block, BlockTitle, Link, Page } from "framework7-react";
import React from "react";

import { Divider } from "ui/widget/divider";

/**
 * PrivacyPolicyPage
 * @returns PrivacyPolicyPage
 */
export const PrivacyPolicyPage: React.FC = () => (
  <Page noNavbar noToolbar>
    <Block style={{ textAlign: "center", marginTop: 80 }}>
      <img src={Logo} width={200} alt="19momo" />
      <BlockTitle style={{ marginTop: 16 }}>・プライバシーポリシー・</BlockTitle>
    </Block>
    <Divider />
    <Block>
      <BlockTitle textColor="var(--f7-theme-color)">[ 利用者情報の取得 ]</BlockTitle>
      <p>本アプリが利用者の個人情報を取得することはありません。</p>
    </Block>
    <Block>
      <BlockTitle textColor="var(--f7-theme-color)">[ 利用者情報の利用 ]</BlockTitle>
      <p>本アプリが利用者の個人情報を利用することはありません。</p>
    </Block>
    <Block>
      <BlockTitle textColor="var(--f7-theme-color)">[ 利用者情報の第三者提供 ]</BlockTitle>
      <p>本アプリが利用者の個人情報を第三者へ提供することはありません。</p>
    </Block>
    <Block>
      <BlockTitle textColor="var(--f7-theme-color)">[ お問い合わせ先 ]</BlockTitle>
      <p>何かご不明な点がございましたら下記からお問い合わせください。</p>
      <p>
        <Link external href="https://www.istart.co.jp/#page8" target="_blank" style={{ textDecoration: "underline" }}>
          お問い合わせフォーム
        </Link>
      </p>
    </Block>
  </Page>
);
