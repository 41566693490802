import Cookies from "js-cookie";
import { dispatch, store, storeReset } from "store";

import {
  AvatarUploadResponse,
  LoginRequest,
  LoginResponse,
  RefreshTokenResponse,
  TokenLoginResponse,
} from "core/model/authorization";
import { authorizationAction } from "core/slice/authorization";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";
import logger from "core/utils/logger";

import { CommonConfig } from "global/config";
import { FetchStatus, Role } from "global/enum";

import { hidePageSpinner, showPageSpinner } from "../global";

/**
 * ログイン処理
 * @param data
 * @param role
 * @returns 結果
 */
export const login = async (data: LoginRequest, role: Role) => {
  if (role === Role.system) return false;
  showPageSpinner();
  try {
    const res = await Api.post<LoginResponse>(role === Role.site ? "/site/login" : "/login", data);
    // ログイン成功の場合
    if (isSuccess(res) && res.body) {
      const { accessToken, refreshToken, ...principal } = res.body;
      // ストアクリア
      storeReset();
      // ユーザ情報の保存
      dispatch(authorizationAction.updatePrincipalAction(principal));
      // token情報をcookieに保存する
      Cookies.set(CommonConfig.accessTokenName, accessToken, { expires: CommonConfig.tokenExpire, path: "/" });
      Cookies.set(CommonConfig.refreshTokenName, refreshToken, { expires: CommonConfig.tokenExpire, path: "/" });
      // token refresh status
      dispatch(authorizationAction.updateTokenRefreshStatusAction(FetchStatus.complete));
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};

/**
 * トークンログイン処理
 * @returns role
 */
export const tokenLogin = async () => {
  // 認証情報が存在する場合、処理正常終了
  const { principal } = store.getState().authorization;
  if (principal) return principal.role;
  showPageSpinner();
  try {
    const res = await Api.get<TokenLoginResponse>("/token-login");
    // ログイン成功の場合
    if (isSuccess(res) && res.body) {
      // ユーザ情報の保存
      dispatch(authorizationAction.updatePrincipalAction(res.body));
      return res.body.role;
    }
    return undefined;
  } catch {
    return undefined;
  } finally {
    hidePageSpinner();
  }
};

/**
 * ログアウト処理
 */
export const logout = () => {
  // ストアクリア
  storeReset();
  // cookieクリア
  Cookies.remove(CommonConfig.accessTokenName);
  Cookies.remove(CommonConfig.refreshTokenName);
};

/**
 * トークンリフレッシュ処理
 * @param refreshToken
 */
export const refreshTokenHandler = async (refreshToken: string) => {
  if (store.getState().authorization.tokenRefreshStatus === FetchStatus.fetching) return;
  try {
    logger.info("try to refresh token.");
    showPageSpinner();
    // token refresh status
    dispatch(authorizationAction.updateTokenRefreshStatusAction(FetchStatus.fetching));
    const res = await Api.post<RefreshTokenResponse>("/refresh-token", { refreshToken });
    if (isSuccess(res) && res.body) {
      logger.info("refresh token success.");
      const { accessToken } = res.body;
      // token情報をcookieに保存する
      Cookies.set(CommonConfig.accessTokenName, accessToken, { expires: CommonConfig.tokenExpire, path: "/" });
      // token refresh status: complete
      dispatch(authorizationAction.updateTokenRefreshStatusAction(FetchStatus.complete));
    } else {
      logger.info("refresh token failed.");
      // token refresh status: failed
      dispatch(authorizationAction.updateTokenRefreshStatusAction(FetchStatus.failed));
    }
  } finally {
    hidePageSpinner();
  }
};

/**
 * アバターアップロード処理
 * @param file
 */
export const avatarUpload = async (file: File) => {
  try {
    showPageSpinner();
    const formData = new FormData();
    formData.append("file", file, file.name);
    const res = await Api.post<AvatarUploadResponse>("/avatar/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (isSuccess(res) && res.body) {
      // store更新
      dispatch(authorizationAction.updateAvatarAction(res.body.fileId));
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};
