import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { ProfileResponse } from "core/model/girl/profile";

/**
 * state
 */
export type ProfileState = Partial<ProfileResponse>;

const initialState: ProfileState = {};

/**
 * slice
 */
export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateAction: (state, action: PayloadAction<ProfileState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

/**
 * action
 */
export const profileAction = profileSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const profileSelector = (state: RootState) => state.profile;
