import { Block, Button, f7, Page, PageContent } from "framework7-react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { PropsWithF7Router } from "routes";

import { GUserResponse } from "core/model/site/top/girl-detail";
import { initializeGirlDetail } from "core/usecase/site/top/girl-detail";

import { ActionSection } from "ui/component/site/top/girl-detail/action-section";
import { BaseInfoSection } from "ui/component/site/top/girl-detail/base-info-section";
import { MediaSection } from "ui/component/site/top/girl-detail/media-section";
import { ProfileSection } from "ui/component/site/top/girl-detail/profile-section";
import { ScheduleSection } from "ui/component/site/top/girl-detail/schedule-section";
import { SkeletonSection } from "ui/component/site/top/girl-detail/skeleton-section";
import { useOnlineStatus } from "ui/hooks/use-online-status";
import { NoData } from "ui/widget/no-data";

/**
 * GirlDetailPage
 * @param root0
 * @param root0.f7route
 * @returns GirlDetailPage
 */
export const GirlDetailPage: React.FC<PropsWithF7Router> = ({ f7route }) => {
  const userId = f7route.params.id!;
  const [initialized, setInitialized] = useState(false);
  const [girlDetail, setGirlDetail] = useState<GUserResponse>();

  const onlineStatus = useOnlineStatus(userId);

  /**
   * pageBeforeInHandler
   */
  const pageBeforeInHandler = () => {
    f7.popup.close();
    // girl詳細取得
    initializeGirlDetail(userId).then(data => {
      setInitialized(true);
      setGirlDetail(data);
    });
  };

  return (
    <Page noNavbar onPageBeforeIn={pageBeforeInHandler} pageContent={false}>
      {initialized ? (
        girlDetail ? (
          <>
            <PageContent>
              <MediaSection mediaList={girlDetail.gphotosList} />
              <ProfileSection profile={girlDetail.guserInfo} onlineStatus={onlineStatus} />
              <ScheduleSection scheduleList={girlDetail.gscheduleList} addressList={girlDetail.addressList} />
              <BaseInfoSection profile={girlDetail.guserInfo} />
            </PageContent>
            <ActionSection
              userId={userId}
              price={girlDetail.guserInfo.price}
              scheduleList={girlDetail.gscheduleList}
              addressList={girlDetail.addressList}
            />
          </>
        ) : (
          <>
            <NoData title={<FormattedMessage id="common.nodata" />} />
            <Block>
              <Button fill href="/top" back force>
                返回首页
              </Button>
            </Block>
          </>
        )
      ) : (
        <SkeletonSection />
      )}
    </Page>
  );
};
