import { OnlineStatusResponse } from "core/model/user";
import Api, { isSuccess } from "core/utils/api";

/**
 * オンラインステータスの取得
 * @param userId
 */
export const getUserOnlineStatus = async (userId: string) => {
  try {
    const res = await Api.get<OnlineStatusResponse>(`/online-status/l/${userId}`);
    if (isSuccess(res)) {
      return res.body;
    }
  } catch {
    // 処理なし
  }
  return undefined;
};
