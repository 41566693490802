import { Link, Popover, List, ListItem } from "framework7-react";
import React from "react";

import { TimelineItem } from "core/model/site/timeline";

import { Icon } from "ui/widget/icon";

type TimelineUserExtraProps = {
  item: TimelineItem;
  onToggleFavorite: () => void;
  onDeleteItem: () => void;
};

/**
 * TimelineExtraMy
 * @param props
 * @param props.item
 * @param props.onToggleFavorite
 * @param props.onDeleteItem
 * @returns TimelineExtraMy
 */
export const TimelineExtraMy: React.FC<TimelineUserExtraProps> = ({ item, onToggleFavorite, onDeleteItem }) => (
  <>
    <Link href={false} popoverOpen={`.popover-menu-timeline-${item.id}`}>
      <Icon name="more_horiz" color="gray" />
    </Link>
    <Popover className={`popover-menu-timeline-${item.id}`} style={{ width: 180 }}>
      <List>
        <ListItem link="#" popoverClose onClick={onToggleFavorite}>
          <div slot="title" className="text-align-middle">
            {item.favorited ? (
              <>
                <Icon name="favorite" color="red" />
                &nbsp;取消
              </>
            ) : (
              <>
                <Icon name="favorite_border" />
                &nbsp;赞
              </>
            )}
          </div>
        </ListItem>
        <ListItem link="#" popoverClose onClick={onDeleteItem}>
          <div slot="title" className="text-align-middle">
            <Icon name="delete" shape="outlined" />
            &nbsp;&nbsp;删除
          </div>
        </ListItem>
      </List>
    </Popover>
  </>
);
