import classNames from "classnames";
import dayjs from "dayjs";
import { Row, Col } from "framework7-react";
import React from "react";
import styled from "styled-components";

import { ScheduleListResponse } from "core/model/girl/schedule";

import { Icon } from "ui/widget/icon";

const Container = styled.div({
  width: "100%",
  marginBottom: 36,

  ".row": {
    margin: "4px 0",
    ".col": {
      textAlign: "center",
    },
  },
  ".schedule-header": {
    fontWeight: "bolder",
    fontSize: 18,
    position: "relative",
    marginTop: 12,
    marginBottom: 12,

    ".schedule-header-left": {
      position: "absolute",
      top: 4,
      fontSize: 14,
      left: 12,
    },

    ".schedule-header-right": {
      position: "absolute",
      top: 4,
      fontSize: 14,
      right: 2,
    },
  },

  ".col.past-day *": {
    color: "#999",
    opacity: 0.6,
  },
});

type ScheduleItemProps = {
  header: React.ReactNode;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  scheduleList: ScheduleListResponse;
};

/**
 * ScheduleItem
 * @param props
 * @param props.header
 * @param props.headerLeft
 * @param props.headerRight
 * @param props.scheduleList
 * @returns ScheduleItem
 */
export const ScheduleItem: React.FC<ScheduleItemProps> = ({ header, headerLeft, headerRight, scheduleList }) => (
  <Container>
    <Row>
      <Col className="schedule-header">
        {headerLeft && <div className="schedule-header-left">{headerLeft}</div>}
        <div>{header}</div>
        {headerRight && <div className="schedule-header-right">{headerRight}</div>}
      </Col>
    </Row>
    <Row>
      {scheduleList.map((item, index) => (
        <Col
          key={index}
          className={classNames({
            "past-day": dayjs().isAfter(dayjs(item.workYmd, "YYYYMMDD"), "day"),
          })}
        >
          <Row>
            <Col>{dayjs(item.workYmd, "YYYYMMDD").format("dd")}</Col>
          </Row>
          <Row>
            <Col>{item.workYmd.substring(6)}</Col>
          </Row>
          <Row>
            <Col>
              {item.workStatus === "1" ? (
                <Icon name="circle" shape="outlined" color="orange" />
              ) : (
                <Icon name="remove" />
              )}
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  </Container>
);
