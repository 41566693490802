import { IntroduceRegistRequest } from "core/model/girl/introduce";
import { hidePageSpinner, showPageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";

/**
 * お紹介ユーザの登録処理
 * @param data
 */
export const registIntroduceUser = async (data: IntroduceRegistRequest) => {
  try {
    showPageSpinner();
    const res = await Api.post("/user/register", data);
    if (isSuccess(res)) {
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};
