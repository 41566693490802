import { Block, BlockTitle, Button, Col, List, ListItem, Row } from "framework7-react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useSwiper } from "swiper/react";

import { siteProfileChargeSelector } from "core/slice/site/profile/charge";

import { CommonConfig } from "global/config";
import { ChargeType } from "global/enum/profile-site";

import { Icon } from "ui/widget/icon";

const Titles = {
  [ChargeType.wechat]: "微信收款",
  [ChargeType.alipay]: "支付宝收款",
  [ChargeType.union]: "银行转账",
  [ChargeType.USDT]: "USDT(虚拟货币)收款",
};

/**
 * ChargeStep2
 * @returns ChargeStep2
 */
export const ChargeStep2: React.FC = () => {
  const swiper = useSwiper();
  const { chargeMInfo } = useSelector(siteProfileChargeSelector);

  const formattedCardNo = useMemo(() => {
    if (!chargeMInfo?.cardNo) return "";
    return chargeMInfo.cardNo.replace(/(\d{4})(?=\d)/g, "$1 ");
  }, [chargeMInfo]);

  if (!chargeMInfo) return null;

  return (
    <>
      <BlockTitle medium>{Titles[chargeMInfo.chargeType]}</BlockTitle>
      {chargeMInfo!.chargeType === ChargeType.union ? (
        <Block>
          <List>
            <ListItem title="收款户名" after={chargeMInfo.name}>
              <Icon name="perm_identity" color="purple" slot="media" />
            </ListItem>
            <ListItem title="收款账号" after={formattedCardNo}>
              <Icon name="payment" color="red" slot="media" />
            </ListItem>
            <ListItem title="收款银行" after={chargeMInfo.cardBank}>
              <Icon name="account_balance_wallet" color="green" slot="media" />
            </ListItem>
          </List>
        </Block>
      ) : (
        <Block className="text-align-center">
          <BlockTitle>收款人: {chargeMInfo.name}</BlockTitle>
          <img src={CommonConfig.chargeBaseUrl + chargeMInfo.qrcode} alt="qrcode" style={{ maxWidth: 300 }} />
        </Block>
      )}
      <BlockTitle>{chargeMInfo.memo}</BlockTitle>
      <Block style={{ marginTop: 32 }}>
        <Row>
          <Col width={50}>
            <Button type="button" color="gray" fill onClick={() => swiper.slidePrev()}>
              上一步
            </Button>
          </Col>
          <Col width={50}>
            <Button type="button" color="green" fill onClick={() => swiper.slideNext()}>
              转账完了，凭证上传
            </Button>
          </Col>
        </Row>
      </Block>
    </>
  );
};
