import { useFormik } from "formik";
import { Link, Navbar, NavLeft, NavRight, NavTitle, Page } from "framework7-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { profileSelector } from "core/slice/girl/profile";
import { profileInitialize, updateProfile } from "core/usecase/girl/profile";
import { goBack } from "core/usecase/global";

import { FormikList, FormikListInput } from "ui/widget/formik";

type Shape = { nickName?: string };

/**
 * ニックネーム編集画面
 * @returns NickNameEditor
 */
export const NickNameEditorPage: React.FC = () => {
  const { nickName } = useSelector(profileSelector);

  const formik = useFormik<Shape>({
    initialValues: {
      nickName: nickName || "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      nickName: yup.string().max(20),
    }),
    onSubmit(value) {
      updateProfile("/profile/nick-name/s", value).then(success => {
        if (success) goBack("/girl/profile/base");
      });
    },
  });

  const { setValues } = formik;

  /**
   * データ再設定
   */
  useEffect(() => {
    setValues({ nickName });
  }, [nickName, setValues]);

  return (
    <Page onPageBeforeIn={profileInitialize}>
      <Navbar>
        <NavLeft backLink backLinkUrl="/girl/profile/base" backLinkForce />
        <NavTitle>ニックネーム</NavTitle>
        <NavRight>
          <Link href={false} onClick={formik.submitForm}>
            確定
          </Link>
        </NavRight>
      </Navbar>

      <FormikList formik={formik}>
        <FormikListInput
          type="text"
          name="nickName"
          label="ニックネーム"
          maxlength={20}
          info="20文字以内で入力してください"
        />
      </FormikList>
    </Page>
  );
};
