import { Block, BlockTitle, Button } from "framework7-react";
import { useSwiper } from "swiper/react";

import { Icon } from "ui/widget/icon";

/**
 * ChargeStep4
 * @returns ChargeStep4
 */
export const ChargeStep4: React.FC = () => {
  const swiper = useSwiper();

  return (
    <>
      <Block style={{ textAlign: "center", marginTop: 80 }}>
        <Icon name="check_circle" size="80px" color="green" />
        <BlockTitle medium>上传成功, 等待管理员确认</BlockTitle>
      </Block>

      <Block>
        <Button type="button" color="green" fill onClick={() => swiper.slideTo(0, 0)}>
          返回
        </Button>
      </Block>
    </>
  );
};
