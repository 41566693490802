import { Button, Card, CardContent, CardFooter, Col, List, ListItem, Row } from "framework7-react";
import React, { useCallback, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import styled from "styled-components";

import { ReserveItem } from "core/model/site/order";
import { cancelReserve, deleteReserve } from "core/usecase/site/order";
import { showConfirmModal } from "core/utils/common";

import { AddressLabel, AreaLabel } from "global/enum-label";
import { ReserveStatusLabel } from "global/enum-label/reserve";
import { ReserveStatus } from "global/enum/reserve";

import { CastItem } from "ui/widget/cast-item";
import { Divider } from "ui/widget/divider";
import { FormattedDate } from "ui/widget/formatted-date";
import { Gallery, GalleryItem } from "ui/widget/gallery";
import { Icon } from "ui/widget/icon";

import { ReserveCancelModal } from "./reserve-cancel-modal";

const Container = styled.div({
  ".list .item-content .item-media": {
    minWidth: "auto",
  },

  ".divider": {
    paddingRight: 0,
    paddingLeft: 48,
  },
});

type OrderItemProps = {
  item: ReserveItem;
  useExpand?: boolean;
  onCancelSuccess?: () => void;
  onDeleteSuccess?: () => void;
};

/**
 * OrderItem
 * @param props
 * @param props.item
 * @param props.useExpand
 * @param props.onCancelSuccess
 * @param props.onDeleteSuccess
 * @returns OrderItem
 */
export const OrderItem: React.FC<OrderItemProps> = ({ item, useExpand = true, onCancelSuccess, onDeleteSuccess }) => {
  const [expand, setExpand] = useState(false);
  const [cancelModalOpened, setCancelModalOpened] = useState(false);

  /**
   * 予約取消
   */
  const handleReserveCancel = useCallback(
    (cancelReason: string) => {
      cancelReserve([item.reserveId], cancelReason).then(success => {
        if (!success) return;
        setCancelModalOpened(false);
        if (onCancelSuccess) onCancelSuccess();
      });
    },
    [item.reserveId, onCancelSuccess],
  );

  /**
   * 予約削除
   */
  const handleReserveDelete = useCallback(() => {
    showConfirmModal("确定要删除吗?", () => {
      deleteReserve(item.reserveId).then(success => {
        if (success && onDeleteSuccess) onDeleteSuccess();
      });
    });
  }, [item.reserveId, onDeleteSuccess]);

  return (
    <Container>
      <Card>
        <CardContent>
          <List>
            <ListItem title="预约见面时间">
              <Icon name="schedule" slot="media" color="blue" />
              <div slot="after">
                <FormattedDate value={item.meetTime} format="YYYY-MM-DD HH:mm" />
              </div>
            </ListItem>
            <ListItem title="时长" after={`${item.hour}小时`}>
              <Icon name="timer" slot="media" color="green" />
            </ListItem>
            <ListItem title="场所">
              <Icon name="place" slot="media" color="orange" />
              <div slot="after">
                <FormattedMessage id={AreaLabel[item.area]} />
                &nbsp;
                <FormattedMessage id={AddressLabel[item.areaAddr]} />
              </div>
            </ListItem>
          </List>
        </CardContent>
        {(!useExpand || expand) && (
          <>
            <Divider margin={false} />
            <CardContent>
              <List>
                <ListItem title="预约NO" after={item.reserveNo}>
                  <Icon name="info" slot="media" color="yellow" />
                </ListItem>
                <ListItem title="下单时间">
                  <Icon name="schedule" slot="media" color="lightblue" />
                  <div slot="after">
                    <FormattedDate value={item.orderTime} format="YYYY-MM-DD HH:mm" />
                  </div>
                </ListItem>
                <ListItem title="状态">
                  <Icon name="label" slot="media" color="lime" />
                  <div slot="after">
                    <FormattedMessage id={ReserveStatusLabel[item.status]} />
                  </div>
                </ListItem>
                <ListItem title="备注">
                  <Icon name="note_alt" slot="media" color="teal" />
                  <div slot="after" style={{ whiteSpace: "pre-line" }}>
                    {item.memo}
                  </div>
                </ListItem>
                <ListItem title="消费积分">
                  <Icon name="paid" slot="media" color="purple" />
                  <div slot="after">
                    <FormattedNumber value={item.payPrice} />P
                  </div>
                </ListItem>
                <ListItem title="预约人数(合计)" after={`${item.peopleNumber}人`}>
                  <Icon name="people" slot="media" color="pink" />
                </ListItem>
              </List>
            </CardContent>
            <CardFooter style={{ paddingTop: 4, paddingBottom: 10 }}>
              <Gallery gap={6}>
                {item.castList.map(({ castId, name, ...others }) => (
                  <GalleryItem key={castId}>
                    <CastItem item={{ userId: castId, nickName: name, ...others }} showTitle={false} absoluteAvatar />
                  </GalleryItem>
                ))}
              </Gallery>
            </CardFooter>
            {item.status !== ReserveStatus.DELETE && (
              <CardFooter>
                <Row style={{ width: "100%", justifyContent: "space-around" }}>
                  {item.status === ReserveStatus.PROCESSING && (
                    <Col width={50}>
                      <Button outline color="deeporange" onClick={() => setCancelModalOpened(true)}>
                        预约取消
                      </Button>
                    </Col>
                  )}
                  <Col width={50} onClick={handleReserveDelete}>
                    <Button fill color="red">
                      删除
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </>
        )}
        {useExpand && (
          <CardFooter>
            <Button onClick={() => setExpand(!expand)} type="button">
              {expand ? (
                <>
                  <Icon name="keyboard_double_arrow_up" />
                  收起
                </>
              ) : (
                <>
                  <Icon name="keyboard_double_arrow_down" />
                  显示更多
                </>
              )}
            </Button>
          </CardFooter>
        )}
      </Card>

      <ReserveCancelModal
        opened={cancelModalOpened}
        onClose={() => setCancelModalOpened(false)}
        onConfirm={handleReserveCancel}
      />
    </Container>
  );
};
