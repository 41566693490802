import { Block, Navbar, Page } from "framework7-react";
import React from "react";

import { PasswordChangeForm } from "ui/component/password-change";

/**
 * パスワード変更画面
 * @returns PasswordReset
 */
export const PasswordChangePage: React.FC = () => (
  <Page>
    <Navbar backLink backLinkUrl="/girl/profile" backLinkForce title="パスワード変更" />
    <Block inset strong>
      <PasswordChangeForm backLink="/girl/profile" />
    </Block>
  </Page>
);
