import { Button, List, ListItem } from "framework7-react";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { SMART_SELECT_DEFAULT_PARAMS } from "global/constant";
import { Area, Address } from "global/enum";
import { AreaLabel, AddressLabel } from "global/enum-label";

import { Modal } from "ui/widget/modal";

type AddressModalProps = {
  opened: boolean;
  onClose: () => void;
  area: Area;
  areaAddr: Address;
  selectableAddress?: Array<Address>;
  onConfirm: (area: Area, areaAddr: Address) => void;
};

/**
 * AddressModal
 * @param props
 * @param props.opened
 * @param props.onClose
 * @param props.area
 * @param props.areaAddr
 * @param props.selectableAddress
 * @param props.onConfirm
 * @returns AddressModal
 */
export const AddressModal: React.FC<AddressModalProps> = ({
  opened,
  onClose,
  area,
  areaAddr,
  selectableAddress,
  onConfirm,
}) => {
  const [localArea, setLocaleArea] = useState(area);
  const [localAreaAddr, setLocalAreaAddr] = useState(areaAddr);

  useEffect(() => {
    if (!opened) return;
    setLocaleArea(area);
    setLocalAreaAddr(areaAddr);
  }, [area, areaAddr, opened]);

  /**
   * handleConfirm
   */
  const handleConfirm = useCallback(() => {
    onConfirm(localArea, localAreaAddr);
    onClose();
  }, [onConfirm, localArea, localAreaAddr, onClose]);

  return (
    <Modal
      fluid
      opened={opened}
      title="预约场所选择"
      onClose={onClose}
      content={
        <>
          <List>
            <ListItem title="都道府县" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
              <div slot="after">
                <FormattedMessage id={AreaLabel[localArea]} />
              </div>
              <select name="area" value={localArea} onChange={e => setLocaleArea(e.target.value as Area)}>
                {Object.entries(AreaLabel).map(([value, label]) => (
                  <option key={value} value={value}>
                    <FormattedMessage id={label} />
                  </option>
                ))}
              </select>
            </ListItem>
            <ListItem title="预约场所" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
              <div slot="after">
                <FormattedMessage id={AddressLabel[localAreaAddr]} />
              </div>
              <select name="areaAddr" value={localAreaAddr} onChange={e => setLocalAreaAddr(e.target.value as Address)}>
                {selectableAddress
                  ? selectableAddress.map(value => (
                      <option key={value} value={value}>
                        <FormattedMessage id={AddressLabel[value]} />
                      </option>
                    ))
                  : Object.entries(AddressLabel).map(([value, label]) => (
                      <option key={value} value={value}>
                        <FormattedMessage id={label} />
                      </option>
                    ))}
              </select>
            </ListItem>
          </List>
          <Button fill onClick={handleConfirm}>
            确定
          </Button>
        </>
      }
    />
  );
};
