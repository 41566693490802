import { Navbar, Page } from "framework7-react";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { PresentsHistoryListResponse } from "core/model/present/api";
import { getSentPresentList } from "core/usecase/present";

import { PresentLogList } from "ui/component/site/present/present-log/present-log-list";
import { PresentLogSummary } from "ui/component/site/present/present-log/present-log-summary";
import { NoData } from "ui/widget/no-data";

/**
 * PresentLogPage
 * @returns PresentLogPage
 */
export const PresentLogPage: React.FC = () => {
  const [presentHistories, setPresentHistories] = useState<PresentsHistoryListResponse>([]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    getSentPresentList()
      .then(setPresentHistories)
      .finally(() => setInitialized(true));
  }, []);

  /** 送ったプレゼント合計 */
  const presentTotal = useMemo(() => presentHistories.reduce((p, c) => p + c.price, 0), [presentHistories]);

  return (
    <Page>
      <Navbar backLink backLinkForce backLinkUrl="/profile" title="送礼记录" />
      {initialized &&
        (presentHistories.length === 0 ? (
          <NoData title={<FormattedMessage id="common.nodata" />} />
        ) : (
          <>
            <PresentLogSummary total={presentTotal} />
            <PresentLogList presentHistories={presentHistories} />
          </>
        ))}
    </Page>
  );
};
