import { Block, Button, Col, f7, Row, Toolbar } from "framework7-react";
import React from "react";
import { useSelector } from "react-redux";

import { RegistReserveRequest } from "core/model/site/order";
import { siteOrderSelector } from "core/slice/site/order";
import { goBack } from "core/usecase/global";
import { registReserve } from "core/usecase/site/order";
import { showConfirmModal } from "core/utils/common";

/**
 * ReserveConfirmAction
 * @returns ReserveConfirmAction
 */
export const ReserveConfirmAction: React.FC = () => {
  const { reserve } = useSelector(siteOrderSelector);

  /**
   * 予約確認
   */
  const handleConfirm = () => {
    showConfirmModal("是否确认下单?", () => {
      const { casts, typeOther, ...others } = reserve;
      // リクエストボディー
      const requestBody: RegistReserveRequest = {
        ...others,
        typeOther: typeOther ? new Date(typeOther) : undefined,
        situation: "",
        castType: "",
        castUserIdList: casts.filter(o => o.selected).flatMap(o => o.selected!.map(s => s.userId)),
      };
      registReserve(requestBody).then(reserveNo => {
        if (!reserveNo) return;
        // 完了画面に遷移する
        f7.view.main.router.navigate("/order/reserve-complete", {
          transition: "f7-push",
          props: {
            reserveNo,
          },
        });
      });
    });
  };

  return (
    <Toolbar bottom>
      <Block style={{ width: "100%" }}>
        <Row>
          <Col width={50}>
            <Button fill color="green" onClick={() => goBack("/order/cast-select", { transition: "f7-push" })}>
              上一步
            </Button>
          </Col>
          <Col width={50}>
            <Button fill color="orange" onClick={handleConfirm}>
              确认下单
            </Button>
          </Col>
        </Row>
      </Block>
    </Toolbar>
  );
};
