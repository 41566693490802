import { Block, Row, Col, Button } from "framework7-react";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { GirlSearchCondition } from "core/model/site/top";
import { siteTopSelector } from "core/slice/site/top";
import { searchGirls } from "core/usecase/site/top";

import { AddressLabel, AreaLabel, HairColorLabel, HairTypeLabel, SakeLabel, TobaccoLabel } from "global/enum-label";
import { RankingLabel } from "global/enum-label/girl";

import { Icon } from "ui/widget/icon";
import { Tag } from "ui/widget/tag";
import { TextEllipsis } from "ui/widget/text-ellipsis";

type Props = {
  onConditionChangeClick: () => void;
};

const TagsCondition = styled.div({
  marginTop: 16,
  paddingLeft: "calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left))",
  paddingRight: "calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left))",

  ".tag": {
    marginRight: 8,
  },

  ".tag:last-child": {
    marginRight: 0,
  },
});

/**
 * SearchCondition
 * @param props
 * @param props.onConditionChangeClick
 * @returns SearchCondition
 */
export const SearchCondition: React.FC<Props> = ({ onConditionChangeClick }) => {
  const { condition } = useSelector(siteTopSelector);
  const { formatMessage } = useIntl();

  /**
   * removeCondtionItem
   */
  const removeCondtionItem = useCallback(
    (name: keyof GirlSearchCondition) => {
      searchGirls({
        ...condition,
        [name]: undefined,
      });
    },
    [condition],
  );

  /**
   * removeHeightCondtion
   */
  const removeHeightCondtion = useCallback(() => {
    searchGirls({
      ...condition,
      minHeight: undefined,
      maxHeight: undefined,
    });
  }, [condition]);

  return (
    <>
      <TagsCondition>
        {condition.key && (
          <Tag
            text={
              <>
                关键词:&nbsp;
                <TextEllipsis text={condition.key} limit={6} />
              </>
            }
            deletable
            onDelete={() => removeCondtionItem("key")}
          />
        )}
        {condition.minHeight && condition.maxHeight && (
          <Tag
            text={`身高: ${condition.minHeight}cm〜${condition.maxHeight}cm`}
            deletable
            onDelete={removeHeightCondtion}
          />
        )}
        {condition.hairType && (
          <Tag
            text={
              <>
                发型:&nbsp;
                <FormattedMessage id={HairTypeLabel[condition.hairType]} />
              </>
            }
            deletable
            onDelete={() => removeCondtionItem("hairType")}
          />
        )}
        {condition.hairColor && (
          <Tag
            text={
              <>
                发色:&nbsp;
                <FormattedMessage id={HairColorLabel[condition.hairColor]} />
              </>
            }
            deletable
            onDelete={() => removeCondtionItem("hairColor")}
          />
        )}
        {condition.tobacco && (
          <Tag
            text={
              <>
                吸烟:&nbsp;
                <FormattedMessage id={TobaccoLabel[condition.tobacco]} />
              </>
            }
            deletable
            onDelete={() => removeCondtionItem("tobacco")}
          />
        )}
        {condition.sake && (
          <Tag
            text={
              <>
                喝酒:&nbsp;
                <FormattedMessage id={SakeLabel[condition.sake]} />
              </>
            }
            deletable
            onDelete={() => removeCondtionItem("sake")}
          />
        )}
        {condition.ranking && condition.ranking.length > 0 && (
          <Tag
            text={
              <>
                等级:&nbsp;
                {condition.ranking.map(ranking => formatMessage({ id: RankingLabel[ranking] })).join(", ")}
              </>
            }
            deletable
            onDelete={() => removeCondtionItem("ranking")}
          />
        )}
        {condition.date && <Tag text="今日出勤" deletable onDelete={() => removeCondtionItem("date")} />}
        {condition.area && (
          <Tag
            text={
              <>
                所在地:&nbsp;
                <FormattedMessage id={AreaLabel[condition.area]} />
              </>
            }
            deletable
            onDelete={() => removeCondtionItem("area")}
          />
        )}
        {condition.address && (
          <Tag
            text={
              <>
                车站:&nbsp;
                <FormattedMessage id={AddressLabel[condition.address]} />
              </>
            }
            deletable
            onDelete={() => removeCondtionItem("address")}
          />
        )}
      </TagsCondition>
      <Block style={{ marginTop: 16 }}>
        <Row>
          <Col width={50}>
            <Button type="button" fill color="teal" onClick={() => searchGirls({})}>
              <Icon name="redo" size={18} />
              &nbsp;重置
            </Button>
          </Col>
          <Col width={50}>
            <Button type="button" fill color="primary" onClick={onConditionChangeClick}>
              <Icon name="search" size={18} />
              &nbsp;更改条件
            </Button>
          </Col>
        </Row>
      </Block>
    </>
  );
};
