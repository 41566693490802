import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";

import { siteProfileSelector } from "core/slice/site/profile";
import { updateProfile } from "core/usecase/site/profile";

import { DateTimePicker } from "ui/widget/picker";

type SelectorsProps = {
  /** visible */
  visible: boolean;
  /** onClose */
  onClose: () => void;
};

/**
 * 生年月日選択用
 */
export const BirthdaySelector = React.memo(({ visible, onClose }: SelectorsProps) => {
  const now = dayjs();
  const { birth } = useSelector(siteProfileSelector).profile;
  const defaultBirth = now.subtract(20, "year").format("YYYY-MM-DD");

  /**
   * handleCancel
   */
  const handleCancel = () => {
    updateProfile("birth", "");
    onClose();
  };

  /**
   * handleConfirm
   * @param value
   */
  const handleConfirm = (value: string) => {
    updateProfile("birth", dayjs(value, "YYYY-MM-DD").format("YYYYMMDD"));
    onClose();
  };

  return (
    <DateTimePicker
      opened={visible}
      onPickerClosed={onClose}
      minYear={now.subtract(100, "year").year()}
      maxYear={now.year()}
      value={birth ? dayjs(birth, "YYYYMMDD").format("YYYY-MM-DD") : defaultBirth}
      format="YYYY-MM-DD"
      toolbarCancelText="清除数据"
      toolbarConfirmText="确定"
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  );
});
