import { Page } from "framework7-react";
import React, { useState } from "react";
import { PropsWithF7Router } from "routes";

import { userVerify } from "core/usecase/signup";

import { Role } from "global/enum";

import { SignupVerifyBlock } from "ui/component/signup/verify-block";

/**
 * メールアドレス確認ページ
 * @param props
 * @param props.f7route
 * @returns SignupVerifyPage
 */
export const SignupVerifyPage: React.FC<PropsWithF7Router> = ({ f7route }) => {
  const [result, setResult] = useState<boolean>();

  return (
    <Page noNavbar noToolbar onPageBeforeIn={() => userVerify(f7route.query.code!, Role.girl).then(setResult)}>
      {result !== undefined && <SignupVerifyBlock result={result} loginLink="/girl/login" signupLink="/girl/signup" />}
    </Page>
  );
};
