import { BlockTitle, List, ListInput } from "framework7-react";
import React from "react";
import { useSelector } from "react-redux";

import { siteOrderSelector } from "core/slice/site/order";
import { updateReserveData } from "core/usecase/site/order";

/**
 * ReserveMemo
 * @returns ReserveMemo
 */
export const ReserveMemo: React.FC = () => {
  const { memo } = useSelector(siteOrderSelector).reserve;

  return (
    <>
      <BlockTitle medium>备注</BlockTitle>
      <List>
        <ListInput
          name="memo"
          type="textarea"
          value={memo}
          placeholder="在此输入备注信息, 200字以内"
          maxlength={200}
          onChange={e => updateReserveData({ memo: e.target.value })}
          clearButton
          onInputClear={() => updateReserveData({ memo: undefined })}
          outline
        />
      </List>
    </>
  );
};
