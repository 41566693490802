import {
  Annual,
  Country,
  Hobby,
  Language,
  Personality,
  ChargeType,
  WalletTargetType,
  ChargeOrderStatus,
} from "global/enum/profile-site";

/**
 * 性格
 */
export const PersonalityLabel = {
  [Personality.noisily]: "安静",
  [Personality.moist]: "圆滑",
  [Personality.paripi]: "善于社交",
};

/**
 * 趣味
 */
export const HobbyLabel = {
  [Hobby.read]: "读书",
  [Hobby.trip]: "旅行",
  [Hobby.music]: "听歌",
  [Hobby.manga]: "漫画",
  [Hobby.video]: "看电影",
  [Hobby.training]: "健身",
  [Hobby.mahjong]: "麻将",
  [Hobby.game]: "游戏",
  [Hobby.golf]: "高尔夫",
  [Hobby.ktv]: "KTV",
  [Hobby.bbq]: "户外",
};

/**
 * 国籍
 */
export const CountryLabel = {
  [Country.China]: "中国",
  [Country.Japan]: "日本",
  [Country.Korea]: "韩国",
  [Country.America]: "美国",
  [Country.Europe]: "欧洲",
  [Country.SouthAsia]: "东南亚",
  [Country.Other]: "其他",
};

/**
 * 年収
 */
export const AnnualLabel = {
  [Annual.lt20]: "0-20万元",
  [Annual.lt40]: "20-40万元",
  [Annual.lt60]: "40-60万元",
  [Annual.lt80]: "60-80万元",
  [Annual.lt100]: "80-100万元",
  [Annual.lt150]: "100-150万元",
  [Annual.lt200]: "150-200万元",
  [Annual.lt300]: "200-300万元",
  [Annual.lt500]: "300-500万元",
  [Annual.lt800]: "500-800万元",
  [Annual.lt1000]: "800-1000万元",
  [Annual.gt1000]: "1000万元以上",
};

/**
 * 言語
 */
export const LanguageLabel = {
  [Language.Chinese]: "汉语",
  [Language.Japanese]: "日语",
  [Language.English]: "英语",
  [Language.Korean]: "韩语",
  [Language.Other]: "其他",
};

/**
 * チャージタイプ
 */
export const ChargeTypeLabel = {
  [ChargeType.wechat]: "微信支付",
  [ChargeType.alipay]: "支付宝",
  [ChargeType.union]: "银行转账",
  [ChargeType.USDT]: "USDT(虚拟货币)",
};

/**
 * ウォレット取引種別
 */
export const WalletTargetTypeLabel = {
  [WalletTargetType.charge]: "充值",
  [WalletTargetType.order]: "下单",
  [WalletTargetType.withdraw]: "提现",
  [WalletTargetType.present]: "礼物",
};

/**
 * チャージオーダー処理状態
 */
export const ChargeOrderStatusLabel = {
  [ChargeOrderStatus.pending]: "管理员确认中",
  [ChargeOrderStatus.approved]: "确认成功，充值中",
  [ChargeOrderStatus.denied]: "充值失败",
  [ChargeOrderStatus.done]: "充值成功",
};
