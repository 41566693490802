import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";

/**
 * FormattedDateProps
 */
export type FormattedDateProps = {
  /** defaultValue */
  defaultValue?: string;
  /** value */
  value?: string | Date | Dayjs | number | null;
  /** originFormat */
  originFormat?: string;
  /** format: default YYYY-MM-DD */
  format?: string;
  /** locale */
  locale?: "ja" | "zh-cn" | "en";
};

/**
 * FormattedDate
 * @param props
 * @returns FormattedDate
 */
export const FormattedDate: React.FC<FormattedDateProps> = props => {
  const { defaultValue = "", value, originFormat, format = "YYYY-MM-DD", locale } = props;

  const fomattedValue = useMemo(() => {
    if (!value) return defaultValue;
    const date = originFormat ? dayjs(value, originFormat, true) : dayjs(value);
    if (!date.isValid()) return defaultValue;
    if (locale) {
      return date.locale(locale).format(format);
    }
    return date.format(format);
  }, [value, defaultValue, originFormat, locale, format]);

  return <>{fomattedValue}</>;
};
