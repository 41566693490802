import classNames from "classnames";
import React from "react";
import styled from "styled-components";

/**
 * GalleryProps
 */
export interface GalleryProps extends React.HTMLAttributes<HTMLDivElement> {
  /** gap: default 16 */
  gap?: number;
  /** columns: default responsive */
  columns?: number | "responsive";
}

const Container = styled.div<{ gap: number; columns: number | "responsive" }>(({ gap, columns }) => ({
  "--f7-grid-gap": gap,
  display: "flex",
  flexFlow: "row wrap",
  alignItems: "stretch",
  justifyContent: "flex-start",
  width: "100%",

  ".gallery-item": {
    marginTop: "var(--f7-grid-gap)",
    marginLeft: "var(--f7-grid-gap)",
    width:
      columns === "responsive"
        ? "calc((100% - var(--f7-grid-gap) * 2) / 3)"
        : `calc((100% - var(--f7-grid-gap) * ${columns - 1}) / ${columns})`,
    height: "auto",
  },

  ".gallery-item:first-child": {
    marginLeft: 0,
  },

  "@media (min-width: 480px)": {
    [`.gallery-item:nth-child(${columns === "responsive" ? 3 : columns}n+1)`]: {
      marginLeft: 0,
    },
  },

  "@media (max-width: 480px)": {
    ".gallery-item": {
      width: columns === "responsive" ? "calc((100% - var(--f7-grid-gap)) / 2)" : undefined,
    },

    [`.gallery-item:nth-child(${columns === "responsive" ? 2 : columns}n+1)`]: {
      marginLeft: 0,
    },
  },
}));

/**
 * Gallery
 * @param props
 * @param props.gap
 * @param props.columns
 * @param props.className
 * @param props.children
 * @returns Gallery
 */
export const Gallery: React.FC<GalleryProps> = ({
  gap = 16,
  columns = "responsive",
  className,
  children,
  ...attrs
}) => (
  <Container gap={gap} columns={columns} className={classNames("gallery", className)} {...attrs}>
    {children}
  </Container>
);
