import { Block, SkeletonBlock } from "framework7-react";
import React from "react";

/**
 * MessageListSkeleton
 * @returns MessageListSkeleton
 */
export const MessageListSkeleton: React.FC = () => (
  <Block>
    {Array.from(Array(8).keys()).map(index => (
      <React.Fragment key={index}>
        <div style={{ width: "75%", display: "flex", flexFlow: "row nowrap", marginBottom: 16 }}>
          <SkeletonBlock tag="div" width="45px" height="45px" borderRadius="50%" effect="wave" />
          <div style={{ flex: 1, marginLeft: 8 }}>
            <SkeletonBlock tag="div" width="100%" height="21px" borderRadius="0" effect="wave" />
            <SkeletonBlock
              tag="div"
              width="100%"
              height="21px"
              borderRadius="0"
              effect="wave"
              style={{ marginTop: 4 }}
            />
          </div>
        </div>
        <div style={{ marginLeft: "auto", width: "75%", display: "flex", flexFlow: "row nowrap", marginBottom: 16 }}>
          <div style={{ flex: 1, marginRight: 8 }}>
            <SkeletonBlock tag="div" width="100%" height="21px" borderRadius="0" effect="wave" />
            <SkeletonBlock
              tag="div"
              width="100%"
              height="21px"
              borderRadius="0"
              effect="wave"
              style={{ marginTop: 4 }}
            />
          </div>
          <SkeletonBlock tag="div" width="45px" height="45px" borderRadius="50%" effect="wave" />
        </div>
      </React.Fragment>
    ))}
  </Block>
);
