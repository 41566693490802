import { useFormik } from "formik";
import { Block, Button, Link, PageContent, Sheet, Toolbar } from "framework7-react";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";

import { showPageSpinner, hidePageSpinner } from "core/usecase/global";
import { newTimeline } from "core/usecase/site/timeline";
import { showErrorModal } from "core/utils/common";
import { compress, heicToJpg, blobToBase64, base64ToFile } from "core/utils/file";

import { FormikList, FormikListInput } from "ui/widget/formik";
import { Icon } from "ui/widget/icon";
import { IconButton } from "ui/widget/icon-button";
import { UploadContainer } from "ui/widget/upload/upload-container";

const ImageViewer = styled.div({
  width: 160,
  borderRadius: 4,
  overflow: "hidden",
  position: "relative",

  ".icon-button": {
    position: "absolute",
    top: 1,
    right: 1,
    // color: "red",
    padding: 0,
    background: "red",
    color: "#fff",
    borderRadius: "50%",
    width: 22,
    height: 22,

    ".icon": {
      fontSize: 18,
    },
  },
});

type TimelineCreateSheetProps = {
  opened: boolean;
  onClose: () => void;
};

type Shape = { message: string; image?: string };

/**
 * TimelineCreateSheet
 * @param props
 * @param props.opened
 * @param props.onClose
 * @returns TimelineCreateSheet
 */
export const TimelineCreateSheet: React.FC<TimelineCreateSheetProps> = ({ opened, onClose }) => {
  const pageContentRef = useRef<{ el: HTMLElement | null }>({ el: null });
  const [image, setImage] = useState<{ name: string; src: string }>();

  const formik = useFormik<Shape>({
    initialValues: {
      message: "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      message: yup.string().required().max(2000).trim(),
      image: yup.string(),
    }),
    onSubmit(value) {
      let file: File | undefined;
      if (image) {
        file = base64ToFile(image.src, image.name);
      }
      newTimeline(value.message, file).then(isSuccess => {
        if (isSuccess) {
          // データクリア
          setImage(undefined);
          formik.setFieldValue("message", "");
          // sheetクローズ
          onClose();
        }
      });
    },
  });
  const { setFieldError } = formik;

  useEffect(() => {
    if (opened) {
      setFieldError("message", undefined);
    }
  }, [opened, setFieldError]);

  /**
   * handleFileChange
   * @param event
   */
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      setImage(undefined);
      return;
    }
    try {
      showPageSpinner(pageContentRef.current.el);
      const file = await compress(await heicToJpg(event.target.files[0]));
      // zoomTo
      const imageSrc = await blobToBase64(file);
      // image
      setImage({ name: file.name, src: imageSrc });
    } catch {
      setImage(undefined);
      showErrorModal("图片格式不正确");
    } finally {
      hidePageSpinner(pageContentRef.current.el);
    }
  };

  return (
    <Sheet
      opened={opened}
      onSheetClose={onClose}
      backdrop
      swipeToClose
      style={{ height: "auto", maxHeight: 600, overflowY: "auto" }}
    >
      <Toolbar>
        <div className="left">
          <Link sheetClose href={false}>
            取消
          </Link>
        </div>
        <div className="right">
          <Button fill color="green" style={{ margin: "0 12px" }} onClick={formik.submitForm}>
            发表
          </Button>
        </div>
      </Toolbar>
      <PageContent ref={pageContentRef}>
        <FormikList formik={formik} noDivider>
          <FormikListInput resizable type="textarea" name="message" maxlength={2000} placeholder="这一刻的想法" />
        </FormikList>
        <Block>
          {image ? (
            <ImageViewer>
              <img src={image.src} alt="" width="100%" />
              <IconButton icon={<Icon name="clear" />} onClick={() => setImage(undefined)} />
            </ImageViewer>
          ) : (
            <UploadContainer
              icon={<Icon name="photo_camera" shape="outlined" size={64} />}
              shape="rounded"
              width={160}
              height={200}
              accept="image/*,.heic,.heif"
              onFileChange={handleFileChange}
            />
          )}
        </Block>
      </PageContent>
    </Sheet>
  );
};
