import { useFormik } from "formik";
import { Button, ListItem, LoginScreenTitle, f7 } from "framework7-react";
import React from "react";
import styled from "styled-components";
import * as yup from "yup";

import { IntroduceRegistRequest } from "core/model/girl/introduce";
import { registIntroduceUser } from "core/usecase/girl/introduce";
import { showConfirmModal } from "core/utils/common";
import { Hyphen } from "core/utils/yup/yup.ext";

import { FormikList, FormikListInput } from "ui/widget/formik";
import { StyledText } from "ui/widget/styled-text";

const Container = styled.div({
  paddingTop: 16,
  paddingBottom: 16,
  border: "1px solid rgba(255, 255, 255, .13)",
  backgroundColor: "#282c34",
  background: `linear-gradient(0deg, 
      rgba(0, 0, 0, 0.6) 0%, 
      rgba(55, 39, 4, 0.2) 100%)`,
  boxShadow: "0 6px 20px 5px rgba(0, 0, 0, .75)",
  backdropFilter: "blur(6px)",
  overflow: "hidden",
});

type Props = {
  id: string;
};

/**
 * FormSection
 * @param props
 * @param props.id
 * @returns FormSection
 */
export const FormSection: React.FC<Props> = ({ id }) => {
  const formik = useFormik<IntroduceRegistRequest>({
    initialValues: {
      id,
      girlName: "",
      age: "",
      tel: "",
      email: "",
      salary: "",
      interviewDate: "",
      memo: "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      id: yup.string().required().number(),
      girlName: yup.string().required().zenkaku().max(100),
      age: yup.string().required().number().max(2),
      tel: yup.string().required().tel(Hyphen.Forbidden),
      email: yup.string().required().email().max(100),
      salary: yup.string().required().max(20),
      interviewDate: yup.string().required().date("YYYY-MM-DDTHH:mm").futureDate({ format: "YYYY-MM-DDTHH:mm" }),
      memo: yup.string().max(2000),
    }),
    onSubmit(value) {
      showConfirmModal("登録したも宜しいでしょう", () => {
        registIntroduceUser(value).then(success => {
          if (success) {
            f7.dialog.alert("登録完了しました");
            formik.resetForm();
          }
        });
      });
    },
  });

  return (
    <Container className="block inset">
      <LoginScreenTitle>
        <StyledText color="gold" size={30}>
          お紹介
        </StyledText>
      </LoginScreenTitle>

      <FormikList formik={formik}>
        <FormikListInput label="氏名" type="text" name="girlName" maxlength={100} outline />
        <FormikListInput label="年齢" type="text" name="age" maxlength={2} outline />
        <FormikListInput label="電話番号(ハイフン不要)" name="tel" type="tel" outline placeholder="ハイフン不要" />
        <FormikListInput label="メールアドレス" name="email" type="email" outline max={100} />
        <FormikListInput label="希望収入(円)" name="salary" type="text" outline />
        <FormikListInput label="希望面談日" name="interviewDate" type="datetime-local" max="9999-12-31 23:59" outline />
        <FormikListInput
          label="メモ"
          type="textarea"
          name="memo"
          resizable
          maxlength={2000}
          outline
          placeholder="2000文字以内"
        />
        <ListItem>
          <Button fill color="orange" type="submit">
            確定
          </Button>
        </ListItem>
      </FormikList>
    </Container>
  );
};
