import { Block, Button, Col, Row, Toolbar } from "framework7-react";
import React from "react";
import { useSelector } from "react-redux";

import { siteOrderSelector } from "core/slice/site/order";
import { goBack } from "core/usecase/global";

/**
 * CastSelectAction
 * @returns CastSelectAction
 */
export const CastSelectAction: React.FC = () => {
  const { casts } = useSelector(siteOrderSelector).reserve;

  return (
    <Toolbar bottom>
      <Block style={{ width: "100%" }}>
        <Row>
          <Col width={50}>
            <Button fill color="green" onClick={() => goBack("/order", { transition: "f7-push" })}>
              上一步
            </Button>
          </Col>
          <Col width={50}>
            <Button
              fill
              color="orange"
              disabled={casts.some(o => o.peopleNumber !== o.selected?.length)}
              href="/order/reserve-confirm"
              transition="f7-push"
            >
              下一步
            </Button>
          </Col>
        </Row>
      </Block>
    </Toolbar>
  );
};
