import { Navbar, Page, PageContent } from "framework7-react";
import React, { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { siteOrderSelector } from "core/slice/site/order";
import { getReserveListOfNextPage, initializeReserveList } from "core/usecase/site/order";

import { OrderHistoryList } from "ui/component/site/order/order-history/order-history-list";

/**
 * OrderHistoryPage
 * @returns OrderHistoryPage
 */
export const OrderHistoryPage: React.FC = () => {
  const allowInfinite = useRef(true);
  const { initialized, hasMore } = useSelector(siteOrderSelector).reserveList;

  useEffect(() => {
    initializeReserveList();
  }, []);

  /**
   * handleInfinite
   */
  const handleInfinite = useCallback(() => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    getReserveListOfNextPage().finally(() => {
      allowInfinite.current = true;
    });
  }, []);

  return (
    <Page pageContent={false}>
      <Navbar title="预约记录" backLink backLinkForce backLinkUrl="/order" />
      <PageContent infinite={initialized && hasMore} onInfinite={handleInfinite}>
        <OrderHistoryList />
      </PageContent>
    </Page>
  );
};
