import { useFormik } from "formik";
import { Navbar, NavLeft, Link, NavTitle, NavRight, Page } from "framework7-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { profileSelector } from "core/slice/girl/profile";
import { profileInitialize, updateProfile } from "core/usecase/girl/profile";
import { goBack } from "core/usecase/global";

import { FormikList, FormikListInput } from "ui/widget/formik";

type Shape = { note?: string };

/**
 * 自己紹介編集画面
 * @returns NoteEditor
 */
export const NoteEditorPage: React.FC = () => {
  const { note } = useSelector(profileSelector);

  const formik = useFormik<Shape>({
    initialValues: {
      note: note || "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      note: yup.string().max(2000),
    }),
    onSubmit(value) {
      updateProfile("/profile/note/s", value).then(success => {
        if (success) goBack("/girl/profile/base");
      });
    },
  });

  const { setValues } = formik;

  /**
   * データ再設定
   */
  useEffect(() => {
    setValues({ note });
  }, [note, setValues]);

  return (
    <Page onPageBeforeIn={profileInitialize}>
      <Navbar>
        <NavLeft backLink backLinkUrl="/girl/profile/base" backLinkForce />
        <NavTitle>自己紹介</NavTitle>
        <NavRight>
          <Link href={false} onClick={formik.submitForm}>
            確定
          </Link>
        </NavRight>
      </Navbar>

      <FormikList formik={formik}>
        <FormikListInput
          resizable
          type="textarea"
          name="note"
          label="自己紹介"
          maxlength={2000}
          info="2000文字以内で入力してください"
        />
      </FormikList>
    </Page>
  );
};
