import { MessageKey } from "i18n";

import { Address, Area, Education, HairColor, HairType, Job, Sake, Tobacco } from "global/enum";

/** お仕事 */
export const JobLabel: Record<Job, MessageKey> = {
  [Job.hide]: "enum.job.hide",
  [Job.employee]: "enum.job.employee",
  [Job.doctor]: "enum.job.doctor",
  [Job.lawyer]: "enum.job.lawyer",
  [Job.CPA]: "enum.job.CPA",
  [Job.managerExecutive]: "enum.job.managerExecutive",
  [Job.governmentWorker]: "enum.job.governmentWorker",
  [Job.clerk]: "enum.job.clerk",
  [Job.majorTrading]: "enum.job.majorTrading",
  [Job.foreignFinance]: "enum.job.foreignFinance",
  [Job.majorCompany]: "enum.job.majorCompany",
  [Job.majorForeign]: "enum.job.majorForeign",
  [Job.creator]: "enum.job.creator",
  [Job.IT]: "enum.job.IT",
  [Job.pilot]: "enum.job.pilot",
  [Job.model]: "enum.job.model",
  [Job.apparel]: "enum.job.apparel",
  [Job.announcer]: "enum.job.announcer",
  [Job.childminder]: "enum.job.childminder",
  [Job.free]: "enum.job.free",
  [Job.student]: "enum.job.student",
  [Job.other]: "enum.job.other",
};

/** 学歴 */
export const EducationLabel: Record<Education, MessageKey> = {
  [Education.hide]: "enum.education.hide",
  [Education.juniorVocational]: "enum.education.juniorVocational",
  [Education.senior]: "enum.education.senior",
  [Education.university]: "enum.education.university",
  [Education.graduate]: "enum.education.graduate",
  [Education.other]: "enum.education.other",
};

/** お酒 */
export const SakeLabel: Record<Sake, MessageKey> = {
  [Sake.hide]: "enum.sake.hide",
  [Sake.no]: "enum.sake.no",
  [Sake.yes]: "enum.sake.yes",
  [Sake.sometimes]: "enum.sake.sometimes",
};

/** タバコ */
export const TobaccoLabel: Record<Tobacco, MessageKey> = {
  [Tobacco.hide]: "enum.tobacco.hide",
  [Tobacco.no]: "enum.tobacco.no",
  [Tobacco.yes]: "enum.tobacco.yes",
  [Tobacco.sometimes]: "enum.tobacco.sometimes",
};

/** 髪型 */
export const HairTypeLabel: Record<HairType, MessageKey> = {
  [HairType.hide]: "enum.hair.type.hide",
  [HairType.short]: "enum.hair.type.short",
  [HairType.medium]: "enum.hair.type.medium",
  [HairType.long]: "enum.hair.type.long",
};

/** 髪の色 */
export const HairColorLabel: Record<HairColor, MessageKey> = {
  [HairColor.hide]: "enum.hair.color.hide",
  [HairColor.black]: "enum.hair.color.black",
  [HairColor.white]: "enum.hair.color.white",
  [HairColor.brown]: "enum.hair.color.brown",
  [HairColor.color]: "enum.hair.color.color",
};

/** AreaLabel  */
export const AreaLabel: Record<Area, MessageKey> = {
  [Area.Tokyo]: "address.prefecture.tokyo",
};

/** AddressLabel */
export const AddressLabel: Record<Address, MessageKey> = {
  /** 六本木 */
  [Address.Roppongi]: "address.municipality.tokyo.roppongi",
  /** 渋谷 */
  [Address.Shibuya]: "address.municipality.tokyo.shibuya",
  /** 銀座 */
  [Address.Ginza]: "address.municipality.tokyo.ginza",
  /** 池袋 */
  [Address.Ikebukuro]: "address.municipality.tokyo.ikebukuro",
  /** 上野 */
  [Address.Ueno]: "address.municipality.tokyo.ueno",
  /** 新宿 */
  [Address.Shinjuku]: "address.municipality.tokyo.shinjuku",
  /** 恵比寿 */
  [Address.Ebisu]: "address.municipality.tokyo.ebisu",
  /** 西麻布 */
  [Address.Nishiazabu]: "address.municipality.tokyo.nishiazabu",
  /** 麻布十番 */
  [Address.Azabujuban]: "address.municipality.tokyo.azabujuban",
  /** 中目黒 */
  [Address.Nakameguro]: "address.municipality.tokyo.nakameguro",
  /** その他 */
  [Address.Other]: "address.municipality.tokyo.other",
};
