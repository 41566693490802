import { Button, Col, List, ListInput, Row } from "framework7-react";
import React, { useState } from "react";

import { Modal } from "ui/widget/modal";

type ReserveCancelModalProps = {
  opened: boolean;
  onClose: () => void;
  onConfirm: (cancelReason: string) => void;
};

/**
 * 予約キャセルもダル
 * @param props
 * @param props.opened
 * @param props.onClose
 * @param props.onConfirm
 * @returns ReserveCancelModal
 */
export const ReserveCancelModal: React.FC<ReserveCancelModalProps> = ({ opened, onClose, onConfirm }) => {
  const [cancelReason, setCancelReason] = useState("");

  return (
    <Modal
      opened={opened}
      title="预约取消确认"
      onClose={onClose}
      fluid
      content={
        <>
          <List>
            <ListInput
              name="cancelReason"
              type="textarea"
              value={cancelReason}
              placeholder="在此输入取消理由(任意), 200字以内"
              maxlength={200}
              onChange={e => setCancelReason(e.target.value)}
              clearButton
              onInputClear={() => setCancelReason("")}
              outline
            />
          </List>
          <Row>
            <Col width={50}>
              <Button outline color="orange" onClick={onClose}>
                取消
              </Button>
            </Col>
            <Col width={50}>
              <Button fill color="orange" onClick={() => onConfirm(cancelReason)}>
                确定
              </Button>
            </Col>
          </Row>
        </>
      }
    />
  );
};
