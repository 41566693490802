import classNames from "classnames";
import dayjs from "dayjs";
import { Block, BlockTitle, Link, List, ListItem, Navbar, NavRight, Page, Popup } from "framework7-react";
import React, { useCallback, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReserveItem } from "core/model/site/order";
import { siteOrderSelector } from "core/slice/site/order";

import { AreaLabel, AddressLabel } from "global/enum-label";
import { ReserveStatusLabel } from "global/enum-label/reserve";
import { ReserveStatus } from "global/enum/reserve";

import { FormattedDate } from "ui/widget/formatted-date";

import { OrderItem } from "../order-item";

const Container = styled.div({
  ".status": {
    marginTop: 4,
    marginBottom: 4,
    padding: "2px 8px",
    borderRadius: 24,
    color: "#fff",
    fontWeight: "bold",
    display: "inline-block",
    width: "auto",
    fontSize: 12,

    "&.status-doing": {
      background: "#e59d1a",
    },
    "&.status-success": {
      background: "#3ecd3e",
    },
    "&.status-fail": {
      background: "red",
    },
    "&.status-cancel": {
      background: "#777",
    },
  },

  ".item-after": {
    fontWeight: "bolder",
    fontSize: 16,
  },
});

/**
 * OrderHistoryList
 * @returns OrderHistoryList
 */
export const OrderHistoryList: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<ReserveItem>();
  const { data } = useSelector(siteOrderSelector).reserveList;
  const thisYear = new Date().getFullYear();

  /**
   * showTitle
   */
  const showTitle = useCallback((item: ReserveItem, prevItem?: ReserveItem) => {
    if (!prevItem) return true;
    return !dayjs(item.orderTime).isSame(dayjs(prevItem.orderTime), "year");
  }, []);

  /**
   * closePopup
   */
  const closePopup = useCallback(() => {
    setSelectedItem(undefined);
  }, []);

  return (
    <Container>
      <List>
        {data.map((item, index) => (
          <React.Fragment key={item.reserveId}>
            {showTitle(item, data[index - 1]) && (
              <Block className="no-margin-bottom">
                <BlockTitle medium>
                  {dayjs(item.orderTime).year() === thisYear ? "今年" : `${dayjs(item.orderTime).year()}年`}
                </BlockTitle>
              </Block>
            )}
            <ul>
              <ListItem link onClick={() => setSelectedItem(item)}>
                <div slot="title" className="text-align-middle">
                  <div>
                    【<FormattedDate value={item.meetTime} format="MM/DD HH:mm" />
                    &nbsp;
                    <FormattedMessage id={AreaLabel[item.area]} />
                    &nbsp;
                    <FormattedMessage id={AddressLabel[item.areaAddr]} />】
                  </div>
                </div>
                <div slot="footer">
                  <div
                    className={classNames("status", {
                      "status-doing": item.status === ReserveStatus.PROCESSING,
                      "status-success": item.status === ReserveStatus.SUCCESS,
                      "status-fail": item.status === ReserveStatus.FAIL,
                      "status-cancel": item.status === ReserveStatus.CANCEL,
                    })}
                  >
                    <FormattedMessage id={ReserveStatusLabel[item.status]} />
                  </div>
                  <div>预约人数: {item.peopleNumber}人</div>
                  <div>预约时长: {item.hour}小时</div>
                </div>
                <div slot="after">
                  <FormattedNumber value={item.payPrice} />P
                </div>
              </ListItem>
            </ul>
          </React.Fragment>
        ))}
      </List>

      <Popup opened={!!selectedItem} onPopupClosed={closePopup} swipeToClose>
        <Page>
          <Navbar title="预约详细">
            <NavRight>
              <Link href={false} popupClose>
                Close
              </Link>
            </NavRight>
          </Navbar>
          {selectedItem && (
            <OrderItem
              useExpand={false}
              item={selectedItem}
              onCancelSuccess={closePopup}
              onDeleteSuccess={closePopup}
            />
          )}
        </Page>
      </Popup>
    </Container>
  );
};
