import { Navbar, NavLeft, NavTitle, Page, PageContent } from "framework7-react";
import React, { useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import { PropsWithF7Router } from "routes";

import { TimelineItem as TimelineItemType, TimelineListResponse } from "core/model/site/timeline";
import { authorizationSelector } from "core/slice/authorization";
import { deleteTimelineItem, getUserTimelines, toggleFavorite } from "core/usecase/site/timeline";

import { SiteConfig } from "global/config";

import { FavoriteIcon } from "ui/component/site/timeline/favorite-icon";
import { TimelineExtraMy } from "ui/component/site/timeline/timeline-extra-my";
import { TimelineItem } from "ui/component/site/timeline/timeline-item";
import { TimelineSkeleton } from "ui/component/site/timeline/timeline-skeleton";
import { NoData } from "ui/widget/no-data";

/**
 * TimelineUserPage
 * @param props
 * @param props.f7route
 * @returns TimelineUserPage
 */
export const TimelineUserPage: React.FC<PropsWithF7Router> = ({ f7route }) => {
  const allowInfinite = useRef(true);
  const [loadMore, setLoadMore] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [page, setPage] = useState(1);
  const [timelineList, setTimelineList] = useState<TimelineListResponse>([]);

  // 認証情報
  const { principal } = useSelector(authorizationSelector);

  // ユーザ取得
  const userId = useMemo(
    () => (f7route.params.userId ? f7route.params.userId : principal!.id),
    [f7route.params.userId, principal],
  );

  useEffectOnce(() => {
    // timeline一覧初期化
    getUserTimelines(page, userId).then(body => {
      setTimelineList(prev => prev.concat(body));
      setLoadMore(body.length >= SiteConfig.timelinePageSize);
      setPage(prev => prev + 1);
      setInitialized(true);
    });
  });

  /**
   * handleInfinite
   */
  const handleInfinite = () => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    // 次のページのtimeline一覧を取得する
    getUserTimelines(page, userId)
      .then(body => {
        setTimelineList(prev => prev.concat(body));
        setLoadMore(body.length >= SiteConfig.timelinePageSize);
        setPage(prev => prev + 1);
      })
      .finally(() => {
        allowInfinite.current = true;
      });
  };

  /**
   * handleToggleFavorite
   * @param item
   */
  const handleToggleFavorite = (item: TimelineItemType) => {
    toggleFavorite(item).then(success => {
      if (success) {
        setTimelineList(prev => {
          const index = prev.findIndex(o => o.id === item.id);
          if (index === -1) return prev;
          const next = [...prev];
          // toggle favorite
          const { favorited, favoriteCount } = next[index];
          if (favorited) {
            next[index].favorited = false;
            next[index].favoriteCount = favoriteCount - 1;
          } else {
            next[index].favorited = true;
            next[index].favoriteCount = favoriteCount + 1;
          }
          return next;
        });
      }
    });
  };

  /**
   * timeline item削除処理
   * @param item
   */
  const handleDeleteTimelineItem = (item: TimelineItemType) => {
    deleteTimelineItem(item.id).then(success => {
      if (success) {
        setTimelineList(prev => {
          const index = prev.findIndex(o => o.id === item.id);
          if (index === -1) return prev;
          const next = [...prev];
          next.splice(index, 1);
          return next;
        });
      }
    });
  };

  return (
    <Page pageContent={false}>
      <Navbar>
        <NavLeft backLink backLinkUrl="/timeline" backLinkForce />
        <NavTitle>日志列表</NavTitle>
      </Navbar>
      <PageContent infinite={loadMore} onInfinite={handleInfinite}>
        {!initialized ? (
          Array.from(Array(4).keys()).map(index => <TimelineSkeleton key={index} />)
        ) : timelineList.length > 0 ? (
          timelineList.map(item => (
            <TimelineItem
              key={item.id}
              item={item}
              extra={
                // ログインユーザとtimelineユーザが一致する場合
                principal?.id === userId ? (
                  <TimelineExtraMy
                    item={item}
                    onToggleFavorite={() => handleToggleFavorite(item)}
                    onDeleteItem={() => handleDeleteTimelineItem(item)}
                  />
                ) : (
                  <FavoriteIcon
                    favorited={item.favorited}
                    favoriteCount={item.favoriteCount}
                    onClick={() => handleToggleFavorite(item)}
                  />
                )
              }
              footer={
                <span>
                  <strong>{item.favoriteCount}</strong>&nbsp;人表示喜欢
                </span>
              }
            />
          ))
        ) : (
          <NoData title={<FormattedMessage id="common.nodata" />} />
        )}
      </PageContent>
    </Page>
  );
};
