import dayjs from "dayjs";
import {
  Block,
  Button,
  Card,
  CardContent,
  Col,
  Link,
  List,
  ListItem,
  Navbar,
  NavRight,
  Page,
  Popup,
  Row,
} from "framework7-react";
import React from "react";
import { FormattedNumber } from "react-intl";
import styled from "styled-components";

import { ChargeLogItem } from "core/model/site/profile/charge";

import { CommonConfig } from "global/config";
import { ChargeOrderStatusLabel, ChargeTypeLabel } from "global/enum-label/site";
import { ChargeOrderStatus } from "global/enum/profile-site";

import { Divider } from "ui/widget/divider";

type ChargeLogDetailProps = {
  item: ChargeLogItem | undefined;
  opened: boolean;
  onPopupClosed: () => void;
  onRevoke: (item: ChargeLogItem) => void;
};

const Container = styled.div({
  ".recharge-img .item-title": {
    alignSelf: "flex-start",
  },
});

/**
 * ChargeLogDetail
 * @param props
 * @returns ChargeLogDetail
 */
export const ChargeLogDetail: React.FC<ChargeLogDetailProps> = props => {
  const { item, opened, onPopupClosed, onRevoke } = props;

  return (
    <Popup opened={opened} onPopupClosed={onPopupClosed} swipeToClose>
      <Page>
        <Navbar title="充值详细">
          <NavRight>
            <Link href={false} popupClose>
              Close
            </Link>
          </NavRight>
        </Navbar>

        {item && (
          <Container>
            <Card>
              <CardContent padding={false}>
                <List>
                  <ListItem title="充值方式" after={ChargeTypeLabel[item.rechargeType]} />
                  <ListItem title="充值金额">
                    <div slot="after">
                      <FormattedNumber value={item.payMoney} />元
                    </div>
                  </ListItem>
                  <ListItem title="充值凭证" className="recharge-img">
                    <img
                      slot="after"
                      src={CommonConfig.chargeBaseUrl + item.rechargeImg}
                      style={{ width: "100%", maxWidth: 240 }}
                      alt="充值凭证"
                    />
                  </ListItem>
                  <ListItem title="申请时间" after={dayjs(item.createdAt).format("YYYY-MM-DD HH:mm")} />
                  <ListItem title="订单号">
                    <div slot="after" style={{ whiteSpace: "pre-line", wordBreak: "break-all", maxWidth: 285 }}>
                      {item.orderNo}
                    </div>
                  </ListItem>
                  <ListItem title="处理状态" after={ChargeOrderStatusLabel[item.payStatus]} />
                </List>
                {item.payStatus === ChargeOrderStatus.done && (
                  <>
                    <Divider className="no-margin" style={{ paddingRight: 0 }} />
                    <List>
                      <ListItem title="实际到账金额">
                        <div slot="after">
                          <FormattedNumber value={item.actualMoney} />元
                        </div>
                      </ListItem>
                      {item.giftMoney > 0 && (
                        <ListItem title="赠送金额">
                          <div slot="after">
                            <FormattedNumber value={item.giftMoney} />元
                          </div>
                        </ListItem>
                      )}
                      <ListItem
                        title="到账时间"
                        after={dayjs(item.payTime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm")}
                      />
                    </List>
                  </>
                )}
              </CardContent>
            </Card>
            {item.payStatus !== ChargeOrderStatus.done && (
              <Block>
                <Row>
                  <Col width={25} />
                  <Col width={50}>
                    <Button fill color="red" type="button" onClick={() => onRevoke(item)}>
                      撤销
                    </Button>
                  </Col>
                  <Col width={25} />
                </Row>
              </Block>
            )}
          </Container>
        )}
      </Page>
    </Popup>
  );
};
