import { Page } from "framework7-react";
import React from "react";
import { PropsWithF7Router } from "routes";

import { FormSection } from "ui/component/girl/introduce/form-section";

/**
 * IntroducePage
 * @param props
 * @param props.f7route
 * @returns IntroducePage
 */
export const IntroducePage: React.FC<PropsWithF7Router> = ({ f7route }) => (
  <Page no-swipeback loginScreen>
    <FormSection id={f7route.query.id!} />
  </Page>
);
