import classNames from "classnames";
import dayjs from "dayjs";
import { Block, Row, Col, Link, SkeletonBlock } from "framework7-react";
import React from "react";
import styled from "styled-components";

import { ScheduleListResponse, ScheduleItem } from "core/model/girl/schedule";

import { Icon } from "ui/widget/icon";

const ScheduleContainer = styled.div({
  width: "100%",
  ".row": {
    margin: "4px 0",
    ".col": {
      textAlign: "center",
    },
  },
  "& > .row:nth-child(1) .col": {
    fontWeight: "bolder",
    fontSize: 16,
  },

  ".col.past-day *": {
    color: "#999",
    opacity: 0.6,
  },
});

/**
 * ScheduleProps
 */
export type ScheduleWeekProps = {
  /** label */
  label: string;
  /** list */
  list: ScheduleListResponse | undefined;
  /** onStatusClick */
  onStatusClick?: (item: ScheduleItem) => void;
};

/**
 * ScheduleWeek
 * @param props
 * @param props.label
 * @param props.list
 * @param props.onStatusClick
 * @returns ScheduleWeek
 */
export const ScheduleWeek: React.FC<ScheduleWeekProps> = ({ label, list, onStatusClick }) => (
  <Block strong inset>
    <ScheduleContainer>
      <Row>
        <Col>{label}</Col>
      </Row>
      {list ? (
        <Row>
          {list.map((item, index) => (
            <Col
              key={index}
              className={classNames({
                "past-day": dayjs().isAfter(dayjs(item.workYmd, "YYYYMMDD"), "day"),
              })}
            >
              <Row>
                <Col>{dayjs(item.workYmd, "YYYYMMDD").format("dd")}</Col>
              </Row>
              <Row>
                <Col>{item.workYmd.substring(6)}</Col>
              </Row>
              <Row>
                <Col>
                  <Link href={false} onClick={() => onStatusClick && onStatusClick(item)}>
                    {item.workStatus === "1" ? (
                      <Icon name="circle" shape="outlined" color="orange" />
                    ) : (
                      <Icon name="remove" />
                    )}
                  </Link>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <SkeletonBlock tag="div" width="100%" height="20px" borderRadius="4px" effect="wave" />
            </Col>
          </Row>
          <Row>
            <Col>
              <SkeletonBlock tag="div" width="100%" height="20px" borderRadius="4px" effect="wave" />
            </Col>
          </Row>
          <Row>
            <Col>
              <SkeletonBlock tag="div" width="100%" height="20px" borderRadius="4px" effect="wave" />
            </Col>
          </Row>
        </>
      )}
    </ScheduleContainer>
  </Block>
);
