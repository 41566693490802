import axios, { AxiosRequestConfig, AxiosError } from "axios";
import axiosRetry from "axios-retry";
import { f7 } from "framework7-react";
import Cookies from "js-cookie";
import { store } from "store";

import { logout, refreshTokenHandler } from "core/usecase/authorization";

import { CommonConfig } from "global/config";
import { FetchStatus, SupportedLanguage } from "global/enum";
import { mixed } from "global/types";

import { showErrorModal } from "../common";
import logger from "../logger";

// defaults
axios.defaults.baseURL = CommonConfig.apiBaseUrl;
axios.defaults.headers.common["Content-Type"] = "application/json;charset=utf-8";

/**
 * ApiCommonResponse
 */
export type ApiCommonResponse<T> = {
  /** コード */
  code: string;
  /** メッセージ */
  message?: string;
  /** メッセージ詳細 */
  details?: Record<string, string>;
  /** データ */
  body?: T;
};

/**
 * ApiResponse
 */
export type ApiResponse<T> = {
  /** レスポンスステータス  */
  status: number;
} & ApiCommonResponse<T>;

/**
 * トークンリフレッシュstatusを監視する
 * @returns resolve
 */
const watchTokenRefreshStatus = () =>
  new Promise<boolean>(resolve => {
    if (store.getState().authorization.tokenRefreshStatus !== FetchStatus.fetching) {
      resolve(true);
      return;
    }
    const timer = setInterval(() => {
      if (store.getState().authorization.tokenRefreshStatus !== FetchStatus.fetching) {
        // clear interval
        clearInterval(timer);
        resolve(true);
      }
    }, 200);
  });

// リトライ設定
axiosRetry(axios, {
  retryCondition({ response, config }) {
    // リフレッシュトークン
    const refreshToken = Cookies.get(CommonConfig.refreshTokenName);
    // トークン有効期限切れエラー(status = 401 and code = AU1012)の場合、トークンをリフレッシュし、再実行
    if (
      refreshToken &&
      response &&
      response.status === 401 &&
      (response.data as ApiCommonResponse<unknown>)?.code === "AU1012"
    ) {
      logger.error("Access Token is expired.");
      return true;
    }
    return false;
  },
  async onRetry(retryCount, error, requestConfig) {
    const refreshToken = Cookies.get(CommonConfig.refreshTokenName);
    if (!refreshToken) return;
    await refreshTokenHandler(refreshToken);
    // アクセストークンを再設定する
    const accessToken = Cookies.get(CommonConfig.accessTokenName);
    requestConfig.headers = {
      ...requestConfig.headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    };
  },
  retries: 1,
});

/**
 * APIハンドリング
 * @param config
 * @returns 結果
 */
const callApi = <T = mixed>(config: AxiosRequestConfig): Promise<ApiResponse<T>> =>
  new Promise<ApiResponse<T>>((resolve, reject) => {
    logger.info("call api start. url = ", config.url);
    const handler = () => {
      // アクセストークン
      const accessToken = Cookies.get(CommonConfig.accessTokenName);
      // api実行
      axios
        .request<ApiCommonResponse<T>>({
          ...config,
          headers: {
            ...config.headers,
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
            "Accept-Language": store.getState().global.language,
          },
        })
        .then(res => {
          logger.info("call api success. url = ", config.url);
          const response: ApiResponse<T> = {
            ...res.data,
            status: res.status,
          };
          resolve(response);
        })
        .catch((error: AxiosError<ApiCommonResponse<T>>) => {
          logger.info("call api failed. url = ", config.url);
          logger.error("Api failed with ", error.message);
          if (error.response) {
            // トークン有効期限切れエラー: status = 401 and code = AU1003
            // 未認証エラー: status = 401 and code = AU1005
            if (error.response.status === 401 && ["AU1003", "AU1005"].includes(error.response.data.code)) {
              showErrorModal(String(error.response.data.message), () => {
                // ログイン画面へ遷移する
                logout();
                if (store.getState().global.language === SupportedLanguage.japanese) {
                  f7.view.main.router.navigate("/girl/login", { clearPreviousHistory: true });
                } else {
                  f7.view.main.router.navigate("/login", { clearPreviousHistory: true });
                }
              });
            } else {
              // その他エラー
              showErrorModal(error.response.data.message);
            }
          } else {
            showErrorModal();
          }
          reject(error);
        });
    };
    // トークン更新中であれば、wait
    if (config.url !== "/refresh-token" && store.getState().authorization.tokenRefreshStatus === FetchStatus.fetching) {
      watchTokenRefreshStatus().then(handler);
    } else {
      handler();
    }
  });

const getHandler = <T = mixed>(url: string, config?: Omit<AxiosRequestConfig, "url" | "method">) =>
  callApi<T>({ url, method: "get", ...config });
const postHandler = <T = mixed>(
  url: string,
  data?: mixed,
  config?: Omit<AxiosRequestConfig, "url" | "method" | "data">,
) => callApi<T>({ url, method: "post", data, ...config });
const deleteHandler = <T = mixed>(
  url: string,
  data?: mixed,
  config?: Omit<AxiosRequestConfig, "url" | "method" | "data">,
) => callApi<T>({ url, method: "delete", data, ...config });
const putHandler = <T = mixed>(
  url: string,
  data?: mixed,
  config?: Omit<AxiosRequestConfig, "url" | "method" | "data">,
) => callApi<T>({ url, method: "put", data, ...config });

const Api = {
  get: getHandler,
  post: postHandler,
  delete: deleteHandler,
  put: putHandler,
};

/**
 * API結果判定
 * @param response
 * @returns 判定結果
 */
export const isSuccess = <T = mixed>(response: ApiResponse<T>) => response.status === 200 && response.code === "1000";

export default Api;
