import { Button, Card, CardContent, CardFooter, List, ListItem, f7 } from "framework7-react";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { ReserveItem } from "core/model/girl/order";
import { getTalkId, initializeTalkList } from "core/usecase/talk";

import { AreaLabel, AddressLabel } from "global/enum-label";

import { Divider } from "ui/widget/divider";
import { FormattedDate } from "ui/widget/formatted-date";
import { Icon } from "ui/widget/icon";

const Container = styled.div({
  ".list .item-content .item-media": {
    minWidth: "auto",
  },

  ".divider": {
    paddingRight: 0,
    paddingLeft: 48,
  },
});

type OrderItemProps = {
  item: ReserveItem;
  useExpand?: boolean;
};

/**
 * OrderItem
 * @param props
 * @param props.item
 * @param props.useExpand
 * @returns OrderItem
 */
export const OrderItem: React.FC<OrderItemProps> = ({ item, useExpand = true }) => {
  const [expand, setExpand] = useState(false);

  /**
   * handleSendMessageBtnClick
   */
  const handleSendMessageBtnClick = useCallback(() => {
    // トークIDの取得
    getTalkId(item.customerId).then(talkId => {
      if (talkId) {
        // トークメッセージ一覧画面に遷移する
        f7.view.main.router.navigate(`/girl/talk/${talkId}`, { transition: "f7-push" });
        // トーク一覧初期化
        initializeTalkList(true);
      }
    });
  }, [item.customerId]);

  return (
    <Container>
      <Card>
        <CardContent>
          <List>
            <ListItem title="お客様名前" after={item.customerName}>
              <Icon name="person" slot="media" color="purple" />
            </ListItem>
            <ListItem title="合流予定時間">
              <Icon name="schedule" slot="media" color="blue" />
              <div slot="after">
                <FormattedDate value={item.meetTime} format="YYYY-MM-DD HH:mm" />
              </div>
            </ListItem>
            <ListItem title="予約場所">
              <Icon name="place" slot="media" color="orange" />
              <div slot="after">
                <FormattedMessage id={AreaLabel[item.area]} />
                &nbsp;
                <FormattedMessage id={AddressLabel[item.areaAddr]} />
              </div>
            </ListItem>
          </List>
        </CardContent>

        {(!useExpand || expand) && (
          <>
            <Divider margin={false} />
            <CardContent>
              <List>
                <ListItem title="予約NO" after={item.reserveNo}>
                  <Icon name="info" slot="media" color="yellow" />
                </ListItem>
                <ListItem title="注文日時">
                  <Icon name="schedule" slot="media" color="lightblue" />
                  <div slot="after">
                    <FormattedDate value={item.orderTime} format="YYYY-MM-DD HH:mm" />
                  </div>
                </ListItem>

                <ListItem title="予約時間" after={`${item.hour} 時間`}>
                  <Icon name="timer" slot="media" color="green" />
                </ListItem>

                <ListItem title="人数(自分を含む)" after={`${item.peopleNumber} 人`}>
                  <Icon name="people" slot="media" color="pink" />
                </ListItem>
                <ListItem title="備考">
                  <Icon name="note_alt" slot="media" color="teal" />
                  <div slot="after" style={{ whiteSpace: "pre-line" }}>
                    {item.memo}
                  </div>
                </ListItem>
              </List>
            </CardContent>
            <CardFooter>
              <Button
                fill
                color="orange"
                style={{ margin: "8px auto", width: "50%", minWidth: 200 }}
                onClick={handleSendMessageBtnClick}
              >
                <Icon name="send" size={18} />
                &nbsp; お客さまを連絡する
              </Button>
            </CardFooter>
          </>
        )}
        {useExpand && (
          <CardFooter>
            <Button onClick={() => setExpand(!expand)} type="button">
              {expand ? (
                <>
                  <Icon name="keyboard_double_arrow_up" />
                  表示を減らす
                </>
              ) : (
                <>
                  <Icon name="keyboard_double_arrow_down" />
                  もっと見る
                </>
              )}
            </Button>
          </CardFooter>
        )}
      </Card>
    </Container>
  );
};
