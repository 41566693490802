import React from "react";
import styled from "styled-components";

import { mixed } from "global/types";

import { Icon } from "ui/widget/icon";

type FavoriteIconType = {
  favorited: boolean;
  favoriteCount: number;
  onClick?: () => mixed;
};

const Container = styled.div<{ favorited: boolean }>(({ favorited }) => ({
  fontSize: 18,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  color: favorited ? "red" : "gray",
}));

/**
 * FavoriteIcon
 * @param props
 * @param props.favorited
 * @param props.favoriteCount
 * @param props.onClick
 * @returns FavoriteIcon
 */
export const FavoriteIcon: React.FC<FavoriteIconType> = ({ favorited, favoriteCount, onClick }) => (
  <Container favorited={favorited} onClick={onClick}>
    <Icon name={favorited ? "favorite" : "favorite_border"} />
    {favoriteCount > 0 && <>&nbsp;{favoriteCount}</>}
  </Container>
);
