import { Card, CardContent, SkeletonBlock } from "framework7-react";
import React from "react";
import styled from "styled-components";

const Container = styled.div({
  ".card": {
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 0,
  },

  ".header": {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",

    ".base": {
      flex: 1,
      marginLeft: 16,
    },
  },

  ".media": {
    aspectRatio: "16 / 9",
  },
});

/**
 * TimelineSkeleton
 * @returns TimelineSkeleton
 */
export const TimelineSkeleton: React.FC = () => (
  <Container>
    <Card>
      <CardContent>
        <div className="header">
          <SkeletonBlock tag="div" width="45px" height="45px" borderRadius="50%" effect="wave" />
          <div className="base">
            <SkeletonBlock
              tag="div"
              width="50%"
              height="24px"
              borderRadius="0"
              effect="wave"
              style={{ marginBottom: 4 }}
            />
            <SkeletonBlock tag="div" width="30%" height="21px" borderRadius="0" effect="wave" />
          </div>
        </div>
        <SkeletonBlock
          tag="div"
          width="100%"
          height="21px"
          borderRadius="0"
          effect="wave"
          style={{ marginTop: 16, marginBottom: 16 }}
        />
        <SkeletonBlock tag="div" width="100%" height="auto" className="media" borderRadius="0" effect="wave" />
      </CardContent>
    </Card>
  </Container>
);
