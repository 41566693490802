import React, { HTMLAttributes, ChangeEvent, ReactElement, ComponentProps, ReactNode } from "react";
import styled from "styled-components";

import { mixed } from "global/types";

import { Icon } from "../icon";

type ContainerProps = {
  width: number;
  height: number;
  shape: "circle" | "rounded" | "rect";
};

interface UploadContainerProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children" | "title">,
    Partial<ContainerProps> {
  name?: string;
  accept?: string;
  onFileChange?: (event: ChangeEvent<HTMLInputElement>) => mixed;
  icon?: ReactElement<ComponentProps<typeof Icon>>;
  title?: ReactNode;
}

const Container = styled.div<ContainerProps>(({ width, height, shape }) => ({
  width,
  height,
  borderRadius: shape === "circle" ? "50%" : shape === "rounded" ? 4 : 0,
  border: "2px dashed #ccc",
  position: "relative",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "center",
  alignItems: "center",
  color: "#aaa",
  background: "#fff",
  fontSize: 20,

  "& > .icon": {
    color: "#aaa",
  },

  "& > input": {
    opacity: 0,
    cursor: "pointer",
    display: "block",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
  },

  "& > span": {
    marginTop: 8,
  },
}));

/**
 * UploadContainer
 * @param props
 * @returns UploadContainer
 */
export const UploadContainer: React.FC<UploadContainerProps> = props => {
  const {
    width = 240,
    height = 240,
    shape = "circle",
    name = "file",
    accept = "*",
    onFileChange,
    icon,
    title,
    ...attrs
  } = props;

  return (
    <Container width={width} height={height} shape={shape} {...attrs}>
      <>
        <input type="file" name={name} accept={accept} onChange={onFileChange} />
        {icon}
        {title && <span>{title}</span>}
      </>
    </Container>
  );
};
