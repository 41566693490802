import { Page } from "framework7-react";
import React, { useCallback } from "react";
import { PropsWithF7Router } from "routes";

import { LoginRequest } from "core/model/authorization";
import * as usecase from "core/usecase/authorization";

import { Role } from "global/enum";

import { LoginForm } from "ui/component/login";

/**
 * Login
 * @param props
 * @param props.f7router
 * @returns Login
 */
export const LoginPage: React.FC<PropsWithF7Router> = ({ f7router }) => {
  /**
   * handleSubmit
   */
  const handleSubmit = useCallback(
    (values: LoginRequest) => {
      usecase.login(values, Role.girl).then(success => {
        if (success) {
          f7router.navigate("/girl/profile", { transition: "f7-cover-v", clearPreviousHistory: true });
        }
      });
    },
    [f7router],
  );

  return (
    <Page no-swipeback loginScreen>
      <LoginForm onSubmit={handleSubmit} forgotPasswordLink="/girl/forgot-password" signupLink="/girl/signup" />
    </Page>
  );
};
