import dayjs from "dayjs";
import { f7 } from "framework7-react";
import { getLocaleMessage } from "i18n";

/**
 * toast
 * @param text
 * @param closeTimeout
 */
export const toast = (text: string, closeTimeout = 1000) => {
  f7.toast.show({
    position: "center",
    text,
    closeTimeout,
  });
};

/**
 * エラーモーダル
 * @param message
 * @param callback
 */
export const showErrorModal = (message?: string, callback?: () => void) => {
  f7.dialog.close();
  const defaultErrorMessage = getLocaleMessage("common.default.error.message");
  const defaultErrorTitle = getLocaleMessage("common.default.error.title");

  f7.dialog
    .create({
      title: defaultErrorTitle,
      text: message || defaultErrorMessage,
      cssClass: "dialog-alert",
      buttons: [
        {
          text: "OK",
          onClick: callback,
        },
      ],
      on: {
        open(dialog) {
          dialog.$backdropEl.addClass("backdrop-alert");
        },
        closed(dialog) {
          dialog.$backdropEl.removeClass("backdrop-alert");
        },
      },
    })
    .open();
};

/**
 * 確認モーダル
 * @param message
 * @param callbackOk
 * @param callbackCancel
 */
export const showConfirmModal = (message?: string, callbackOk?: () => void, callbackCancel?: () => void) => {
  f7.dialog.close();
  const defaultConfirmMessage = getLocaleMessage("common.default.confirm.message");
  const defaultConfirmTitle = getLocaleMessage("common.default.confirm.title");

  f7.dialog
    .create({
      title: defaultConfirmTitle,
      text: message || defaultConfirmMessage,
      cssClass: "dialog-alert",
      buttons: [
        {
          text: "Cancel",
          onClick: callbackCancel,
        },
        {
          text: "Ok",
          bold: true,
          onClick: callbackOk,
        },
      ],
      on: {
        open(dialog) {
          dialog.$backdropEl.addClass("backdrop-alert");
        },
        closed(dialog) {
          dialog.$backdropEl.removeClass("backdrop-alert");
        },
      },
    })
    .open();
};

/**
 * 年齢の計算
 * @param birth
 * @param format
 * @returns 年齢
 */
export const calcAge = (birth: string, format = "YYYYMMDD") => {
  const birthday = dayjs(birth, format);
  const now = dayjs();
  return now.diff(birthday, "year");
};

/**
 * check element is in viewport
 * @param el
 * @param offset
 * @param offset.top
 * @param offset.right
 * @param offset.bottom
 * @param offset.left
 * @returns result
 */
export const isInViewport = (
  el: HTMLElement,
  offset?: { top?: number; right?: number; bottom?: number; left?: number },
) => {
  const rect = el.getBoundingClientRect();
  const offsetTop = offset?.top ?? 0;
  const offsetRight = offset?.right ?? 0;
  const offsetBottom = offset?.bottom ?? 0;
  const offsetLeft = offset?.left ?? 0;
  return (
    rect.top - offsetTop >= 0 &&
    rect.left - offsetLeft >= 0 &&
    rect.bottom + offsetBottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right + offsetRight <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
