import classNames from "classnames";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

const Container = styled.div({
  display: "inline-flex",
  alignItems: "center",
  marginRight: 6,

  ".status": {
    display: "block",
    width: 10,
    height: 10,
    borderRadius: 5,
    marginRight: 6,

    "&.online": {
      background: "green",
    },

    "&.offline": {
      background: "gray",
    },
  },
});

type OnlineStatusBarProps = {
  /** オンラインステータス */
  online: boolean;
  /** 最終登録日時 */
  lastConnectedDtm?: string | Date | number;
};

/**
 * OnlineStatusBar
 * @param props
 * @param props.online
 * @param props.lastConnectedDtm
 * @returns OnlineStatusBar
 */
export const OnlineStatusBar: React.FC<OnlineStatusBarProps> = ({ online, lastConnectedDtm }) => {
  const { formatMessage } = useIntl();

  const label = useMemo(() => {
    if (online) return formatMessage({ id: "client.status.online" });
    if (!lastConnectedDtm)
      return formatMessage(
        { id: "client.status.online.days.ago" },
        {
          days: 7,
        },
      );
    const now = dayjs();

    // day check
    let diffDay = now.diff(lastConnectedDtm, "day");
    if (diffDay > 7) diffDay = 7;
    if (diffDay > 0)
      return formatMessage(
        { id: "client.status.online.days.ago" },
        {
          days: diffDay,
        },
      );

    // hour check
    const diffHour = now.diff(lastConnectedDtm, "hour");
    if (diffHour > 0)
      return formatMessage(
        { id: "client.status.online.hours.ago" },
        {
          hours: diffHour,
        },
      );

    // minute check
    let diffMinute = now.diff(lastConnectedDtm, "minute");
    if (diffMinute <= 0) diffMinute = 1;
    return formatMessage(
      { id: "client.status.online.minutes.ago" },
      {
        minutes: diffMinute,
      },
    );
  }, [formatMessage, lastConnectedDtm, online]);

  return (
    <Container>
      <div className={classNames("status", online ? "online" : "offline")} />
      {label}
    </Container>
  );
};
