import SVGAliPay from "assets/icon/aliPay.svg";
import SVGUnionPay from "assets/icon/unionPay.svg";
import SVGUSDT from "assets/icon/USDT.svg";
import SVGWechatPay from "assets/icon/wechatPay.svg";
import { Block, BlockTitle, Button, Link, List, ListItem } from "framework7-react";
import React, { useCallback, useState } from "react";
import { useSwiper } from "swiper/react";

import { initializeChargeMInfo } from "core/usecase/site/profile/charge";

import { ChargeTypeLabel } from "global/enum-label/site";
import { ChargeType } from "global/enum/profile-site";

const ChargeIcons: Record<string, string> = {
  [ChargeType.wechat]: SVGWechatPay,
  [ChargeType.alipay]: SVGAliPay,
  [ChargeType.union]: SVGUnionPay,
  [ChargeType.USDT]: SVGUSDT,
};

// TODO 現在微信支付・USDT(虚拟货币)以外利用不可
const ChargeTypes = Object.entries(ChargeTypeLabel).filter(([v]) =>
  [ChargeType.wechat, ChargeType.USDT].includes(v as ChargeType),
);

/**
 * ChargeStep1
 * @returns ChargeStep1
 */
export const ChargeStep1: React.FC = () => {
  const swiper = useSwiper();
  const [chargeType, setChargeType] = useState(ChargeType.wechat);

  /**
   * handleClick
   */
  const handleClick = useCallback(() => {
    initializeChargeMInfo(chargeType).then(success => {
      if (success) {
        swiper.slideNext();
      }
    });
  }, [chargeType, swiper]);

  return (
    <>
      <BlockTitle medium className="text-align-middle">
        <span>充值方式选择</span>
        <Link href="/profile/charge-log" transition="f7-push" style={{ marginLeft: "auto", fontSize: 14 }}>
          充值记录&nbsp;&gt;
        </Link>
      </BlockTitle>
      <List>
        {ChargeTypes.map(([value, label]) => (
          <ListItem
            key={value}
            radio
            radioIcon="end"
            title={label}
            value={value}
            name="chargeType"
            checked={value === chargeType}
            onClick={() => setChargeType(value as ChargeType)}
          >
            <img src={ChargeIcons[value]} alt="wechat-pay" width={36} slot="media" />
          </ListItem>
        ))}
      </List>

      <Block>
        <Button type="button" color="green" fill onClick={handleClick}>
          下一步
        </Button>
      </Block>
    </>
  );
};
