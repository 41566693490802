import { Block, Button, Col, Row } from "framework7-react";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { hidePageSpinner, showPageSpinner } from "core/usecase/global";
import { showErrorModal } from "core/utils/common";
import { compress, blobToBase64, getImageSize, heicToJpg } from "core/utils/file";

import { Divider } from "ui/widget/divider";
import { Icon } from "ui/widget/icon";
import { Cropper, CropperInstance, getCircleCanvas } from "ui/widget/image-cropper";
import { UploadContainer } from "ui/widget/upload/upload-container";

/**
 * AvatarEditorProps
 */
export type AvatarEditorProps = {
  /** キャンセルボタン押下 */
  onCancel: () => void;
  /** 変更ボタン押下 */
  onChange?: () => void;
  /** 確定ボタン押下 */
  onConfirm: (croppedImg: string, fileName: string) => void;
};

/**
 * アバター編集コンポーネント
 * @param props
 * @param props.onCancel
 * @param props.onChange
 * @param props.onConfirm
 * @returns AvatarEditor
 */
export const AvatarEditor: React.FC<AvatarEditorProps> = ({ onCancel, onChange, onConfirm }) => {
  const [avatar, setAvatar] = useState<string>();
  const [zoomTo, setZoomTo] = useState<number>();
  const [fileName, setFileName] = useState<string>();
  const cropperRef = useRef<CropperInstance>();
  const uploadContainerRef = useRef<HTMLDivElement>(null);

  const intl = useIntl();

  /**
   * handleFileChange
   * @param event
   */
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      setAvatar(undefined);
      return;
    }
    try {
      showPageSpinner(uploadContainerRef.current);
      const file = await compress(await heicToJpg(event.target.files[0]));
      // zoomTo
      const imageSrc = await blobToBase64(file);
      const { width, height } = await getImageSize(imageSrc);
      const zoomHorizontal = Number(((window.outerWidth - 32) / width).toFixed(2));
      const zoomVertical = Number((400 / height).toFixed(2));
      setZoomTo(Math.max(Math.min(zoomHorizontal, zoomVertical, 1), 0.1));
      // file name
      setFileName(file.name);
      // avatar src
      setAvatar(imageSrc);
    } catch {
      setAvatar(undefined);
      showErrorModal(intl.formatMessage({ id: "upload.error.image.type" }));
    } finally {
      hidePageSpinner(uploadContainerRef.current);
    }
  };

  /**
   * 変更ボタン押下
   */
  const handleChange = () => {
    if (!avatar) return;
    setAvatar(undefined);
    if (onChange) onChange();
  };

  /**
   * ファイルアップロード確認処理
   */
  const handleConfirm = async () => {
    if (!cropperRef.current || !fileName || !avatar) return;
    const dataUrl = getCircleCanvas(
      cropperRef.current.getCroppedCanvas({
        width: 750,
        height: 750,
      }),
    ).toDataURL();
    onConfirm(dataUrl, fileName);
  };

  return (
    <>
      {!avatar && (
        <Block>
          <div style={{ height: 400, paddingTop: 80 }} ref={uploadContainerRef}>
            <UploadContainer
              style={{ margin: "0 auto" }}
              name="file"
              accept="image/*,.heic,.heif"
              icon={<Icon name="add_a_photo" size={80} />}
              title={<FormattedMessage id="upload.avatar.editor.title" />}
              onFileChange={handleFileChange}
            />
          </div>
        </Block>
      )}
      {avatar && (
        <Block>
          <Cropper
            style={{ height: 400, width: "100%" }}
            circle
            zoomTo={zoomTo}
            background={false}
            aspectRatio={1}
            src={avatar}
            viewMode={1}
            cropBoxResizable={false}
            minCropBoxWidth={120}
            minCropBoxHeight={120}
            responsive
            autoCropArea={1}
            onInitialized={instance => {
              cropperRef.current = instance;
            }}
          />
        </Block>
      )}
      <Divider />
      <Block>
        <Row>
          <Col>
            <Button color="gray" fill onClick={onCancel}>
              <FormattedMessage id="common.cancel" />
            </Button>
          </Col>
          <Col>
            <Button color="blue" fill disabled={!avatar} onClick={handleChange}>
              <FormattedMessage id="common.change" />
            </Button>
          </Col>
          <Col>
            <Button fill disabled={!avatar} onClick={handleConfirm}>
              <FormattedMessage id="common.confirm" />
            </Button>
          </Col>
        </Row>
      </Block>
    </>
  );
};
