import { useFormik } from "formik";
import { Block, Button, ListItem, LoginScreenTitle } from "framework7-react";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";

import { PasswordResetModel } from "core/model/forgot-password";
import { showConfirmModal } from "core/utils/common";

import { Divider } from "ui/widget/divider";
import { FormikList, FormikListInput } from "ui/widget/formik";
import { Icon } from "ui/widget/icon";

/**
 * バリデーションスキーマ
 * @param passwordLabel
 * @returns validationSchema
 */
const createValidationSchema = (passwordLabel: string): yup.ObjectSchema<PasswordResetModel> =>
  yup.object().shape({
    password: yup.string().required().lengthBetween(6, 45),
    verifyPassword: yup
      .string()
      .required()
      .equalWith({ ref: yup.ref("password"), label: passwordLabel }),
  });

type PasswordResetFormProps = {
  /** onSubmit */
  onSubmit: (values: PasswordResetModel) => void;
  /** ログインページリンク */
  loginLink: string;
};

/**
 * パスワード再設定フォーム
 * @param props
 * @param props.onSubmit
 * @param props.loginLink
 * @returns PasswordResetForm
 */
export const PasswordResetForm: React.FC<PasswordResetFormProps> = ({ onSubmit, loginLink }) => {
  const intl = useIntl();

  const validationSchema = useMemo(
    () => createValidationSchema(intl.formatMessage({ id: "forgot.password.reset.password" })),
    [intl],
  );

  const formik = useFormik<PasswordResetModel>({
    initialValues: {
      password: "",
      verifyPassword: "",
    },
    validateOnChange: true,
    validationSchema,
    onSubmit(values) {
      showConfirmModal(intl.formatMessage({ id: "forgot.password.reset.confirm" }), () => {
        onSubmit(values);
      });
    },
  });

  return (
    <Block inset strong>
      <LoginScreenTitle>
        <FormattedMessage id="forgot.password.reset.title" />
      </LoginScreenTitle>
      <br />
      <FormikList noDivider formik={formik}>
        {/* パスワード */}
        <FormikListInput
          type="password"
          placeholder={intl.formatMessage({ id: "forgot.password.reset.password.placeholder" })}
          name="password"
          outline
        >
          <div slot="label">
            <FormattedMessage id="forgot.password.reset.password" />
          </div>
          <Icon name="lock_outline" size="28px" slot="media" style={{ marginTop: 28 }} />
        </FormikListInput>

        {/* 確認パスワード */}
        <FormikListInput
          type="password"
          placeholder={intl.formatMessage({ id: "forgot.password.reset.password.confirm.placeholder" })}
          name="verifyPassword"
          outline
        >
          <div slot="label">
            <FormattedMessage id="forgot.password.reset.password.confirm" />
          </div>
          <Icon name="lock_outline" size="28px" slot="media" style={{ marginTop: 28 }} />
        </FormikListInput>

        <ListItem className="margin-top">
          <Button type="submit" fill>
            <FormattedMessage id="forgot.password.reset.button" />
          </Button>
        </ListItem>

        <ListItem>
          <Divider label={<FormattedMessage id="common.or" />} style={{ padding: 0 }} />
        </ListItem>

        <ListItem>
          <Button outline href={loginLink} style={{ width: "100%" }}>
            <FormattedMessage id="common.back.login" />
          </Button>
        </ListItem>
      </FormikList>
    </Block>
  );
};
