import { f7 } from "framework7-react";

import { PasswordResetRequest } from "core/model/forgot-password";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";

import { Role } from "global/enum";

import { hidePageSpinner, showPageSpinner } from "../global";

/**
 * パスワード再設定お手続きstart
 * @param email
 * @param role
 * @returns 処理結果
 */
export const passwordResetProcessStart = async (email: string, role: Role) => {
  if (role === Role.system) return false;
  try {
    showPageSpinner();
    const res = await Api.post(role === Role.site ? "/site/forgot-password" : "/forgot-password", { email });
    if (isSuccess(res)) {
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};

/**
 * コードの妥当性チェック
 * @param code
 */
export const codeVerify = async (code: string) => {
  try {
    showPageSpinner();
    const res = await Api.get("/password-reset-init", { params: { code } });
    if (isSuccess(res)) {
      return true;
    }
    if (res.code === "AU1009") {
      // コード有効期限切れの場合、有効期限切れ画面に遷移する
      f7.view.main.router.navigate("/forgot-password/url-expired", { clearPreviousHistory: true });
      return false;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};

/**
 * パスワード再設定
 * @param data
 */
export const passwordReset = async (data: PasswordResetRequest) => {
  try {
    showPageSpinner();
    const res = await Api.post("/password-reset", data);
    if (isSuccess(res)) {
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};
