import dayjs from "dayjs";
import { Picker as F7Picker } from "framework7/types";
import React, { useCallback, useMemo } from "react";

import { Picker, PickerColumn, PickerProps } from ".";

/**
 * Formats
 */
type FormatTypes = "YYYYMMDD" | "YYYY-MM-DD" | "YYYY-MM-DD HH:mm";

/**
 * DateTimePickerProps
 */
export interface DateTimePickerProps extends Omit<PickerProps, "value" | "onConfirm" | "cols"> {
  /** min: default=1970 */
  minYear?: number;
  /** max: default=2099 */
  maxYear?: number;
  /** value */
  value?: string;
  /** format: default=YYYY-MM-DD */
  format?: FormatTypes;
  /** onConfirm */
  onConfirm?: (value: string) => void;
}

/**
 * resetDay
 * @param picker
 * @param year
 * @param month
 */
const resetDay = (picker: F7Picker.Picker, year: string | undefined, month: string | undefined) => {
  const yearNum = year ? Number(year) : new Date().getFullYear();
  const monthNum = month ? Number(month) : new Date().getMonth();
  // daysInMonth
  const daysInMonth = new Date(yearNum, monthNum, 0).getDate();
  // day > daysInMonthの場合、リセット
  const index = picker.cols.findIndex(o => o.cssClass === "datetime-picker-day");
  if (index === -1) return;
  const day = picker.cols[index].value;
  if (Number(day) > daysInMonth) {
    picker.cols[index].setValue(String(daysInMonth));
  }
  // visibility
  if (!picker.cols[index].items) return;
  if (daysInMonth === 28) {
    picker.cols[index].items[28].style.visibility = "hidden";
    picker.cols[index].items[29].style.visibility = "hidden";
    picker.cols[index].items[30].style.visibility = "hidden";
  } else if (daysInMonth === 29) {
    picker.cols[index].items[28].style.visibility = "visible";
    picker.cols[index].items[29].style.visibility = "hidden";
    picker.cols[index].items[30].style.visibility = "hidden";
  } else if (daysInMonth === 30) {
    picker.cols[index].items[28].style.visibility = "visible";
    picker.cols[index].items[29].style.visibility = "visible";
    picker.cols[index].items[30].style.visibility = "hidden";
  } else {
    picker.cols[index].items[28].style.visibility = "visible";
    picker.cols[index].items[29].style.visibility = "visible";
    picker.cols[index].items[30].style.visibility = "visible";
  }
};

/**
 * createYearCols
 * @param minYear
 * @param maxYear
 * @returns yearCols
 */
const createYearCols = (minYear: number, maxYear: number): PickerColumn => ({
  textAlign: "right",
  width: 140,
  values: Array.from({ length: maxYear - minYear + 1 }, (_, i) => String(i + minYear)),
  cssClass: "datetime-picker-year",
  onChange(picker, value) {
    const month = picker.cols.find(o => o.cssClass === "datetime-picker-month")?.value as string;
    resetDay(picker, value, month);
  },
});
/** monthCols */
const monthCols: PickerColumn = {
  textAlign: "center",
  values: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
  cssClass: "datetime-picker-month",
  onChange(picker, value) {
    const year = picker.cols.find(o => o.cssClass === "datetime-picker-year")?.value as string;
    resetDay(picker, year, value);
  },
};
/** dayCols */
const dayCols: PickerColumn = {
  textAlign: "left",
  values: Array.from({ length: 31 }, (_, i) => `0${i + 1}`.slice(-2)),
  cssClass: "datetime-picker-day",
};
/** hourCols */
const hourCols: PickerColumn = {
  textAlign: "right",
  values: Array.from({ length: 24 }, (_, i) => `0${i}`.slice(-2)),
  cssClass: "datetime-picker-hour",
};
/** minuteCols */
const minuteCols: PickerColumn = {
  textAlign: "left",
  values: Array.from({ length: 60 }, (_, i) => `0${i}`.slice(-2)),
  cssClass: "datetime-picker-minute",
};
/**
 * create divider
 * @param divider
 * @returns createDivider
 */
const createDivider = (divider: string): PickerColumn => ({
  textAlign: "center",
  divider: true,
  content: divider,
});

/**
 * createCols
 * @param format
 * @param minYear
 * @param maxYear
 * @returns cols
 */
const createCols = (format: FormatTypes, minYear: number, maxYear: number): PickerColumn[] => {
  const yearCols = createYearCols(minYear, maxYear);
  if (format === "YYYYMMDD") {
    return [yearCols, monthCols, dayCols];
  }
  if (format === "YYYY-MM-DD") {
    const divider = createDivider("-");
    return [yearCols, divider, monthCols, divider, dayCols];
  }
  const dateDivider = createDivider("-");
  const timeDivider = createDivider(":");
  return [yearCols, dateDivider, monthCols, dateDivider, dayCols, hourCols, timeDivider, minuteCols];
};

/**
 * DateTimePicker
 * @param props
 * @returns DateTimePicker
 */
export const DateTimePicker: React.FC<DateTimePickerProps> = props => {
  const { minYear = 1970, maxYear = 2099, value, format = "YYYY-MM-DD", onConfirm, ...options } = props;

  // cols
  const cols = useMemo(() => createCols(format, minYear, maxYear), [format, minYear, maxYear]);

  // pickerValue
  const pickerValue = useMemo(() => {
    const date = dayjs(value, format);
    if (!date.isValid()) return undefined;
    if (["YYYYMMDD", "YYYY-MM-DD"].includes(format)) {
      return [date.year().toString(), `0${date.month() + 1}`.slice(-2), `0${date.date()}`.slice(-2)];
    }
    return [
      date.year().toString(),
      `0${date.month() + 1}`.slice(-2),
      `0${date.date()}`.slice(-2),
      `0${date.hour()}`.slice(-2),
      `0${date.minute()}`.slice(-2),
    ];
  }, [value, format]);

  /**
   * handleConfirm
   */
  const handleConfirm = useCallback(
    (value: string[]) => {
      if (!onConfirm) return;
      const year = Number(value[0]);
      const month = Number(value[1]) - 1;
      const day = Number(value[2]);
      if (value.length > 3) {
        onConfirm(dayjs(new Date(year, month, day, Number(value[3]), Number(value[4]))).format(format));
      } else {
        onConfirm(dayjs(new Date(year, month, day)).format(format));
      }
    },
    [format, onConfirm],
  );

  return <Picker {...options} value={pickerValue} cols={cols} onConfirm={handleConfirm} />;
};
