import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector as rawUseSelector } from "react-redux";
import { Reducer } from "redux";

import { combineReducer } from "./reducers";

const rootReducer: Reducer<ReturnType<typeof combineReducer>> = (state, action) => {
  if (action.type === "store/reset") {
    return combineReducer(undefined, action);
  }
  return combineReducer(state, action);
};

// configure store
const store = configureStore({
  reducer: rootReducer,
});

// dispatch
const { dispatch } = store;

/**
 * ストア初期化
 * @returns initial state
 */
export const storeReset = () => dispatch({ type: "store/reset" });

/**
 * RootState
 */
export type RootState = ReturnType<typeof store.getState>;

// useSelector
const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;

export { store, dispatch, useSelector };
