import { Navbar, Page } from "framework7-react";
import React from "react";

import { TalkList } from "ui/component/talk/talk-list";

/**
 * トーク画面
 * @returns TalkPage
 */
export const TalkPage: React.FC = () => (
  <Page>
    <Navbar title="メッセージ" />
    <TalkList isGirlAccess />
  </Page>
);
