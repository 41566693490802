import { Block, SkeletonBlock } from "framework7-react";
import React from "react";
import styled from "styled-components";

const Container = styled.div({
  ".media": {
    aspectRatio: "16 / 9",
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 4,
  },

  ".thumbnail": {
    marginLeft: 4,
    display: "flex",
    flexFlow: "row nowrap",

    "*": {
      marginRight: 4,
    },
  },

  ".mb-4": {
    marginBottom: 4,
  },
  ".mb-16": {
    marginBottom: 16,
  },
});

/**
 * SkeletonSection
 * @returns SkeletonSection
 */
export const SkeletonSection: React.FC = () => (
  <Container>
    <SkeletonBlock tag="div" width="100%" height="auto" className="media" borderRadius="0" effect="wave" />
    <div className="thumbnail">
      <SkeletonBlock tag="div" width="80px" height="80px" borderRadius="0" effect="wave" />
      <SkeletonBlock tag="div" width="80px" height="80px" borderRadius="0" effect="wave" />
      <SkeletonBlock tag="div" width="80px" height="80px" borderRadius="0" effect="wave" />
      <SkeletonBlock tag="div" width="80px" height="80px" borderRadius="0" effect="wave" />
    </div>
    <Block strong>
      <SkeletonBlock tag="div" width="100%" height="16px" borderRadius="0" effect="wave" className="mb-4" />
      <SkeletonBlock tag="div" width="100%" height="16px" borderRadius="0" effect="wave" className="mb-16" />
      <SkeletonBlock tag="div" width="100%" height="24px" borderRadius="0" effect="wave" />
    </Block>
  </Container>
);
