import classNames from "classnames";
import React from "react";
import styled from "styled-components";

type ContainerProps = {
  /** hoverable */
  hoverable: boolean;
  /** card */
  card: boolean;
};

/**
 * GalleryItemProps
 */
export interface GalleryItemProps extends React.HTMLAttributes<HTMLDivElement>, Partial<ContainerProps> {}

const Container = styled.div<ContainerProps>(({ hoverable, card }) => ({
  height: "auto",
  minHeight: "240px",
  boxShadow: card ? "var(--f7-card-box-shadow)" : undefined,
  borderRadius: card ? "var(--f7-card-border-radius)" : undefined,

  "& img": {
    transition: "all .2s",
  },

  "&:hover img": {
    transform: hoverable ? "scale(1.2)" : undefined,
    opacity: 0.75,
  },
}));

/**
 * GalleryItem
 * @param props
 * @param props.hoverable
 * @param props.card
 * @param props.className
 * @param props.children
 * @returns GalleryItem
 */
export const GalleryItem: React.FC<GalleryItemProps> = ({
  hoverable = false,
  card = true,
  className,
  children,
  ...attrs
}) => (
  <Container hoverable={hoverable} card={card} className={classNames("gallery-item", className)} {...attrs}>
    {children}
  </Container>
);
