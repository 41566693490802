import { Block, BlockTitle } from "framework7-react";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import { ProfileResponse } from "core/model/girl/profile";
import { OnlineStatusResponse } from "core/model/user";
import { calcAge } from "core/utils/common";

import { OnlineStatus, Hidden } from "global/enum";
import { JobLabel } from "global/enum-label";
import { Sex } from "global/enum/profile-girl";

import { Icon } from "ui/widget/icon";
import { OnlineStatusBar } from "ui/widget/online-status-bar";
import { RankingBar } from "ui/widget/ranking-bar";
import { TextEllipsis } from "ui/widget/text-ellipsis";

type ProfileSectionProps = {
  profile: ProfileResponse;
  onlineStatus: OnlineStatusResponse | undefined;
};

/**
 * ProfileSection
 * @param props
 * @param props.profile
 * @param props.onlineStatus
 * @returns ProfileSection
 */
export const ProfileSection: React.FC<ProfileSectionProps> = ({ profile, onlineStatus }) => (
  <Block strong className="no-margin">
    <BlockTitle className="text-align-middle">
      <OnlineStatusBar
        online={onlineStatus?.online === OnlineStatus.online}
        lastConnectedDtm={onlineStatus?.lastConnectedDtm}
      />
      <span>{profile.nickName}</span>
      {profile.sex === Sex.female ? (
        <Icon name="female" color="pink" />
      ) : profile.sex === Sex.male ? (
        <Icon name="male" color="blue" />
      ) : (
        <Icon name="help_center" color="green" />
      )}
      {profile.birthHidden !== Hidden.hide && profile.birth && <span>{calcAge(profile.birth)}岁</span>}
      &nbsp;&nbsp;&nbsp;&nbsp;
      <RankingBar ranking={profile.ranking} />
    </BlockTitle>
    <div className="text-align-middle">
      {profile.job && (
        <>
          <div style={{ whiteSpace: "nowrap" }}>
            <FormattedMessage id={JobLabel[profile.job]} />
          </div>
          &nbsp;/&nbsp;
        </>
      )}
      <TextEllipsis text={profile.title} />
    </div>
    <br />
    <BlockTitle medium className="text-align-middle no-margin">
      <div>30分あたりの料金</div>
      <div style={{ marginLeft: "auto" }}>
        <FormattedNumber value={profile.price} />P
      </div>
    </BlockTitle>
  </Block>
);
