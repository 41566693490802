import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { Principal } from "core/model/authorization";

import { FetchStatus } from "global/enum";

/**
 * state
 */
export type AuthorizationState = {
  /** ユーザ情報 */
  principal?: Principal;
  /** tokenRefreshStatus */
  tokenRefreshStatus: FetchStatus;
};

const initialState: AuthorizationState = {
  tokenRefreshStatus: FetchStatus.idle,
};

/**
 * slice
 */
export const authorizationSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    /**
     * updatePrincipalAction
     * @param state
     * @param action
     */
    updatePrincipalAction: (state, action: PayloadAction<Principal>) => {
      state.principal = action.payload;
    },
    /**
     * updateTokenRefreshStatusAction
     * @param state
     * @param action
     */
    updateTokenRefreshStatusAction: (state, action: PayloadAction<FetchStatus>) => {
      state.tokenRefreshStatus = action.payload;
    },
    /**
     * logoutAction
     * @param state
     */
    logoutAction: state => {
      state.principal = undefined;
    },
    /**
     * updateAvatarAction
     * @param state
     * @param action
     */
    updateAvatarAction: (state, action: PayloadAction<string>) => {
      if (!state.principal) return;
      state.principal.avatar = action.payload;
    },
  },
});

/**
 * action
 */
export const authorizationAction = authorizationSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const authorizationSelector = (state: RootState) => state.authorization;
