import { Navbar, Page } from "framework7-react";
import React, { useEffect } from "react";

import { shopListInitialize } from "core/usecase/site/shop";

import { ShopList } from "ui/component/site/shop/shop-list";

/**
 * ShopPage
 * @returns ShopPage
 */
export const ShopPage: React.FC = () => {
  useEffect(() => {
    // 店舗一覧取得
    shopListInitialize();
  }, []);

  return (
    <Page>
      <Navbar title="店铺" />
      <ShopList />
    </Page>
  );
};
