import { Page } from "framework7-react";
import React from "react";
import { PropsWithF7Router } from "routes";

import { SendCompleteBlock } from "ui/component/forgot-password/send-complete-block";

/**
 * SendCompleteProps
 */
type SendCompleteProps = PropsWithF7Router<{
  /** メールアドレス */
  email: string;
}>;

/**
 * パスワードのお忘れ-送信完了画面
 * @param props
 * @param props.email
 * @param props.f7router
 * @returns SendComplete
 */
export const SendCompletePage: React.FC<SendCompleteProps> = ({ email, f7router }) => {
  const onPageBeforeIn = () => {
    if (!email) {
      f7router.navigate("/404", {
        clearPreviousHistory: true,
      });
    }
  };

  return (
    <Page onPageBeforeIn={onPageBeforeIn}>
      <SendCompleteBlock email={email} loginLink="/girl/login" />
    </Page>
  );
};
