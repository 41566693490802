import { Navbar, Page, PageContent } from "framework7-react";
import { useState, useCallback, useRef } from "react";
import { useEffectOnce } from "react-use";

import { getChargeLogOfNextPage, initializeChargeLog } from "core/usecase/site/profile/charge";

import { ChargeLogList } from "ui/component/site/profile/charge-log/charge-log-list";

/**
 * チャージ履歴画面
 * @returns ChargeLogPage
 */
export const ChargeLogPage = () => {
  const allowInfinite = useRef(true);
  const [loadMore, setLoadMore] = useState(true);

  useEffectOnce(() => {
    initializeChargeLog().then(setLoadMore);
  });

  /**
   * handleInfinite
   */
  const handleInfinite = useCallback(() => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    getChargeLogOfNextPage()
      .then(setLoadMore)
      .finally(() => {
        allowInfinite.current = true;
      });
  }, []);

  return (
    <Page pageContent={false}>
      <Navbar backLink backLinkUrl="/profile" backLinkForce title="充值记录" />

      <PageContent infinite={loadMore} onInfinite={handleInfinite}>
        <ChargeLogList />
      </PageContent>
    </Page>
  );
};
