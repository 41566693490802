import { CustomDateLocale, CustomStringLocale, LocaleObject } from "yup";

const mixed: Required<LocaleObject["mixed"]> = {
  default: "请输入正确的值",
  required: "请填写该字段",
  defined: "该值不能为undefined",
  notNull: "该值不能为null",
  oneOf: "请输入${values}之一",
  notOneOf: "请输入${values}以外的值",
  notType: "该值必须为'${type}'类型",
};

const string: Required<LocaleObject["string"]> = {
  length: "请输入${length}个字符",
  min: "请至少输入${min}个字符",
  max: "最多只能输入${max}个字符",
  matches: "请按以下格式输入: ${regex}",
  email: "请输入正确的邮箱地址格式",
  url: "请输入正确的网址格式",
  uuid: "请输入正确的UUID格式",
  trim: "请删除两端空格",
  lowercase: "只能输入小写字母",
  uppercase: "只能输入大写字母",
};

const number: Required<LocaleObject["number"]> = {
  min: "该值必须大于等于${min}",
  max: "该值必须小于等于${max}",
  lessThan: "该值必须大于${less}",
  moreThan: "该值必须小于${more}",
  positive: "请输入正数",
  negative: "请输入负数",
  integer: "请输入整数",
};

const date: Required<LocaleObject["date"]> = {
  min: "请输入${min}之后的日期",
  max: "请输入${max}之前的日期",
};

const boolean: Required<LocaleObject["boolean"]> = {
  isValue: "该值必须为${value}",
};

const object: Required<LocaleObject["object"]> = {
  noUnknown: "该值必须有此属性: ${value}",
};

const array: Required<LocaleObject["array"]> = {
  min: "该数组中至少有${min}个元素",
  max: "该数组中最多只能有${max}个元素",
  length: "该数组中只能有${length}个元素",
};

/**
 * カストマイズ[string]メッセージ
 */
export const customStringLocale: CustomStringLocale = {
  selectRequired: "该值必须选择",
  lengthBetween: "请输入${min}~${max}个字符",
  katakana: "请输入全角片假名",
  hiragana: "请输入全角平假名",
  zenkaku: "请输入正确的汉字",
  hankaku: "请输入半角字符",
  number: "请输入数字",
  decimal: "请输入整数或小数",
  date: "请按照以下格式输入日期: ${format}",
  pastDate: "请输入过去的日期",
  futureDate: "请输入未来的日期",
  alpha: "请输入英文字符",
  alphaNumber: "请输入英数字",
  alphaNumberSymbol: "请输入英数字符号",
  alphaSymbol: "请输入英文或符号",
  numberSymbol: "请输入数字或符号",
  symbol: "请输入符号",
  tel: "请输入电话号码",
  postal: "请输入邮编",
  equalWith: "${reference}不一致",
};

export const customDateLocale: CustomDateLocale = {
  pastDate: "请输入过去的日期",
  futureDate: "请输入未来的日期",
};

export const locale: Required<LocaleObject> = {
  mixed,
  string,
  number,
  date,
  boolean,
  object,
  array,
};
