import classNames from "classnames";
import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";

import { Icon } from "../icon";
import { IconButton } from "../icon-button";
import "./index.css";

type ActionButton = {
  /** String with Button's text (could be HTML string). */
  text: string;
  /** Enables bold button text. (default false) */
  bold?: boolean;
  /** Button color, one of default colors. */
  color?:
    | "red"
    | "green"
    | "blue"
    | "pink"
    | "yellow"
    | "orange"
    | "purple"
    | "deeppurple"
    | "lightblue"
    | "teal"
    | "lime"
    | "deeporange"
    | "gray"
    | "white"
    | "black";
  /** Additional button CSS class. */
  className?: string;
  /** Callback function that will be executed after click on this button. */
  onClick?: (e: React.MouseEvent) => void;
};

/**
 * ModalProps
 */
export interface ModalProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  /** model open */
  opened: boolean;
  /** show closeButton: default false */
  closeButton?: boolean;
  /** title */
  title?: React.ReactNode;
  /** text */
  text?: React.ReactNode;
  /** content */
  content?: React.ReactNode;
  /** actions */
  actions?: ActionButton[];
  /** modal width: full width - 32px  */
  fluid?: boolean;
  /** backdrop close: default = true */
  backdropClose?: boolean;
  /** onClose */
  onClose?: () => void;
}

/**
 * Modal
 * @param props
 * @returns Modal
 */
export const Modal: React.FC<ModalProps> = props => {
  const {
    opened,
    closeButton = false,
    title,
    text,
    content,
    actions,
    fluid = false,
    backdropClose = true,
    onClose,
    style,
    className,
    ...attrs
  } = props;

  useEffect(() => {
    const html = document.getElementsByTagName("html")[0]!;
    if (opened) {
      html.classList.add("with-modal");
    } else {
      html.classList.remove("with-modal");
    }
  }, [opened]);

  /**
   * handleBackdropClick
   */
  const handleBackdropClick = useCallback(() => {
    if (!backdropClose || !onClose) return;
    onClose();
  }, [backdropClose, onClose]);

  if (!opened) return null;

  return createPortal(
    <>
      <div className="modal-backdrop backdrop-in" onClick={handleBackdropClick} />
      <div
        {...attrs}
        className={classNames("modal", "modal-in", className, {
          "modal-fluid": fluid,
          "modal-buttons-1": actions?.length === 1,
          "modal-buttons-2": actions?.length === 2,
          "modal-buttons-3": actions?.length === 3,
        })}
        style={{ ...style, display: "block" }}
      >
        <div className="modal-inner">
          {title && <div className="modal-title">{title}</div>}
          {closeButton && <IconButton onClick={onClose} icon={<Icon name="close" />} className="modal-close" />}
          {text && <div className="modal-text">{text}</div>}
          {content}
        </div>
        {actions && (
          <div className="modal-buttons">
            {actions.map((action, index) => (
              <span
                key={index}
                className={classNames("modal-button", action.className, {
                  "modal-button-bold": action.bold,
                  [`color-${action.color}`]: !!action.color,
                })}
                onClick={action.onClick}
              >
                {action.text}
              </span>
            ))}
          </div>
        )}
      </div>
    </>,
    document.getElementById("modal-root")!,
  );
};
