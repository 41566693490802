import { Button, Card, CardContent, CardHeader, Col, Row } from "framework7-react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { siteOrderSelector } from "core/slice/site/order";
import { updateReserveData } from "core/usecase/site/order";

import { AddressLabel, AreaLabel } from "global/enum-label";
import { ReserveTypeLabel } from "global/enum-label/reserve";
import { ReserveType } from "global/enum/reserve";

import { Icon } from "ui/widget/icon";
import { RankingBar } from "ui/widget/ranking-bar";

import { AddressModal } from "./address-modal";
import { ConditionItem } from "./condition-item";
import { PeopleNumberModal } from "./people-number-modal";
import { ReserveHourModal } from "./reserve-hour-modal";
import { ScheduledMeetTimeModal } from "./scheduled-meet-time-modal";

const PeopleNumberWrapper = styled.div({
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: 6,

  ".people-number": {
    fontSize: 20,
    marginRight: 4,
    fontWeight: "bold",
    color: "orange",
  },
});

/**
 * ReserveCondition
 * @returns ReserveCondition
 */
export const ReserveCondition: React.FC = () => {
  const { reserve } = useSelector(siteOrderSelector);

  const [scheduledMeetTimeModalOpened, setScheduledMeetTimeModalOpened] = useState(false);
  const [reserveHourModelOpened, setReserveHourModelOpened] = useState(false);
  const [peopleNumberModalOpened, setPeopleNumberModalOpened] = useState(false);
  const [addressModalOpened, setAddressModalOpened] = useState(false);

  return (
    <>
      <Card>
        <CardHeader>女孩预约</CardHeader>
        <CardContent>
          <Row>
            <Col width={100} xsmall={50} style={{ marginBottom: 16 }}>
              <ConditionItem
                icon={<Icon name="schedule" />}
                label="预约时间:"
                title={
                  <>
                    <FormattedMessage id={ReserveTypeLabel[reserve.type]} />
                    <br />
                    <span style={{ fontWeight: "normal", color: "orange" }}>
                      {reserve.type === ReserveType.other && `(${reserve.typeOther})`}
                    </span>
                  </>
                }
                onClick={() => setScheduledMeetTimeModalOpened(true)}
              />
            </Col>
            <Col width={100} xsmall={50} style={{ alignSelf: "stretch", marginBottom: 16 }}>
              <ConditionItem
                icon={<Icon name="timer" shape="outlined" />}
                label="预约时长:"
                title={`${reserve.hour}小时`}
                onClick={() => setReserveHourModelOpened(true)}
              />
            </Col>
          </Row>
          <Row>
            <Col width={100} style={{ marginBottom: 16 }}>
              <ConditionItem
                icon={<Icon name="people" shape="outlined" />}
                label="女孩人数(级别):"
                title={reserve.casts.map(o => (
                  <PeopleNumberWrapper key={o.ranking}>
                    <RankingBar shadow width={160} ranking={o.ranking} />
                    <div>
                      <span className="people-number">{o.peopleNumber}</span>人
                    </div>
                  </PeopleNumberWrapper>
                ))}
                onClick={() => setPeopleNumberModalOpened(true)}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 16 }}>
            <Col width={100}>
              <ConditionItem
                icon={<Icon name="place" shape="outlined" />}
                label="场所:"
                title={
                  <>
                    <FormattedMessage id={AreaLabel[reserve.area]} />
                    &emsp;
                    <FormattedMessage id={AddressLabel[reserve.areaAddr]} />
                  </>
                }
                onClick={() => setAddressModalOpened(true)}
              />
            </Col>
          </Row>
          <Row>
            <Col width={100}>
              <Button fill color="orange" href="/order/cast-select" transition="f7-push">
                下一步
              </Button>
            </Col>
          </Row>
        </CardContent>
      </Card>

      <ScheduledMeetTimeModal
        opened={scheduledMeetTimeModalOpened}
        onClose={() => setScheduledMeetTimeModalOpened(false)}
        type={reserve.type}
        typeOther={reserve.typeOther}
        onConfirm={(type, typeOther) => updateReserveData({ type, typeOther })}
      />
      <ReserveHourModal
        opened={reserveHourModelOpened}
        onClose={() => setReserveHourModelOpened(false)}
        hour={reserve.hour}
        onConfirm={hour => updateReserveData({ hour })}
      />
      <PeopleNumberModal
        opened={peopleNumberModalOpened}
        onClose={() => setPeopleNumberModalOpened(false)}
        casts={reserve.casts}
        onConfirm={casts => updateReserveData({ casts })}
      />
      <AddressModal
        opened={addressModalOpened}
        onClose={() => setAddressModalOpened(false)}
        area={reserve.area}
        areaAddr={reserve.areaAddr}
        onConfirm={(area, areaAddr) => updateReserveData({ area, areaAddr })}
      />
    </>
  );
};
