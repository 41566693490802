import { Block, BlockTitle, Col, f7, Link, Row } from "framework7-react";
import { PhotoBrowser as F7PhotoBrowser } from "framework7/components/photo-browser";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { MediaItem } from "core/model/media";
import { mediaSelector } from "core/slice/girl/media";
import { mediaDelete, mediaUpload } from "core/usecase/girl/media";
import { showConfirmModal } from "core/utils/common";

import { CommonConfig } from "global/config";
import { MediaType } from "global/enum";

import { Icon } from "ui/widget/icon";

const MediaContainer = styled.div({
  ".media-title": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 16,
  },

  ".media-item": {
    background: "#d7d5d5",
    height: "auto",
    aspectRatio: "3 / 4",
    border: "1px solid var(--f7-page-bg-color)",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",

    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },

    ".media-item-inner": {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    ".media-item-inner.media-item-add input": {
      opacity: 0,
      cursor: "pointer",
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
    },

    ".media-item-inner.media-item-delete": {
      background: "rgba(0, 0, 0, .375)",
      color: "#c0bfbf",
    },

    ".media-item-inner.media-item-video": {
      background: "rgba(0, 0, 0, .375)",
      color: "#fff",
    },
  },
});

/**
 * Media Tab
 * @returns Media Tab
 */
export const TabMedia: React.FC = () => {
  const photoBrowserRef = useRef<F7PhotoBrowser.PhotoBrowser>();
  const mediaList = useSelector(mediaSelector);
  const [deleteMode, setDeleteMode] = useState(false);

  useEffect(() => {
    if (!mediaList) {
      photoBrowserRef.current = undefined;
      return;
    }
    photoBrowserRef.current = f7.photoBrowser.create({
      photos: mediaList.map(item => {
        if (item.type === MediaType.video) {
          return `
            <div style="width: 100%; height: 100%; display: flex; align-items: center; z-index: 2">
              <video
                controls="true" width="100%" style="max-height: calc(100% - 100px)" 
                src="${CommonConfig.mediaBaseUrl + item.photoId}" 
              />
            </div>
            <img src="${CommonConfig.thumbnailBaseUrl + item.thumbnail}" style="position: absolute; z-index: 1" />
          `;
        }
        return CommonConfig.mediaBaseUrl + item.photoId;
      }),
      type: "popup",
      theme: "dark",
      swiper: {
        cssMode: false,
      },
    });
  }, [mediaList]);

  /**
   * handleMediaItemClick
   * @param item
   * @param index
   */
  const handleMediaItemClick = (item: MediaItem, index: number) => {
    if (deleteMode) {
      showConfirmModal("削除しても宜しいでしょうか", () => {
        mediaDelete(item);
      });
    } else {
      photoBrowserRef.current?.open(index);
    }
  };

  return (
    <MediaContainer>
      <BlockTitle className="media-title">
        <span>写真・ビデオ一覧</span>
        {mediaList && mediaList.length > 0 && (
          <Link href={false} onClick={() => setDeleteMode(!deleteMode)}>
            {deleteMode ? (
              <>
                <Icon name="done" size={24} />
                <span>完了</span>
              </>
            ) : (
              <>
                <Icon name="delete" size={24} color="orange" />
                <span className="text-color-orange">削除</span>
              </>
            )}
          </Link>
        )}
      </BlockTitle>
      <Block>
        <Row noGap style={{ justifyContent: "flex-start" }}>
          <Col className="media-item" width={33}>
            <div className="media-item-inner media-item-add">
              <Icon name="add" size={80} color="gray" />
              <input
                type="file"
                name="file"
                accept="image/*,.heic,.heif,video/*"
                onChange={e => mediaUpload(e.target.files?.[0])}
              />
            </div>
          </Col>
          {mediaList?.map((item, index) => (
            <Col className="media-item" width={33} key={item.rowsId} onClick={() => handleMediaItemClick(item, index)}>
              <img src={CommonConfig.thumbnailBaseUrl + item.thumbnail} alt="" />
              {!deleteMode && item.type === MediaType.video && (
                <div className="media-item-inner media-item-video">
                  <Icon name="play_circle" shape="outlined" size={60} />
                </div>
              )}
              {deleteMode && (
                <div className="media-item-inner media-item-delete">
                  <Icon name="delete" size={80} />
                </div>
              )}
            </Col>
          ))}
        </Row>
      </Block>
    </MediaContainer>
  );
};
