import { Block, BlockTitle } from "framework7-react";
import React from "react";
import { FormattedNumber } from "react-intl";

import { Divider } from "ui/widget/divider";

type PresentLogSummaryProps = {
  total: number;
};

/**
 * プレゼント合計
 * @param props
 * @param props.total
 *
 * @returns PresentLogSummary
 */
export const PresentLogSummary: React.FC<PresentLogSummaryProps> = ({ total }) => (
  <Block>
    <BlockTitle className="text-align-center" style={{ color: "gray" }}>
      プレゼント合計(P)
    </BlockTitle>
    <BlockTitle medium className="text-align-center no-margin-top no-margin-bottom">
      <FormattedNumber value={total} />
    </BlockTitle>
    <Divider />
  </Block>
);
