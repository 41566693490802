import { useFormik } from "formik";
import { Link, Navbar, NavLeft, NavRight, NavTitle, Page } from "framework7-react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { profileSelector } from "core/slice/girl/profile";
import { profileInitialize, updateProfile } from "core/usecase/girl/profile";
import { goBack } from "core/usecase/global";

import { FormikList, FormikListInput } from "ui/widget/formik";

type Shape = { firstNmKanji?: string; lastNmKanji?: string; firstNmKana?: string; lastNmKana?: string };

/**
 * 姓名編集画面
 * @returns NameEditor
 */
export const NameEditorPage: React.FC = () => {
  const { lastNmKanji, firstNmKanji, lastNmKana, firstNmKana } = useSelector(profileSelector);

  const formik = useFormik<Shape>({
    initialValues: {
      lastNmKanji: lastNmKanji || "",
      firstNmKanji: firstNmKanji || "",
      lastNmKana: lastNmKana || "",
      firstNmKana: firstNmKana || "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      lastNmKanji: yup.string().max(20).zenkaku(),
      firstNmKanji: yup.string().max(20).zenkaku(),
      lastNmKana: yup.string().max(20).katakana(),
      firstNmKana: yup.string().max(20).katakana(),
    }),
    onSubmit(value) {
      updateProfile("/profile/name/s", value).then(success => {
        if (success) goBack("/girl/profile/base");
      });
    },
  });

  const { setValues } = formik;

  /**
   * データ再設定
   */
  useEffect(() => {
    setValues({ lastNmKanji, firstNmKanji, lastNmKana, firstNmKana });
  }, [firstNmKana, firstNmKanji, lastNmKana, lastNmKanji, setValues]);

  return (
    <Page onPageBeforeIn={profileInitialize}>
      <Navbar>
        <NavLeft backLink backLinkUrl="/girl/profile/base" backLinkForce />
        <NavTitle>姓名</NavTitle>
        <NavRight>
          <Link href={false} onClick={formik.submitForm}>
            確定
          </Link>
        </NavRight>
      </Navbar>

      <FormikList formik={formik}>
        <FormikListInput
          type="text"
          name="lastNmKanji"
          label="姓（漢字）"
          maxlength={20}
          info="全角20文字以内で入力してください"
        />
        <FormikListInput
          type="text"
          name="firstNmKanji"
          label="名（漢字）"
          maxlength={20}
          info="全角20文字以内で入力してください"
        />
        <FormikListInput
          type="text"
          name="lastNmKana"
          label="姓（カナ）"
          maxlength={20}
          info="全角カタカナ20文字以内で入力してください"
        />
        <FormikListInput
          type="text"
          name="firstNmKana"
          label="名（カナ）"
          maxlength={20}
          info="全角カタカナ20文字以内で入力してください"
        />
      </FormikList>
    </Page>
  );
};
