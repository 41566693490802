/**
 * 性格
 */
export enum Personality {
  /** わいわい */
  noisily = "1",
  /** しっとり */
  moist = "2",
  /** パリピ */
  paripi = "3",
}

/**
 * 趣味
 */
export enum Hobby {
  /** 読書 */
  read = "1",
  /** 旅行 */
  trip = "2",
  /** 音楽 */
  music = "3",
  /** 漫画 */
  manga = "4",
  /** 映画 */
  video = "5",
  /** トレーニング */
  training = "6",
  /** 麻雀 */
  mahjong = "7",
  /** ゲーム */
  game = "8",
  /** ゴルフ */
  golf = "9",
  /** カラオケ */
  ktv = "10",
  /** BBQ */
  bbq = "11",
}

/** 性別 */
export enum Sex {
  /** 男 */
  male = "1",
  /** 女 */
  female = "2",
  /** 中性 */
  neuter = "3",
}

/**
 * 国籍
 */
export enum Country {
  /** 中国 */
  China = "1",
  /** 日本 */
  Japan = "2",
  /** 韓国 */
  Korea = "3",
  /** アメリカ */
  America = "4",
  /** ヨーロッパ */
  Europe = "5",
  /** 南アジア */
  SouthAsia = "6",
  /** その他 */
  Other = "7",
}

/**
 * 言語
 */
export enum Language {
  /** 中国語 */
  Chinese = "1",
  /** 日本語 */
  Japanese = "2",
  /** 英語 */
  English = "3",
  /** 韓国語 */
  Korean = "4",
  /** その他 */
  Other = "5",
}

/**
 * 年収
 */
export enum Annual {
  /** 0-20万元 */
  lt20 = "1",
  /** 20-40万元 */
  lt40 = "2",
  /** 40-60万元 */
  lt60 = "3",
  /** 60-80万元 */
  lt80 = "4",
  /** 80-100万元 */
  lt100 = "5",
  /** 100-150万元 */
  lt150 = "6",
  /** 150-200万元 */
  lt200 = "7",
  /** 200-300万元 */
  lt300 = "8",
  /** 300-500万元 */
  lt500 = "9",
  /** 500-800万元 */
  lt800 = "10",
  /** 800-1000万元 */
  lt1000 = "11",
  /** 1000万元以上 */
  gt1000 = "12",
}

/**
 * チャージタイプ
 */
export enum ChargeType {
  /** wechat */
  wechat = "1",
  /** alipay */
  alipay = "2",
  /** union */
  union = "3",
  /** USDT */
  USDT = "4",
}

/**
 * ウォレット取引種別
 */
export enum WalletTargetType {
  /** チャージ */
  charge = "1",
  /** オーダー */
  order = "2",
  /** 出金 */
  withdraw = "3",
  /** プレゼント */
  present = "4",
}

/**
 * ウォレット処理状態
 */
export enum WalletLogStatus {
  /** 未処理 */
  undo = "0",
  /** 処理済 */
  done = "1",
}

/**
 * チャージオーダー処理状態
 */
export enum ChargeOrderStatus {
  /** 承認待ち */
  pending = "1",
  /** 承認済 */
  approved = "2",
  /** 否認 */
  denied = "3",
  /** 支払済 */
  done = "4",
}
