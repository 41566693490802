import classNames from "classnames";
import { Link } from "framework7-react";
import { FormattedNumber } from "react-intl";
import styled from "styled-components";

import { calcAge } from "core/utils/common";

import { CommonConfig } from "global/config";
import { Job, Ranking } from "global/enum";
import { Sex } from "global/enum/profile-girl";

import { Icon } from "../icon";
import { RankingBar } from "../ranking-bar";
import { TextEllipsis } from "../text-ellipsis";

/** CastItemType */
export type CastItemType = {
  /** id */
  userId: string;
  /** マスクサムネイル */
  maskThumbnail: string;
  /** ニックネーム */
  nickName?: string;
  /** 性別 */
  sex?: Sex;
  /** 生年月日 */
  birth?: string;
  /** お仕事 */
  job?: Job;
  /** タイトル */
  title?: string;
  /** クラス */
  ranking?: Ranking;
  /** 30分料金 */
  price: number;
};

interface CastItemInnerProps {
  /** item */
  item: CastItemType;
  /** showInfo: default true */
  showInfo?: boolean;
  /** showTitle: default true */
  showTitle?: boolean;
  /** showPrice: default true */
  showPrice?: boolean;
  /** absolute avatar: default false */
  absoluteAvatar?: boolean;
}

type BorderType = "none" | "normal" | "animated";

interface CastItemProps extends CastItemInnerProps, React.HTMLAttributes<HTMLDivElement> {
  /** link: default true */
  link?: boolean;
  /** border: default normal */
  border?: BorderType;
}

const Container = styled.div<{ border: BorderType }>(({ border }) => ({
  display: "block",
  width: "100%",
  height: "100%",
  borderRadius: "inherit",
  position: "relative",
  border: border === "normal" ? "var(--app-cast-item-border-width) var(--f7-theme-color) solid" : undefined,

  "&::after": {
    content: border === "animated" ? "''" : undefined,
    position: "absolute",
    top: "calc(-1 * var(--app-cast-item-border-width))",
    left: "calc(-1 * var(--app-cast-item-border-width))",
    zIndex: -1,
    width: "calc(100% + var(--app-cast-item-border-width) * 2)",
    height: "calc(100% + var(--app-cast-item-border-width) * 2)",
    background: "var(--app-cast-item-after-background)",
    backgroundSize: "300% 300%",
    backgroundPosition: "0 50%",
    borderRadius: "inherit",
    animation: "moveGradient 4s alternate infinite",
  },

  ".link": {
    display: "flex",
    width: "100%",
    height: "100%",
    flexFlow: "column nowrap",
    alignItems: "flex-start",

    ".cast-item-content": {
      width: "100%",
      height: "auto",
      background: "#d7d5d5",
      aspectRatio: "15 / 16",
      cursor: "pointer",
      overflow: "hidden",
      position: "relative",

      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },

      ".ranking-bar": {
        position: "absolute",
        zIndex: 2,
        bottom: 0,
        left: 0,
      },
    },

    ".cast-item-footer": {
      width: "100%",
      color: "var(--f7-theme-color-text-color)",
      padding: "2px 8px 8px",
      flex: 1,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start",
      justifyContent: "space-between",
      background: "linear-gradient(135deg, #222, #111, #333, #111)",

      ".cast-item-info": {
        fontWeight: "bold",
        fontSize: 14,
        display: "flex",
        flexFlow: "row wrap",
        alignItems: "center",

        "&>*": {
          marginLeft: 0,
        },

        "&>*:not(:last-child)": {
          marginRight: 4,
        },
      },

      ".cast-item-title": {
        color: "#aaa",
        fontSize: 12,
      },

      ".cast-item-price": {
        marginTop: "auto",
        background: "rgba(102, 11, 4, .5)",
        color: "#fff",
        fontSize: 14,
        fontWeight: "bolder",
        paddingLeft: 4,
        paddingRight: 4,
      },
    },
  },
}));

/**
 * CastItemInner
 * @param props
 * @param props.item
 * @param props.showInfo
 * @param props.showTitle
 * @param props.showPrice
 * @param props.absoluteAvatar
 * @returns CastItemInner
 */
const CastItemInner: React.FC<CastItemInnerProps> = ({
  item,
  showInfo = true,
  showTitle = true,
  showPrice = true,
  absoluteAvatar = false,
}) => (
  <>
    <div className="cast-item-content">
      <img src={absoluteAvatar ? item.maskThumbnail : CommonConfig.thumbnailBaseUrl + item.maskThumbnail} alt="" />
      {item.ranking && <RankingBar ranking={item.ranking} />}
    </div>
    <div className="cast-item-footer">
      {showInfo && (
        <div className="cast-item-info">
          <span>{item.nickName}</span>
          {item.sex === Sex.female ? (
            <Icon name="female" color="pink" size={18} />
          ) : item.sex === Sex.male ? (
            <Icon name="male" color="blue" size={18} />
          ) : (
            <></>
          )}
          {item.birth && <span>{calcAge(item.birth)}歳</span>}
        </div>
      )}
      {showTitle && item.title && <TextEllipsis className="cast-item-title" text={item.title} />}
      {showPrice && item.price && (
        <div className="cast-item-price">
          <FormattedNumber value={item.price} />
          P&nbsp;/&nbsp;30分
        </div>
      )}
    </div>
  </>
);

/**
 * CastItem
 * @param props
 * @param props.link
 * @param props.item
 * @param props.card
 * @param props.border
 * @param props.className
 * @param props.children
 * @returns CastItem
 */
export const CastItem: React.FC<CastItemProps> = ({
  item,
  link = true,
  border = "normal",
  className,
  children,
  ...attrs
}) => (
  <Container border={border} className={classNames("cast-item", className)} {...attrs}>
    {link ? (
      <Link href={`/detail/${item.userId}`} transition="f7-push">
        <CastItemInner item={item} {...attrs} />
      </Link>
    ) : (
      <CastItemInner item={item} {...attrs} />
    )}
    {children}
  </Container>
);
