import dayjs from "dayjs";
import { Block, BlockTitle, List, ListItem } from "framework7-react";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { siteProfileWalletSelector } from "core/slice/site/profile/wallet";

import { WalletTargetTypeLabel } from "global/enum-label/site";
import { WalletTargetType } from "global/enum/profile-site";

import { NoData } from "ui/widget/no-data";

const Container = styled.div({
  ".status": {
    margin: "4px 0",
    padding: "4px 12px",
    borderRadius: 24,
    color: "#fff",
    fontWeight: "bold",
    display: "inline-block",
    width: "auto",
    fontSize: 12,

    "&.status-doing": {
      background: "#e59d1a",
    },
    "&.status-done": {
      background: "#3ecd3e",
    },
  },

  ".item-after": {
    fontWeight: "bolder",

    ".target-charge": {
      color: "#3ecd3e",
    },

    ".target-payment": {
      color: "#ccc",
    },
  },
});

/**
 * 取引履歴
 * @returns WalletLogList
 */
export const WalletLogList: React.FC = () => {
  const { data } = useSelector(siteProfileWalletSelector).walletLog;

  return (
    <Container>
      <Block>
        <BlockTitle>余额变动明细</BlockTitle>
        {data && data.length > 0 ? (
          <List mediaList>
            {data.map(o => (
              <ListItem key={o.number} title={WalletTargetTypeLabel[o.targetType]}>
                <div slot="footer">
                  <div>{dayjs(o.applyTime).format("YYYY年MM月D日 HH时mm分")}</div>
                  {o.status === "0" ? (
                    <div className="status status-doing">处理中</div>
                  ) : (
                    <div className="status status-done">处理完成</div>
                  )}
                  <div>流水号: {o.number}</div>
                </div>
                <div
                  slot="after"
                  className={o.targetType === WalletTargetType.charge ? "target-charge" : "target-payment"}
                >
                  {o.targetType === WalletTargetType.charge ? "+" : "-"}
                  <FormattedNumber value={o.fee} />
                  &nbsp;P
                </div>
              </ListItem>
            ))}
          </List>
        ) : (
          <NoData title={<FormattedMessage id="common.nodata" />} />
        )}
      </Block>
    </Container>
  );
};
