import { Link, Tab, Tabs, Toolbar } from "framework7-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swiper from "swiper/types/swiper-class";

import { siteProfileSelector } from "core/slice/site/profile";
import { updateActiveTab } from "core/usecase/site/profile";

import { TabCharge } from "./tab-charge";
import { TabWallet } from "./tab-wallet";

export const SwiperContext = React.createContext<{
  /** swiper */
  swiper?: Swiper;
}>({});

/**
 * TabsSection
 * @returns TabsSection
 */
export const TabsSection: React.FC = () => {
  const [swiper, setSwiper] = useState<Swiper>();
  const { activeTab } = useSelector(siteProfileSelector);

  useEffect(() => {
    if (!swiper) return;
    // swiper.activeIndex = ["order", "wallet", "charge"].indexOf(activeTab);
    swiper.activeIndex = ["wallet", "charge"].indexOf(activeTab);
  }, [activeTab, swiper]);

  return (
    <>
      <Toolbar tabbar inner className="highlight-bottom">
        {/* <Link href={false} tabLink="#tab_order" tabLinkActive={activeTab === "order"}>
          订单
        </Link> */}
        <Link href={false} tabLink="#tab_wallet" tabLinkActive={activeTab === "wallet"}>
          钱包
        </Link>
        <Link href={false} tabLink="#tab_charge" tabLinkActive={activeTab === "charge"}>
          充值
        </Link>
      </Toolbar>
      <Tabs
        swipeable
        swiperParams={{
          simulateTouch: false,
          on: {
            _swiper(_swiper) {
              setSwiper(_swiper);
            },
          },
        }}
        style={{ height: "auto" }}
      >
        <SwiperContext.Provider value={{ swiper }}>
          {/* 订单 */}
          {/* <Tab id="tab_order" onTabShow={() => updateActiveTab("order")}>
            <TabOrder />
          </Tab> */}
          {/* 钱包 */}
          <Tab id="tab_wallet" style={{ minHeight: 450 }} onTabShow={() => updateActiveTab("wallet")}>
            <TabWallet />
          </Tab>
          {/* 充值 */}
          <Tab id="tab_charge" onTabShow={() => updateActiveTab("charge")}>
            <TabCharge />
          </Tab>
        </SwiperContext.Provider>
      </Tabs>
    </>
  );
};
