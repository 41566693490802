import { Page } from "framework7-react";
import React, { useCallback } from "react";
import { PropsWithF7Router } from "routes";

import { SignupModel } from "core/model/signup";

import { SignupForm } from "ui/component/signup/form";

/**
 * SignupPageProps
 */
type SignupPageProps = PropsWithF7Router<{
  /** メールアドレス */
  email?: string;
  /** パスワード */
  password?: string;
}>;

/**
 * Signup
 * @param props
 * @param props.f7router
 * @param props.email
 * @param props.password
 * @returns Signup
 */
export const SignupPage: React.FC<SignupPageProps> = ({ f7router, email, password }) => {
  /**
   * handleSubmit
   */
  const handleSubmit = useCallback(
    (values: SignupModel) => {
      f7router.navigate("/signup/confirm", {
        transition: "f7-push",
        props: {
          email: values.email,
          password: values.password,
        },
      });
    },
    [f7router],
  );

  return (
    <Page loginScreen>
      <SignupForm email={email} password={password} onSubmit={handleSubmit} loginLink="/login" />
    </Page>
  );
};
