import classNames from "classnames";
import dayjs from "dayjs";
import { List, ListItem } from "framework7-react";
import React, { useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ChargeLogItem } from "core/model/site/profile/charge";
import { siteProfileChargeSelector } from "core/slice/site/profile/charge";
import { revokeChargeOrder } from "core/usecase/site/profile/charge";
import { showConfirmModal } from "core/utils/common";

import { ChargeOrderStatusLabel, ChargeTypeLabel } from "global/enum-label/site";
import { ChargeOrderStatus } from "global/enum/profile-site";

import { NoData } from "ui/widget/no-data";

import { ChargeLogDetail } from "./charge-log-detail";

const Container = styled.div({
  paddingLeft: "calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left))",
  paddingRight: "calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-right))",

  ".item-footer": {
    paddingTop: 8,
  },

  ".status": {
    marginLeft: 12,
    padding: "2px 8px",
    borderRadius: 24,
    color: "#fff",
    fontWeight: "bold",
    display: "inline-block",
    width: "auto",
    fontSize: 12,

    "&.status-pending": {
      background: "#e59d1a",
    },
    "&.status-approved": {
      background: "#18c9ea",
    },
    "&.status-denied": {
      background: "#e80c0c",
    },
    "&.status-done": {
      background: "#3ecd3e",
    },
  },

  ".money": {
    fontWeight: "bold",
    color: "#333",
    textAlign: "right",
    fontSize: 16,
  },
  ".money-detail": {
    fontSize: 12,
  },
});

const ChargeStatusClassName = {
  [ChargeOrderStatus.pending]: "status-pending",
  [ChargeOrderStatus.approved]: "status-approved",
  [ChargeOrderStatus.denied]: "status-denied",
  [ChargeOrderStatus.done]: "status-done",
};

/**
 * ChargeLogList
 * @returns ChargeLogList
 */
export const ChargeLogList: React.FC = () => {
  const { data } = useSelector(siteProfileChargeSelector).chargeLog;
  const [selectedItem, setSelectedItem] = useState<ChargeLogItem>();

  /**
   * revokeHandler
   * @param item
   */
  const revokeHandler = (item: ChargeLogItem) => {
    showConfirmModal("您确定要撤销该充值申请吗?", () =>
      revokeChargeOrder(item.orderId).then(() => {
        setSelectedItem(undefined);
      }),
    );
  };

  return (
    <Container>
      {data && data.length > 0 ? (
        <List>
          {data.map(o => (
            <ListItem key={o.orderId} link onClick={() => setSelectedItem(o)}>
              <div slot="title" className="text-align-middle">
                <div>{ChargeTypeLabel[o.rechargeType]}</div>
                <div className={classNames("status", ChargeStatusClassName[o.payStatus])}>
                  {ChargeOrderStatusLabel[o.payStatus]}
                </div>
              </div>
              <div slot="footer">
                <div>
                  充值金额: <FormattedNumber value={o.payMoney} />元
                </div>
                <div>申请时间: {dayjs(o.createdAt).format("YYYY-MM-DD HH:mm")}</div>
                <div>单号: {o.orderNo}</div>
              </div>
              {o.payStatus === ChargeOrderStatus.done && (
                <div slot="after">
                  <div className="money">
                    + <FormattedNumber value={o.actualMoney} />元
                  </div>
                  {o.giftMoney > 0 && (
                    <div className="money-detail">
                      (内含赠送
                      <FormattedNumber value={o.giftMoney} />
                      元)
                    </div>
                  )}
                </div>
              )}
            </ListItem>
          ))}
        </List>
      ) : (
        <NoData title={<FormattedMessage id="common.nodata" />} />
      )}
      <ChargeLogDetail
        opened={!!selectedItem}
        item={selectedItem}
        onPopupClosed={() => setSelectedItem(undefined)}
        onRevoke={revokeHandler}
      />
    </Container>
  );
};
