import Cookies from "js-cookie";
import React, { PropsWithChildren, useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useUpdate } from "react-use";
import io, { Socket } from "socket.io-client";

import { authorizationSelector } from "core/slice/authorization";

import { CommonConfig } from "global/config";

import { commonEventHandler } from "./common-event-handler";

export * from "./events";

const SocketContext = React.createContext<{ socket?: Socket }>({});

/**
 * useSocket
 * @returns socket
 */
export const useSocket = () => useContext(SocketContext).socket;

/**
 * SocketClientProvider
 * @param props
 * @param props.children
 * @returns SocketClientProvider
 */
export const SocketClientProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { principal } = useSelector(authorizationSelector);
  const socketRef = useRef<Socket>();
  const update = useUpdate();

  useEffect(() => {
    // ログイン済の場合、接続
    if (principal) {
      // 前回接続を閉じる
      if (socketRef.current?.connected) {
        socketRef.current.disconnect();
        return;
      }

      // アクセストークン
      const accessToken = Cookies.get(CommonConfig.accessTokenName);
      // 接続
      const socket = io(CommonConfig.socketServer, {
        // 再接続回数
        reconnectionAttempts: CommonConfig.socketReconnectionAttemps,
        // 認証
        auth: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // event
      commonEventHandler(socket);

      socketRef.current = socket;
    } else if (socketRef.current?.connected) {
      // ログアウトの場合、接続を閉じる
      socketRef.current.disconnect();
      socketRef.current = undefined;
    }
    update();
  }, [principal, update]);

  return <SocketContext.Provider value={{ socket: socketRef.current }}>{children}</SocketContext.Provider>;
};
