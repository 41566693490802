import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { ShopListResponse, ShopState } from "core/model/site/shop";

const initialState: ShopState = {
  list: {
    initialized: false,
  },
};

/**
 * slice
 */
export const siteShopSlice = createSlice({
  name: "site/shop",
  initialState,
  reducers: {
    /**
     * shop一覧初期化
     * @param state
     * @param action
     */
    initializeShopListAction: (state, action: PayloadAction<ShopListResponse>) => {
      state.list.initialized = true;
      state.list.data = action.payload;
    },
  },
});

/**
 * action
 */
export const siteShopAction = siteShopSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const siteShopSelector = (state: RootState) => state["site/shop"];
