import classNames from "classnames";
import { Icon as F7Icon } from "framework7-react";
import { IconProps as F7IconProps } from "framework7-react/components/icon";

/**
 * IconProps
 */
export interface IconProps extends Omit<F7IconProps, "material" | "f7" | "icon" | "ios" | "aurora" | "md"> {
  /** name */
  name: string;
  /** shape */
  shape?: "outlined" | "filled" | "rounded" | "sharp" | "two-tone";
}

/**
 * Icon
 * @param props
 * @param props.name
 * @param props.shape
 * @param props.className
 * @returns Icon
 */
export const Icon: React.FC<IconProps> = ({ name, shape = "filled", className, ...attrs }) => {
  const classes = classNames(className, {
    "material-icons-outlined": shape === "outlined",
    "material-icons-rounded": shape === "rounded",
    "material-icons-sharp": shape === "sharp",
    "material-icons-two-tone": shape === "two-tone",
  });

  return <F7Icon {...attrs} material={name} className={classes} />;
};
