import dayjs from "dayjs";
import { Card, CardContent, CardFooter, Link } from "framework7-react";
import React, { useMemo } from "react";
import styled from "styled-components";

import { TimelineItem as Item } from "core/model/site/timeline";

import { CommonConfig } from "global/config";

import { Avatar } from "ui/widget/avatar";

type TimelineItemProps = {
  item: Item;
  link?: string;
  extra?: React.ReactNode;
  footer?: React.ReactNode;
};

const Container = styled.div({
  ".card": {
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 0,
  },

  ".header": {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",

    ".base": {
      paddingLeft: 16,
      paddingRight: 16,
      flex: 1,

      ".user": {
        fontSize: 16,
        color: "#333",
      },

      ".time": {
        fontSize: 12,
        color: "#999",
      },
    },

    // ".favorite": {
    //   fontSize: 18,
    //   cursor: "pointer",
    //   display: "flex",
    //   alignItems: "center",
    //   color: "gray",
    // },

    // ".favorited": {
    //   color: "red",
    // },
  },

  ".message": {
    whiteSpace: "pre-line",
  },

  ".media": {
    marginTop: 12,
    img: {
      maxWidth: "100%",
      width: "auto",
      height: "auto",
    },
  },
});

/**
 * TimelineItem
 * @param props
 * @param props.item
 * @param props.extra
 * @param props.footer
 * @param props.link
 * @returns TimelineItem
 */
export const TimelineItem: React.FC<TimelineItemProps> = ({ item, link, extra, footer }) => {
  const timeDisp = useMemo(() => {
    const date = dayjs(item.ymd + item.hhss, "YYYYMMDDHHmm");
    if (dayjs().isSame(date, "year")) {
      return date.format("MM-DD(ddd) HH:mm");
    }
    return date.format("YYYY-MM-DD(ddd) HH:mm");
  }, [item.ymd, item.hhss]);

  return (
    <Container>
      <Card>
        <CardContent>
          <div className="header">
            <Avatar src={item.avatar} shape="circle" size={45} />
            <div className="base">
              <div className="user">
                {link ? (
                  <Link href={link} transition="f7-push">
                    {item.username}
                  </Link>
                ) : (
                  item.username
                )}
              </div>
              <div className="time">{timeDisp}</div>
            </div>
            {extra}
          </div>
          <div className="message">{item.message}</div>
          {item.image && (
            <div className="media">
              <img src={CommonConfig.timelineBaseUrl + item.image} alt="" />
            </div>
          )}
        </CardContent>
        {footer && <CardFooter>{footer}</CardFooter>}
      </Card>
    </Container>
  );
};
