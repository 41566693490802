import classNames from "classnames";
import React from "react";
import styled from "styled-components";

import { Ranking } from "global/enum";

const Container = styled.div<{ width: string | number; shadow: boolean }>(({ width, shadow }) => ({
  textTransform: "uppercase",
  padding: "4px 12px",
  fontFamily: "serif, Helvetica Neue, cursive",
  fontSize: 14,
  width,
  textAlign: "center",
  fontWeight: 900,
  color: "#fff",

  "&.ranking-premium": {
    color: "rgb(31 54 52)",
    boxShadow: shadow
      ? `0 3px 6px rgb(0 0 0 / 16%), 
                0 3px 6px rgb(110 80 20 / 40%), 
                inset 0 -2px 5px 1px rgb(15 77 60), 
                inset 0 -1px 1px 3px rgb(96 227 199)`
      : undefined,
    background: "linear-gradient(160deg, #075340, #337968, #67d1b7, #38a48b, #0d503f)",
    textShadow: "0 2px 2px rgb(67 212 188)",
  },

  "&.ranking-vip": {
    color: "rgb(120,50,5)",
    boxShadow: shadow
      ? `0 3px 6px rgba(0,0,0,.16), 
                0 3px 6px rgba(110,80,20,.4),
                inset 0 -2px 5px 1px rgba(139,66,8,1),
                inset 0 -1px 1px 3px rgba(250,227,133,1)`
      : undefined,
    background: "linear-gradient(160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07)",
    textShadow: "0 2px 2px rgba(250, 227, 133, 1)",
  },

  "&.ranking-royal": {
    color: "rgb(63 49 10)",
    boxShadow: shadow
      ? `0 3px 6px rgb(0 0 0 / 16%), 
                0 3px 6px rgb(110 80 20 / 40%), 
                inset 0 -2px 5px 1px rgb(30 29 28), 
                inset 0 -1px 1px 3px rgb(201 201 201)`
      : undefined,
    background: "linear-gradient(160deg, #322f2c, #6d6c6a, #b4b4b4, #84827c, #121212)",
    textShadow: "0 2px 2px rgb(248 246 234)",
  },

  "&.ranking-vippp": {
    color: "rgb(59 3 36)",
    boxShadow: shadow
      ? `0 3px 6px rgb(0 0 0 / 16%), 
                0 3px 6px rgb(110 80 20 / 40%), 
                inset 0 -2px 5px 1px rgb(96 8 55), 
                inset 0 -1px 1px 3px rgb(220 113 194)`
      : undefined,
    background: "linear-gradient(160deg, #5a074e, #be0eaa, #ed729c, #982a71, #640e46)",
    textShadow: "0 2px 2px rgb(245 168 214)",
  },
}));

/**
 * RankProps
 */
export interface RankingBarProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {
  /** ranking */
  ranking: Ranking;
  /** label */
  label?: string;
  /** width */
  width?: number | string;
  /** shadow. default = false */
  shadow?: boolean;
}

const RankLabel = {
  [Ranking.premium]: "premium",
  [Ranking.royal]: "royal",
  [Ranking.vippp]: "vippp",
  [Ranking.vip]: "vip",
};

/**
 * RankingBar
 * @param props
 * @param props.ranking
 * @param props.label
 * @param props.width
 * @param props.shadow
 * @param props.className
 * @returns RankingBar
 */
export const RankingBar: React.FC<RankingBarProps> = ({
  ranking,
  label,
  width = 96,
  shadow = false,
  className,
  ...attrs
}) => (
  <Container
    width={width}
    shadow={shadow}
    className={classNames("ranking-bar", className, {
      "ranking-premium": ranking === Ranking.premium,
      "ranking-royal": ranking === Ranking.royal,
      "ranking-vip": ranking === Ranking.vip,
      "ranking-vippp": ranking === Ranking.vippp,
    })}
    {...attrs}
  >
    {label || RankLabel[ranking]}
  </Container>
);
