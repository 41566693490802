import { Page, Navbar, PageContent } from "framework7-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { siteProfileWalletSelector } from "core/slice/site/profile/wallet";
import { getWalletLogOfNextPage, initializeWalletLog } from "core/usecase/site/profile/wallet";

import { ConditionSection } from "ui/component/site/profile/wallet-log/condition-section";
import { WalletLogList } from "ui/component/site/profile/wallet-log/wallet-log-list";

/**
 * WalletLogPage
 * @returns WalletLogPage
 */
export const WalletLogPage: React.FC = () => {
  const { query } = useSelector(siteProfileWalletSelector).walletLog;
  const allowInfinite = useRef(true);
  const [loadMore, setLoadMore] = useState(true);

  useEffect(() => {
    initializeWalletLog(query).then(setLoadMore);
  }, [query]);

  /**
   * handleInfinite
   */
  const handleInfinite = useCallback(() => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    getWalletLogOfNextPage()
      .then(setLoadMore)
      .finally(() => {
        allowInfinite.current = true;
      });
  }, []);

  return (
    <Page pageContent={false}>
      <Navbar backLink backLinkUrl="/profile" backLinkForce title="余额变动明细" />
      <PageContent infinite={loadMore} onInfinite={handleInfinite}>
        <ConditionSection />
        <WalletLogList />
      </PageContent>
    </Page>
  );
};
