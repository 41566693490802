import { dispatch, store } from "store";

import { BankResponse, SaveBankRequest, SaveBankResponse } from "core/model/girl/bank";
import { bankAction } from "core/slice/girl/bank";
import { showPageSpinner, hidePageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";

/**
 * 口座情報取得
 */
export const bankInitialize = async () => {
  if (store.getState().bank.data) return;
  try {
    showPageSpinner();
    const res = await Api.get<BankResponse>("/user/bank/l");
    if (isSuccess(res)) {
      // store更新
      dispatch(bankAction.updateBankAction(res.body!));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};

/**
 * 口座情報保存
 * @param data
 */
export const saveBank = async (data: SaveBankRequest) => {
  try {
    showPageSpinner();
    const res = await Api.post<SaveBankResponse>("/user/bank/s", data);
    if (isSuccess(res) && res.body) {
      // store更新
      dispatch(bankAction.updateBankAction(res.body));
      return true;
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};
