import React, { ComponentProps, MouseEventHandler, ReactElement, ReactNode } from "react";
import styled from "styled-components";

import { Icon } from "ui/widget/icon";

type ConditionItemProps = {
  icon: ReactElement<ComponentProps<typeof Icon>>;
  label: string;
  title: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const Container = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: "center",
  cursor: "pointer",
  padding: "6px 10px",
  background: "linear-gradient(45deg, #111, #222, #333, #000)",
  border: "1px solid transparent",
  transition: "all .2s",
  boxShadow: "2px 2px 2px #000, -1px -1px 1px #555",
  textAlign: "left",

  ".condition-item-media": {
    marginRight: 8,
  },

  ".condition-item-inner": {
    lineHeight: 1.4,
    width: "100%",

    ".condition-item-label": {
      fontSize: 12,
      color: "#fff",
    },

    ".condition-item-title": {
      width: "100%",
      fontSize: 14,
      color: "var(--f7-theme-color-text-color)",
      fontWeight: "bolder",
    },
  },

  "&:hover": {
    opacity: 0.75,
    borderColor: "orange",
  },
});

/**
 * ConditionItem
 * @param props
 * @param props.icon
 * @param props.label
 * @param props.title
 * @param props.onClick
 * @returns ConditionItem
 */
export const ConditionItem: React.FC<ConditionItemProps> = ({ icon, label, title, onClick }) => (
  <Container onClick={onClick}>
    <div className="condition-item-media">{icon}</div>
    <div className="condition-item-inner">
      <div className="condition-item-label">{label}</div>
      <div className="condition-item-title">{title}</div>
    </div>
  </Container>
);
