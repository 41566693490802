import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSocket, SocketEvents } from "socket";

import { OnlineStatusResponse } from "core/model/user";
import { getUserOnlineStatus } from "core/usecase/user";
import { toast } from "core/utils/common";

import { OnlineStatus } from "global/enum";

/**
 * useOnlineStatus
 * @param userId
 * @returns useOnlineStatus
 */
export const useOnlineStatus = (userId: string | undefined) => {
  const socket = useSocket();
  const { formatMessage } = useIntl();
  const [userOnlineStatus, setUserOnlineStatus] = useState<OnlineStatusResponse>();

  /**
   * toggleConnectHandler
   */
  const toggleConnectHandler = useCallback(
    (data: OnlineStatusResponse) => {
      if (userId !== data.userId) return;
      // 通知
      toast(
        data.online === OnlineStatus.online
          ? formatMessage({ id: "client.status.connected" })
          : formatMessage({ id: "client.status.disconnected" }),
      );

      // ステータス更新
      setUserOnlineStatus(data);
    },
    [formatMessage, userId],
  );

  /**
   * 初期化
   */
  useEffect(() => {
    // クライアント情報取得
    if (userId) getUserOnlineStatus(userId).then(setUserOnlineStatus);
  }, [userId]);

  /**
   * socket event
   */
  useEffect(() => {
    // userConnect
    socket?.on(SocketEvents.userConnect, (data: OnlineStatusResponse) => toggleConnectHandler(data));
    // userDisconnect
    socket?.on(SocketEvents.userDisconnect, (data: OnlineStatusResponse) => toggleConnectHandler(data));

    return () => {
      socket?.off(SocketEvents.userConnect);
      socket?.off(SocketEvents.userDisconnect);
    };
  }, [socket, toggleConnectHandler]);

  return userOnlineStatus;
};
