const Config = {
  common: {
    // api base url
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL!,
    // socket server
    socketServer: process.env.REACT_APP_SOCKET_SERVER!,
    // socket reconnection attemps
    socketReconnectionAttemps: Number(process.env.REACT_APP_SOCKET_RECONNECTION_ATTEMPS),
    // cookie name of access token
    accessTokenName: process.env.REACT_APP_ACCESS_TOKEN_NAME!,
    // cookie name of refresh token
    refreshTokenName: process.env.REACT_APP_REFRESH_TOKEN_NAME!,
    // Token有効期限
    tokenExpire: Number(process.env.REACT_APP_TOKEN_EXPIRATION_DAY),
    // 写真・ビデオbase url
    mediaBaseUrl: `${process.env.REACT_APP_API_BASE_URL!}resource/media/`,
    // サムネイルbase url
    thumbnailBaseUrl: `${process.env.REACT_APP_API_BASE_URL!}resource/thumbnail/`,
    // アバターbase url
    avatarBaseUrl: `${process.env.REACT_APP_API_BASE_URL!}resource/avatar/`,
    // timeline base url
    timelineBaseUrl: `${process.env.REACT_APP_API_BASE_URL!}resource/timeline/`,
    // charge base url
    chargeBaseUrl: `${process.env.REACT_APP_API_BASE_URL!}resource/charge/`,
    // talk media base url
    talkMediaBaseUrl: `${process.env.REACT_APP_API_BASE_URL!}resource/talk/media/`,
    // talk thumbnail base url
    talkThumbnailBaseUrl: `${process.env.REACT_APP_API_BASE_URL!}resource/talk/thumbnail/`,
    // 店舗写真base url
    shopPhotoBaseUrl: `${process.env.REACT_APP_API_BASE_URL!}resource/system/shop/`,
    // プレゼント base url
    presentBaseUrl: `${process.env.REACT_APP_API_BASE_URL!}resource/present/`,
    // アップローダファイルサイズ上限
    uploadMaxFileSize: Number(process.env.REACT_APP_UPLOAD_MAX_FILE_SIZE_MB),
    // ページサイズ
    apiPageSize: Number(process.env.REACT_APP_API_PAGE_SIZE),
  },
  site: {
    // timelineページサイズ
    timelinePageSize: Number(process.env.REACT_APP_TIMELINE_PAGE_SIZE),
    // 取引履歴ページサイズ
    walletLogPageSize: Number(process.env.REACT_APP_WALLET_LOG_PAGE_SIZE),
    // チャージ履歴ページサイズ
    chargeLogPageSize: Number(process.env.REACT_APP_CHARGE_LOG_PAGE_SIZE),
  },
  talk: {
    // トークメッセージページサイズ
    talkMessagePageSize: Number(process.env.REACT_APP_TALK_MESSAGE_PAGE_SIZE),
  },
};

export default Config;
export const CommonConfig = Config.common;
export const SiteConfig = Config.site;
export const TalkConfig = Config.talk;
