import { List, ListItem, SkeletonBlock } from "framework7-react";
import React from "react";

/**
 * TalkListSkeleton
 * @returns TalkListSkeleton
 */
export const TalkListSkeleton: React.FC = () => (
  <List mediaList>
    {Array.from(Array(12).keys()).map(index => (
      <ListItem key={index}>
        <div slot="media">
          <SkeletonBlock tag="div" width="45px" height="45px" borderRadius="50%" effect="wave" />
        </div>
        <div slot="title">
          <SkeletonBlock
            tag="div"
            width="120px"
            height="24px"
            borderRadius="0"
            effect="wave"
            style={{ marginBottom: 4 }}
          />
        </div>
        <div slot="subtitle">
          <SkeletonBlock tag="div" width="100%" height="21px" borderRadius="0" effect="wave" />
        </div>
      </ListItem>
    ))}
  </List>
);
