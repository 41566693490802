import { Router } from "framework7/modules/router/router";

import { AccessDeniedPage } from "ui/page/common/access-denied";
import { NotFoundPage } from "ui/page/common/not-found";
import { SiteRoot } from "ui/page/site";
import { ForgotPasswordPage } from "ui/page/site/forgot-password";
import { PasswordResetPage } from "ui/page/site/forgot-password/password-reset";
import { PasswordResetCompletePage } from "ui/page/site/forgot-password/password-reset-complete";
import { SendCompletePage } from "ui/page/site/forgot-password/send-complete";
import { UrlExpiredPage } from "ui/page/site/forgot-password/url-expired";
import { LoginPage } from "ui/page/site/login";
import { OrderPage } from "ui/page/site/order";
import { CastSelectPage } from "ui/page/site/order/cast-select";
import { OrderHistoryPage } from "ui/page/site/order/order-history";
import { ReserveCompletePage } from "ui/page/site/order/reserve-complete";
import { ReserveConfirmPage } from "ui/page/site/order/reserve-confirm";
import { PasswordChangePage } from "ui/page/site/password-change";
import { PresentLogPage } from "ui/page/site/present/present-log";
import { ProfilePage } from "ui/page/site/profile";
import { ProfileAvatarEditorPage } from "ui/page/site/profile/avatar-editor";
import { ProfileBaseEditorPage } from "ui/page/site/profile/base-editor";
import { AddressEditorPage } from "ui/page/site/profile/base-editor/editor/address";
import { HeightEditorPage } from "ui/page/site/profile/base-editor/editor/height";
import { NickNameEditorPage } from "ui/page/site/profile/base-editor/editor/nick-name";
import { NoteEditorPage } from "ui/page/site/profile/base-editor/editor/note";
import { TitleEditorPage } from "ui/page/site/profile/base-editor/editor/title";
import { ChargeLogPage } from "ui/page/site/profile/charge-log";
import { WalletLogPage } from "ui/page/site/profile/wallet-log";
import { ShopPage } from "ui/page/site/shop";
import { ShopDetailPage } from "ui/page/site/shop/shop-detail";
import { SignupPage } from "ui/page/site/signup";
import { SignupCompletePage } from "ui/page/site/signup/complete";
import { SignupConfirmPage } from "ui/page/site/signup/confirm";
import { SignupVerifyPage } from "ui/page/site/signup/verify";
import { TalkPage } from "ui/page/site/talk";
import { TalkMessagePage } from "ui/page/site/talk/talk-message";
import { TimelinePage } from "ui/page/site/timeline";
import { TimelineUserPage } from "ui/page/site/timeline/timeline-user";
import { TopPage } from "ui/page/site/top";
import { GirlDetailPage } from "ui/page/site/top/girl-detail";

export const siteRoutes: Router.RouteParameters[] = [
  {
    path: "/",
    component: SiteRoot,
    tabs: [
      {
        path: "/top",
        id: "tab-top",
        component: TopPage,
      },
      {
        path: "/talk",
        id: "tab-talk",
        component: TalkPage,
      },
      {
        path: "/order",
        id: "tab-order",
        component: OrderPage,
      },
      {
        path: "/timeline",
        id: "tab-timeline",
        component: TimelinePage,
      },
      {
        path: "/shop",
        id: "tab-shop",
        component: ShopPage,
      },
      {
        path: "/profile",
        id: "tab-profile",
        component: ProfilePage,
      },
    ],
  },

  // girl detail
  {
    path: "/detail/:id",
    component: GirlDetailPage,
  },

  // talk
  {
    path: "/talk/:id",
    component: TalkMessagePage,
  },

  // timeline
  {
    path: "/timeline/my",
    component: TimelineUserPage,
  },
  {
    path: "/timeline/user/:userId",
    component: TimelineUserPage,
  },

  // order
  {
    path: "/order/history",
    component: OrderHistoryPage,
  },
  {
    path: "/order/cast-select",
    component: CastSelectPage,
  },
  {
    path: "/order/reserve-confirm",
    component: ReserveConfirmPage,
  },
  {
    path: "/order/reserve-complete",
    component: ReserveCompletePage,
  },

  // shop detail
  {
    path: "/shop/:id",
    component: ShopDetailPage,
  },

  // profile
  {
    path: "/profile/avatar",
    component: ProfileAvatarEditorPage,
  },
  {
    path: "/profile/base",
    component: ProfileBaseEditorPage,
    routes: [
      // ニックネーム
      {
        path: "/nick-name",
        component: NickNameEditorPage,
      },
      // タイトル
      {
        path: "/title",
        component: TitleEditorPage,
      },
      // 自己紹介
      {
        path: "/note",
        component: NoteEditorPage,
      },
      // 身長
      {
        path: "/height",
        component: HeightEditorPage,
      },
      // 所在地
      {
        path: "/address",
        component: AddressEditorPage,
      },
    ],
  },

  // 取引履歴
  {
    path: "/profile/wallet-log",
    component: WalletLogPage,
  },

  // チャージ履歴
  {
    path: "/profile/charge-log",
    component: ChargeLogPage,
  },

  // プレゼント履歴
  {
    path: "/present-log",
    component: PresentLogPage,
  },

  // ログイン
  {
    path: "/login",
    component: LoginPage,
  },

  // アカウント作成
  {
    path: "/signup",
    component: SignupPage,
    routes: [
      {
        path: "/confirm",
        component: SignupConfirmPage,
      },
      {
        path: "/complete",
        component: SignupCompletePage,
      },
      {
        path: "/verify",
        async({ resolve, to }) {
          // code必須
          if (to.query.code) {
            resolve({ component: SignupVerifyPage });
          } else {
            resolve({ component: NotFoundPage });
          }
        },
      },
    ],
  },

  // パスワードお忘れ
  {
    path: "/forgot-password",
    component: ForgotPasswordPage,
    routes: [
      {
        path: "/send-complete",
        component: SendCompletePage,
      },
      {
        path: "/reset",
        async({ resolve, to }) {
          // code必須
          if (to.query.code) {
            resolve({ component: PasswordResetPage });
          } else {
            resolve({ component: NotFoundPage });
          }
        },
      },
      {
        path: "/url-expired",
        component: UrlExpiredPage,
      },
      {
        path: "/reset-complete",
        component: PasswordResetCompletePage,
      },
    ],
  },

  // パスワード変更
  {
    path: "/password-change",
    component: PasswordChangePage,
  },

  // アクセス権限なし画面
  {
    path: "/access-denied",
    component: AccessDeniedPage,
  },
];
