import { dispatch, store } from "store";

import { FollowFavoriteResponse } from "core/model/girl/follow";
import { followAction } from "core/slice/girl/follow";
import Api, { isSuccess } from "core/utils/api";

import { hidePageSpinner, showPageSpinner } from "../../global";

/**
 * フォロー、フォロワー、いいねの総件数取得
 */
export const followFavoriteInitialize = async () => {
  if (store.getState().follow.followFavorite) return;
  try {
    showPageSpinner();
    const res = await Api.get<FollowFavoriteResponse>("/follow/follow-favorite/l");
    if (isSuccess(res) && res.body) {
      // store更新
      dispatch(followAction.updateFollowFavoriteAction(res.body));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};
