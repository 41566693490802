import { f7 } from "framework7-react";
import { Router } from "framework7/modules/router/router";
import { useRef } from "react";
import { useEffectOnce, useUpdate } from "react-use";

/**
 * PropsWithF7Router
 */
export type PropsWithF7Router<P = unknown> = P & {
  /** f7route */
  f7route: Router.Route;
  /** f7router */
  f7router: Router.Router;
};

/**
 * useRoute
 * @returns currentRoute
 */
export const useRoute = () => {
  const { router } = f7.view.main;
  const routeRef = useRef<Router.Route>(router.currentRoute);
  const update = useUpdate();

  useEffectOnce(() => {
    setTimeout(() => {
      routeRef.current = router.currentRoute;
      update();
    });
    router.on("routeChange", to => {
      routeRef.current = to;
      update();
    });
  });

  return routeRef.current;
};
