import dayjs from "dayjs";
import { f7, Message, Messages, MessagesTitle } from "framework7-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { InitMessageResponse, MessageItem } from "core/model/talk";
import { authorizationSelector } from "core/slice/authorization";
import { formattedMessageDate, updateLastViewMessageId } from "core/usecase/talk";

import { CommonConfig } from "global/config";
import { TalkMessageType } from "global/enum";

import { Avatar } from "ui/widget/avatar";
import { Icon } from "ui/widget/icon";

type MessageListProps = {
  data: InitMessageResponse;
};

const MediaViewer = styled.div({
  position: "relative",
  cursor: "pointer",
  transition: "all .2s",

  ".video-player": {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0, 0, 0, .375)",
    color: "#fff",
  },

  "&:hover": {
    opacity: ".75",
  },
});

type MedifiedMessageItem =
  | {
      isMessageTitle: true;
      messageTitle: string;
      messageId: string;
    }
  | ({
      isMessageTitle: false;
      isFirst: boolean;
      isLast: boolean;
      isTail: boolean;
      formattedSendDtm: string;
    } & MessageItem);

/**
 * トークメッセージ一覧
 * @param props
 * @param props.data
 * @returns MessageList
 */
export const MessageList: React.FC<MessageListProps> = ({ data }) => {
  const { principal } = useSelector(authorizationSelector);
  // 最終閲覧メッセージID
  const [lastViewMessageId] = useState<string>(data.lastViewMessageId);

  /**
   * 最終閲覧メッセージIDの更新
   */
  useEffect(() => {
    const lastIndex = data.messageList.length - 1;
    if (lastIndex === -1) return;
    // 最終閲覧メッセージIDを変更する場合、更新
    const lastMessageId = data.messageList[lastIndex].messageId;
    if (lastMessageId !== data.lastViewMessageId) {
      updateLastViewMessageId({
        talkId: data.talkId,
        messageId: lastMessageId,
      });
    }
  }, [data.lastViewMessageId, data.messageList, data.talkId]);

  /**
   * handleMediaClick
   */
  const handleMediaClick = useCallback((item: MessageItem) => {
    const photos = [];
    if (item.messageType === TalkMessageType.video) {
      photos.push(`
        <div style="width: 100%; height: 100%; display: flex; align-items: center; z-index: 2">
          <video
            controls="true" width="100%" style="max-height: calc(100% - 100px)" 
            src="${CommonConfig.talkMediaBaseUrl + item.message}" 
          />
        </div>
        <img src="${CommonConfig.talkThumbnailBaseUrl + item.thumbnail}" style="position: absolute; z-index: 1" />
      `);
    } else {
      photos.push(CommonConfig.talkMediaBaseUrl + item.message);
    }

    f7.photoBrowser
      .create({
        photos,
        type: "popup",
        theme: "dark",
        toolbar: false,
        swiper: {
          cssMode: false,
        },
      })
      .open();
  }, []);

  /**
   * isFirst
   * @param current
   * @param prev
   * @returns result
   */
  const isFirst = useCallback((current: MessageItem, prev: MessageItem | undefined) => {
    if (current.senderId !== prev?.senderId) return true;
    // 日付チェック: 5分以上であれば、true
    return dayjs(current.sendDtm).diff(prev.sendDtm, "minute") > 5;
  }, []);

  /**
   * isLast
   * @param current
   * @param prev
   * @returns result
   */
  const isLast = useCallback((current: MessageItem, next: MessageItem | undefined) => {
    if (current.senderId !== next?.senderId) return true;
    // 日付チェック: 5分以上であれば、true
    return dayjs(next.sendDtm).diff(current.sendDtm, "minute") > 5;
  }, []);

  /**
   * modifiedData
   */
  const modifiedMessageList = useMemo(() => {
    const result: MedifiedMessageItem[] = [];
    let messageTitle = "";
    data.messageList.forEach((o, index) => {
      const currentMessageTitle = formattedMessageDate(o.sendDtm)!;
      // メッセージタイトル
      if (!messageTitle || messageTitle !== currentMessageTitle) {
        result.push({
          isMessageTitle: true,
          messageTitle: currentMessageTitle,
          messageId: `${o.messageId}_title`,
        });
      }
      messageTitle = currentMessageTitle;
      // メッセージ
      const prev = data.messageList[index - 1];
      const next = data.messageList[index + 1];
      const isLastMessage = isLast(o, next);
      result.push({
        ...o,
        isMessageTitle: false,
        isFirst: isFirst(o, prev),
        isLast: isLastMessage,
        isTail: isLastMessage,
        formattedSendDtm: dayjs(o.sendDtm).format("HH:mm"),
      });
    });
    return result;
  }, [data.messageList, isFirst, isLast]);

  return (
    <>
      <Messages>
        {/* メッセージ一覧 */}
        {modifiedMessageList.map((o, index) => (
          <React.Fragment key={o.messageId}>
            {o.isMessageTitle ? (
              <MessagesTitle>{o.messageTitle}</MessagesTitle>
            ) : (
              <Message
                name={o.formattedSendDtm}
                type={o.senderId === principal?.id ? "sent" : "received"}
                first={o.isFirst}
                last={o.isLast}
                tail={o.isTail}
              >
                <Avatar
                  src={o.senderId === principal?.id ? principal.avatar : data.talkUserAvatar}
                  shape="circle"
                  slot="avatar"
                  style={{ width: "100%", height: "100%" }}
                />
                {o.messageType === TalkMessageType.text && (
                  <span slot="text" style={{ whiteSpace: "pre-line" }}>
                    {o.message}
                  </span>
                )}
                {[TalkMessageType.image, TalkMessageType.video].includes(o.messageType) && (
                  <MediaViewer slot="image" onClick={() => handleMediaClick(o)}>
                    <img src={CommonConfig.talkThumbnailBaseUrl + o.thumbnail} alt="" />
                    {o.messageType === TalkMessageType.video && (
                      <div className="video-player">
                        <Icon name="play_circle" shape="outlined" size={60} />
                      </div>
                    )}
                  </MediaViewer>
                )}
              </Message>
            )}

            {/* 既読メッセージマーク */}
            {lastViewMessageId === o.messageId && data.messageList[index + 1] && (
              <MessagesTitle className="readed-message-title">
                <FormattedMessage id="talk.readed.message.title" />
              </MessagesTitle>
            )}
          </React.Fragment>
        ))}
      </Messages>
    </>
  );
};
