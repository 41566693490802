import { Page } from "framework7-react";
import React, { useCallback } from "react";
import { PropsWithF7Router } from "routes";

import { SignupRequest } from "core/model/signup";
import * as usecase from "core/usecase/signup";

import { Role } from "global/enum";

import { SignupConfirmBlock } from "ui/component/signup/confirm-block";

/**
 * SignupConfirmProps
 */
type SignupConfirmProps = PropsWithF7Router<{
  /** メールアドレス */
  email: string;
  /** パスワード */
  password: string;
}>;

/**
 * SignupConfirm
 * @param props
 * @param props.f7router
 * @param props.email
 * @param props.password
 * @returns SignupConfirm
 */
export const SignupConfirmPage: React.FC<SignupConfirmProps> = ({ f7router, email, password }) => {
  /**
   * onPageBeforeIn
   */
  const onPageBeforeIn = () => {
    if (!email)
      f7router.navigate("/404", {
        clearPreviousHistory: true,
      });
  };

  /**
   * signup処理
   */
  const handleSignup = useCallback(
    (values: SignupRequest) => {
      usecase.registUser(values, Role.girl).then(success => {
        if (success) {
          f7router.navigate("/girl/signup/complete", {
            transition: "f7-push",
            props: {
              email: values.email,
            },
          });
        }
      });
    },
    [f7router],
  );

  return (
    <Page loginScreen onPageBeforeIn={onPageBeforeIn}>
      <SignupConfirmBlock email={email} password={password} handleSignup={handleSignup} signupLink="/girl/signup" />
    </Page>
  );
};
