import React, { CSSProperties } from "react";
import styled from "styled-components";

type Options = {
  /** font color: default = orange */
  color: CSSProperties["color"] | "gold";
  /** font-size: default = 24  */
  size: number | string;
  /** bold */
  bold: boolean;
};

const Container = styled.span<Options>(({ color, size, bold }) => ({
  color: color === "gold" ? "var(--f7-theme-color-text-color)" : color,
  fontSize: size,
  fontWeight: bold ? "bold" : "normal",
  background:
    color === "gold"
      ? "linear-gradient(to bottom, #cfc09f 22%, #634f2c 24%, #cfc09f 26%, #cfc09f 27%, #dfb020 40%, #704d03 78%)"
      : undefined,
  backgroundClip: color === "gold" ? "text" : undefined,
  WebkitBackgroundClip: color === "gold" ? "text" : undefined,
  WebkitTextFillColor: color === "gold" ? "transparent" : undefined,
}));

/**
 * StyledTextProps
 */
export interface StyledTextProps extends Partial<Options>, Omit<React.HTMLAttributes<HTMLSpanElement>, "color"> {}

/**
 * StyledText
 * @param props
 * @param props.color
 * @param props.size
 * @param props.bold
 * @param props.children
 * @returns StyledText
 */
export const StyledText: React.FC<StyledTextProps> = ({
  color = "orange",
  size = 20,
  bold = true,
  children,
  ...attrs
}) => (
  <Container color={color} size={size} bold={bold} {...attrs}>
    {children}
  </Container>
);
