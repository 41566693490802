import { Block, BlockTitle, Button } from "framework7-react";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { GirlListResponse } from "core/model/site/top";
import { siteOrderSelector } from "core/slice/site/order";
import { toggleSelectCast } from "core/usecase/site/order";

import { CastItem } from "ui/widget/cast-item";
import { Gallery, GalleryItem } from "ui/widget/gallery";
import { Icon } from "ui/widget/icon";
import { RankingBar } from "ui/widget/ranking-bar";

const SelectContainer = styled.div({
  ".cast-item": {
    position: "relative",
    paddingBottom: 40,

    ".cast-selected-mask": {
      position: "absolute",
      top: 0,
      left: 0,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, .7)",
      zIndex: 110,
      fontWeight: "bolder",
      fontSize: 18,
      color: "#fff",
    },

    ".button": {
      width: "80%",
      margin: "8px auto",
      position: "absolute",
      bottom: 0,
      left: 16,
    },
  },
});

type CastSelectProps = {
  castList: GirlListResponse;
};

/**
 * CastSelect
 * @param props
 * @param props.castList
 * @returns CastSelect
 */
export const CastSelect: React.FC<CastSelectProps> = ({ castList }) => {
  const { casts } = useSelector(siteOrderSelector).reserve;

  return (
    <>
      <BlockTitle medium>请选择希望预约的女孩</BlockTitle>
      {casts.map(o => (
        <Block key={o.ranking} strong>
          <BlockTitle medium className="text-align-middle">
            <RankingBar ranking={o.ranking} shadow />
            <div style={{ marginLeft: 16, color: "#777" }}>
              (<span>{o.selected?.length ?? 0}</span>&nbsp;/&nbsp;
              <span>{o.peopleNumber}</span>人)
            </div>
          </BlockTitle>
          <SelectContainer>
            <Gallery gap={6}>
              {castList
                .filter(item => item.ranking === o.ranking)
                .map(item => (
                  <GalleryItem key={item.userId}>
                    <CastItem item={item} showTitle={false}>
                      <Button
                        fill
                        color="orange"
                        small
                        onClick={() => toggleSelectCast(item)}
                        disabled={o.selected?.length === o.peopleNumber}
                      >
                        选择
                      </Button>
                      {o.selected?.find(o => o.userId === item.userId) && (
                        <div className="cast-selected-mask">
                          <span>选择中</span>
                          <Button fill color="orange" small onClick={() => toggleSelectCast(item)}>
                            选择取消
                          </Button>
                        </div>
                      )}
                    </CastItem>
                  </GalleryItem>
                ))}
              {castList.filter(item => item.ranking === o.ranking).length === 0 && (
                <div className="text-align-middle text-color-warning">
                  <Icon name="warning" />
                  <strong style={{ marginLeft: 8 }}>未找到出勤女孩, 请重新选择</strong>
                </div>
              )}
            </Gallery>
          </SelectContainer>
        </Block>
      ))}
      <br />
      <br />
    </>
  );
};
