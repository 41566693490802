import { dispatch, store } from "store";

import { ShopListResponse } from "core/model/site/shop";
import { siteShopAction } from "core/slice/site/shop";
import { hidePageSpinner, showPageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";

/**
 * 店舗一覧取得
 */
export const shopListInitialize = async () => {
  if (store.getState()["site/shop"].list.initialized) return;

  showPageSpinner();
  try {
    const res = await Api.get<ShopListResponse>("/site/shop/list");
    if (isSuccess(res) && res.body) {
      // storeに保存する
      dispatch(siteShopAction.initializeShopListAction(res.body));
      return;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};
