import { useFormik } from "formik";
import { Block, BlockTitle, Button, Col, ListItem, Row } from "framework7-react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useSwiper, useSwiperSlide } from "swiper/react";
import * as yup from "yup";

import { siteProfileChargeSelector } from "core/slice/site/profile/charge";
import { showPageSpinner, hidePageSpinner } from "core/usecase/global";
import { createChargeOrder } from "core/usecase/site/profile/charge";
import { showErrorModal } from "core/utils/common";
import { base64ToFile, blobToBase64, compress, heicToJpg } from "core/utils/file";

import { FormikList, FormikListInput } from "ui/widget/formik";
import { Icon } from "ui/widget/icon";
import { IconButton } from "ui/widget/icon-button";
import { UploadContainer } from "ui/widget/upload/upload-container";

const ImageViewer = styled.div({
  width: 160,
  borderRadius: 4,
  overflow: "hidden",
  position: "relative",

  ".icon-button": {
    position: "absolute",
    top: 1,
    right: 1,
    padding: 0,
    background: "red",
    color: "#fff",
    borderRadius: "50%",
    width: 22,
    height: 22,

    ".icon": {
      fontSize: 18,
    },
  },
});

type Shape = { payMoney: string };

/**
 * ChargeStep3
 * @returns ChargeStep3
 */
export const ChargeStep3: React.FC = () => {
  const swiper = useSwiper();
  const { isActive } = useSwiperSlide();
  const [image, setImage] = useState<{ name: string; src: string }>();
  const uploadContainerRef = useRef<HTMLDivElement>(null);
  const { chargeMInfo } = useSelector(siteProfileChargeSelector);

  const formik = useFormik<Shape>({
    initialValues: {
      payMoney: "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      payMoney: yup.string().number().min(0).required(),
    }),
    onSubmit({ payMoney }) {
      if (!chargeMInfo || !image) return;
      const chargeImg = base64ToFile(image.src, image.name);
      if (!chargeImg) return;
      createChargeOrder(chargeMInfo.chargeType, payMoney, chargeImg).then(success => {
        if (success) {
          swiper.slideNext();
        }
      });
    },
  });
  const { setFieldError, setFieldValue } = formik;

  useEffect(() => {
    if (isActive) {
      setImage(undefined);
      setFieldValue("payMoney", "");
      setFieldError("payMoney", undefined);
    }
  }, [isActive, setFieldError, setFieldValue]);

  /**
   * handleFileChange
   * @param event
   */
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      setImage(undefined);
      return;
    }
    try {
      showPageSpinner(uploadContainerRef.current);
      const file = await compress(await heicToJpg(event.target.files[0]));
      // zoomTo
      const imageSrc = await blobToBase64(file);
      // image
      setImage({ name: file.name, src: imageSrc });
    } catch {
      setImage(undefined);
      showErrorModal("图片格式不正确");
    } finally {
      hidePageSpinner(uploadContainerRef.current);
    }
  };

  return (
    <>
      <BlockTitle medium>转账凭证上传</BlockTitle>
      <FormikList formik={formik} inlineLabels inset>
        <FormikListInput type="text" name="payMoney" label="充值金额" />
        <ListItem>
          <div className="item-title item-label">转账凭证上传</div>
          <div style={{ marginRight: "auto" }} ref={uploadContainerRef}>
            {image ? (
              <ImageViewer>
                <img src={image.src} alt="" width="100%" />
                <IconButton icon={<Icon name="clear" />} onClick={() => setImage(undefined)} />
              </ImageViewer>
            ) : (
              <UploadContainer
                icon={<Icon name="photo_camera" shape="outlined" size={64} />}
                shape="rounded"
                width={160}
                height={200}
                accept="image/*,.heic,.heif"
                onFileChange={handleFileChange}
              />
            )}
          </div>
        </ListItem>
      </FormikList>
      <Block>
        <Row>
          <Col width={50}>
            <Button type="button" color="gray" fill onClick={() => swiper.slidePrev()}>
              上一步
            </Button>
          </Col>
          <Col width={50}>
            <Button type="button" color="green" fill onClick={formik.submitForm}>
              下一步
            </Button>
          </Col>
        </Row>
      </Block>
    </>
  );
};
