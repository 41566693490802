import { f7, f7ready, Link, Messagebar } from "framework7-react";
import { Messagebar as MessagebarType } from "framework7/components/messagebar";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSocket } from "socket";
import styled from "styled-components";

import { sendMedia, sendMessage } from "core/usecase/talk";
import { showErrorModal } from "core/utils/common";

import { TalkMessageType } from "global/enum";

import { Icon } from "ui/widget/icon";

const CameraContainer = styled.div({
  position: "relative",
  display: "block",
  marginRight: 8,

  "& > input": {
    opacity: 0,
    cursor: "pointer",
    display: "block",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
  },
});

type MessageBarProps = {
  talkId: string;
};

/**
 * MessageBar
 * @param root0
 * @param root0.talkId
 * @returns MessageBar
 */
export const MessageBar: React.FC<MessageBarProps> = ({ talkId }) => {
  const [messageText, setMessageText] = useState("");
  const { formatMessage } = useIntl();
  // messagebar
  const messagebar = useRef<MessagebarType.Messagebar>();
  // socket
  const socket = useSocket();

  useEffect(() => {
    f7ready(() => {
      messagebar.current = f7.messagebar.get(".messagebar");
    });
  });

  /**
   * 画像・ビデオの送信処理
   */
  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        sendMedia(talkId, event.target.files[0], socket);
      }
    },
    [socket, talkId],
  );

  /**
   * 送信処理
   */
  const handleSendMessageBtnClick = useCallback(() => {
    const message = messageText.trim();
    if (!message) {
      showErrorModal(formatMessage({ id: "talk.message.not.empty" }));
      return;
    }
    // 送信処理
    sendMessage({ message, messageType: TalkMessageType.text, talkId }, socket).finally(() => {
      messagebar.current?.focus();
    });
    // メッセージをクリアする
    setMessageText("");
  }, [formatMessage, messageText, socket, talkId]);

  return (
    <Messagebar
      value={messageText}
      attachmentsVisible={false}
      sheetVisible={false}
      onInput={e => setMessageText(e.target.value)}
    >
      <CameraContainer slot="inner-start" className="link">
        <Icon name="camera_alt" color="primary" size={26} style={{ marginTop: 4 }} />
        <input type="file" name="file" accept="image/*,.heic,.heif,video/*" onChange={handleFileChange} />
        <div className="input" />
      </CameraContainer>

      <Link href={false} slot="inner-end" onClick={handleSendMessageBtnClick}>
        <Icon name="send" color="red" size={26} />
      </Link>
    </Messagebar>
  );
};
