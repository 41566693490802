import { Block, BlockTitle, Button, Segmented } from "framework7-react";
import React from "react";
import { useSelector } from "react-redux";

import { siteProfileWalletSelector } from "core/slice/site/profile/wallet";
import { updateQueryCondition } from "core/usecase/site/profile/wallet";

import { WalletTargetTypeLabel } from "global/enum-label/site";
import { WalletTargetType } from "global/enum/profile-site";

/**
 * ウォレット取引履歴の検索条件
 * @returns ConditionSection
 */
export const ConditionSection: React.FC = () => {
  const { query } = useSelector(siteProfileWalletSelector).walletLog;

  /**
   * handleClick
   * @param targetType
   */
  const handleClick = (targetType: WalletTargetType | "ALL") => {
    if (query.targetType === targetType) return;
    // 検索条件更新
    updateQueryCondition({ targetType });
  };

  return (
    <Block>
      <BlockTitle className="no-margin">业务种类</BlockTitle>
      <Segmented strong tag="p">
        <Button type="button" active={query.targetType === "ALL"} onClick={() => handleClick("ALL")}>
          全部
        </Button>
        {Object.entries(WalletTargetTypeLabel).map(([value, text]) => (
          <Button
            key={value}
            type="button"
            active={query.targetType === value}
            onClick={() => handleClick(value as WalletTargetType)}
          >
            {text}
          </Button>
        ))}
      </Segmented>
    </Block>
  );
};
