import React from "react";
import { useSelector } from "react-redux";

import { profileSelector } from "core/slice/girl/profile";
import { updateProfile } from "core/usecase/girl/profile";

import { BloodLabel } from "global/enum-label/girl";
import { Blood } from "global/enum/profile-girl";

import { Picker, PickerColumn } from "ui/widget/picker";

import { SelectorsProps } from ".";

const cols: PickerColumn[] = [
  {
    textAlign: "center",
    values: Object.keys(BloodLabel),
    displayValues: Object.values(BloodLabel),
  },
];

/**
 * 血液型選択用
 */
export const BloodSelector = React.memo(({ visible, onClose }: SelectorsProps) => {
  const { bloodType } = useSelector(profileSelector);

  /**
   * handleConfirm
   * @param value
   */
  const handleConfirm = (value: string[]) => {
    updateProfile("/profile/blood-type/s", { bloodType: value[0] as Blood });
    onClose();
  };

  return (
    <Picker
      cols={cols}
      value={[bloodType as string]}
      onConfirm={handleConfirm}
      opened={visible}
      onPickerClosed={onClose}
    />
  );
});
