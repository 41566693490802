import { Ranking } from "global/enum";
import { Blood, DepositType, Sex } from "global/enum/profile-girl";

/**
 * 性別
 */
export const SexLabel = {
  [Sex.hide]: "非表示",
  [Sex.male]: "男",
  [Sex.female]: "女",
  [Sex.neuter]: "中性",
};

/** 血液型 */
export const BloodLabel = {
  [Blood.hide]: "非表示",
  [Blood.A]: "A",
  [Blood.B]: "B",
  [Blood.O]: "O",
  [Blood.AB]: "AB",
};

/** クラス */
export const RankingLabel = {
  [Ranking.premium]: "PREMIUM",
  [Ranking.vip]: "VIP",
  [Ranking.royal]: "ROYAL",
  [Ranking.vippp]: "VIPPP",
};

/** 預金種類 */
export const DepositTypeLabel = {
  [DepositType.usually]: "普通",
  [DepositType.current]: "当座",
  [DepositType.regular]: "定期",
  [DepositType.other]: "その他",
};
