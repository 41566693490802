import { Swiper, SwiperSlide } from "framework7-react";
import React from "react";

import { ChargeStep1 } from "./step1";
import { ChargeStep2 } from "./step2";
import { ChargeStep3 } from "./step3";
import { ChargeStep4 } from "./step4";

/**
 * Charge Tab
 * @returns Charge Tab
 */
export const TabCharge: React.FC = () => (
  <>
    <Swiper simulateTouch={false} allowTouchMove={false} noSwiping>
      <SwiperSlide style={{ minHeight: 450 }}>
        <ChargeStep1 />
      </SwiperSlide>
      <SwiperSlide style={{ minHeight: 450 }}>
        <ChargeStep2 />
      </SwiperSlide>
      <SwiperSlide style={{ minHeight: 450 }}>
        <ChargeStep3 />
      </SwiperSlide>
      <SwiperSlide style={{ minHeight: 450 }}>
        <ChargeStep4 />
      </SwiperSlide>
    </Swiper>
  </>
);
