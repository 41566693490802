import { useFormik } from "formik";
import { Link, Navbar, NavLeft, NavRight, NavTitle, Page } from "framework7-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { siteProfileSelector } from "core/slice/site/profile";
import { goBack } from "core/usecase/global";
import { profileInitialize, updateProfile } from "core/usecase/site/profile";

import { FormikList, FormikListInput } from "ui/widget/formik";

type Shape = { nickName?: string };

/**
 * ニックネーム編集画面
 * @returns NickNameEditor
 */
export const NickNameEditorPage: React.FC = () => {
  const { nickName } = useSelector(siteProfileSelector).profile;

  const formik = useFormik<Shape>({
    initialValues: {
      nickName: nickName || "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      nickName: yup.string().max(20),
    }),
    onSubmit(value) {
      updateProfile("nickName", value.nickName).then(success => {
        if (success) goBack("/profile/base");
      });
    },
  });

  const { setValues } = formik;

  /**
   * データ再設定
   */
  useEffect(() => {
    setValues({ nickName });
  }, [nickName, setValues]);

  return (
    <Page onPageBeforeIn={profileInitialize}>
      <Navbar>
        <NavLeft backLink backLinkUrl="/profile/base" backLinkForce />
        <NavTitle>昵称</NavTitle>
        <NavRight>
          <Link href={false} onClick={formik.submitForm}>
            确定
          </Link>
        </NavRight>
      </Navbar>

      <FormikList formik={formik}>
        <FormikListInput type="text" name="nickName" label="昵称" maxlength={20} info="20个字符以内" />
      </FormikList>
    </Page>
  );
};
