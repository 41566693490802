import { Block, BlockTitle, List, ListItem } from "framework7-react";
import React, { useMemo } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";

import { siteOrderSelector } from "core/slice/site/order";

import { AddressLabel, AreaLabel } from "global/enum-label";
import { ReserveTypeLabel } from "global/enum-label/reserve";
import { ReserveType } from "global/enum/reserve";

import { CastItem } from "ui/widget/cast-item";
import { Gallery, GalleryItem } from "ui/widget/gallery";
import { Icon } from "ui/widget/icon";
import { RankingBar } from "ui/widget/ranking-bar";
import { StyledText } from "ui/widget/styled-text";

/**
 * 予約内容
 * @returns ReserveContent
 */
export const ReserveContent: React.FC = () => {
  const { reserve } = useSelector(siteOrderSelector);

  /** 予約人数(合計)  */
  const peopleNumberTotal = useMemo(
    () => reserve.casts.reduce((prev, current) => prev + current.peopleNumber, 0),
    [reserve.casts],
  );

  /** 消費ポイント合計 */
  const pointTotal = useMemo(
    () =>
      reserve.casts
        .filter(o => !!o.selected)
        .reduce((prev, current) => {
          const subtotal = current.selected!.reduce((p, c) => p + (c.price ?? 0) * reserve.hour * 2, 0);
          return prev + subtotal;
        }, 0),
    [reserve.casts, reserve.hour],
  );

  return (
    <>
      <BlockTitle medium>预约内容</BlockTitle>
      <List>
        <ListItem title="预约时间">
          <Icon name="schedule" slot="media" color="blue" />
          <div slot="after">
            <FormattedMessage id={ReserveTypeLabel[reserve.type]} />
            <br />
            <span style={{ fontWeight: "normal", color: "orange" }}>
              {reserve.type === ReserveType.other && `(${reserve.typeOther})`}
            </span>
          </div>
        </ListItem>
        <ListItem title="预约场所">
          <Icon name="place" slot="media" color="orange" />
          <div slot="after">
            <FormattedMessage id={AreaLabel[reserve.area]} />
            &emsp;
            <FormattedMessage id={AddressLabel[reserve.areaAddr]} />
          </div>
        </ListItem>
        <ListItem title="预约时长" after={`${reserve.hour}小时`}>
          <Icon name="timer" slot="media" color="green" />
        </ListItem>
        <ListItem title="女孩人数(合计)">
          <div slot="after">
            <StyledText>{peopleNumberTotal}</StyledText>人
          </div>
          <Icon name="people" slot="media" color="pink" />
        </ListItem>
        <li>
          <ul>
            {reserve.casts.map(o => (
              <ListItem key={o.ranking} after={`${o.peopleNumber}人`}>
                <RankingBar shadow width={120} ranking={o.ranking} slot="title" />
              </ListItem>
            ))}
          </ul>
        </li>
        <ListItem divider />
        <ListItem title="所需积分">
          <Icon name="paid" slot="media" color="purple" />
          <div slot="after">
            <span
              style={{
                fontSize: 24,
                fontWeight: "bolder",
                color: "orange",
                textShadow: "0 2px 2px #f2d7a4",
              }}
            >
              <FormattedNumber value={pointTotal} />
            </span>
            &nbsp;P
          </div>
        </ListItem>
      </List>

      <BlockTitle medium>预约女孩</BlockTitle>
      <Block strong>
        <Gallery gap={8}>
          {reserve.casts.map(o => (
            <React.Fragment key={o.ranking}>
              {o.selected?.map(item => (
                <GalleryItem key={item.userId}>
                  <CastItem item={item} showTitle={false} />
                </GalleryItem>
              ))}
            </React.Fragment>
          ))}
        </Gallery>
      </Block>
    </>
  );
};
