import React, { useMemo } from "react";
import styled from "styled-components";

const Container = styled.div({
  display: "block",
  width: "100%",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

/**
 * TextEllipsis
 */
export interface TextEllipsisProps extends React.HTMLAttributes<HTMLDivElement> {
  /** 最大文字数 */
  limit?: number | "auto";
  /** 最大文字数を超える時表示マーク: default: ... */
  suffix?: string;
  /** 文字 */
  text: string | undefined | null;
}

/**
 * TextEllipsis
 * @param props
 * @param props.limit
 * @param props.suffix
 * @param props.text
 * @returns TextEllipsis
 */
export const TextEllipsis: React.FC<TextEllipsisProps> = ({ limit = "auto", suffix = "...", text, ...attrs }) => {
  const dispText = useMemo(() => {
    if (!text) return <>&nbsp;</>;
    if (limit === "auto") return text;
    if (limit && limit > 0 && text.length > limit) return text.substring(0, limit) + suffix;
    return text;
  }, [limit, suffix, text]);

  return <Container {...attrs}>{dispText}</Container>;
};
