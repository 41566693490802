import { Block, BlockTitle, Button, Card, CardContent } from "framework7-react";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Divider } from "ui/widget/divider";
import { Icon } from "ui/widget/icon";

type SignupVerifyBlockProps = {
  /** メールアドレス確認結果 */
  result: boolean;
  /** ログインページリンク */
  loginLink: string;
  /** サインアップページリンク */
  signupLink: string;
};

/**
 * メールアドレス確認
 * @param props
 * @param props.result
 * @param props.loginLink
 * @param props.signupLink
 * @returns SignupVerifyBlock
 */
export const SignupVerifyBlock: React.FC<SignupVerifyBlockProps> = ({ result, loginLink, signupLink }) => (
  <>
    {result === true && (
      <>
        <Block style={{ textAlign: "center", marginTop: 60 }}>
          <Icon name="check_circle" size="80px" color="green" />
          <BlockTitle medium>
            <FormattedMessage id="signup.verify.ok.title" />
          </BlockTitle>
        </Block>
        <Card>
          <CardContent>
            <div style={{ textAlign: "left", fontSize: 14, color: "#666" }}>
              <FormattedMessage id="signup.verify.ok.message1" />
              <br />
              <FormattedMessage id="signup.verify.ok.message2" />
            </div>
          </CardContent>
        </Card>
        <Block>
          <Button fill href={loginLink} transition="f7-push">
            <FormattedMessage id="common.back.login" />
          </Button>
        </Block>
      </>
    )}
    {result === false && (
      <>
        <Block style={{ textAlign: "center", marginTop: 60 }}>
          <Icon name="error" size={80} color="orange" />
          <BlockTitle medium>
            <FormattedMessage id="signup.verify.fail.title" />
          </BlockTitle>
        </Block>
        <Card>
          <CardContent>
            <div style={{ textAlign: "left", fontSize: 14, color: "#666" }}>
              <FormattedMessage id="signup.verify.fail.message1" />
              <br />
              <FormattedMessage id="signup.verify.fail.message2" />
            </div>
          </CardContent>
        </Card>
        <Block>
          <Button fill href={signupLink} transition="f7-push">
            <FormattedMessage id="signup.verify.goto.signup" />
          </Button>
          <Divider label={<FormattedMessage id="common.or" />} />
          <Button outline href={loginLink} transition="f7-push">
            <FormattedMessage id="common.back.login" />
          </Button>
        </Block>
      </>
    )}
  </>
);
