import classNames from "classnames";
import { Link, Swiper, SwiperSlide } from "framework7-react";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { useUpdate } from "react-use";
import styled from "styled-components";
import SwiperClass from "swiper/types/swiper-class";

import { MediaListResponse } from "core/model/media";

import { CommonConfig } from "global/config";
import { MediaType } from "global/enum";

const Container = styled.div({
  position: "relative",

  ".swiper": {
    borderBottom: "2px solid #fff",

    ".swiper-slide": {
      height: 540,
      overflow: "hidden",

      img: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },

      "& > *": {
        maxWidth: "100%",
      },
    },

    ".swiper-button-prev, .swiper-button-next": {
      background: "rgba(0, 0, 0, .675)",
      width: 36,
      height: 72,
      marginTop: "calc(0px - 36px)",
    },
  },

  ".thumbnail": {
    display: "flex",
    flexFlow: "row nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    minWidth: "100%",
    borderBottom: "1px solid #999",

    ".thumbnail-wrapper": {
      width: 80,
      height: 80,
      border: "2px solid #fff",
      cursor: "pointer",
      transition: "all .2s",
      flexShrink: 0,

      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },

      "&.active, &:hover": {
        borderColor: "#f57dc9",
      },
    },
  },

  ".link.back": {
    position: "absolute",
    top: 24,
    left: 16,
    background: "#888",
    color: "#ddd",
    borderRadius: "50%",
    transition: "all .2s",
    width: 32,
    height: 32,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      opacity: 0.75,
    },
  },
});

type MediaSectionProps = {
  mediaList: MediaListResponse;
};

/**
 * MediaSection
 * @param props
 * @param props.mediaList
 * @returns MediaSection
 */
export const MediaSection: React.FC<MediaSectionProps> = ({ mediaList }) => {
  const [swiper, setSwiper] = useState<SwiperClass>();
  const update = useUpdate();

  return (
    <Container>
      <Swiper navigation simulateTouch={false} onSwiper={setSwiper} onSlideChange={update}>
        {mediaList.map(o => (
          <SwiperSlide key={o.rowsId}>
            {o.type === MediaType.photo ? (
              <img src={CommonConfig.mediaBaseUrl + o.photoId} alt="" />
            ) : (
              <ReactPlayer url={CommonConfig.mediaBaseUrl + o.photoId} controls />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="thumbnail">
        {mediaList.map((o, index) => (
          <div
            key={o.rowsId}
            onClick={() => swiper?.slideTo(index)}
            className={classNames("thumbnail-wrapper", {
              active: swiper && mediaList[swiper.activeIndex].rowsId === o.rowsId,
            })}
          >
            <img src={CommonConfig.thumbnailBaseUrl + o.thumbnail} alt="" draggable={false} />
          </div>
        ))}
      </div>
      <Link href="/top" back className="icon-only">
        <i className="icon icon-back" />
      </Link>
    </Container>
  );
};
