import { Block, SkeletonBlock } from "framework7-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { siteTopSelector } from "core/slice/site/top";

import { CastItem } from "ui/widget/cast-item";
import { Gallery, GalleryItem } from "ui/widget/gallery";
import { NoData } from "ui/widget/no-data";

/**
 * GirlList
 * @returns GirlList
 */
export const GirlList: React.FC = () => {
  const { girlList, initialized } = useSelector(siteTopSelector);

  return (
    <Block style={{ marginTop: -32, marginBottom: 60 }}>
      {!initialized ? (
        <Gallery>
          {Array.from(Array(12).keys()).map(index => (
            <GalleryItem key={index}>
              <SkeletonBlock tag="div" width="100%" height="100%" borderRadius="4px" effect="wave" />
            </GalleryItem>
          ))}
        </Gallery>
      ) : girlList && girlList.length > 0 ? (
        <Gallery gap={32}>
          {girlList.map(item => (
            <GalleryItem hoverable key={item.userId}>
              <CastItem border="animated" item={item} />
            </GalleryItem>
          ))}
        </Gallery>
      ) : (
        <NoData title={<FormattedMessage id="common.nodata" />} detail="没有找到符合条件的数据" />
      )}
    </Block>
  );
};
