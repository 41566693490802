import dayjs from "dayjs";
import { dispatch, store } from "store";

import {
  CancelReserveRequest,
  LatestReserveResponse,
  ListReserveResponse,
  RegistReserveRequest,
  RegistReserveResponse,
  ReserveState,
} from "core/model/site/order";
import { GirlItem, GirlListRequest, GirlListResponse } from "core/model/site/top";
import { siteOrderAction } from "core/slice/site/order";
import { showPageSpinner, hidePageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";

import { ReserveType } from "global/enum/reserve";

/**
 * 予約データ更新
 * @param data
 */
export const updateReserveData = (data: Partial<ReserveState>) => {
  dispatch(siteOrderAction.updateReserveAction(data));
};

/**
 * キャスト選択
 * @param cast
 */
export const toggleSelectCast = (cast: GirlItem) => {
  dispatch(siteOrderAction.toggleSelectCastAction(cast));
};

/**
 * 予約可能なキャスト一覧を取得する
 * @param reserve
 * @returns キャスト一覧
 */
export const searchReservableCasts = async (reserve: ReserveState) => {
  showPageSpinner();
  try {
    // リクエストボディー
    const requestBody: GirlListRequest = {
      area: reserve.area,
      address: reserve.areaAddr,
    };

    const now = dayjs();
    // 予約開始日時
    if (reserve.type === ReserveType.halfHourLater) {
      requestBody.date = now.add(30, "minute").toDate();
    } else if (reserve.type === ReserveType.oneHourLater) {
      requestBody.date = now.add(1, "hour").toDate();
    } else if (reserve.type === ReserveType.oneAndHalfHourLater) {
      requestBody.date = now.add(1.5, "hour").toDate();
    } else if (reserve.typeOther) {
      requestBody.date = new Date(reserve.typeOther);
    } else {
      requestBody.date = now.add(30, "minute").toDate();
    }
    // ランク
    requestBody.ranking = reserve.casts.map(o => o.ranking);

    // 検索処理を行う
    const res = await Api.post<GirlListResponse>("/site/user/girl/list", requestBody);
    // 成功の場合
    if (isSuccess(res) && res.body) {
      return res.body;
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return [];
};

/**
 * 予約登録
 * @param requestBody
 * @returns 予約No
 */
export const registReserve = async (requestBody: RegistReserveRequest) => {
  showPageSpinner();
  try {
    // 予約登録処理を行う
    const res = await Api.post<RegistReserveResponse>("/site/reserve/c", requestBody);
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(siteOrderAction.appendReserveItemAction(res.body));
      // 予約登録情報のクリア
      dispatch(siteOrderAction.clearReserveAction());
      return res.body.reserveNo;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return undefined;
};

/**
 * 直近の予約一覧の初期化
 * @param force
 */
export const initializeLatestReserveList = async (force = false) => {
  if (!force && store.getState()["site/order"].latestReserveList.initialized) return;
  showPageSpinner();
  try {
    const res = await Api.get<LatestReserveResponse>("/site/reserve/latest");
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(siteOrderAction.initializeLatestReserveList(res.body));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};

/**
 * 予約一覧の初期化
 * @param force
 */
export const initializeReserveList = async (force = false) => {
  if (!force && store.getState()["site/order"].reserveList.initialized) return;
  showPageSpinner();
  try {
    const res = await Api.get<ListReserveResponse>("/site/reserve/list", {
      params: {
        page: 1,
      },
    });
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(siteOrderAction.initializeReserveListAction(res.body));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};

/**
 * 次のページの予約一覧を取得する
 * @returns hasMore ? true : false
 */
export const getReserveListOfNextPage = async () => {
  try {
    const res = await Api.get<ListReserveResponse>("/site/reserve/list", {
      params: {
        page: store.getState()["site/order"].reserveList.page,
      },
    });
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(siteOrderAction.appendReserveListAction(res.body));
    }
  } catch {
    // 処理なし
  }
};

/**
 * 予約キャンセル
 * @param reserveIds
 * @param cancelReason
 * @returns 結果
 */
export const cancelReserve = async (reserveIds: Array<string>, cancelReason?: string) => {
  showPageSpinner();
  try {
    const body: CancelReserveRequest = {
      reserveIds,
      cancelReason,
    };
    const res = await Api.post("/site/reserve/cancel", body);
    // 成功の場合
    if (isSuccess(res)) {
      // データ再取得
      initializeLatestReserveList(true);
      initializeReserveList(true);
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};

/**
 * 予約削除
 * @param reserveId
 * @returns 結果
 */
export const deleteReserve = async (reserveId: string) => {
  showPageSpinner();
  try {
    const res = await Api.post("/site/reserve/d", { reserveId });
    // 成功の場合
    if (isSuccess(res)) {
      // データ再取得
      dispatch(siteOrderAction.deleteReserveAction({ reserveId }));
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};
