import { Badge, Link, Page, Tab, Tabs, Toolbar } from "framework7-react";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { talkSelector } from "core/slice/talk";
import { initPresentMasterList } from "core/usecase/global";
import { getUnreadCntSum } from "core/usecase/talk";

import { Icon } from "ui/widget/icon";

import { useRoute } from "../../../routes";

/**
 * GirlRoot
 * @returns GirlRoot
 */
export const GirlRoot: React.FC = () => {
  const { url } = useRoute();

  // メッセージ数
  const { unreadMsgCnt } = useSelector(talkSelector);
  const modifiedUnreadMsgCnt = useMemo(() => {
    if (unreadMsgCnt <= 0) return undefined;
    if (unreadMsgCnt > 99) return "+99";
    return unreadMsgCnt;
  }, [unreadMsgCnt]);

  useEffect(() => {
    // 未読総件数の取得
    getUnreadCntSum();
    // プレゼントマスタ一覧の初期化
    initPresentMasterList();
  }, []);

  return (
    <Page pageContent={false}>
      <Toolbar tabbar bottom>
        {/* <Link
          tab-link
          href="/girl/home"
          route-tab-id="tab-home"
          iconMaterial="search"
          tabLinkActive={/^\/girl\/home\/?/.test(url)}
        >
          探す
        </Link> */}
        <Link
          tab-link
          href="/girl/order"
          route-tab-id="tab-order"
          iconMaterial="history"
          tabLinkActive={/^\/girl\/order\/?/.test(url)}
        >
          合流履歴
        </Link>
        <Link tab-link href="/girl/talk" route-tab-id="tab-talk" tabLinkActive={/^\/girl\/talk\/?/.test(url)}>
          <Icon name="message">{modifiedUnreadMsgCnt && <Badge color="red">{modifiedUnreadMsgCnt}</Badge>}</Icon>
          <span className="tabbar-label">トーク</span>
        </Link>
        <Link
          tab-link
          href="/girl/profile"
          route-tab-id="tab-profile"
          iconMaterial="manage_accounts"
          tabLinkActive={/^\/girl\/profile\/?/.test(url)}
        >
          プロファイル
        </Link>
      </Toolbar>
      <Tabs swipeable animated routable swiperParams={{ simulateTouch: false }}>
        {/* <Tab id="tab-home" /> */}
        <Tab id="tab-order" />
        <Tab id="tab-talk" />
        <Tab id="tab-profile" />
      </Tabs>
    </Page>
  );
};
