import { Page } from "framework7-react";
import { PropsWithF7Router } from "routes";

import { ReserveCompleteBlock } from "ui/component/site/order/reserve-complete/reserve-complete-block";

type ReserveCompletePageProps = PropsWithF7Router<{
  reserveNo?: string;
}>;

/**
 * ReserveCompletePage
 * @param props
 * @param props.reserveNo
 * @param props.f7router
 * @returns ReserveCompletePage
 */
export const ReserveCompletePage: React.FC<ReserveCompletePageProps> = ({ f7router, reserveNo }) => {
  /**
   * handlePageBeforeIn
   */
  const handlePageBeforeIn = () => {
    if (!reserveNo) {
      f7router.navigate("/order", {
        clearPreviousHistory: true,
      });
    }
  };

  return (
    <Page noNavbar noToolbar noSwipeback onPageBeforeIn={handlePageBeforeIn}>
      {reserveNo && <ReserveCompleteBlock reserveNo={reserveNo} />}
    </Page>
  );
};
