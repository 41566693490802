import { useFormik } from "formik";
import { Block, Button, ListItem, LoginScreenTitle } from "framework7-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import { ForgotPasswordRequest } from "core/model/forgot-password";
import { goBack } from "core/usecase/global";

import { Divider } from "ui/widget/divider";
import { FormikList, FormikListInput } from "ui/widget/formik";

type ForgotPasswordFormProps = {
  /** onSubmit */
  onSubmit: (values: ForgotPasswordRequest) => void;
  /** ログインページリンク */
  loginLink: string;
};

/**
 * パスワードお忘れフォーム
 * @param props
 * @param props.onSubmit
 * @param props.loginLink
 * @returns ForgotPasswordForm
 */
export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onSubmit, loginLink }) => {
  const formik = useFormik<ForgotPasswordRequest>({
    initialValues: {
      email: "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      email: yup.string().required().email().max(45),
    }),
    onSubmit,
  });

  return (
    <Block inset strong>
      <LoginScreenTitle>
        <FormattedMessage id="forgot.password.title" />
      </LoginScreenTitle>
      <br />
      <FormikList noDivider formik={formik}>
        <FormikListInput type="text" name="email" outline>
          <div slot="label">
            <FormattedMessage id="forgot.password.email" />
          </div>
        </FormikListInput>

        <ListItem style={{ fontSize: 13, color: "#777", marginTop: 0 }}>
          <FormattedMessage id="forgot.password.message1" />
          <br />
          <FormattedMessage id="forgot.password.message2" />
        </ListItem>

        <ListItem className="margin-top">
          <Button type="submit" fill>
            <FormattedMessage id="common.send" />
          </Button>
        </ListItem>

        <ListItem>
          <Divider label={<FormattedMessage id="common.or" />} style={{ padding: 0 }} />
        </ListItem>

        <ListItem>
          <Button type="button" outline onClick={() => goBack(loginLink)}>
            <FormattedMessage id="common.back.login" />
          </Button>
        </ListItem>
      </FormikList>
    </Block>
  );
};
