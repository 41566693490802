export * from "./image";
export * from "./video";

/**
 * blobからbase64への変換処理
 * @param blob
 * @returns base64
 */
export const blobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      resolve(reader.result as string);
    });
    reader.addEventListener("error", error => {
      reject(error);
    });
    reader.readAsDataURL(blob);
  });

/**
 * blobからbase64への変換処理
 * @param base64
 * @returns base64
 */
export const base64ToBlob = (base64: string) => {
  const matches = base64.match(/^data:(.+);base64,(.+)/);
  if (matches === null) return null;
  const fileType = matches[1];

  const buffer = Buffer.from(matches[2], "base64");
  return new Blob([buffer], { type: fileType });
};

/**
 * base64ToFile
 * @param base64
 * @param fileName
 * @returns file
 */
export const base64ToFile = (base64: string, fileName: string) => {
  const matches = base64.match(/^data:(.+);base64,(.+)/);
  if (matches === null) return undefined;
  const fileType = matches[1];

  const buffer = Buffer.from(matches[2], "base64");
  return new File([buffer], fileName, { type: fileType });
};
