import { Page } from "framework7-react";
import React from "react";
import { useSelector } from "react-redux";
import { PropsWithF7Router } from "routes";

import { talkSelector } from "core/slice/talk";
import { initializeMessageList } from "core/usecase/talk";

import { MessageBar } from "ui/component/talk/message-bar";
import { MessageList } from "ui/component/talk/message-list";
import { MessageListContent } from "ui/component/talk/message-list-content";
import { MessageListSkeleton } from "ui/component/talk/message-list-skeleton";
import { MessageNav } from "ui/component/talk/message-nav";

/**
 * トークメッセージ一覧
 * @param props
 * @param props.f7route
 * @returns TalkMessagePage
 */
export const TalkMessagePage: React.FC<PropsWithF7Router> = ({ f7route }) => {
  const talkId = f7route.params.id!;
  const { data, initialized } = useSelector(talkSelector).messages[talkId] || { initialized: false };

  return (
    <Page pageContent={false} onPageBeforeIn={() => initializeMessageList(talkId)}>
      <MessageNav initialized={initialized} data={data} backLinkUrl="/talk" />
      <MessageListContent initialized={initialized} data={data}>
        {initialized && data ? <MessageList data={data} /> : <MessageListSkeleton />}
      </MessageListContent>
      <MessageBar talkId={talkId} />
    </Page>
  );
};
