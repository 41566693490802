import { Block, BlockTitle, Link, List, ListItem, Navbar, Page } from "framework7-react";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { authorizationSelector } from "core/slice/authorization";
import { siteProfileSelector } from "core/slice/site/profile";
import {
  profileInitialize,
  updateHobby,
  updateLanguage,
  updatePersonality,
  updateProfile,
} from "core/usecase/site/profile";

import { SMART_SELECT_DEFAULT_PARAMS } from "global/constant";
import { SakeLabel, TobaccoLabel } from "global/enum-label";
import { AnnualLabel, CountryLabel, HobbyLabel, LanguageLabel, PersonalityLabel } from "global/enum-label/site";
import { Hobby, Language } from "global/enum/profile-site";

import { BirthdaySelector } from "ui/component/site/profile/base-editor/birthday-selector";
import { Avatar } from "ui/widget/avatar";
import { FormattedDate } from "ui/widget/formatted-date";
import { TextEllipsis } from "ui/widget/text-ellipsis";

/**
 * プロファイル編集画面
 * @returns ProfileBaseEditor
 */
export const ProfileBaseEditorPage: React.FC = () => {
  const { profile } = useSelector(siteProfileSelector);
  const { principal } = useSelector(authorizationSelector);

  const [birthSelectorVisible, setBirthSelectorVisible] = useState(false);

  return (
    <Page onPageBeforeIn={profileInitialize}>
      <Navbar backLink backLinkUrl="/profile" backLinkForce title="资料编辑" />
      <Block style={{ textAlign: "center" }}>
        <Link href="/profile/avatar" transition="f7-cover-v">
          <Avatar src={principal?.avatar} size={80} shape="circle" />
        </Link>
        <BlockTitle style={{ marginTop: 4 }}>更改头像</BlockTitle>
      </Block>
      <List>
        <ListItem
          link="/profile/base/nick-name"
          transition="f7-push"
          title="昵称"
          after={profile.nickName || "未设置"}
        />
        <ListItem link="/profile/base/title" transition="f7-push" title="现在心情">
          <TextEllipsis slot="after" limit={15} text={profile.title || "未设置"} />
        </ListItem>
        <ListItem
          link
          title="性格"
          after={profile.personality ? profile.personality.map(o => PersonalityLabel[o]).join(", ") : "未设置"}
          smartSelect
          smartSelectParams={{
            openIn: "popover",
            setValueText: false,
            on: {
              closed(smartSelect) {
                updatePersonality((smartSelect.getValue() as string[]).join(","));
              },
            },
          }}
        >
          <select name="personality" multiple defaultValue={profile.personality}>
            {Object.entries(PersonalityLabel).map(([value, label]) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </ListItem>

        <ListItem
          link
          transition="f7-push"
          title="兴趣爱好"
          after={profile.hobby ? profile.hobby.map(o => HobbyLabel[o]).join(", ") : "未设置"}
          smartSelect
          smartSelectParams={{
            openIn: "popup",
            setValueText: false,
            popupCloseLinkText: "确定",
            on: {
              closed(smartSelect) {
                updateHobby((smartSelect.getValue() as string[]).join(","));
              },
            },
          }}
        >
          <select name="hobby" multiple>
            {Object.entries(HobbyLabel).map(([value, label]) => (
              <option value={value} key={value} selected={profile.hobby?.includes(value as Hobby)}>
                {label}
              </option>
            ))}
          </select>
        </ListItem>
        <ListItem link="/profile/base/note" transition="f7-push" title="介绍">
          <TextEllipsis slot="after" limit={15} text={profile.note || "未设置"} />
        </ListItem>
      </List>

      <BlockTitle>基本信息</BlockTitle>
      <List>
        <ListItem
          link="/profile/base/height"
          transition="f7-push"
          title="身高(cm)"
          after={profile.height || "未设置"}
        />
        <ListItem
          link="/profile/base/address"
          transition="f7-push"
          title="所在地"
          after={profile.address || "未设置"}
        />
        <ListItem
          link
          transition="f7-push"
          title="国籍"
          after={profile.country ? CountryLabel[profile.country] : "未设置"}
          smartSelect
          smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}
        >
          <select name="country" value={profile.country} onChange={e => updateProfile("country", e.target.value)}>
            <option value="">请选择</option>
            {Object.entries(CountryLabel).map(([value, label]) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </ListItem>
        <ListItem
          link
          transition="f7-push"
          title="年收入"
          after={profile.annual ? AnnualLabel[profile.annual] : "未设置"}
          smartSelect
          smartSelectParams={{
            openIn: "popup",
            setValueText: false,
            popupCloseLinkText: "确定",
          }}
        >
          <select name="annual" value={profile.annual} onChange={e => updateProfile("annual", e.target.value)}>
            <option value="">请选择</option>
            {Object.entries(AnnualLabel).map(([value, label]) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </ListItem>
        <ListItem link title="喝酒" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
          <div slot="after">{profile.sake ? <FormattedMessage id={SakeLabel[profile.sake]} /> : "未设置"}</div>
          <select name="sake" value={profile.sake} onChange={e => updateProfile("sake", e.target.value)}>
            {Object.entries(SakeLabel).map(([value, label]) => (
              <option value={value} key={value}>
                <FormattedMessage id={label} />
              </option>
            ))}
          </select>
        </ListItem>
        <ListItem link title="抽烟" smartSelect smartSelectParams={SMART_SELECT_DEFAULT_PARAMS}>
          <div slot="after">{profile.tobacco ? <FormattedMessage id={TobaccoLabel[profile.tobacco]} /> : "未设置"}</div>
          <select name="tobacco" value={profile.tobacco} onChange={e => updateProfile("tobacco", e.target.value)}>
            {Object.entries(TobaccoLabel).map(([value, label]) => (
              <option value={value} key={value}>
                <FormattedMessage id={label} />
              </option>
            ))}
          </select>
        </ListItem>
        <ListItem link title="出生年月日" onClick={() => setBirthSelectorVisible(true)}>
          <div slot="after">
            <FormattedDate defaultValue="未设置" value={profile.birth} />
          </div>
        </ListItem>
        <ListItem
          link
          title="语言"
          after={profile.lang ? profile.lang.map(o => LanguageLabel[o]).join(", ") : "未设置"}
          smartSelect
          smartSelectParams={{
            openIn: "popover",
            setValueText: false,
            on: {
              closed(smartSelect) {
                updateLanguage((smartSelect.getValue() as string[]).join(","));
              },
            },
          }}
        >
          <select name="lang" multiple>
            {Object.entries(LanguageLabel).map(([value, label]) => (
              <option value={value} key={value} selected={profile.lang?.includes(value as Language)}>
                {label}
              </option>
            ))}
          </select>
        </ListItem>
      </List>

      <BirthdaySelector visible={birthSelectorVisible} onClose={() => setBirthSelectorVisible(false)} />
    </Page>
  );
};
