import { AxiosProgressEvent } from "axios";
import dayjs from "dayjs";
import { f7 } from "framework7-react";
import { SocketEvents } from "socket";
import { Socket } from "socket.io-client";
import { dispatch, store } from "store";

import {
  InitMessageResponse,
  ListPrevMessageRequest,
  ListPrevMessageResponse,
  MediaUploadResponse,
  SendMessageRequest,
  SendMessageResponse,
  TalkListResponse,
  UpdateLastViewMessageRequest,
} from "core/model/talk";
import { talkAction } from "core/slice/talk";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";
import { heicToJpg, compress, createVideoCover } from "core/utils/file";
import logger from "core/utils/logger";

import { CommonConfig, TalkConfig } from "global/config";
import { MediaUploadError, SupportedLanguage, TalkMessageType } from "global/enum";

import { showPageSpinner, hidePageSpinner } from "../global";

/**
 * トーク一覧取得
 * @param force
 */
export const initializeTalkList = async (force = false) => {
  if (!force && store.getState().talk.talkList.initialized) return;
  showPageSpinner();
  try {
    const res = await Api.get<TalkListResponse>("/talk/list");
    if (isSuccess(res) && res.body) {
      // storeに保存する
      dispatch(talkAction.updateTalkListAction(res.body));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};

/**
 * トーク日付フォーマット
 * @param date
 * @returns formattedTalkDate
 */
export const formattedTalkDate = (date?: Date) => {
  if (!date) return undefined;
  const { language } = store.getState().global;

  const now = dayjs();
  const target = dayjs(date);
  // 年チェック: 1年以上であれば、YYYY/MM/DDにする
  const diffYear = now.diff(target, "year");
  if (diffYear > 0) {
    return target.format("YYYY/MM/DD");
  }
  // 週チェック: 一週間以上であれば、M月D日
  const diffWeek = now.diff(target, "week");
  if (diffWeek > 0) {
    return target.format("M月D日");
  }
  // 今日の場合、 HH:mm
  if (target.isToday()) {
    return target.format("HH:mm");
  }
  // 昨日の場合、「昨日 HH:mm」
  if (target.isYesterday()) {
    if (language === SupportedLanguage.japanese) {
      return target.format("昨日 HH:mm");
    }
    return target.format("昨天 HH:mm");
  }
  // 上記以外、dddd
  return target.format("dddd");
};

/**
 * トークメッセージ日付フォーマット
 * @param date
 * @returns formattedMessageDate
 */
export const formattedMessageDate = (date?: Date) => {
  if (!date) return undefined;
  const { language } = store.getState().global;

  const now = dayjs();
  const target = dayjs(date);
  // 年チェック: 1年以上であれば、YYYY/MM/DDにする
  const diffYear = now.diff(target, "year");
  if (diffYear > 0) {
    return target.format("YYYY/MM/DD");
  }
  // 週チェック: 一週間以上であれば、M月D日
  const diffWeek = now.diff(target, "week");
  if (diffWeek > 0) {
    return target.format("M月D日");
  }
  // 今日の場合、 HH:mm
  if (target.isToday()) {
    return language === SupportedLanguage.japanese ? "今日" : "今天";
  }
  // 昨日の場合、「昨日 HH:mm」
  if (target.isYesterday()) {
    return language === SupportedLanguage.japanese ? "今日" : "今天";
  }
  // 上記以外、dddd
  return target.format("dddd");
};

/**
 * メッセージ一覧初期化
 * @param talkId
 */
export const initializeMessageList = async (talkId: string) => {
  if (store.getState().talk.messages[talkId]?.initialized) return;
  showPageSpinner();
  try {
    // メッセージ一覧初期化
    const res = await Api.get<InitMessageResponse>(`/talk/messages/init/${talkId}`);
    if (isSuccess(res) && res.body) {
      // storeに保存する
      dispatch(talkAction.initMessageListAction({ talkId, data: res.body }));
    } else {
      showErrorModal(res.message);
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};

/**
 * 前のページのメッセージ一覧を取得する
 * @param data
 */
export const listPrevPageMessages = async (data: ListPrevMessageRequest) => {
  try {
    // 前のページのメッセージ一覧を取得する
    const res = await Api.post<ListPrevMessageResponse>("/talk/messages/prev", data);
    if (isSuccess(res) && res.body) {
      // storeに保存する
      dispatch(talkAction.prependMessageListAction({ talkId: data.talkId, data: res.body }));
      return res.body.length >= TalkConfig.talkMessagePageSize;
    }
  } catch {
    // 処理なし
  }
  return true;
};

/**
 * トークID取得
 * @param talkUserId
 * @returns Promise<string>
 */
export const getTalkId = async (talkUserId: string) => {
  showPageSpinner();
  try {
    const res = await Api.get<string>(`/talk/id/${talkUserId}`);
    if (isSuccess(res) && res.body) {
      return res.body;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return undefined;
};

/**
 * メッセージ送信処理
 * @param data
 * @param socket
 * @returns 送信結果
 */
export const sendMessage = async (data: SendMessageRequest, socket: Socket | undefined) => {
  try {
    // 送信処理
    const res = await Api.post<SendMessageResponse>("/talk/message/send", data);
    if (isSuccess(res) && res.body) {
      const { myTalkItem, destTalkItem, messageItem } = res.body;
      // storeに保存する
      dispatch(talkAction.newMessageAction({ talkItem: myTalkItem, messageItem }));
      // Socket送信
      if (socket) {
        socket.emit(SocketEvents.newMessage, { talkItem: destTalkItem, messageItem });
      }
      return;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  }
};

/**
 * 画像送信用フォームデータ作成
 * @param file
 */
const createFormDataOfPhoto = async (file: File) => {
  // heicからjpgの変換
  const covertedFile = await heicToJpg(file);

  // サムネイル作成
  const thumbnail = await compress(covertedFile, {
    quality: 0.8,
    maxWidth: 450,
    maxHeight: 450,
  });

  // 画像圧縮処理
  let media = covertedFile;
  if (covertedFile.size > 2 * 1024 * 1024) {
    media = await compress(covertedFile, {
      quality: 0.8,
      maxWidth: 1920,
      maxHeight: 1920,
    });
  }

  return {
    thumbnail,
    media,
  };
};

/**
 * ビデオ送信用フォームデータ作成
 * @param file
 */
const createFormDataOfVideo = async (file: File) => {
  // ビデオカバーの作成
  const videoCoverOrigin = await createVideoCover(file);

  // ビデオカバーの圧縮処理
  const videoCover = await compress(videoCoverOrigin, {
    quality: 0.8,
    maxWidth: 450,
    maxHeight: 450,
  });

  return {
    thumbnail: videoCover,
    media: file,
  };
};

/**
 * 画像・ビデオの送信処理
 * @param talkId
 * @param file
 * @param socket
 */
export const sendMedia = async (talkId: string, file: File, socket: Socket | undefined) => {
  // ファイルサイズチェック
  if (file.size > CommonConfig.uploadMaxFileSize * 1024 * 1024) {
    return MediaUploadError.maxSizeExceed;
  }

  const dialog = f7.dialog.preloader();
  dialog.open();
  // onUploadProgress
  const onUploadProgress = (event: AxiosProgressEvent) => {
    const total = (event.total! / 1024 / 1024).toFixed(2);
    const loaded = (event.loaded! / 1024 / 1024).toFixed(2);
    dialog.setText(`Sending: ${loaded}MB / ${total}MB`);
  };

  try {
    let isImage = true;
    let thumbnail: File;
    let media: File;
    // 写真の場合
    if (/^image\/.+$/.test(file.type)) {
      const data = await createFormDataOfPhoto(file);
      thumbnail = data.thumbnail;
      media = data.media;
    } else if (/^video\/.+$/.test(file.type)) {
      // ビデオの場合
      const data = await createFormDataOfVideo(file);
      thumbnail = data.thumbnail;
      media = data.media;
      isImage = false;
    } else {
      return MediaUploadError.invalidType;
    }

    // form data
    const formData = new FormData();
    formData.append("thumbnail", thumbnail, thumbnail.name);
    formData.append("media", media, media.name);

    // 画像・ビデオのアップロード処理
    const res = await Api.post<MediaUploadResponse>("/talk/message/media/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
    // アップロード失敗の場合、処理終了
    if (!isSuccess(res) || !res.body) {
      showErrorModal(res.message);
      return false;
    }

    // メッセージの送信
    await sendMessage(
      {
        talkId,
        message: res.body.fileId,
        messageType: isImage ? TalkMessageType.image : TalkMessageType.video,
        thumbnail: res.body.thumbnailId,
      },
      socket,
    );
    return true;
  } catch (e) {
    logger.error(e);
    return MediaUploadError.other;
  } finally {
    dialog.close();
  }
};

/**
 * 未読総件数の取得
 */
export const getUnreadCntSum = async () => {
  try {
    const res = await Api.get<number>("/talk/unread-count");
    if (isSuccess(res) && res.body !== undefined) {
      // storeに保存する
      dispatch(talkAction.updateUnreadMsgCntAction(res.body));
    }
  } catch {
    // 処理なし
  }
};

/**
 * 最終閲覧メッセージIDの更新処理
 * @param data
 */
export const updateLastViewMessageId = async (data: UpdateLastViewMessageRequest) => {
  try {
    const res = await Api.post("/talk/last-view-message/u", data);
    if (isSuccess(res)) {
      // ストア更新
      dispatch(talkAction.updateLastViewMessageAction(data));
    } else {
      showErrorModal(res.message);
    }
  } catch {
    // 処理なし
  }
};
