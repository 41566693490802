import classNames from "classnames";
import React from "react";
import styled from "styled-components";

import { Icon } from "../icon";
import { IconButton } from "../icon-button";

const Container = styled.div({
  margin: "2px 0",
  paddingLeft: 12,
  paddingRight: 12,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 8,
  border: "1px solid var(--f7-theme-color)",
  height: 32,
  fontSize: 13,
  whiteSpace: "nowrap",

  "&.tag-selectable": {
    cursor: "pointer",
    transition: "background .2s",

    "&:hover": {
      background: "rgba(var(--f7-theme-color-rgb), .5)",
    },
  },

  "&.tag-selected": {
    background: "rgba(var(--f7-theme-color-rgb), .5)",
    transition: "background .2s",

    "&:hover": {
      background: "rgba(var(--f7-theme-color-rgb), .8)",
    },
  },

  "&.tag-deletable": {
    position: "relative",
    paddingRight: 32,

    ".icon-button": {
      position: "absolute",
      right: 0,
    },
  },
});

/**
 * TagProps
 */
export interface TagProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {
  /** text */
  text: React.ReactNode;
  /** selectable: default false */
  selectable?: boolean;
  /** selected: default false */
  selected?: boolean;
  /** deletable: default false */
  deletable?: boolean;
  /** onDelete */
  onDelete?: () => void;
}

/**
 * Tag
 * @param props
 * @param props.text
 * @param props.selectable
 * @param props.selected
 * @param props.deletable
 * @param props.onDelete
 * @param props.className
 * @returns Tag
 */
export const Tag: React.FC<TagProps> = ({
  text,
  selectable = false,
  selected = false,
  deletable = false,
  onDelete,
  className,
  ...attrs
}) => {
  const classes = classNames(className, "tag", {
    "tag-selectable": selectable,
    "tag-selected": selected,
    "tag-deletable": deletable,
  });

  return (
    <Container className={classes} {...attrs}>
      {text}
      {deletable && <IconButton onClick={onDelete} color="gray" icon={<Icon name="close" />} />}
    </Container>
  );
};
