import { List, ListItem, Toggle, ListInput } from "framework7-react";
import React, { useEffect, useState } from "react";

import { ScheduleItem } from "core/model/girl/schedule";

import { FormattedDate } from "ui/widget/formatted-date";
import { Modal } from "ui/widget/modal";

/**
 * ScheduleSettingModalProps
 */
export type ScheduleSettingModalProps = {
  /** item */
  item: ScheduleItem | undefined;
  /** onCancel */
  onCancel: () => void;
  /** onConfirm */
  onConfirm: (item: ScheduleItem) => void;
};

/**
 * ScheduleSettingModal
 * @param props
 * @param props.item
 * @param props.onCancel
 * @param props.onConfirm
 * @returns ScheduleSettingModal
 */
export const ScheduleSettingModal: React.FC<ScheduleSettingModalProps> = ({ item, onCancel, onConfirm }) => {
  const [localWorkStatus, setLocalWorkStatus] = useState(false);
  const [localWorkMemo, setLocalWorkMemo] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setLocalWorkStatus(item?.workStatus === "1");
      setLocalWorkMemo(item?.workMemo || "");
    });
  }, [item]);

  /**
   * handleConfirm
   */
  const handleConfirm = () => {
    onConfirm({ ...item!, workStatus: localWorkStatus ? "1" : "", workMemo: localWorkMemo });
  };

  return (
    <Modal
      opened={!!item}
      fluid
      title="スケジュール設定"
      content={
        <List inset style={{ border: "1px solid #dfdfe3", marginLeft: 0, marginRight: 0 }}>
          <ListItem title="出勤日">
            {item && (
              <div slot="after">
                <FormattedDate value={item.workYmd} originFormat="YYYYMMDD" format="YYYY-MM-DD(dd)" locale="ja" />
              </div>
            )}
          </ListItem>
          <ListItem title="出勤状態">
            <Toggle checked={localWorkStatus} slot="after" onChange={() => setLocalWorkStatus(!localWorkStatus)} />
          </ListItem>
          <ListInput
            inlineLabel
            // label="メモ"
            className="test"
            type="textarea"
            value={localWorkMemo}
            resizable
            onChange={e => setLocalWorkMemo(e.target.value)}
            clearButton
            maxlength={500}
            inputStyle={{ paddingRight: 24 }}
            onInputClear={() => setLocalWorkMemo("")}
          >
            <div slot="label" style={{ textAlign: "left" }}>
              メモ
            </div>
          </ListInput>
        </List>
      }
      actions={[
        {
          text: "キャンセル",
          color: "red",
          onClick: onCancel,
        },
        {
          text: "確定",
          onClick: handleConfirm,
        },
      ]}
    />
  );
};
