import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { ProfileResponse } from "core/model/site/profile";

import { Hobby, Language, Personality } from "global/enum/profile-site";

/**
 * SelectableTab
 */
export type SelectableTab = "order" | "wallet" | "charge";

/**
 * SiteProfileDataType
 */
export type SiteProfileDataType = Partial<
  Omit<ProfileResponse, "personality" | "hobby" | "lang"> & {
    /** 性格 */
    personality: Personality[];
    /** 趣味 */
    hobby: Hobby[];
    /** 言語 */
    lang: Language[];
  }
>;

/**
 * state
 */
export type SiteProfileState = {
  /** initialized */
  initialized: boolean;
  /** profile */
  profile: SiteProfileDataType;
  /** activeTab */
  activeTab: SelectableTab;
};

const initialState: SiteProfileState = {
  initialized: false,
  profile: {},
  activeTab: "wallet",
};

/**
 * slice
 */
export const siteProfileSlice = createSlice({
  name: "site/profile",
  initialState,
  reducers: {
    /**
     * プロファイル初期化
     * @param state
     * @param action
     */
    profileInitializeAction: (state, action: PayloadAction<SiteProfileDataType>) => {
      state.initialized = true;
      state.profile = action.payload;
    },
    /**
     * updateProfileItemAction
     * @param state
     * @param action
     */
    updateProfileItemAction: (state, action: PayloadAction<SiteProfileDataType>) => {
      state.profile = { ...state.profile, ...action.payload };
    },
    /**
     * updateActiveTabAction
     * @param state
     * @param action
     */
    updateActiveTabAction: (state, action: PayloadAction<SelectableTab>) => {
      state.activeTab = action.payload;
    },
  },
});

/**
 * action
 */
export const siteProfileAction = siteProfileSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const siteProfileSelector = (state: RootState) => state["site/profile"];
