import errorSvg from "assets/image/error.svg";
import { Block, BlockTitle, Button } from "framework7-react";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Role } from "global/enum";

import { Divider } from "ui/widget/divider";

type UrlExpiredBlockProps = {
  /** role */
  role: Role;
  /** パスワードお忘れページリンク */
  forgotPasswordLink: string;
};

/**
 * 有効期限切れページBlock
 * @param props
 * @param props.role
 * @param props.forgotPasswordLink
 * @returns UrlExpiredBlock
 */
export const UrlExpiredBlock: React.FC<UrlExpiredBlockProps> = ({ role, forgotPasswordLink }) => (
  <>
    <Block className="text-align-center">
      <img src={errorSvg} alt="expired" style={{ maxWidth: 260 }} />
      <BlockTitle medium>
        <FormattedMessage id="forgot.password.reset.expired.title" />
      </BlockTitle>
      <BlockTitle>
        <FormattedMessage id="forgot.password.reset.expired.message1" />
        <br />
        <FormattedMessage id="forgot.password.reset.expired.message2" />
      </BlockTitle>
    </Block>
    <Block>
      <Button fill href={forgotPasswordLink}>
        <FormattedMessage id="forgot.password.reset.expired.button" />
      </Button>
    </Block>
    {role === Role.site && (
      <>
        <Divider label={<FormattedMessage id="common.or" />} />
        <Block>
          <Button outline href="/">
            <FormattedMessage id="common.back.top" />
          </Button>
        </Block>
      </>
    )}
  </>
);
