import { Router } from "framework7/modules/router/router";

import { NotFoundPage } from "ui/page/common/not-found";
import { PrivacyPolicyPage } from "ui/page/common/privacy-policy";

import { girlRoutes } from "./girl";
import { siteRoutes } from "./site";

export * from "./hooks";

export const routes: Router.RouteParameters[] = [
  {
    path: "/",
    detailRoutes: [
      // site
      ...siteRoutes,

      // girl
      ...girlRoutes,

      {
        path: "/privacy-policy",
        component: PrivacyPolicyPage,
      },

      {
        path: "(.*)",
        component: NotFoundPage,
      },
    ],
  },
];
