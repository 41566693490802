import React, { useMemo } from "react";
import styled from "styled-components";

import { kmgFormat } from "core/utils/format";

const Container = styled.div({
  width: "100%",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",

  "& > span:first-child": {
    fontWeight: "bolder",
  },

  "& > span:last-child": {
    color: "#777",
  },
});

/**
 * NumberMenuProps
 */
export interface NumberMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  /** number */
  number: number;
  /** label */
  label: string;
  /** onClick */
  onClick?: () => void;
}

/**
 * NumberMenu
 * @param props
 * @param props.number
 * @param props.label
 * @param props.onClick
 * @returns NumberMenu
 */
export const NumberMenu: React.FC<NumberMenuProps> = ({ number, label, onClick, ...attrs }) => {
  const displayNumber = useMemo(() => kmgFormat(number), [number]);

  return (
    <Container {...attrs} onClick={onClick}>
      <span>{displayNumber}</span>
      <span>{label}</span>
    </Container>
  );
};
