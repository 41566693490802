import { GUserResponse } from "core/model/site/top/girl-detail";
import { showPageSpinner, hidePageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";

/**
 * Gユーザ詳細取得
 * @param userId
 * @returns 取得結果
 */
export const initializeGirlDetail = async (userId: string) => {
  showPageSpinner();
  try {
    const res = await Api.get<GUserResponse>("site/user/info/l", {
      params: {
        id: userId,
      },
    });
    // 成功の場合
    if (isSuccess(res)) {
      return res.body;
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return undefined;
};
