import { Fab, Link, Navbar, NavRight, Page, PageContent } from "framework7-react";
import React, { useCallback, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";

import { authorizationSelector } from "core/slice/authorization";
import { siteTimelineSelector } from "core/slice/site/timeline";
import { getTimelinesOfNextPage, initializeTimelines, toggleFavorite } from "core/usecase/site/timeline";

import { FavoriteIcon } from "ui/component/site/timeline/favorite-icon";
import { TimelineCreateSheet } from "ui/component/site/timeline/timeline-create-sheet";
import { TimelineItem } from "ui/component/site/timeline/timeline-item";
import { TimelineSkeleton } from "ui/component/site/timeline/timeline-skeleton";
import { Icon } from "ui/widget/icon";
import { NoData } from "ui/widget/no-data";

/**
 * TimelinePage
 * @returns TimelinePage
 */
export const TimelinePage: React.FC = () => {
  const allowInfinite = useRef(true);
  const [loadMore, setLoadMore] = useState(true);
  const [createSheetOpened, setCreateSheetOpened] = useState(false);
  const { timelineList, initialized } = useSelector(siteTimelineSelector);

  // 認証情報
  const { principal } = useSelector(authorizationSelector);

  useEffectOnce(() => {
    initializeTimelines().then(setLoadMore);
  });

  /**
   * handleInfinite
   */
  const handleInfinite = useCallback(() => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    getTimelinesOfNextPage()
      .then(setLoadMore)
      .finally(() => {
        allowInfinite.current = true;
      });
  }, []);

  return (
    <Page pageContent={false}>
      <Navbar title="朋友圈">
        <NavRight>
          <Link href="/timeline/my" transition="f7-push">
            我的
          </Link>
        </NavRight>
      </Navbar>
      <PageContent infinite={loadMore} onInfinite={handleInfinite}>
        <div style={{ paddingTop: 16, paddingBottom: 16 }}>
          {!initialized ? (
            Array.from(Array(4).keys()).map(index => <TimelineSkeleton key={index} />)
          ) : timelineList && timelineList.length > 0 ? (
            timelineList.map(item => (
              <TimelineItem
                key={item.id}
                item={item}
                link={principal?.id === item.userId ? "/timeline/my" : `/timeline/user/${item.userId}`}
                extra={
                  <FavoriteIcon
                    favorited={item.favorited}
                    favoriteCount={item.favoriteCount}
                    onClick={() => toggleFavorite(item)}
                  />
                }
              />
            ))
          ) : (
            <NoData title={<FormattedMessage id="common.nodata" />} />
          )}
        </div>
      </PageContent>
      <Fab position="right-bottom" slot="fixed" color="orange" onClick={() => setCreateSheetOpened(true)}>
        <Icon name="add" size={36} />
      </Fab>
      <TimelineCreateSheet opened={createSheetOpened} onClose={() => setCreateSheetOpened(false)} />
    </Page>
  );
};
