import { SignupRequest } from "core/model/signup";
import Api, { isSuccess } from "core/utils/api";
import { showErrorModal } from "core/utils/common";

import { Role } from "global/enum";

import { hidePageSpinner, showPageSpinner } from "../global";

/**
 * ユーザ存在チェック
 * @param email
 */
export const userExist = async (email: string): Promise<string | undefined> => {
  try {
    const res = await Api.get("/user/exist", { params: { email } });
    return isSuccess(res) ? "" : res.message;
  } catch {
    return undefined;
  }
};

/**
 * アカウント作成処理
 * @param data
 * @param role
 * @returns 結果
 */
export const registUser = async (data: SignupRequest, role: Role) => {
  if (role === Role.system) return false;
  showPageSpinner();
  try {
    const res = await Api.post(role === Role.site ? "/site/user/c" : "/user/c", data);
    // 成功の場合
    if (isSuccess(res)) {
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};

/**
 * アカウント作成後のメール確認
 * @param code
 * @param role
 * @returns 処理結果
 */
export const userVerify = async (code: string, role: Role) => {
  if (role === Role.system) return false;
  showPageSpinner();
  try {
    const res = await Api.get(role === Role.site ? "/site/user/verify" : "/user/verify", { params: { code } });
    // 成功の場合
    if (isSuccess(res)) {
      return true;
    }
    showErrorModal(res.message);
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
  return false;
};
