import { App as F7App, View } from "framework7-react";
import { AppProps as F7AppProps } from "framework7-react/components/app";
import { Localized } from "i18n";
import { Provider as StoreProvider } from "react-redux";
import { routes } from "routes";
import { SocketClientProvider } from "socket";
import { store } from "store";

import { authorization } from "routes/authorization";

const f7params: F7AppProps = {
  theme: "auto",
  name: "19momo App",
  id: "co.jp.19momo",
  routes,
  view: {
    browserHistory: true,
    browserHistorySeparator: "",
    routesBeforeEnter: authorization,
  },
};

/**
 * App
 * @returns App
 */
const App = () => (
  <StoreProvider store={store}>
    <Localized>
      <SocketClientProvider>
        <F7App {...f7params}>
          <View url="/" main />
        </F7App>
      </SocketClientProvider>
    </Localized>
  </StoreProvider>
);

export default App;
