import dayjs, { Dayjs } from "dayjs";
import { Block, Button, Col, List, ListInput, ListItem, Row } from "framework7-react";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import { ScheduleListResponse } from "core/model/girl/schedule";
import { RegistReserveRequest } from "core/model/site/order";
import { registReserve } from "core/usecase/site/order";
import { showConfirmModal, toast } from "core/utils/common";

import { Address, Area } from "global/enum";
import { AreaLabel, AddressLabel } from "global/enum-label";
import { ReserveTypeLabel } from "global/enum-label/reserve";
import { ReserveType } from "global/enum/reserve";

import { Icon } from "ui/widget/icon";
import { Modal } from "ui/widget/modal";
import { StyledText } from "ui/widget/styled-text";

import { AddressModal } from "../../order/reserve-condition/address-modal";
import { ConditionItem } from "../../order/reserve-condition/condition-item";
import { ReserveHourModal } from "../../order/reserve-condition/reserve-hour-modal";
import { ScheduledMeetTimeModal } from "../../order/reserve-condition/scheduled-meet-time-modal";

type ReserveModalProps = {
  userId: string;
  price: number;
  scheduleList: ScheduleListResponse;
  addressList: Array<Address> | undefined;
  opened: boolean;
  onClose: () => void;
};

type ReserveCondition = Omit<RegistReserveRequest, "typeOther"> & {
  typeOther?: string;
};

/**
 * 予約モーダル
 * @param props
 * @param props.userId
 * @param props.price
 * @param props.scheduleList
 * @param props.addressList
 * @param props.opened
 * @param props.onClose
 * @returns ReserveModal
 */
export const ReserveModal: React.FC<ReserveModalProps> = ({
  userId,
  price,
  scheduleList,
  addressList,
  opened,
  onClose,
}) => {
  const [reserve, setReserve] = useState<ReserveCondition>({
    type: ReserveType.halfHourLater,
    area: Area.Tokyo,
    areaAddr: addressList?.[0] || Address.Roppongi,
    hour: 1,
    castUserIdList: [userId],
  });

  const [scheduledMeetTimeModalOpened, setScheduledMeetTimeModalOpened] = useState(false);
  const [reserveHourModelOpened, setReserveHourModelOpened] = useState(false);
  const [addressModalOpened, setAddressModalOpened] = useState(false);

  // 料金合計
  const totalPrice = useMemo(() => price * reserve.hour * 2, [price, reserve.hour]);

  // 選択可能な日付
  const selectableDates = useMemo(
    () =>
      scheduleList
        .filter(o => o.workStatus === "1")
        .map(o => dayjs(o.workYmd, "YYYYMMDD"))
        .filter(date => !date.isBefore(dayjs(), "day")),
    [scheduleList],
  );

  // エラー
  const validationError = useMemo(() => {
    const now = dayjs();
    // 合流日付チェック
    let meetTime: Dayjs;
    if (reserve.type === ReserveType.halfHourLater) {
      meetTime = now.add(0.5, "hour");
    } else if (reserve.type === ReserveType.oneHourLater) {
      meetTime = now.add(1, "hour");
    } else if (reserve.type === ReserveType.oneAndHalfHourLater) {
      meetTime = now.add(1.5, "hour");
    } else {
      meetTime = dayjs(reserve.typeOther);
    }
    const isMeetTimeValid = selectableDates.some(o => o.isSame(meetTime, "day"));
    if (!isMeetTimeValid) {
      return "当前时间无法预约，请选择其他时间。";
    }

    // 予約場所チェック
    if (addressList && !addressList.includes(reserve.areaAddr)) {
      return "当前地点无法预约，请选择其他场所。";
    }

    return undefined;
  }, [reserve.type, reserve.areaAddr, reserve.typeOther, selectableDates, addressList]);

  /**
   * 予約確認
   */
  const reserveConfirm = useCallback(() => {
    if (validationError) return;
    showConfirmModal("是否确定预约?", () => {
      const { typeOther, ...others } = reserve;
      const requestBody: RegistReserveRequest = {
        ...others,
        typeOther: typeOther ? dayjs(typeOther).toDate() : undefined,
      };
      registReserve(requestBody).then(reserveNo => {
        if (reserveNo) {
          toast("Success");
          onClose();
        }
      });
    });
  }, [onClose, reserve, validationError]);

  return (
    <>
      <Modal
        opened={opened}
        closeButton
        title="女孩预约"
        onClose={onClose}
        fluid
        content={
          <Block className="no-margin-bottom no-padding text-align-left">
            {validationError && (
              <Block
                inset
                strong
                textColor="error"
                bgColor="error"
                borderColor="error"
                className="text-align-middle bordered no-margin-left no-margin-right"
              >
                <Icon name="error" />
                <strong>{validationError}</strong>
              </Block>
            )}
            <Row>
              <Col width={100} xsmall={50} style={{ marginBottom: 16 }}>
                <ConditionItem
                  icon={<Icon name="schedule" />}
                  label="预约时间:"
                  title={
                    <>
                      <FormattedMessage id={ReserveTypeLabel[reserve.type]} />
                      <br />
                      <span style={{ fontWeight: "normal", color: "orange" }}>
                        {reserve.type === ReserveType.other && `(${reserve.typeOther})`}
                      </span>
                    </>
                  }
                  onClick={() => setScheduledMeetTimeModalOpened(true)}
                />
              </Col>
              <Col width={100} xsmall={50} style={{ alignSelf: "stretch", marginBottom: 16 }}>
                <ConditionItem
                  icon={<Icon name="timer" shape="outlined" />}
                  label="预约时长:"
                  title={`${reserve.hour}小时`}
                  onClick={() => setReserveHourModelOpened(true)}
                />
              </Col>
            </Row>
            <Row>
              <Col width={100} style={{ marginBottom: 16 }}>
                <ConditionItem
                  icon={<Icon name="place" shape="outlined" />}
                  label="场所:"
                  title={
                    <>
                      <FormattedMessage id={AreaLabel[reserve.area]} />
                      &emsp;
                      <FormattedMessage id={AddressLabel[reserve.areaAddr]} />
                    </>
                  }
                  onClick={() => setAddressModalOpened(true)}
                />
              </Col>
            </Row>
            <List className="list-bg-transparent">
              <ListItem>
                <div>所需积分</div>
                <div>
                  <StyledText color="gold" size={28}>
                    <FormattedNumber value={totalPrice} />
                  </StyledText>
                  &nbsp;P
                </div>
              </ListItem>
            </List>
            <List className="list-no-divider list-item-no-padding list-bg-transparent">
              <ListInput
                label="备注"
                type="textarea"
                value={reserve.memo}
                placeholder="在此输入备注信息, 200字以内"
                maxlength={200}
                onChange={e => setReserve(prev => ({ ...prev, memo: e.target.value }))}
                clearButton
                onInputClear={() => setReserve(prev => ({ ...prev, memo: undefined }))}
                outline
              />
            </List>
            <Row>
              <Col width={100}>
                <Button type="button" fill color="orange" onClick={reserveConfirm} disabled={!!validationError}>
                  预约确定
                </Button>
              </Col>
            </Row>
          </Block>
        }
      />
      <ScheduledMeetTimeModal
        opened={scheduledMeetTimeModalOpened}
        onClose={() => setScheduledMeetTimeModalOpened(false)}
        type={reserve.type}
        typeOther={reserve.typeOther}
        onConfirm={(type, typeOther) => setReserve(prev => ({ ...prev, type, typeOther }))}
        selectableDates={selectableDates}
      />
      <ReserveHourModal
        opened={reserveHourModelOpened}
        onClose={() => setReserveHourModelOpened(false)}
        hour={reserve.hour}
        onConfirm={hour => setReserve(prev => ({ ...prev, hour }))}
      />
      <AddressModal
        opened={addressModalOpened}
        onClose={() => setAddressModalOpened(false)}
        area={reserve.area}
        areaAddr={reserve.areaAddr}
        selectableAddress={addressList}
        onConfirm={(area, areaAddr) => setReserve(prev => ({ ...prev, area, areaAddr }))}
      />
    </>
  );
};
