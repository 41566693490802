import { useFormik } from "formik";
import { Navbar, NavLeft, Link, NavTitle, NavRight, Page } from "framework7-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { profileSelector } from "core/slice/girl/profile";
import { profileInitialize, updateProfile } from "core/usecase/girl/profile";
import { goBack } from "core/usecase/global";

import { FormikList, FormikListInput } from "ui/widget/formik";

type Shape = { birthplace?: string };

/**
 * 出身地編集画面
 * @returns BirthplaceEditor
 */
export const BirthplaceEditorPage: React.FC = () => {
  const { birthplace } = useSelector(profileSelector);

  const formik = useFormik<Shape>({
    initialValues: {
      birthplace: birthplace || "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      birthplace: yup.string().max(50),
    }),
    onSubmit(value) {
      updateProfile("/profile/birthplace/s", value).then(success => {
        if (success) goBack("/girl/profile/base");
      });
    },
  });

  const { setValues } = formik;

  /**
   * データ再設定
   */
  useEffect(() => {
    setValues({ birthplace });
  }, [birthplace, setValues]);

  return (
    <Page onPageBeforeIn={profileInitialize}>
      <Navbar>
        <NavLeft backLink backLinkUrl="/girl/profile/base" backLinkForce />
        <NavTitle>出身地</NavTitle>
        <NavRight>
          <Link href={false} onClick={formik.submitForm}>
            確定
          </Link>
        </NavRight>
      </Navbar>

      <FormikList formik={formik}>
        <FormikListInput
          type="text"
          name="birthplace"
          label="出身地"
          maxlength={50}
          info="50文字以内で入力してください"
        />
      </FormikList>
    </Page>
  );
};
