import { useFormik } from "formik";
import { Button, Block, Link, ListItem, LoginScreenTitle } from "framework7-react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";

import { LoginModel, LoginRequest } from "core/model/authorization";

import { Divider } from "ui/widget/divider";
import { FormikList, FormikListInput } from "ui/widget/formik/";
import { Icon } from "ui/widget/icon";

type LoginFormProps = {
  /** onSubmit */
  onSubmit: (values: LoginRequest) => void;
  /** パスワード忘れページリンク */
  forgotPasswordLink: string;
  /** アカウント作成ページリンク */
  signupLink: string;
};

/**
 * LoginForm
 * @param props
 * @param props.onSubmit
 * @param props.forgotPasswordLink
 * @param props.signupLink
 * @returns Login
 */
export const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, forgotPasswordLink, signupLink }) => {
  const intl = useIntl();

  const formik = useFormik<LoginModel>({
    initialValues: {
      username: "",
      password: "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      username: yup.string().required().max(100),
      password: yup.string().required().min(6).max(45),
    }),
    onSubmit,
  });

  return (
    <Block inset strong>
      <LoginScreenTitle>
        <FormattedMessage id="login.title" />
      </LoginScreenTitle>
      <br />

      <FormikList noDivider formik={formik}>
        {/* アカウントID */}
        <FormikListInput
          type="text"
          placeholder={intl.formatMessage({ id: "login.account.id.placeholder" })}
          name="username"
          outline
        >
          <div slot="label">
            <FormattedMessage id="login.account.id" />
          </div>
          <Icon name="person_outline" size="28px" slot="media" style={{ marginTop: 28 }} />
        </FormikListInput>

        {/* パスワード */}
        <FormikListInput
          type="password"
          placeholder={intl.formatMessage({ id: "login.password.placeholder" })}
          name="password"
          outline
        >
          <div slot="label">
            <FormattedMessage id="login.password" />
          </div>
          <Icon name="lock_outline" size="28px" slot="media" style={{ marginTop: 28 }} />
        </FormikListInput>

        {/* パスワードお忘れの方はこちら */}
        <ListItem>
          <Link href={forgotPasswordLink} transition="f7-push">
            <FormattedMessage id="login.password.forgot" />
          </Link>
        </ListItem>

        {/* ログイン */}
        <ListItem>
          <Button type="submit" fill>
            <FormattedMessage id="login.button" />
          </Button>
        </ListItem>

        <ListItem>
          <Divider label={<FormattedMessage id="common.or" />} style={{ padding: 0 }} />
        </ListItem>

        {/* アカウントの新規作成 */}
        <ListItem>
          <Button outline href={signupLink} transition="f7-push" style={{ width: "100%" }}>
            <FormattedMessage id="login.account.create" />
          </Button>
        </ListItem>
      </FormikList>
    </Block>
  );
};
