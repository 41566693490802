import dayjs, { OpUnitType } from "dayjs";
import * as localeJa from "i18n/ja/yup";
import * as localeZh from "i18n/zh/yup";
import {
  setLocale,
  addMethod,
  string,
  StringSchema,
  Message,
  CustomStringLocale,
  StringDateCompareOptions,
  Reference,
  CustomDateLocale,
  DateSchema,
  date,
} from "yup";

import { SupportedLanguage } from "global/enum";
import { mixed } from "global/types";

/**
 * Hyphen
 */
export enum Hyphen {
  Forbidden,
  Only,
  Allow,
}

const Patterns = {
  katakana: /^[ァ-ンヴー]+$/,
  hiragana: /^[ぁ-んー]+$/,
  zenkaku: /^[^\x20-\x7e]+$/,
  hankaku: /^[\x20-\x7e]+$/,
  number: /^\d+$/,
  decimal: /^[-]?\d+(\.\d+)?$/,
  alpha: /^[a-zA-Z]+$/,
  alphaNumber: /^[0-9a-zA-Z]+$/,
  alphaNumberSymbol: /^[0-9a-zA-Z!-/:-@[-`{-~]+$/,
  alphaSymbol: /^[a-zA-Z!-/:-@[-`{-~]+$/,
  numberSymbol: /^[0-9!-/:-@[-`{-~]+$/,
  symbol: /^[!-/:-@[-`{-~]+$/,
  tel: /^(0[5-9]0\d{8}|0[1-9]\d{8})$/,
  telHyphen:
    /^(0[5-9]0-\d{4}-\d{4})|(0[1-9]-\d{4}-\d{4})|(0[1-9]\d-\d{3}-\d{4})|(0[1-9]\d{2}-\d{2}-\d{4})|(0[1-9]\d{3}-\d-\d{4})|(0[1-9]\d{4}-\d{4})$/,
  postal: /^\d{7}$/,
  postalHyphen: /^\d{3}-\d{4}$/,
};

type StringValidators = Array<{
  name: keyof CustomStringLocale;
  validator: (this: StringSchema, defaultMessage: Message, ...args: mixed[]) => StringSchema;
}>;

type DateValidators = Array<{
  name: keyof CustomDateLocale;
  validator: (this: DateSchema, defaultMessage: Message, ...args: mixed[]) => DateSchema;
}>;

const stringValidators: StringValidators = [
  // 選択必須チェック
  {
    name: "selectRequired",
    validator(defaultMessage, message?: Message) {
      return this.test("selectRequired", message ?? defaultMessage, value => !!value);
    },
  },
  // サイズ区間チェック
  {
    name: "lengthBetween",
    validator(defaultMessage, min: number, max: number, message?: Message) {
      return this.test({
        name: "lengthBetween",
        message: message ?? defaultMessage,
        params: { min, max },
        test(value) {
          if (!value) return true;
          return value.length >= min && value.length <= max;
        },
      });
    },
  },
  // カタカナチェック
  {
    name: "katakana",
    validator(defaultMessage, message?: Message) {
      return this.test("katakana", message ?? defaultMessage, value => {
        if (!value) return true;
        return Patterns.katakana.test(value);
      });
    },
  },
  // ひらがなチェック
  {
    name: "hiragana",
    validator(defaultMessage, message?: Message) {
      return this.test("hiragana", message ?? defaultMessage, value => {
        if (!value) return true;
        return Patterns.hiragana.test(value);
      });
    },
  },
  // 全角文字チェック
  {
    name: "zenkaku",
    validator(defaultMessage, message?: Message) {
      return this.test("zenkaku", message ?? defaultMessage, value => {
        if (!value) return true;
        return Patterns.zenkaku.test(value);
      });
    },
  },
  // 半角文字チェック
  {
    name: "hankaku",
    validator(defaultMessage, message?: Message) {
      return this.test("hankaku", message ?? defaultMessage, value => {
        if (!value) return true;
        return Patterns.hankaku.test(value);
      });
    },
  },
  // 数字チェック
  {
    name: "number",
    validator(defaultMessage, message?: Message) {
      return this.test("number", message ?? defaultMessage, value => {
        if (!value) return true;
        return Patterns.number.test(value);
      });
    },
  },
  // 整数または小数チェック
  {
    name: "decimal",
    validator(defaultMessage, message?: Message) {
      return this.test("decimal", message ?? defaultMessage, value => {
        if (!value) return true;
        return Patterns.decimal.test(value);
      });
    },
  },
  // 日付チェック
  // format参照: https://dayjs.gitee.io/docs/zh-CN/display/format
  {
    name: "date",
    validator(defaultMessage, format: string, message?: Message) {
      return this.test({
        name: "date",
        message: message ?? defaultMessage,
        params: { format },
        test(value) {
          if (!value) return true;
          return dayjs(String(value), format, true).isValid();
        },
      });
    },
  },
  // 過去日チェック
  {
    name: "pastDate",
    validator(defaultMessage, option: StringDateCompareOptions, message?: Message) {
      return this.test("pastDate", message ?? defaultMessage, value => {
        if (!value) return true;
        return dayjs(value, option.format, true).isBefore(dayjs(), option.unit);
      });
    },
  },
  // 未来日チェック
  {
    name: "futureDate",
    validator(defaultMessage, option: StringDateCompareOptions, message?: Message) {
      return this.test("futureDate", message ?? defaultMessage, value => {
        if (!value) return true;
        return dayjs(value, option.format, true).isAfter(dayjs(), option.unit);
      });
    },
  },
  // 英字チェック
  {
    name: "alpha",
    validator(defaultMessage, message?: Message) {
      return this.test("alpha", message ?? defaultMessage, value => {
        if (!value) return true;
        return Patterns.alpha.test(value);
      });
    },
  },
  // 英数字チェック
  {
    name: "alphaNumber",
    validator(defaultMessage, message?: Message) {
      return this.test("alphaNumber", message ?? defaultMessage, value => {
        if (!value) return true;
        return Patterns.alphaNumber.test(value);
      });
    },
  },
  // 英数字記号チェック
  {
    name: "alphaNumberSymbol",
    validator(defaultMessage, message?: Message) {
      return this.test("alphaNumberSymbol", message ?? defaultMessage, value => {
        if (!value) return true;
        return Patterns.alphaNumberSymbol.test(value);
      });
    },
  },
  // 英字記号チェック
  {
    name: "alphaSymbol",
    validator(defaultMessage, message?: Message) {
      return this.test("alphaSymbol", message ?? defaultMessage, value => {
        if (!value) return true;
        return Patterns.alphaSymbol.test(value);
      });
    },
  },
  // 数字記号チェック
  {
    name: "numberSymbol",
    validator(defaultMessage, message?: Message) {
      return this.test("numberSymbol", message ?? defaultMessage, value => {
        if (!value) return true;
        return Patterns.numberSymbol.test(value);
      });
    },
  },
  // 記号チェック
  {
    name: "symbol",
    validator(defaultMessage, message?: Message) {
      return this.test("symbol", message ?? defaultMessage, value => {
        if (!value) return true;
        return Patterns.symbol.test(value);
      });
    },
  },
  // 電話番号チェック
  {
    name: "tel",
    validator(defaultMessage, hyphen: Hyphen, message?: Message) {
      return this.test("tel", message ?? defaultMessage, value => {
        if (!value) return true;
        if (hyphen === Hyphen.Forbidden) {
          return Patterns.tel.test(value);
        }
        if (hyphen === Hyphen.Only) {
          return Patterns.telHyphen.test(value);
        }
        return Patterns.tel.test(value) || Patterns.telHyphen.test(value);
      });
    },
  },
  // 郵便番号チェック
  {
    name: "postal",
    validator(defaultMessage, hyphen: Hyphen, message?: Message) {
      return this.test("postal", message ?? defaultMessage, value => {
        if (!value) return true;
        if (hyphen === Hyphen.Forbidden) {
          return Patterns.postal.test(value);
        }
        if (hyphen === Hyphen.Only) {
          return Patterns.postalHyphen.test(value);
        }
        return Patterns.postal.test(value) || Patterns.postalHyphen.test(value);
      });
    },
  },
  // 一致チェック
  {
    name: "equalWith",
    validator(defaultMessage, reference: { ref: Reference; label?: string }, message?: Message) {
      return this.test({
        name: "equalWith",
        message: message ?? defaultMessage,
        params: { reference: reference.label },
        test(value) {
          if (!value) return true;
          return String(value) === this.resolve(reference.ref);
        },
      });
    },
  },
];

const dateValidators: DateValidators = [
  // 過去日チェック
  {
    name: "pastDate",
    validator(defaultMessage, unit?: OpUnitType, message?: Message) {
      return this.test("pastDate", message ?? defaultMessage, value => {
        if (!value) return true;
        return dayjs(value).isBefore(dayjs(), unit);
      });
    },
  },
  // 未来日チェック
  {
    name: "futureDate",
    validator(defaultMessage, unit?: OpUnitType, message?: Message) {
      return this.test("futureDate", message ?? defaultMessage, value => {
        if (!value) return true;
        return dayjs(value).isAfter(dayjs(), unit);
      });
    },
  },
];

/**
 * yup初期化
 * @param language
 */
export const yupInit = (language = SupportedLanguage.chinese) => {
  const { locale, customStringLocale, customDateLocale } = language === SupportedLanguage.chinese ? localeZh : localeJa;
  setLocale(locale);

  // stringValidators
  stringValidators.forEach(({ name, validator }) => {
    addMethod<StringSchema>(string, name, function fn(...args: mixed[]) {
      const defaultMessage = customStringLocale[name];
      return validator.apply(this, [defaultMessage, ...args]);
    });
  });

  // dateValidators
  dateValidators.forEach(({ name, validator }) => {
    addMethod<DateSchema>(date, name, function fn(...args: mixed[]) {
      const defaultMessage = customDateLocale[name];
      return validator.apply(this, [defaultMessage, ...args]);
    });
  });
};

yupInit();
