import { Button, List, ListItem, Stepper } from "framework7-react";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { ReserveCastState } from "core/model/site/order";

import { Ranking } from "global/enum";

import { Modal } from "ui/widget/modal";
import { RankingBar } from "ui/widget/ranking-bar";

type PeopleNumberModalProps = {
  opened: boolean;
  onClose: () => void;
  casts: ReserveCastState[];
  onConfirm: (casts: ReserveCastState[]) => void;
};

const Container = styled.div({
  "--f7-list-item-padding-horizontal": 0,

  ".list .item-title": {
    width: "100%",
    paddingLeft: 2,
    paddingRight: 2,

    ".item-title-inner": {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      justifyContent: "space-between",

      ".people-number": {
        marginRight: 2,
        marginBottom: 8,
        fontSize: 32,
        fontWeight: "bold",
        color: "orange",
      },
    },
  },
});

/**
 * PeopleNumberModal
 * @param props
 * @param props.opened
 * @param props.onClose
 * @param props.casts
 * @param props.onConfirm
 * @returns PeopleNumberModal
 */
export const PeopleNumberModal: React.FC<PeopleNumberModalProps> = ({ opened, onClose, casts, onConfirm }) => {
  const [localCasts, setLocaleCasts] = useState<ReserveCastState[]>([]);

  useEffect(() => {
    if (!opened) return;
    setLocaleCasts(
      Object.values(Ranking).map(o => {
        const peopleNumber = casts.find(m => m.ranking === o)?.peopleNumber ?? 0;
        return {
          ranking: o,
          peopleNumber,
        };
      }),
    );
  }, [opened, casts]);

  /**
   * handlePeopleNumberChange
   */
  const handlePeopleNumberChange = useCallback((value: number, ranking: Ranking) => {
    setLocaleCasts(prev => {
      const next = [...prev];
      const index = next.findIndex(o => o.ranking === ranking);
      next[index].peopleNumber = value;
      return next;
    });
  }, []);

  /**
   * handleConfirm
   */
  const handleConfirm = useCallback(() => {
    const selected = localCasts.filter(o => o.peopleNumber > 0);
    if (selected.length === 0) return;
    onConfirm(selected);
    onClose();
  }, [localCasts, onClose, onConfirm]);

  return (
    <Modal
      fluid
      opened={opened}
      title="预约女孩人数(级别)"
      onClose={onClose}
      content={
        <Container>
          <List>
            {localCasts.map(o => (
              <ListItem key={o.ranking}>
                <div className="item-title-inner" slot="title">
                  <RankingBar shadow ranking={o.ranking} />
                  <div>
                    <span className="people-number">{o.peopleNumber}</span>人
                  </div>
                  <Stepper
                    min={0}
                    buttonsOnly
                    small
                    value={o.peopleNumber}
                    color="green"
                    raised
                    onStepperChange={v => handlePeopleNumberChange(v, o.ranking)}
                  />
                </div>
              </ListItem>
            ))}
          </List>
          <Button fill onClick={handleConfirm} disabled={!localCasts.some(o => o.peopleNumber > 0)}>
            确定
          </Button>
        </Container>
      }
    />
  );
};
