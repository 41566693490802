import emptySvg from "assets/image/empty.svg";
import { Block, BlockTitle, Button, Link, List, ListItem } from "framework7-react";
import React from "react";
import { useSelector } from "react-redux";

import { bankSelector } from "core/slice/girl/bank";

import { DepositTypeLabel } from "global/enum-label/girl";
import { DepositType } from "global/enum/profile-girl";

import { Divider } from "ui/widget/divider";
import { Icon } from "ui/widget/icon";

/**
 * Payment Tab
 * @returns Payment Tab
 */
export const TabPayment: React.FC = () => {
  const bank = useSelector(bankSelector);

  return (
    <>
      {!bank ? (
        <Block style={{ display: "flex", flexFlow: "column nowrap", justifyContent: "center", alignItems: "center" }}>
          <img src={emptySvg} alt="no data" style={{ maxWidth: 260 }} />
          <BlockTitle className="no-margin">口座情報未設定</BlockTitle>
          <Divider />
          <Button fill style={{ width: "50%" }} href="/girl/profile/bank" transition="f7-cover-v">
            <Icon name="add" size={20} />
            &nbsp;新規追加
          </Button>
        </Block>
      ) : (
        <>
          <BlockTitle style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            口座情報
            <Link href="/girl/profile/bank" transition="f7-cover-v" color="orange">
              <Icon name="border_color" size={18} />
              &nbsp;<span>編集</span>
            </Link>
          </BlockTitle>
          <List>
            <ListItem title="金融機関コード" after={bank.bankCd}>
              <Icon name="account_balance_wallet" color="green" slot="media" />
            </ListItem>
            <ListItem title="金融機関名称" after={bank.bankNm}>
              <Icon name="account_balance_wallet" color="green" slot="media" />
            </ListItem>
            <ListItem title="支店名（漢字）" after={bank.bankchKanji}>
              <Icon name="account_balance" color="orange" slot="media" />
            </ListItem>
            <ListItem title="支店名（カナ）" after={bank.bankchKana}>
              <Icon name="account_balance" color="orange" slot="media" />
            </ListItem>
            <ListItem title="支店コード" after={bank.bankchCd}>
              <Icon name="account_balance" color="orange" slot="media" />
            </ListItem>
            <ListItem title="預金種類" after={DepositTypeLabel[bank.depositType as DepositType]}>
              <Icon name="store" color="teal" slot="media" />
            </ListItem>
            <ListItem title="口座番号" after={bank.accountCd}>
              <Icon name="payment" color="red" slot="media" />
            </ListItem>
            <ListItem title="口座名義（漢字）" after={bank.accountNm}>
              <Icon name="perm_identity" color="purple" slot="media" />
            </ListItem>
            <ListItem title="口座名義（カナ）" after={bank.accountNmKana}>
              <Icon name="perm_identity" color="purple" slot="media" />
            </ListItem>
          </List>
        </>
      )}
    </>
  );
};
