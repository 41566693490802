import { Page } from "framework7-react";
import React from "react";

import { PasswordResetCompleteBlock } from "ui/component/forgot-password/password-reset-complete";

/**
 * パスワード再設定完了画面
 * @returns PasswordResetComplete
 */
export const PasswordResetCompletePage: React.FC = () => (
  <Page noNavbar noToolbar>
    <PasswordResetCompleteBlock loginLink="/girl/login" />
  </Page>
);
