import { Block, Link, SkeletonBlock } from "framework7-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { siteShopSelector } from "core/slice/site/shop";

import { CommonConfig } from "global/config";

import { Gallery, GalleryItem } from "ui/widget/gallery";
import { Icon } from "ui/widget/icon";
import { NoData } from "ui/widget/no-data";

const ShopContainer = styled.div({
  cursor: "pointer",
  border: "1px solid orange",
  height: "100%",
  borderRadius: "inherit",

  ".link": {
    display: "block",
  },

  img: {
    width: "100%",
    aspectRatio: "15/ 16",
    objectFit: "cover",
  },
  ".shop-alt": {
    width: "100%",
    aspectRatio: "15/ 16",
    background: "var(--skeleton-color)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".shop-desc": {
    padding: "2px 6px 6px",
    color: "#ccc",

    ".shop-name": {
      fontWeight: "bold",
      fontSize: 15,
    },

    ".shop-open-time": {
      color: "#c6ee0e",
    },
  },
});

/**
 * 店舗一覧
 * @returns ShopList
 */
export const ShopList: React.FC = () => {
  const { initialized, data } = useSelector(siteShopSelector).list;

  return (
    <Block>
      {!initialized ? (
        <Gallery columns={2}>
          {Array.from(Array(8).keys()).map(index => (
            <GalleryItem key={index}>
              <SkeletonBlock
                tag="div"
                width="100%"
                height="100%"
                borderRadius="4px"
                effect="wave"
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                <Icon name="store" size={90} color="gray" />
              </SkeletonBlock>
            </GalleryItem>
          ))}
        </Gallery>
      ) : data && data.length > 0 ? (
        <Gallery columns={2}>
          {data.map(item => (
            <GalleryItem key={item.shopId}>
              <ShopContainer>
                <Link href={`/shop/${item.shopId}`} transition="f7-push">
                  {item.image1 ? (
                    <img src={CommonConfig.shopPhotoBaseUrl + item.image1} alt="shop" />
                  ) : (
                    <div className="shop-alt">
                      <Icon name="store" size={80} color="gray" />
                    </div>
                  )}
                  <div className="shop-desc">
                    <div className="shop-name">{item.shopName}</div>
                    <div>
                      营业时间: <span className="shop-open-time">{item.openTime}</span>
                    </div>
                  </div>
                </Link>
              </ShopContainer>
            </GalleryItem>
          ))}
        </Gallery>
      ) : (
        <NoData title={<FormattedMessage id="common.nodata" />} />
      )}
    </Block>
  );
};
