import { Block, BlockTitle, Link, List, ListItem, Swiper, SwiperSlide } from "framework7-react";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SwiperClass from "swiper/types/swiper-class";

import { ScheduleListResponse } from "core/model/girl/schedule";

import { Address, Area } from "global/enum";
import { AddressLabel, AreaLabel } from "global/enum-label";

import { ScheduleItem } from "./schedule-item";

type ScheduleSectionProps = {
  scheduleList: ScheduleListResponse;
  addressList: Array<Address> | undefined;
};

/**
 * ScheduleSection
 * @param props
 * @param props.scheduleList
 * @param props.addressList
 * @returns ScheduleSection
 */
export const ScheduleSection: React.FC<ScheduleSectionProps> = ({ scheduleList, addressList }) => {
  const [swiper, setSwiper] = useState<SwiperClass>();
  const { formatMessage } = useIntl();

  return (
    <Block strong>
      <BlockTitle medium>出勤</BlockTitle>
      <List>
        <ListItem title="都道府县">
          <div slot="after">
            <FormattedMessage id={AreaLabel[Area.Tokyo]} />
          </div>
        </ListItem>
        <ListItem title="出勤地" className="list-item-after-wrap">
          <div slot="after">
            {addressList && addressList.length > 0
              ? addressList.map(addr => formatMessage({ id: AddressLabel[addr] })).join(", ")
              : "任意"}
          </div>
        </ListItem>
      </List>
      {/* <Divider /> */}
      <Swiper pagination simulateTouch={false} onSwiper={setSwiper}>
        <SwiperSlide>
          <ScheduleItem
            header="本周出勤计划"
            headerRight={
              <Link href={false} onClick={() => swiper?.slideNext()}>
                下周&nbsp;&gt;
              </Link>
            }
            scheduleList={scheduleList.slice(0, 7)}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ScheduleItem
            header="下周出勤计划"
            headerLeft={
              <Link href={false} onClick={() => swiper?.slidePrev()}>
                &lt;&nbsp;本周
              </Link>
            }
            scheduleList={scheduleList.slice(7)}
          />
        </SwiperSlide>
      </Swiper>
    </Block>
  );
};
