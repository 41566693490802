import { Navbar, Page, PageContent } from "framework7-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { GirlListResponse } from "core/model/site/top";
import { siteOrderSelector } from "core/slice/site/order";
import { searchReservableCasts } from "core/usecase/site/order";

import { CastSelect } from "ui/component/site/order/cast-select/cast-select";
import { CastSelectAction } from "ui/component/site/order/cast-select/cast-select-action";

/**
 * CastSelectPage
 * @returns CastSelectPage
 */
export const CastSelectPage: React.FC = () => {
  const [castList, setCastList] = useState<GirlListResponse>([]);
  const { reserve } = useSelector(siteOrderSelector);

  /**
   * handleBeforePageIn
   */
  const handleBeforePageIn = () => {
    searchReservableCasts(reserve).then(setCastList);
  };

  return (
    <Page pageContent={false} onPageBeforeIn={handleBeforePageIn}>
      <Navbar backLink backLinkForce backLinkUrl="/order" title="女孩选择" />
      <PageContent>
        <CastSelect castList={castList} />
      </PageContent>
      <CastSelectAction />
    </Page>
  );
};
