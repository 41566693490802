import { useFormik } from "formik";
import { Button, ListItem } from "framework7-react";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";

import { PasswordChangeModel } from "core/model/password-change";
import { goBack } from "core/usecase/global";
import { passwordChange } from "core/usecase/password-change";
import { showConfirmModal, toast } from "core/utils/common";

import { FormikList, FormikListInput } from "ui/widget/formik";
import { Icon } from "ui/widget/icon";

type PasswordChangeFormProps = {
  backLink: string;
};

/**
 * バリデーションスキーマ
 * @param passwordLabel
 * @returns バリデーションスキーマ
 */
const createValidationSchema = (passwordLabel: string): yup.ObjectSchema<PasswordChangeModel> =>
  yup.object().shape({
    oldPassword: yup.string().required().lengthBetween(6, 45),
    newPassword: yup.string().required().lengthBetween(6, 45),
    verifyPassword: yup
      .string()
      .required()
      .equalWith({ ref: yup.ref("newPassword"), label: passwordLabel }),
  });

/**
 * パスワード変更form
 * @param props
 * @param props.backLink
 * @returns PasswordChangeForm
 */
export const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({ backLink }) => {
  const intl = useIntl();

  const validationSchema = useMemo(
    () => createValidationSchema(intl.formatMessage({ id: "password.change.new.password" })),
    [intl],
  );

  const formik = useFormik<PasswordChangeModel>({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      verifyPassword: "",
    },
    validateOnChange: true,
    validationSchema,
    onSubmit(values) {
      showConfirmModal(intl.formatMessage({ id: "password.change.confirm.message" }), () => {
        passwordChange(values).then(success => {
          if (success) {
            toast("Success");
            goBack(backLink);
          }
        });
      });
    },
  });

  return (
    <FormikList noDivider formik={formik}>
      <FormikListInput
        type="password"
        placeholder={intl.formatMessage({ id: "password.change.old.password.placeholder" })}
        name="oldPassword"
        outline
      >
        <div slot="label">
          <FormattedMessage id="password.change.old.password" />
        </div>
        <Icon name="lock_outline" size="28px" slot="media" style={{ marginTop: 28 }} />
      </FormikListInput>

      <FormikListInput
        type="password"
        placeholder={intl.formatMessage({ id: "password.change.new.password.placeholder" })}
        name="newPassword"
        outline
      >
        <div slot="label">
          <FormattedMessage id="password.change.new.password" />
        </div>
        <Icon name="lock_outline" size="28px" slot="media" style={{ marginTop: 28 }} />
      </FormikListInput>

      <FormikListInput
        type="password"
        placeholder={intl.formatMessage({ id: "password.change.new.password.confirm.placeholder" })}
        name="verifyPassword"
        outline
      >
        <div slot="label">
          <FormattedMessage id="password.change.new.password.confirm" />
        </div>
        <Icon name="lock_outline" size="28px" slot="media" style={{ marginTop: 28 }} />
      </FormikListInput>

      <ListItem className="margin-top">
        <Button type="submit" fill>
          <FormattedMessage id="password.change.button" />
        </Button>
      </ListItem>
    </FormikList>
  );
};
