import { useFormik } from "formik";
import { Navbar, NavLeft, Link, NavTitle, NavRight, Page } from "framework7-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { profileSelector } from "core/slice/girl/profile";
import { profileInitialize, updateProfile } from "core/usecase/girl/profile";
import { goBack } from "core/usecase/global";

import { FormikList, FormikListInput } from "ui/widget/formik";

type Shape = { interest?: string };

/**
 * 趣味編集画面
 * @returns InterestEditor
 */
export const InterestEditorPage: React.FC = () => {
  const { interest } = useSelector(profileSelector);

  const formik = useFormik<Shape>({
    initialValues: {
      interest: interest || "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      interest: yup.string().max(100),
    }),
    onSubmit(value) {
      updateProfile("/profile/interest/s", value).then(success => {
        if (success) goBack("/girl/profile/base");
      });
    },
  });

  const { setValues } = formik;

  /**
   * データ再設定
   */
  useEffect(() => {
    setValues({ interest });
  }, [interest, setValues]);

  return (
    <Page onPageBeforeIn={profileInitialize}>
      <Navbar>
        <NavLeft backLink backLinkUrl="/girl/profile/base" backLinkForce />
        <NavTitle>趣味</NavTitle>
        <NavRight>
          <Link href={false} onClick={formik.submitForm}>
            確定
          </Link>
        </NavRight>
      </Navbar>

      <FormikList formik={formik}>
        <FormikListInput
          resizable
          type="textarea"
          name="interest"
          label="趣味"
          maxlength={100}
          info="100文字以内で入力してください"
        />
      </FormikList>
    </Page>
  );
};
