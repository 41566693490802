import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { ScheduleListResponse, SaveScheduleResponse, AttendanceData } from "core/model/girl/schedule";

/**
 * state
 */
export type ScheduleState = {
  /** 直近のスケジュール一覧 */
  list?: ScheduleListResponse;
  /** 出勤管理 */
  attendance?: AttendanceData;
  /** attendanceInitialized */
  attendanceInitialized: boolean;
};

const initialState: ScheduleState = {
  attendanceInitialized: false,
};

/**
 * slice
 */
export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    /**
     * スケジュール一覧設定Action
     * @param state
     * @param action
     */
    updateScheduleListAction: (state, action: PayloadAction<ScheduleListResponse>) => {
      state.list = action.payload;
    },
    /**
     * スケジュール更新Action
     * @param state
     * @param action
     */
    updateScheduleItemAction: (state, action: PayloadAction<SaveScheduleResponse>) => {
      if (!state.list) return;
      const index = state.list.findIndex(item => item.workYmd === action.payload.workYmd);
      if (index !== -1) {
        state.list![index] = action.payload;
      }
    },
    /**
     * 出勤管理更新Action
     * @param state
     * @param action
     */
    updateAttendance: (state, action: PayloadAction<AttendanceData | undefined>) => {
      state.attendance = action.payload;
      state.attendanceInitialized = true;
    },
  },
});

/**
 * action
 */
export const scheduleAction = scheduleSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const scheduleSelector = (state: RootState) => state.schedule;
