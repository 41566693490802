import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { MediaItem, MediaListResponse } from "core/model/media";

/**
 * state
 */
export type MediaState = {
  /** list */
  list?: MediaListResponse;
};

const initialState: MediaState = {};

/**
 * slice
 */
export const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    /**
     * 写真・ビデオ一覧設定Action
     * @param state
     * @param action
     */
    updateMediaListAction: (state, action: PayloadAction<MediaListResponse>) => {
      state.list = action.payload;
    },
    /**
     * 写真・ビデオ追加Action
     * @param state
     * @param action
     */
    prependMediaItemAction: (state, action: PayloadAction<MediaItem>) => {
      if (!state.list) {
        state.list = [action.payload];
      } else {
        state.list = [action.payload, ...state.list];
      }
    },
    /**
     * 指定する写真・ビデオの削除Action
     * @param state
     * @param action
     */
    deleteMediaItemAction: (state, action: PayloadAction<MediaItem>) => {
      if (!state.list) return;
      const index = state.list.findIndex(o => o.rowsId === action.payload.rowsId);
      if (index !== -1) {
        state.list.splice(index, 1);
      }
    },
  },
});

/**
 * action
 */
export const mediaAction = mediaSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const mediaSelector = (state: RootState) => state.media.list;
