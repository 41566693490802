import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { ChargeLogResponse, ChargeMResponse } from "core/model/site/profile/charge";

/**
 * state
 */
export type SiteProfileChargeState = {
  /** チャージマスタ情報 */
  chargeMInfo?: ChargeMResponse;
  /** チャージ履歴 */
  chargeLog: {
    initialized: boolean;
    page: number;
    data?: ChargeLogResponse;
  };
};

const initialState: SiteProfileChargeState = {
  chargeLog: {
    initialized: false,
    page: 1,
  },
};

/**
 * slice
 */
export const siteProfileChargeSlice = createSlice({
  name: "site/profile/charge",
  initialState,
  reducers: {
    /**
     * チャージマスタ情報更新
     * @param state
     * @param action
     */
    updateChargeMInfoAction: (state, action: PayloadAction<ChargeMResponse>) => {
      state.chargeMInfo = action.payload;
    },
    /**
     * チャージ履歴ページ更新
     * @param state
     */
    increaseChargeLogPageAction: state => {
      state.chargeLog.page += 1;
    },
    /**
     * チャージ履歴一覧初期化
     * @param state
     * @param action
     */
    initializeChargeLogAction: (state, action: PayloadAction<ChargeLogResponse>) => {
      state.chargeLog.initialized = true;
      state.chargeLog.data = action.payload;
    },
    /**
     * チャージ履歴一覧追加
     * @param state
     * @param action
     */
    appendChargeLogAction: (state, action: PayloadAction<ChargeLogResponse>) => {
      state.chargeLog.data = (state.chargeLog.data || []).concat(action.payload);
    },
    /**
     * チャージ履歴一覧Item削除
     * @param state
     * @param action
     */
    deleteChargeLogItemAction: (state, action: PayloadAction<string>) => {
      if (!state.chargeLog.data) return;
      const index = state.chargeLog.data.findIndex(o => o.orderId === action.payload);
      if (index !== -1) {
        state.chargeLog.data.splice(index, 1);
      }
    },
  },
});

/**
 * action
 */
export const siteProfileChargeAction = siteProfileChargeSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const siteProfileChargeSelector = (state: RootState) => state["site/profile/charge"];
