import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { BankResponse } from "core/model/girl/bank";

/**
 * state
 */
export type BankState = {
  /** data */
  data?: BankResponse;
};

const initialState: BankState = {};

/**
 * slice
 */
export const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    /**
     * 口座情報更新Action
     * @param state
     * @param action
     */
    updateBankAction: (state, action: PayloadAction<BankResponse>) => {
      state.data = action.payload;
    },
  },
});

/**
 * action
 */
export const bankAction = bankSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const bankSelector = (state: RootState) => state.bank.data;
