import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

import { InitMessageResponse, ListPrevMessageResponse, newMessageData, TalkListResponse } from "core/model/talk";

/**
 * state
 */
export type TalkState = {
  /** 未読メッセージ件数  */
  unreadMsgCnt: number;
  /** トーク一覧 */
  talkList: {
    initialized: boolean;
    data?: TalkListResponse;
  };
  /** メッセージ一覧 */
  messages: Record<
    string,
    | {
        initialized: boolean;
        data: InitMessageResponse;
      }
    | undefined
  >;
};

const initialState: TalkState = {
  unreadMsgCnt: 0,
  talkList: {
    initialized: false,
  },
  messages: {},
};

/**
 * slice
 */
export const talkSlice = createSlice({
  name: "talk",
  initialState,
  reducers: {
    /**
     * 未読メッセージ件数更新
     * @param state
     * @param action
     */
    updateUnreadMsgCntAction: (state, action: PayloadAction<number>) => {
      state.unreadMsgCnt = action.payload;
    },
    /**
     * 未読メッセージ件数更新
     * @param state
     */
    increaseUnreadMsgCntAction: state => {
      state.unreadMsgCnt += 1;
    },
    /**
     * トーク一覧設定
     * @param state
     * @param action
     */
    updateTalkListAction: (state, action: PayloadAction<TalkListResponse>) => {
      state.talkList.initialized = true;
      state.talkList.data = action.payload;
    },
    /**
     * メッセージ一覧初期化
     * @param state
     * @param action
     */
    initMessageListAction: (state, action: PayloadAction<{ talkId: string; data: InitMessageResponse }>) => {
      const { talkId, data } = action.payload;
      state.messages[talkId] = {
        initialized: true,
        data,
      };
    },
    /**
     * メッセージ一覧にリスト追加
     * @param state
     * @param action
     */
    prependMessageListAction: (state, action: PayloadAction<{ talkId: string; data: ListPrevMessageResponse }>) => {
      const { talkId, data } = action.payload;
      if (!state.messages[talkId]) return;
      state.messages[talkId]!.data.messageList = [...data, ...state.messages[talkId]!.data.messageList];
    },
    /**
     * 最終閲覧メッセージの更新
     * @param state
     * @param action
     */
    updateLastViewMessageAction: (state, action: PayloadAction<{ talkId: string; messageId: string }>) => {
      const { talkId, messageId } = action.payload;
      // 最終閲覧メッセージの更新
      if (state.messages[talkId]) {
        state.messages[talkId]!.data.lastViewMessageId = messageId;
      }
      // 未読件数の更新
      if (state.talkList.initialized) {
        const index = state.talkList.data?.findIndex(o => o.talkId === talkId);
        if (index !== undefined && index !== -1) {
          state.talkList.data![index].unreadCnt = 0;
        }
      }
    },
    /**
     * メッセージ追加
     * @param state
     * @param action
     */
    newMessageAction: (state, action: PayloadAction<newMessageData>) => {
      const { talkItem, messageItem } = action.payload;
      // トーク再設定
      if (state.talkList.initialized) {
        const index = state.talkList.data?.findIndex(o => o.talkId === talkItem.talkId);
        if (index !== undefined && index !== -1) {
          state.talkList.data![index] = talkItem;
        }
      }
      // メッセージの再設定
      const { talkId } = talkItem;
      if (state.messages[talkId]?.initialized) {
        state.messages[talkId]!.data.messageList = [...state.messages[talkId]!.data.messageList, messageItem];
      }
    },
  },
});

/**
 * action
 */
export const talkAction = talkSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const talkSelector = (state: RootState) => state.talk;
