import { SmartSelect } from "framework7/types";

/**
 * smartSelect default parameters
 */
export const SMART_SELECT_DEFAULT_PARAMS: SmartSelect.Parameters = {
  openIn: "popover",
  closeOnSelect: true,
  setValueText: false,
};
