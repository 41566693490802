import { useFormik } from "formik";
import { Navbar, NavLeft, Link, NavTitle, NavRight, Page } from "framework7-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { siteProfileSelector } from "core/slice/site/profile";
import { goBack } from "core/usecase/global";
import { updateProfile, profileInitialize } from "core/usecase/site/profile";

import { FormikList, FormikListInput } from "ui/widget/formik";

type Shape = { address?: string };

/**
 * 居住地編集画面
 * @returns AddressEditor
 */
export const AddressEditorPage: React.FC = () => {
  const { address } = useSelector(siteProfileSelector).profile;

  const formik = useFormik<Shape>({
    initialValues: {
      address: address || "",
    },
    validateOnChange: true,
    validationSchema: yup.object().shape({
      address: yup.string().max(200),
    }),
    onSubmit(value) {
      updateProfile("address", value.address).then(success => {
        if (success) goBack("/profile/base");
      });
    },
  });

  const { setValues } = formik;

  /**
   * データ再設定
   */
  useEffect(() => {
    setValues({ address });
  }, [address, setValues]);

  return (
    <Page onPageBeforeIn={profileInitialize}>
      <Navbar>
        <NavLeft backLink backLinkUrl="/profile/base" backLinkForce />
        <NavTitle>所在地</NavTitle>
        <NavRight>
          <Link href={false} onClick={formik.submitForm}>
            确定
          </Link>
        </NavRight>
      </Navbar>

      <FormikList formik={formik}>
        <FormikListInput type="text" name="address" label="所在地" maxlength={200} info="200个字符以内" />
      </FormikList>
    </Page>
  );
};
