import { NavRight, Navbar, Page, PageContent } from "framework7-react";
import { isEmpty } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "react-use";

import { siteTopSelector } from "core/slice/site/top";
import { initializeGirls, getGirlsOfNextPage } from "core/usecase/site/top";

import { GirlList } from "ui/component/site/top/girl-list";
import { SearchCondition } from "ui/component/site/top/search-condition";
import { SearchConditionPopup } from "ui/component/site/top/search-condition-popup";
import { Divider } from "ui/widget/divider";
import { Icon } from "ui/widget/icon";
import { IconButton } from "ui/widget/icon-button";
import { StyledText } from "ui/widget/styled-text";

/**
 * TopPage
 * @returns TopPage
 */
export const TopPage: React.FC = () => {
  const { condition, page } = useSelector(siteTopSelector);
  const allowInfinite = useRef(true);
  const [loadMore, setLoadMore] = useState(true);
  const [searchOpened, setSearchOpened] = useState(false);

  // 検索中
  const isSearching = useMemo(() => !isEmpty(condition), [condition]);

  useEffectOnce(() => {
    initializeGirls().then(setLoadMore);
  });

  /**
   * handleInfinite
   */
  const handleInfinite = () => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    getGirlsOfNextPage(page + 1, condition)
      .then(setLoadMore)
      .finally(() => {
        allowInfinite.current = true;
      });
  };

  return (
    <Page pageContent={false}>
      <Navbar title="发现">
        <NavRight>
          <IconButton icon={<Icon name="search" />} onClick={() => setSearchOpened(true)} />
        </NavRight>
      </Navbar>
      <PageContent infinite={loadMore} onInfinite={handleInfinite}>
        {isSearching && <SearchCondition onConditionChangeClick={() => setSearchOpened(true)} />}
        <Divider backRhombus label={<StyledText color="gold">{isSearching ? "检索结果" : "出勤女孩"}</StyledText>} />
        <GirlList />

        {/* 検索条件編集用ポップアップ */}
        <SearchConditionPopup opened={searchOpened} onPopupClosed={() => setSearchOpened(false)} />
      </PageContent>
    </Page>
  );
};
