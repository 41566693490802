import { Navbar, Page } from "framework7-react";
import React, { useEffect } from "react";

import { initializeLatestReserveList } from "core/usecase/site/order";

import { LatestOrder } from "ui/component/site/order/latest-order";
import { ReserveCondition } from "ui/component/site/order/reserve-condition";

/**
 * OrderPage
 * @returns OrderPage
 */
export const OrderPage: React.FC = () => {
  useEffect(() => {
    initializeLatestReserveList();
  }, []);

  return (
    <Page>
      <Navbar title="订单" />
      <ReserveCondition />
      <LatestOrder />
    </Page>
  );
};
