import { dispatch, store } from "store";

import { ListLatestReserveResponse, ListReserveResponse } from "core/model/girl/order";
import { OrderAction } from "core/slice/girl/order";
import { showPageSpinner, hidePageSpinner } from "core/usecase/global";
import Api, { isSuccess } from "core/utils/api";

/**
 * 直近の予約一覧の初期化
 * @param force
 */
export const initializeLatestReserveList = async (force = false) => {
  if (!force && store.getState().order.latestReserveList.initialized) return;
  showPageSpinner();
  try {
    const res = await Api.get<ListLatestReserveResponse>("/user/reserve/latest");
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(OrderAction.initializeLatestReserveList(res.body));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};

/**
 * 予約一覧の初期化
 * @param force
 */
export const initializeReserveList = async (force = false) => {
  if (!force && store.getState().order.reserveList.initialized) return;
  showPageSpinner();
  try {
    const res = await Api.get<ListReserveResponse>("/user/reserve/list", {
      params: {
        page: 1,
      },
    });
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(OrderAction.initializeReserveListAction(res.body));
    }
  } catch {
    // 処理なし
  } finally {
    hidePageSpinner();
  }
};

/**
 * 次のページの予約一覧を取得する
 * @returns hasMore ? true : false
 */
export const getReserveListOfNextPage = async () => {
  try {
    const res = await Api.get<ListReserveResponse>("/user/reserve/list", {
      params: {
        page: store.getState().order.reserveList.page,
      },
    });
    // 成功の場合
    if (isSuccess(res) && res.body) {
      dispatch(OrderAction.appendReserveListAction(res.body));
    }
  } catch {
    // 処理なし
  }
};
