import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { RootState } from "store";

import { WalletLogResponse, WalletResponse } from "core/model/site/profile/wallet";

import { WalletTargetType } from "global/enum/profile-site";

/**
 * ウォレット履歴検索条件
 */
export type WalletLogQueryCondtion = {
  /** 取引種別 */
  targetType: WalletTargetType | "ALL";
};

/**
 * state
 */
export type SiteProfileWalletState = {
  /** wallet */
  wallet: {
    /** wallet initialized */
    initialized: boolean;
    /** wallet */
    data?: WalletResponse;
    /** 更新日時 */
    updateDatetime?: string;
  };
  /** 取引履歴 */
  walletLog: {
    /** page */
    page: number;
    /** 取引履歴一覧 */
    data?: WalletLogResponse;
    /** 検索条件 */
    query: WalletLogQueryCondtion;
  };
};

const initialState: SiteProfileWalletState = {
  wallet: {
    initialized: false,
  },
  walletLog: {
    page: 1,
    query: {
      targetType: "ALL",
    },
  },
};

/**
 * slice
 */
export const siteProfileWalletSlice = createSlice({
  name: "site/profile/wallet",
  initialState,
  reducers: {
    /**
     * walletM情報更新
     * @param state
     * @param action
     */
    initializeWalletAction: (state, action: PayloadAction<WalletResponse>) => {
      state.wallet.initialized = true;
      state.wallet.data = action.payload;
      state.wallet.updateDatetime = dayjs().format("YYYY-MM-DD HH:mm:ss");
    },
    /**
     * 取引履歴ページ更新
     * @param state
     */
    increaseWalletLogPageAction: state => {
      state.walletLog.page += 1;
    },
    /**
     * 取引履歴一覧初期化
     * @param state
     * @param action
     */
    initializeWalletLogAction: (state, action: PayloadAction<WalletLogResponse>) => {
      state.walletLog.data = action.payload;
    },
    /**
     * 取引履歴一覧追加
     * @param state
     * @param action
     */
    appendWalletLogAction: (state, action: PayloadAction<WalletLogResponse>) => {
      state.walletLog.data = (state.walletLog.data || []).concat(action.payload);
    },
    /**
     * 検索条件更新
     * @param state
     * @param action
     */
    updateQueryConditionAction: (state, action: PayloadAction<WalletLogQueryCondtion>) => {
      state.walletLog.query = action.payload;
    },
  },
});

/**
 * action
 */
export const siteProfileWalletAction = siteProfileWalletSlice.actions;

/**
 * selector
 * @param state RootState
 * @returns selector
 */
export const siteProfileWalletSelector = (state: RootState) => state["site/profile/wallet"];
